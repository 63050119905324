import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useTranslation } from "react-i18next";

const FacebookLoginButton = ({ onLogin }) => {
  const { t } = useTranslation();
  const responseFacebook = (response) => {
    // Call the onLogin callback with the access token
    if (response.accessToken) {
      onLogin(response.accessToken);
    }
  };

  return (
    <FacebookLogin
      appId="1161359504850366"
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      render={(renderProps) => (
        <button className="pz-button__facebook" onClick={renderProps.onClick}>
          {t("register.facebook_button")}
        </button>
      )}
    />
  );
};

export default FacebookLoginButton;
