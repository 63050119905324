const reactions_en = {
  unlogged: "Sign up to React",
  reactions: {
    like: "like",
    dislike: "dislike",
    quality: "quality",
    useful: "useful",
    efficient: "efficient",
  },
};
export default reactions_en;
