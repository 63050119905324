import React from "react";
import { Button, Image } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEnvelope,
  faLink,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { ActiveAccountModal, Activities } from "apps/profile/components";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import ShareButton from "../sharebutton";
import { useTranslation } from "react-i18next";

const ProfileCard = ({ user, getUserProfile, onClickToEdit }) => {
  const [activeAccountModalVisible, setActiveAccountModalVisible] =
    React.useState(false);
  const { t } = useTranslation();

  const closeModal = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setActiveAccountModalVisible(false);
  };

  if (user?.profile) {
    return (
      <section className="pz-profile-card">
        <section className="pz-profile-card__photo">
          {user?.profile?.picture ? (
            <Image
              className="pz-profile-card__image"
              src={user?.profile?.picture}
            />
          ) : (
            <Image
              className="pz-profile-card__image"
              name="icons/male-avatar.svg"
            />
          )}
        </section>
        <section className="pz-profile-card__header">
          <h5>{user?.slug}</h5>
          <h1 className="pz-profile-card__header--title">
            <span>{user?.full_name}</span>
            {typeof onClickToEdit === "function" ? (
              <FontAwesomeIcon
                className="edit"
                icon={faEdit}
                onClick={onClickToEdit}
              />
            ) : null}
          </h1>
        </section>
        {user?.profile?.introduction ? (
          <>
            <section className="pz-profile-card__body">
              <section>
                <h4 className="bold">{t("profile.experience.about")}</h4>
                <p style={{ wordBreak: "break-word" }}>
                  {user?.profile?.introduction}
                </p>
              </section>
            </section>
            <section className="pz-profile-card__links">
              <h5 className="pz-profile-card__link">
                <FontAwesomeIcon icon={faLink} />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={user?.profile?.website}
                >
                  {user?.profile?.website}
                </a>
              </h5>
              <h5 className="pz-profile-card__link">
                <FontAwesomeIcon icon={faLocationArrow} />
                {user?.profile?.location}
              </h5>
              <h5 className="pz-profile-card__link">
                <ShareButton />
              </h5>
            </section>
          </>
        ) : null}
        <section className="pz-profile-card__detail">
          <h3 className="bold">{t("profile.experience.title")}</h3>
          <section className="pz-profile-card__experiences">
            <article className="pz-profile-card__experience">
              {user?.profile?.apprentice_exp >=
              CONSTANTS.BADGES_GOALS.STUDENT.BRONCE ? (
                <Image
                  name={
                    user?.profile?.apprentice_exp >=
                    CONSTANTS.BADGES_GOALS.STUDENT.SILVER
                      ? user?.profile?.apprentice_exp >=
                        CONSTANTS.BADGES_GOALS.STUDENT.GOLD
                        ? "ilustrations/medals/ORO.svg"
                        : "ilustrations/medals/PLATA.svg"
                      : "ilustrations/medals/BRONCE.svg"
                  }
                  title={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.STUDENT.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.STUDENT.GOLD
                        ? "Maestro"
                        : "Experto"
                      : "Talento Emergente"
                  }
                  className="pz-profile-card__experience--badge"
                />
              ) : null}
              <h1 className="pz-profile-card__experience--number">
                {user?.profile?.apprentice_exp || 0}
              </h1>
              <h5 className="bold">{t("profile.experience.pts_student")}</h5>
            </article>
            <article className="pz-profile-card__experience">
              {user?.profile?.interaction_exp >=
              CONSTANTS.BADGES_GOALS.INTERACTION.BRONCE ? (
                <Image
                  name={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.INTERACTION.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.INTERACTION.GOLD
                        ? "ilustrations/medals/ORO.svg"
                        : "ilustrations/medals/PLATA.svg"
                      : "ilustrations/medals/BRONCE.svg"
                  }
                  title={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.INTERACTION.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.INTERACTION.GOLD
                        ? "Maestro"
                        : "Experto"
                      : "Talento Emergente"
                  }
                  className="pz-profile-card__experience--badge"
                />
              ) : null}
              <h1 className="pz-profile-card__experience--number">
                {user?.profile?.interaction_exp || 0}
              </h1>
              <h5 className="bold">{t("profile.experience.pts_panzofi")}</h5>
            </article>
            <article className="pz-profile-card__experience">
              {user?.profile?.professor_exp >=
              CONSTANTS.BADGES_GOALS.PROFESSOR.BRONCE ? (
                <Image
                  name={
                    user?.profile?.professor_exp >=
                    CONSTANTS.BADGES_GOALS.PROFESSOR.SILVER
                      ? user?.profile?.professor_exp >=
                        CONSTANTS.BADGES_GOALS.PROFESSOR.GOLD
                        ? "ilustrations/medals/ORO.svg"
                        : "ilustrations/medals/PLATA.svg"
                      : "ilustrations/medals/BRONCE.svg"
                  }
                  title={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.PROFESSOR.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.PROFESSOR.GOLD
                        ? "Maestro"
                        : "Experto"
                      : "Talento Emergente"
                  }
                  className="pz-profile-card__experience--badge"
                />
              ) : null}
              <h1 className="pz-profile-card__experience--number">
                {user?.profile?.professor_exp || 0}
              </h1>
              <h5 className="bold">{t("profile.experience.pts_profesor")}</h5>
            </article>
          </section>
          {getUserProfile ? (
            <Activities
              quest={user.questionare}
              user_slug={user.slug}
              getUserProfile={getUserProfile}
            />
          ) : null}
        </section>
      </section>
    );
  }

  if (user?.employee) {
    return (
      <section className="pz-profile-card">
        <section className="pz-profile-card__photo">
          {user?.employee?.picture ? (
            <Image
              className="pz-profile-card__image"
              src={user?.employee?.picture}
            />
          ) : (
            <Image
              className="pz-profile-card__image"
              name="icons/tentacle.svg"
            />
          )}
        </section>
        <section className="pz-profile-card__header">
          <h5>{user?.slug}</h5>
          <h1 className="bold">{user?.full_name}</h1>
        </section>

        <section className="pz-profile-card__detail">
          <h3 className="bold">Mi organización</h3>
          <section className="pz-profile-card__experiences">
            <Link
              to={CONSTANTS.URLS.PROFILE.USER_DETAIL(
                user?.employee?.organization?.slug
              )}
              className="pz-profile-card__experience"
            >
              {user?.employee?.organization?.logo ? (
                <Image
                  className="pz-profile-card__experience--image"
                  src={user?.employee?.organization?.logo}
                />
              ) : (
                <Image
                  className="pz-profile-card__experience--image"
                  name="icons/tentacle.svg"
                />
              )}
              <h5 className="bold">{user?.employee?.organization?.slug}</h5>
            </Link>
          </section>
        </section>
      </section>
    );
  }

  if (user?.organization) {
    return (
      <section className="pz-profile-card">
        <section className="pz-profile-card__photo">
          {user?.organization?.logo ? (
            <Image
              className="pz-profile-card__image"
              src={user?.organization?.logo}
            />
          ) : (
            <Image
              className="pz-profile-card__image"
              name="icons/tentacle.svg"
            />
          )}
        </section>
        <section className="pz-profile-card__header">
          <h1 className="bold">{user?.full_name}</h1>
        </section>

        <section className="pz-profile-card__body">
          <section>
            {user?.organization?.is_active ? (
              <p>
                Tu cuenta se encuentra <span className="bold">activa</span>,
                puedes agregar empleados y crear cursos que seran unicamente de
                tu organización
              </p>
            ) : (
              <>
                <p>
                  Tu cuenta <span className="bold">no esta activa</span> aún,
                  contacta a nuestro equipo de cuentas al correo{" "}
                  <a className="bold" href="mailto:accounts@panzofi.com">
                    accounts@panzofi.com
                  </a>{" "}
                  para acordar los terminos de pago, luego podras activar tu
                  cuenta aqui mismo.
                </p>
                <Button
                  primary
                  thin
                  onClick={() => {
                    setActiveAccountModalVisible(true);
                  }}
                >
                  Activar cuenta
                </Button>
              </>
            )}
          </section>
        </section>
        <section className="pz-profile-card__links">
          <h5 className="pz-profile-card__link">
            <FontAwesomeIcon icon={faLink} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={user?.organization?.website}
            >
              {user?.organization?.website}
            </a>
          </h5>
          <h5 className="pz-profile-card__link">
            <FontAwesomeIcon icon={faEnvelope} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${user?.email}`}
            >
              {user?.email}
            </a>
          </h5>
        </section>

        <section className="pz-profile-card__detail"></section>

        <ActiveAccountModal
          is_visible={activeAccountModalVisible}
          closeModal={closeModal}
        />
      </section>
    );
  }

  return null;
};

export default ProfileCard;
