import React from "react";
import eases from "eases";
import SlideToggle from "react-slide-toggle";
import { Button, Form, InputField, PasswordField } from "components/ui";
import { Link, withRouter } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";
import { FacebookLoginButton } from "apps/auth/components";

const NavbarLoginForm = ({ title, auth, history }) => {
  const { t } = useTranslation();

  const handleFacebookLogin = (accessToken) => {
    const data = {
      access_token: accessToken,
    };
    auth.facebook_login(data);
  };

  const handleSubmit = (data) => {
    auth.login(data);
  };

  const renderLoginForm = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__form">
      <Button
        primary
        ghost={toggleState === "EXPANDED" || toggleState === "EXPANDING"}
        onClick={onToggle}
      >
        {title}
      </Button>
      <section
        className="pz-navbar__form-container"
        ref={setCollapsibleElement}
      >
        <section className="pz-navbar__form--content">
          <Form onSubmit={handleSubmit}>
            {({ onChange }) => (
              <React.Fragment>
                <InputField
                  label={t("login.user")}
                  name="username"
                  onChange={onChange}
                />
                <PasswordField
                  label={t("login.password")}
                  name="password"
                  onChange={onChange}
                />
                <Button type="submit" primary thin>
                  {t("login.button_login")}
                </Button>
                <FacebookLoginButton onLogin={handleFacebookLogin} />
                <h5 className="text-center">{t("login.or")}</h5>
                <Button
                  type="button"
                  primary
                  ghost
                  thin
                  onClick={() => history.push(CONSTANTS.URLS.AUTH.SIGNUP)}
                >
                  {t("login.button_signup")}
                </Button>
                <h5 className="text-center">
                  <Link to={CONSTANTS.URLS.AUTH.FORGOT_PASSWORD}>
                    {t("login.label_forgot")}
                  </Link>
                </h5>
              </React.Fragment>
            )}
          </Form>
        </section>
      </section>
    </section>
  );

  return (
    <SlideToggle
      duration={500}
      easeCollapse={eases["quintOut"]}
      easeExpand={eases["quintOut"]}
      render={renderLoginForm}
      bestPerformance
      collapsed
    />
  );
};

export default connectReduxReducers(withRouter(NavbarLoginForm), "auth");
