import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import { CONSTANTS } from "shared/constants";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConfirmationDeleteAccountModal = ({
  is_visible,
  closeModal,
  auth,
  history,
}) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    deleteAccount();
  };

  const deleteAccount = () => {
    const deleteCourseCallback = ({ status }) => {
      if (status === 204) history.push(CONSTANTS.URLS.LANDING.HOME);
    };
    const data = {
      id: auth.user.id,
    };
    auth.deleteAccount(data, deleteCourseCallback);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h2>{t("others.settings.delete.confirmation")}</h2>

      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <Button thin onClick={handleDelete}>
          {t("others.settings.delete.confirmation_button")}
        </Button>
      </section>
    </Modal>
  );
};

export default withRouter(
  connectReduxReducers(ConfirmationDeleteAccountModal, "auth")
);
