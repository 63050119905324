import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { Page } from "components/common";
import { useTranslation } from "react-i18next";

const LandingConsultantPage = () => {
  const { t } = useTranslation();
  return (
    <main className="pz-landing">
      <Navbar />

      <Page>
        <section className="pz-landing__cover">
          <section className="pz-landing__cover--content animate__animated animate__bounceIn">
            <Image
              name="ilustrations/consultan-cover.svg"
              className="pz-landing__cover--image"
            />
            <h1 className="text-center bold">{t("landing.advisory.title")}</h1>
            <p>{t("landing.advisory.subtitle")}</p>
            <Button primary>
              <a
                target="_top"
                rel="noopener noreferrer"
                href={"https://share.hsforms.com/1skQPMQyLQ-OEUIc6OhVWWQbptug"}
              >
                {t("landing.advisory.button")}
              </a>
            </Button>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.service.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/sales.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item1.title")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/course.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item2.title")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/validation.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item3.title")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/promote2.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item4.title")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/automation.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item5.title")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/quality.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item6.title")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/audience.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item7.title")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/success.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <p>{t("landing.advisory.service.item8.title")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.starter.title")}</h2>
          </section>
          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/target.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.starter.item1.title")}
                  </h4>
                  <p>{t("landing.advisory.starter.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/time.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.starter.item2.title")}
                  </h4>
                  <p>
                    {t("landing.advisory.starter.item2.subtitle")} <br></br>
                    <br></br> {t("landing.advisory.starter.item2.subtitle2")}
                  </p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/vacant.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.starter.item3.title")}
                  </h4>
                  <p>{t("landing.advisory.starter.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/calendar.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.starter.item4.title")}
                  </h4>
                  <p>
                    {t("landing.advisory.starter.item4.subtitle")}
                    <br></br> <br></br>
                    {t("landing.advisory.starter.item4.subtitle2")}
                  </p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.level.title")}</h2>
            <p>{t("landing.advisory.level.subtitle")}</p>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.advance.title")}</h2>
          </section>
          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/target.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.advance.item1.title")}
                  </h4>
                  <p>{t("landing.advisory.advance.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/time.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.advance.item2.title")}
                  </h4>
                  <p>
                    {t("landing.advisory.advance.item2.subtitle")} <br></br>
                    <br></br> {t("landing.advisory.advance.item2.subtitle2")}
                  </p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/vacant.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.advance.item3.title")}
                  </h4>
                  <p>{t("landing.advisory.advance.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/calendar.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.advance.item4.title")}
                  </h4>
                  <p>
                    {t("landing.advisory.advance.item4.subtitle")}
                    <br></br> <br></br>
                    {t("landing.advisory.advance.item4.subtitle2")}
                  </p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.plans.title")}</h2>
            <p>{t("landing.advisory.plans.subtitle")}</p>
          </section>
          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--seconditem">
                <Image
                  name="ilustrations/pricing1-consultant.svg"
                  className="pz-landing__how-works--item--thirdimage"
                />
              </article>
              <Button primary ghost thin>
                <a
                  target="_top"
                  rel="noopener noreferrer"
                  href={
                    "https://share.hsforms.com/1sMnlioX0Sh294ul0fgK7zAbptug"
                  }
                >
                  {t("landing.advisory.plans.item1.button")}
                </a>
              </Button>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--seconditem">
                <Image
                  name="ilustrations/pricing2-consultant.svg"
                  className="pz-landing__how-works--item--thirdimage"
                />
              </article>
              <Button primary ghost thin>
                <a
                  target="_top"
                  rel="noopener noreferrer"
                  href={
                    "https://share.hsforms.com/164LncGD1RH6X3c7Dk-lc8Abptug"
                  }
                >
                  {t("landing.advisory.plans.item2.button")}
                </a>
              </Button>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.how_works.title")}</h2>
            <p>{t("landing.advisory.how_works.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/planning.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.how_works.item1.title")}
                  </h4>
                  <p>{t("landing.advisory.how_works.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/debit-card.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.how_works.item2.title")}
                  </h4>
                  <p>{t("landing.advisory.how_works.item2.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/switch.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.how_works.item3.title")}
                  </h4>
                  <p>{t("landing.advisory.how_works.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/start.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.how_works.item4.title")}
                  </h4>
                  <p>{t("landing.advisory.how_works.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.advisory.cases.title")}</h2>
            <p>{t("landing.advisory.cases.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/creator-reference2.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.cases.item1.title")}
                  </h4>
                  <p>
                    {t("landing.advisory.cases.item1.subtitle")} <br></br>
                    <br></br>
                    {t("landing.advisory.cases.item1.subtitle2")}
                  </p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/creator-reference1.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.advisory.cases.item2.title")}
                  </h4>
                  <p>
                    {t("landing.advisory.cases.item2.subtitle")} <br></br>
                    <br></br>
                    {t("landing.advisory.cases.item2.subtitle2")}
                  </p>{" "}
                </section>
              </article>
            </section>
          </section>
        </section>
      </Page>

      <Footer />
    </main>
  );
};

export default LandingConsultantPage;
