import React from 'react';
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Page } from "components/common";


const LandingBusinessPartnersPage = () => {

    return (
        <main className='pz-landing'>

            <Navbar />

            <Page>
                <section className='pz-landing__cover'>
                    <section className='pz-landing__cover--content animate__animated animate__bounceIn'>
                        <Image name='ilustrations/group.svg' className='pz-landing__cover--image' />
                        <h1 className='text-center bold'>Estamos cambiando la forma de capacitar en línea</h1>
                        <p className='bold'>Se uno de nuestros alineados de negocio</p>
                        <Button primary><a href='https://forms.gle/rXCzYfHo2HX6eSnF8' className='pz-landing__button'>Regístrate como socio<FontAwesomeIcon icon={faArrowRight} className='pz-landing__button--icon' /></a></Button>
                    </section>
                </section>

                <section className='pz-landing__section pz-landing__services-section'>
                    <section className='pz-landing__services'>
                        <article className='pz-landing__services--service'>
                            <Image name='ilustrations/partner_agreement.svg' className='pz-landing__services--service--image' /> 
                            <section className='pz-landing__services--service--content'>
                                <h3 className='bold'>Únete al ecosistema de socios </h3>
                                <p>Te invitamos a trabajar de la mano con Panzofi para capacitar a las empresas del mañana.</p>
                            </section>
                        </article>
                        <article className='pz-landing__services--service'>
                            <Image name='ilustrations/stand_out.svg' className='pz-landing__services--service--image' />
                            <section className='pz-landing__services--service--content'>
                                <h3 className='bold'>Revoluciona a las empresas</h3>
                                <p>Sabemos que eres el mejor en lo que haces, por eso, queremos que seas parte del equipo para ofrecer cursos exclusivos a organizaciones.</p>
                            </section>
                        </article>
                        <article className='pz-landing__services--service'>
                            <Image name='ilustrations/benefits.svg' className='pz-landing__services--service--image' />
                            <section className='pz-landing__services--service--content'>
                                <h3 className='bold'>Obtén benificios</h3>
                                <p>Crea una lección en diferentes formatos y gana dinero enseñando a empresas con el tema de tu especialidad.</p>
                            </section>
                        </article>                    
                    </section>
                </section>

                <section className='pz-landing__section'>
                    <section className='text-center'>
                        <h2 className='bold'>¿Cómo funciona?</h2>
                        <p>Es muy fácil e intuitivo sin importar la edad</p>
                    </section>

                    <section className='pz-landing__how-works'>
                        <section className='pz-landing__how-works--column'>
                            <article className='pz-landing__how-works--item column'>
                                <Image name='icons/navigate.svg' className='pz-landing__how-works--item--image'/>
                                <section className='pz-landing__how-works--item--content'>
                                    <h4 className='bold'>Registrar</h4>
                                    <p>Resgístrate como socio</p>
                                </section>
                            </article>
                            <article className='pz-landing__how-works--item column'>
                                <Image name='icons/contact.svg' className='pz-landing__how-works--item--image'/>
                                <section className='pz-landing__how-works--item--content'>
                                    <h4 className='bold'>Contactar</h4>
                                    <p>Un asesor te estará contactando para continuar el proceso</p>
                                </section>
                            </article>
                            <article className='pz-landing__how-works--item column'>
                                <Image name='icons/send.svg' className='pz-landing__how-works--item--image'/>
                                <section className='pz-landing__how-works--item--content'>
                                    <h4 className='bold'>Enviar</h4>
                                    <p>Envíanos recursos teóricos y prácticos con altos estándares</p>
                                </section>
                            </article>
                            <article className='pz-landing__how-works--item column'>
                                <Image name='icons/promote.svg' className='pz-landing__how-works--item--image'/>
                                <section className='pz-landing__how-works--item--content'>
                                    <h4 className='bold'>Promocionar</h4>
                                    <p>Promociona tu lección en redes sociales y con tu comunidad</p>
                                </section>
                            </article>
                        </section>
                    </section>
                </section>
            </Page>

            <Footer />
        </main>
    );
};

export default LandingBusinessPartnersPage;