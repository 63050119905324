const others_en = {
  share_button: "Share",
  share_modal: {
    title: "Share this content",
  },
  input_valid: {
    empty: "The text is empty",
    special: "The text contains special characters",
    spaces: "The text should not contain spaces",
    password: "It must have uppercase, lowercase, and at least 8 characters",
    youtube: "This is not a valid YouTube URL.",
    youtube_or_docs: "This is not a valid YouTube or Google Docs URL.",
  },
  forgot_password: {
    title: "Forgot Your Password?",
    subtitle:
      "Confirm your username and your email associated with your account to send you a security code so you can change your password.",
    input: "Email",
    send: "Send",
    login: "Back to login",
  },
  cookies: {
    banner: {
      title: "Our website uses cookies to enhance your experience, check our ",
      subtitle: "Privacy Policies",
      allow: "Allow All",
      customize: "Customize",
    },
    modal: {
      title: "Cookie Settings",
      subtitle:
        "Select the cookies you allow us to use. For more information, check our",
      link: "privacy policies",
      obligatory: "Necessary (required)",
      functionality: "Cookies that allow us to improve functionality",
      relevant: "Cookies that allow us to provide relevant advertising",
      performance: "Cookies that allow us to improve performance",
      button: "Accept",
    },
  },
  settings: {
    account: {
      title: "Account Information",
      subtitle: "Here you will find the details of your account",
    },
    profile: {
      title: "Profile Information",
      subtitle: "",
    },
    password: {
      title: "Change your password",
      subtitle: "",
      password: "New Password",
      password2: "Confirm your new Password",
      button: "Change my password",
    },
    delete: {
      title: "Here you can delete your account if you wish.",
      button: "Delete my account",
      confirmation: "Delete my account?",
      confirmation_button: "Delete",
    },
  },
  trivia: {
    modal: {
      start_btn: "Start trivia",
      close_btn: "Close trivia",
      link_input: "If you don't know the answer to this question,",
      link: "visit this content",
      finished_title:
        "Congratulations on successfully completing the Panzofi trivia!",
      finished_desc: "Click the submit button to receive your results",
      send_btn: "Submit trivia",
      results_title: "Here are your results",
      results_pass:
        "Congratulations, you have passed! 10 student points have been added to your profile.",
      results_fail:
        "You haven't obtained enough points, but remember you can try again.",
      results: "You scored {{score}} points",
    },
  },
  search: {
    not_found: "Sorry, we couldn't find results for",
    recommendation: "You might be interested in our recommendations",
    lessons: {
      title: "Lessons",
      subtitle: "Lessons found",
    },
    posts: {
      title: "Educlips",
      subtitle: "Educlips found",
    },
    profiles: {
      title: "Profiles",
      subtitle: "Profiles found",
    },
    forums: {
      title: "Forums",
      subtitle: "Forums found",
    },
  },
};
export default others_en;
