const courses_es = {
  category_entrepreneurship: "Emprendimiento",
  category_lifehacks: "Lifehacks",
  category_businessstrategy: "Estrategia de negocios",
  category_finance: "Finanzas",
  categpry_psychology: "Psicología",
  recent: "Panzo contenido reciente",
  content: "Panzo contenido",
  more_courses: "Mostrar más contenido",
  no_more_courses: "No hay más contenido",
  bar: {
    lessons: "Lecciones",
    shorts: "Edu clips (Beta)",
  },
};
export default courses_es;
