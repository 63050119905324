const categories_en = {
  "ciencias-sociales": {
    name: "Social sciences",
    sub_categories: {
      antropologia: {
        name: "Anthropology",
        sub_categories: {},
      },
      "administracion-de-empresas": {
        name: "Business Administration",
        sub_categories: {},
      },
      historia: {
        name: "History",
        sub_categories: {},
      },
      psicologia: {
        name: "Psychology",
        sub_categories: {},
      },
      "ciencias-politicas": {
        name: "Political Sciences",
        sub_categories: {},
      },
      "ciencias-economicas": {
        name: "Economic Sciences",
        sub_categories: {},
      },
      contraterrorismo: {
        name: "Counterterrorism",
        sub_categories: {},
      },
      "desarrollo-infantil": {
        name: "Child development",
        sub_categories: {},
      },
      "desarrollo-internacional": {
        name: "International development",
        sub_categories: {},
      },
      educacion: {
        name: "Education",
        sub_categories: {},
      },
      "educacion-mas-alta": {
        name: "Higher education",
        sub_categories: {},
      },
      "estudios-de-genero": {
        name: "Gender studies",
        sub_categories: {},
      },
      felicidad: {
        name: "Happiness",
        sub_categories: {},
      },
      "formacion-del-profesorado": {
        name: "Teacher training",
        sub_categories: {},
      },
      "gestion-de-riesgos": {
        name: "Risk management",
        sub_categories: {},
      },
      globalizacion: {
        name: "Globalization",
        sub_categories: {},
      },
      inmigracion: {
        name: "Immigration",
        sub_categories: {},
      },
      k12: {
        name: "K12",
        sub_categories: {},
      },
      macroeconomica: {
        name: "Macroeconomic",
        sub_categories: {},
      },
      microeconomia: {
        name: "Microeconomics",
        sub_categories: {},
      },
      sociologia: {
        name: "Sociology",
        sub_categories: {},
      },
      "tecnologia-educacional": {
        name: "Educational Technology",
        sub_categories: {},
      },
      "teoria-de-juego": {
        name: "Game theory",
        sub_categories: {},
      },
      geografia: {
        name: "Geography",
        sub_categories: {},
      },
      demografia: {
        name: "Demography",
        sub_categories: {},
      },
      comunicacion: {
        name: "Communication",
        sub_categories: {},
      },
      "ciencias-juridicas": {
        name: "Legal Sciences",
        sub_categories: {},
      },
    },
  },
  salud: {
    name: "Health",
    sub_categories: {
      "administracion-de-salubridad": {
        name: "Health administration",
        sub_categories: {},
      },
      "anatomia-humana": {
        name: "Human anatomy",
        sub_categories: {},
      },
      "atencion-al-paciente": {
        name: "Patient care",
        sub_categories: {},
      },
      enfermeria: {
        name: "Nursing",
        sub_categories: {},
      },
      "informatica-de-salud": {
        name: "Health informatics",
        sub_categories: {},
      },
      "la-seguridad": {
        name: "Safety",
        sub_categories: {},
      },
      medicina: {
        name: "Medicine",
        sub_categories: {},
      },
      "medicina-veterinaria": {
        name: "Veterinary Medicine",
        sub_categories: {},
      },
      nutricion: {
        name: "Nutrition",
        sub_categories: {},
      },
      psicologia: {
        name: "Psychology",
        sub_categories: {},
      },
      "salud-publica": {
        name: "Public health",
        sub_categories: {},
      },
      fisioterapia: {
        name: "Physiotherapy",
        sub_categories: {},
      },
    },
  },
  "administracion-de-empresas": {
    name: "Business Administration",
    sub_categories: {
      contabilidad: {
        name: "Accounting",
        sub_categories: {},
      },
      emprendimiento: {
        name: "Entrepreneurship",
        sub_categories: {},
      },
      "estrategia-de-negocios": {
        name: "Business strategy",
        sub_categories: {},
      },
      finanzas: {
        name: "Finance",
        sub_categories: {},
      },
      "finanzas-corporativas": {
        name: "Corporate finance",
        sub_categories: {},
      },
      "fundamentos-empresariales": {
        name: "Business Fundamentals",
        sub_categories: {},
      },
      "gestion-de-proyectos": {
        name: "Project management",
        sub_categories: {},
      },
      "jefe-de-operaciones": {
        name: "Operations Manager",
        sub_categories: {},
      },
      "liderazgo-y-gestion": {
        name: "Leadership and management",
        sub_categories: {},
      },
      marketing: {
        name: "Márketing",
        sub_categories: {},
      },
    },
  },
  "artes-y-humanidades": {
    name: "Arts and Humanities",
    sub_categories: {
      arte: {
        name: "Art",
        sub_categories: {},
      },
      idiomas: {
        name: "Languages",
        sub_categories: {},
      },
      composicion: {
        name: "Composition",
        sub_categories: {},
      },
      etica: {
        name: "Ethics",
        sub_categories: {},
      },
      filosofia: {
        name: "Philosophy",
        sub_categories: {},
      },
      "historia-del-arte": {
        name: "History of art",
        sub_categories: {},
      },
      literatura: {
        name: "Literature",
        sub_categories: {},
      },
      musica: {
        name: "Music",
        sub_categories: {},
      },
      religion: {
        name: "Religion",
        sub_categories: {},
      },
      linguistica: {
        name: "Linguistics",
        sub_categories: {},
      },
    },
  },
  "ciencia-de-los-datos": {
    name: "Data science",
    sub_categories: {
      "analisis-de-los-datos": {
        name: "Data analysis",
        sub_categories: {},
      },
      "aprendizaje-automatico": {
        name: "Machine learning",
        sub_categories: {},
      },
      "gestion-de-datos": {
        name: "Data management",
        sub_categories: {},
      },
      "probabilidades-y-estadisticas": {
        name: "Odds and statistics",
        sub_categories: {},
      },
    },
  },
  "ciencias-biologicas": {
    name: "Biological Sciences",
    sub_categories: {
      adn: {
        name: "ADN",
        sub_categories: {},
      },
      bioelectricidad: {
        name: "Bioelectricity",
        sub_categories: {},
      },
      bioinformatica: {
        name: "Bioinformatics",
        sub_categories: {},
      },
      "biologia-celular": {
        name: "Cell biology",
        sub_categories: {},
      },
      "biologia-molecular": {
        name: "Molecular biology",
        sub_categories: {},
      },
      biotecnologia: {
        name: "Biotechnology",
        sub_categories: {},
      },
      "ciencia-basica": {
        name: "Basic science",
        sub_categories: {},
      },
      "ciencias-de-la-vida": {
        name: "Life sciences",
        sub_categories: {},
      },
      evolucion: {
        name: "Evolution",
        sub_categories: {},
      },
      genetica: {
        name: "Genetics",
        sub_categories: {},
      },
      investigacion: {
        name: "Investigation",
        sub_categories: {},
      },
      microbiologia: {
        name: "Microbiology",
        sub_categories: {},
      },
      neurociencia: {
        name: "Neuroscience",
        sub_categories: {},
      },
    },
  },
  "ciencias-fisicas-y-naturales": {
    name: "Physical and Natural Sciences",
    sub_categories: {
      astronomia: {
        name: "Astronomy",
        sub_categories: {},
      },
      "calentamiento-global": {
        name: "Global warming",
        sub_categories: {},
      },
      "ciencia-de-los-materiales": {
        name: "Material science",
        sub_categories: {},
      },
      biologia: {
        name: "Biology",
        sub_categories: {},
      },
      ecosistemas: {
        name: "Ecosystems",
        sub_categories: {},
      },
      energia: {
        name: "Energy",
        sub_categories: {},
      },
      "energia-renovable": {
        name: "Renewable energy",
        sub_categories: {},
      },
      "energia-solar": {
        name: "Solar energy",
        sub_categories: {},
      },
      fisica: {
        name: "Physics",
        sub_categories: {},
      },
      geologia: {
        name: "Geology",
        sub_categories: {},
      },
      mecanica: {
        name: "Mechanics",
        sub_categories: {},
      },
      nanotecnologia: {
        name: "Nanotechnology",
        sub_categories: {},
      },
      naturaleza: {
        name: "Nature",
        sub_categories: {},
      },
      "planificacion-urbana": {
        name: "Urban planification",
        sub_categories: {},
      },
      quimica: {
        name: "Chemistry",
        sub_categories: {},
      },
      sustentabilidad: {
        name: "Sustainability",
        sub_categories: {},
      },
      termodinamica: {
        name: "Thermodynamics",
        sub_categories: {},
      },
    },
  },
  "ciencias-politicas": {
    name: "Political Sciences",
    sub_categories: {
      democracia: {
        name: "Democracy",
        sub_categories: {},
      },
      "derechos-humanos": {
        name: "Human rights",
        sub_categories: {},
      },
      gobierno: {
        name: "Government",
        sub_categories: {},
      },
      ley: {
        name: "Law",
        sub_categories: {},
      },
      politica: {
        name: "Politics",
        sub_categories: {},
      },
      "politica-exterior": {
        name: "Foreign policy",
        sub_categories: {},
      },
      sociedad: {
        name: "Society",
        sub_categories: {},
      },
    },
  },
  "desarrollo-personal": {
    name: "Personal development",
    sub_categories: {
      "desarrollo-personal-general": {
        name: "General personal development",
        sub_categories: {},
      },
      lifehacks: {
        name: "Lifehacks",
        sub_categories: {},
      },
      meditacion: {
        name: "Meditation",
        sub_categories: {},
      },
      productividad: {
        name: "Productivity",
        sub_categories: {},
      },
    },
  },
  diseno: {
    name: "Design",
    sub_categories: {
      "3d-y-animacion": {
        name: "3D and animation",
        sub_categories: {},
      },
      "design-thinking": {
        name: "Design Thinking",
        sub_categories: {},
      },
      "diseno-arquitectonico": {
        name: "Architectural design",
        sub_categories: {},
      },
      "diseno-de-interiores": {
        name: "Interior design",
        sub_categories: {},
      },
      "diseno-de-juegos": {
        name: "Game design",
        sub_categories: {},
      },
      "diseno-grafico": {
        name: "Graphic design and ilustration",
        sub_categories: {},
      },
      "diseno-web-ui": {
        name: "Web design (UI)",
        sub_categories: {},
      },
      "experiencia-de-usuario-ux": {
        name: "UX/UI Design",
        sub_categories: {},
      },
      "herramientas-de-diseno": {
        name: "Design tools",
        sub_categories: {},
      },
      moda: {
        name: "Fashion Design",
        sub_categories: {},
      },
    },
  },
  gastronomia: {
    name: "Food and Drinks",
    sub_categories: {
      "alta-cocina": {
        name: "High kitchen",
        sub_categories: {},
      },
      comidas: {
        name: "Food",
        sub_categories: {},
      },
      reposteria: {
        name: "Desserts",
        sub_categories: {},
      },
      bebidas: {
        name: "Drinks",
        sub_categories: {},
      },
      "bar-y-cocteleria": {
        name: "Bar and Cocktail Bar",
        sub_categories: {},
      },
      "bases-culinarias": {
        name: "Culinary Bases",
        sub_categories: {},
      },
      "cocina-creativa": {
        name: "Creative Cuisine",
        sub_categories: {},
      },
      "cocina-de-autor": {
        name: "Signature Cuisine",
        sub_categories: {},
      },
      "cocina-fusion": {
        name: "Fusion Kitchen",
        sub_categories: {},
      },
      "cocina-tradicional": {
        name: "Traditional cuisine",
        sub_categories: {},
      },
      "cocina-vanguardia": {
        name: "Avant-garde Kitchen",
        sub_categories: {},
      },
      "nouvelle-cuisine": {
        name: "Nouvelle cuisine",
        sub_categories: {},
      },
    },
  },
  idiomas: {
    name: "Languages",
    sub_categories: {
      "aprender-portugues": {
        name: "Learning portuguese",
        sub_categories: {},
      },
      "aprendiendo-espanol": {
        name: "Learning Spanish",
        sub_categories: {},
      },
      "aprendiendo-ingles": {
        name: "Learning English",
        sub_categories: {},
      },
      escritura: {
        name: "Writing",
        sub_categories: {},
      },
      gramatica: {
        name: "Grammar",
        sub_categories: {},
      },
      "preparacion-de-prueba": {
        name: "Test preparation",
        sub_categories: {},
      },
    },
  },
  "informatica-y-software": {
    name: "IT and software",
    sub_categories: {
      blockchain: {
        name: "Blockchain",
        sub_categories: {},
      },
      "computacion-en-la-nube": {
        name: "Cloud Computing",
        sub_categories: {},
      },
      "desarrollo-de-software": {
        name: "Software development",
        sub_categories: {},
      },
      "desarrollo-movil": {
        name: "Mobile development",
        sub_categories: {},
      },
      "desarrollo-web": {
        name: "Web development",
        sub_categories: {},
      },
      hardware: {
        name: "Hardware",
        sub_categories: {},
      },
      "inteligencia-artificial": {
        name: "Artificial intelligence",
        sub_categories: {},
      },
      programacion: {
        name: "Programming",
        sub_categories: {},
      },
      "redes-y-seguridad": {
        name: "Networks and security",
        sub_categories: {},
      },
    },
  },
  ingenieria: {
    name: "Engineering",
    sub_categories: {
      "ingenieria-aeroespacial": {
        name: "Aerospace engineering",
        sub_categories: {},
      },
      "ingenieria-industrial": {
        name: "Industrial engineering",
        sub_categories: {},
      },
      "informatica-y-software": {
        name: "Systems and Software Engineering",
        sub_categories: {},
      },
      "automatizacion-industrial": {
        name: "Industrial Automation",
        sub_categories: {},
      },
      "ingenieria-ambiental": {
        name: "Environmental engineering",
        sub_categories: {},
      },
      "ingenieria-electrica": {
        name: "Electric engineering",
        sub_categories: {},
      },
      "ingenieria-electronica": {
        name: "Electronic Engineering",
        sub_categories: {},
      },
      "ingenieria-estructural": {
        name: "Structural engineering",
        sub_categories: {},
      },
      "ingenieria-mecanica": {
        name: "Mechatronics Engineering",
        sub_categories: {},
      },
      "ingeniero-civil": {
        name: "Civil engineer",
        sub_categories: {},
      },
    },
  },
  "matematicas-y-logica": {
    name: "Mathematics",
    sub_categories: {
      algebra: {
        name: "Algebra",
        sub_categories: {},
      },
      algoritmos: {
        name: "Algorithms",
        sub_categories: {},
      },
      calculo: {
        name: "Calculus",
        sub_categories: {},
      },
      estadisticas: {
        name: "Statistics",
        sub_categories: {},
      },
      geometria: {
        name: "Geometry",
        sub_categories: {},
      },
      logica: {
        name: "Logic",
        sub_categories: {},
      },
      probabilidad: {
        name: "Probability",
        sub_categories: {},
      },
      regresion: {
        name: "Regression",
        sub_categories: {},
      },
      trigonometria: {
        name: "Trigonometry",
        sub_categories: {},
      },
    },
  },
};

export default categories_en;
