import React from "react";
import { Modal } from "components/common";
import { Button, FileField, Form, InputField, TagField } from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { CONSTANTS } from "shared/constants";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModuleModal = ({
  threads,
  is_visible,
  closeModal,
  forum,
  course_id,
  history,
}) => {
  const { t } = useTranslation();
  const tagsArray =
    !!forum && forum.tags ? forum.tags.split(",").map((tag) => tag.trim()) : [];

  React.useEffect(() => {
    if (is_visible) {
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const handleSubmit = (data) => {
    const formData = {
      ...data,
      course: course_id,
    };
    threads.createForum(formData, (response) => {
      if (response.status === 201) {
        closeModal();
        history.push(CONSTANTS.URLS.FORUM.FORUM_DETAIL(response?.data?.slug));
      }
    });
  };

  const editCourse = (data) => {
    threads.modifyForum(forum.slug, data, (response) => {
      if (response.status === 200) {
        history.push(CONSTANTS.URLS.FORUM.FORUM_DETAIL(response?.data?.slug));
      }
    });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {!!forum ? (
        <>
          <h1>{t("modals.forum.title.edit_title")}</h1>
          <p>{t("modals.forum.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.forum.title.create_title")}</h1>
          <p>{t("modals.forum.subtitle.create_subtitle")}</p>
        </>
      )}
      <Form onSubmit={!!forum ? editCourse : handleSubmit} className="pz-form">
        {({ onChange, data: { tags = tagsArray, name, description } }) => (
          <React.Fragment>
            <FileField
              errMsg={!!forum ? "" : t("modals.forum.file_input.error")}
              nameDefault={t("modals.forum.file_input.state")}
              label={t("modals.forum.file_input.title")}
              name="thumbnail"
              onChange={onChange}
              notRequired={!!forum}
            />
            <InputField
              label={`${t("modals.forum.title_input.title")} (${
                typeof name === "string" ? name.length : 0
              }/50)`}
              maxLength={50}
              name="name"
              onChange={onChange}
              defaultValue={!!forum ? forum?.name : ""}
              notRequired={!!forum}
            />
            <TagField
              label={t("modals.course.tags_input.title")}
              name="tags"
              onChange={onChange}
              tags={tags}
              defaultValue={tagsArray}
              notRequired={!!forum}
            />
            <TextareaField
              label={`${t("modals.forum.body_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/300)`}
              maxLength={300}
              name="description"
              onChange={onChange}
              defaultValue={!!forum ? forum?.description : ""}
              notRequired={!!forum}
            />

            <small className="bold">
              {" "}
              {t("modals.forum.info_input.title")}
            </small>

            <Button type="submit" primary>
              {!!forum
                ? t("modals.forum.submit_button.edit_title")
                : t("modals.forum.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "threads"));
