import React from "react";
import { AuthForm } from "apps/auth/components";
import { CONSTANTS } from "shared/constants";
import { Button, InputField } from "components/ui";
import { Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = ({ auth, history }) => {
  const { t } = useTranslation();
  const [shouldRedirectToChangePassword, setShouldRedirect] =
    React.useState(false);

  const goToLogin = () => {
    history.push(CONSTANTS.URLS.AUTH.LOGIN);
  };

  const submitForgotPassword = (data) => {
    auth.forgot_password(data, () => {
      setShouldRedirect(true);
    });
  };

  const navbarActions = (
    <>
      <h5>{t("navbar.login_span")}</h5>
      <Button
        primary
        thin
        onClick={() => {
          history.push(CONSTANTS.URLS.AUTH.LOGIN);
        }}
      >
        {t("navbar.login")}
      </Button>
    </>
  );

  if (shouldRedirectToChangePassword) {
    return <Redirect to={CONSTANTS.URLS.AUTH.CHANGE_PASSWORD} />;
  }

  return (
    <AuthForm
      navbarActions={navbarActions}
      title={t("others.forgot_password.title")}
      onSubmit={submitForgotPassword}
      message={t("others.forgot_password.subtitle")}
    >
      {({ onChange }) => (
        <React.Fragment>
          <InputField
            label={t("others.forgot_password.input")}
            name="email"
            onChange={onChange}
            type="email"
            required
          />
          <section className="pz-auth-form__buttons">
            <Button type="submit" primary>
              {t("others.forgot_password.send")}
            </Button>
            <Button type="button" thin ghost onClick={goToLogin}>
              {t("others.forgot_password.login")}
            </Button>
          </section>
        </React.Fragment>
      )}
    </AuthForm>
  );
};

export default withRouter(ForgotPasswordPage);
