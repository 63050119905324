import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

const ShareModal = ({ is_visible, closeModal, hashtag, title, url }) => {
  const { t } = useTranslation();
  const location = window.location.href;
  const handleCopy = () => {
    navigator.clipboard.writeText(url ? url : location);
  };
  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h1>{t("others.share_modal.title")}</h1>
      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <FacebookShareButton
          url={url ? url : location}
          quote="Panzofi"
          hashtag={hashtag ? hashtag : "Panzofi"}
          title={title ? title : "Panzofi"}
        >
          <FacebookIcon round />
        </FacebookShareButton>
        <TwitterShareButton
          url={url ? url : location}
          title={title ? title : "Panzofi"}
        >
          <TwitterIcon round />
        </TwitterShareButton>
        <WhatsappShareButton
          url={url ? url : location}
          title={title ? title : "Panzofi"}
        >
          <WhatsappIcon round />
        </WhatsappShareButton>
      </section>
      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <input
          disabled
          value={url ? url : location}
          className="pz-form__inputdisable"
        ></input>
        <Button primary thin ghost onClick={handleCopy}>
          <FontAwesomeIcon icon={faCopy} className="" />
        </Button>
      </section>
    </Modal>
  );
};

export default ShareModal;
