const course_es = {
  level: "nivel",
  number_modules: "numero de módulos",
  updated: "actualizado",
  language: "idioma",
  save: "Guardar",
  languages: {
    spanish: "español",
    english: "inglés",
    portuguese: "portugués",
    french: "francés",
  },
  states: {
    public: "publico",
    private: "privado",
    error:
      "Tu contenido se encuentra privado, da clic en hacer público para que tu contenido sea visible.",
  },
  buttons: {
    states_button: {
      make_public: "Hacer público",
      make_private: "Hacer privado",
    },
    create_module: "Crear módulo",
    upload_content: "Subir contenido",
    edit_lesson: "Editar lección",
    delete_lesson: "Eliminar lección",
    report_content: "Reportar Contenido",
  },
  no_mudules_logged:
    "Aún no has creado tu primer módulo, créa uno y comienza a compartir tu conocimiento.",
  no_mudules_unlogged: "Esta lista no tiene módulos creados aún.",
};
export default course_es;
