import React from "react";
import { Modal } from "components/common";
import { Button, Label, Toggle } from "components/ui";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const ModuleModal = ({
  courses,
  course,
  is_visible,
  closeModal,
  currentQuiz,
  history,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = React.useState({
    title: "",
    description: "",
    module: null,
    course: course?.id,
    questions: [
      { text: "", url: "", options: [{ text: "", is_correct: false }] },
    ], // Initialize with one question and one option
  });
  const [forModule, setForModule] = React.useState(
    course?.course_quiz ? true : false
  );

  React.useEffect(() => {
    if (is_visible && currentQuiz) {
      courses.getQuiz(currentQuiz.id, (response) => {
        setFormData(response.status === 200 ? response.data : null);
        setForModule(currentQuiz.course ? false : true);
      });
    } else {
      setForModule(false);
      setFormData({
        title: "",
        description: "",
        module: null,
        course: course?.id,
        questions: [
          { text: "", url: "", options: [{ text: "", is_correct: false }] },
        ], // Initialize with one question and one option
      });
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.module !== null || formData.course != null)
      courses.createQuiz(formData, (response) => {
        if (response.status === 201) {
          closeModal(true);
        }
      });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    if (formData.module !== null || formData.course != null)
      courses.modifyQuiz(currentQuiz.id, formData, (response) => {
        if (response.status === 200) {
          closeModal(true);
        }
      });
  };

  const handleChange = (event, questionIndex, optionIndex) => {
    const { name, value, checked } = event.target;
    const updatedQuestions = [...formData.questions];

    if (name.startsWith("option_")) {
      // Update option text
      updatedQuestions[questionIndex].options[optionIndex].text = value;
    } else if (name.startsWith("is_correct_")) {
      // Update option's correctness
      updatedQuestions[questionIndex].options[optionIndex].is_correct = checked;
    } else {
      // Update question text or URL
      updatedQuestions[questionIndex][name] = value;
    }
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddQuestion = (questionIndex) => {
    const updatedQuestions = [...formData.questions];
    const newQuestion = {
      text: "",
      url: "",
      options: [{ text: "", is_correct: false }],
    };
    updatedQuestions.splice(questionIndex + 1, 0, newQuestion);
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...formData.questions];
    const newOption = {
      text: "",
      is_correct: false,
    };
    // Ensure the options array is initialized before adding the new option
    if (!updatedQuestions[questionIndex].options) {
      updatedQuestions[questionIndex].options = [];
    }
    updatedQuestions[questionIndex].options.splice(
      optionIndex + 1,
      0,
      newOption
    );
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleRemoveQuestion = (questionIndex) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions.splice(questionIndex, 1);
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...formData.questions];
    const updatedOptions = [...updatedQuestions[questionIndex].options];
    updatedOptions.splice(optionIndex, 1);
    updatedQuestions[questionIndex].options = updatedOptions;
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleToggle = () => {
    if (forModule === false) {
      setFormData({ ...formData, course: null });
      setForModule(true);
    } else {
      setFormData({ ...formData, course: course?.id, module: null });
      setForModule(false);
    }
  };

  const getModuleOptions = () =>
    course?.modules?.map(({ title, id }, key) => ({
      label: `módulo ${key + 1}: ${title}`,
      value: id,
    }));

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
    background: "#172B4D",
    color: "#EBECF0",
    margin: 0,
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {currentQuiz ? (
        <>
          <h1>{t("modals.quiz.title.edit_title")}</h1>
          <p>{t("modals.quiz.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.quiz.title.create_title")}</h1>
          <p>{t("modals.quiz.subtitle.create_subtitle")}</p>
        </>
      )}
      <form
        onSubmit={currentQuiz ? handleEdit : handleSubmit}
        className="pz-form"
      >
        {currentQuiz ? (
          <>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.quiz.module_input.label")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={forModule}
                onChange={handleToggle}
              />
            </article>
            {forModule ? (
              <>
                <section className="pz-form__field">
                  <Label htmlFor="module" h labelAsText>
                    {t("modals.quiz.module_input.title")}
                  </Label>
                  <Select
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        padding: "0",
                      }),
                      control: (provided) => ({
                        ...provided,
                        border: 0,
                        fontSize: "0.8rem",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        fontSize: "0.8rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        paddingRight: "0",
                      }),
                      group: (provided) => ({}),
                      groupHeading: (provided) => ({}),
                    }}
                    id="module"
                    name="module"
                    options={getModuleOptions()}
                    className="pz-form__input"
                    closeMenuOnSelect={true}
                    required={true}
                    isMulti={false}
                    onChange={(value) =>
                      setFormData({ ...formData, module: value.value })
                    }
                    formatGroupLabel={formatGroupLabel}
                  />
                </section>
              </>
            ) : null}
            <section className="pz-form__field">
              <Label>{t("modals.quiz.title_input.title")}</Label>
              <input
                className="pz-form__input"
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required={true}
                maxLength={100}
              />
            </section>
            <section className="pz-form__field">
              <Label>{t("modals.quiz.description_input.title")}</Label>
              <textarea
                className="pz-form__textarea"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required={true}
                maxLength={300}
              />
            </section>
            {formData.questions.map((question, questionIndex) => (
              <div key={questionIndex} className="quiz__form__question">
                <section className="pz-form__field">
                  <Label>
                    {t("modals.quiz.question.text_input.title")}{" "}
                    {questionIndex + 1}
                  </Label>
                  <input
                    className="pz-form__input"
                    type="text"
                    name="text"
                    value={question.text}
                    onChange={(e) => handleChange(e, questionIndex)}
                    required={true}
                    maxLength={300}
                  />
                </section>

                <section className="pz-form__field">
                  <Label>{t("modals.quiz.question.url_input.title")}</Label>
                  <input
                    className="pz-form__input"
                    type="url"
                    name="url"
                    value={question.url}
                    onChange={(e) => handleChange(e, questionIndex)}
                    required={false}
                    maxLength={200}
                  />
                </section>

                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <section className="grid-columns grid-items-middle grid-gap-20">
                      <section className="pz-form__field">
                        <Label>
                          {t("modals.quiz.option.text_input.title")}{" "}
                          {optionIndex + 1}
                        </Label>
                        <input
                          className="pz-form__input"
                          type="text"
                          name={`option_${optionIndex}`}
                          value={option.text}
                          onChange={(e) =>
                            handleChange(e, questionIndex, optionIndex)
                          }
                          required={true}
                          maxLength={100}
                        />
                      </section>
                      <article className="pz-filter__checkbox2">
                        <label className="pz-filter__label">
                          {t("modals.quiz.option.correct_input.title")}
                        </label>
                        <section className="pz-form__toggle">
                          <input
                            type="checkbox"
                            name={`is_correct_${optionIndex}`}
                            checked={option.is_correct}
                            onChange={(e) =>
                              handleChange(e, questionIndex, optionIndex)
                            }
                          />
                          <span />
                        </section>
                      </article>

                      <section className="grid-columns grid-gap-20">
                        {question.options.length > 1 ? (
                          <>
                            <Button
                              ghost
                              type="button"
                              title={t("modals.quiz.option.btns.remove")}
                              onClick={() =>
                                handleRemoveOption(questionIndex, optionIndex)
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                            <Button
                              ghost
                              primary
                              title={t("modals.quiz.option.btns.add")}
                              type="button"
                              onClick={() =>
                                handleAddOption(questionIndex, optionIndex)
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </>
                        ) : (
                          <Button
                            ghost
                            primary
                            title={t("modals.quiz.option.btns.add")}
                            type="button"
                            onClick={() =>
                              handleAddOption(questionIndex, optionIndex)
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                      </section>
                    </section>
                    <br />
                  </div>
                ))}
                <section className="grid-columns grid-gap-20">
                  {formData.questions.length > 1 ? (
                    <>
                      <Button
                        ghost
                        type="button"
                        title={t("modals.quiz.question.btns.add")}
                        onClick={() => handleRemoveQuestion(questionIndex)}
                      >
                        {t("modals.quiz.question.btns.remove")}
                      </Button>
                      <Button
                        primary
                        ghost
                        title={t("modals.quiz.question.btns.remove")}
                        type="button"
                        onClick={() => handleAddQuestion(questionIndex)}
                      >
                        {t("modals.quiz.question.btns.add")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      primary
                      ghost
                      title={t("modals.quiz.question.btns.add")}
                      type="button"
                      onClick={() => handleAddQuestion(questionIndex)}
                    >
                      {t("modals.quiz.question.btns.add")}
                    </Button>
                  )}
                </section>
              </div>
            ))}
          </>
        ) : (
          <>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.quiz.module_input.label")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={forModule}
                onChange={handleToggle}
              />
            </article>
            {forModule ? (
              <>
                <section className="pz-form__field">
                  <Label htmlFor="module" h labelAsText>
                    {t("modals.quiz.module_input.title")}
                  </Label>
                  <Select
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        padding: "0",
                      }),
                      control: (provided) => ({
                        ...provided,
                        border: 0,
                        fontSize: "0.8rem",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        fontSize: "0.8rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        paddingRight: "0",
                      }),
                      group: (provided) => ({}),
                      groupHeading: (provided) => ({}),
                    }}
                    id="module"
                    name="module"
                    options={getModuleOptions()}
                    className="pz-form__input"
                    closeMenuOnSelect={true}
                    required={true}
                    isMulti={false}
                    onChange={(value) =>
                      setFormData({ ...formData, module: value.value })
                    }
                    formatGroupLabel={formatGroupLabel}
                  />
                </section>
              </>
            ) : null}
            <section className="pz-form__field">
              <Label>{t("modals.quiz.title_input.title")}</Label>
              <input
                className="pz-form__input"
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required={true}
                maxLength={100}
              />
            </section>
            <section className="pz-form__field">
              <Label>{t("modals.quiz.description_input.title")}</Label>
              <textarea
                className="pz-form__textarea"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required={true}
                maxLength={300}
              />
            </section>
            {formData.questions.map((question, questionIndex) => (
              <div key={questionIndex} className="quiz__form__question">
                <section className="pz-form__field">
                  <Label>
                    {t("modals.quiz.question.text_input.title")}{" "}
                    {questionIndex + 1}
                  </Label>
                  <input
                    className="pz-form__input"
                    type="text"
                    name="text"
                    value={question.text}
                    onChange={(e) => handleChange(e, questionIndex)}
                    required={true}
                    maxLength={300}
                  />
                </section>

                <section className="pz-form__field">
                  <Label>{t("modals.quiz.question.url_input.title")}</Label>
                  <input
                    className="pz-form__input"
                    type="url"
                    name="url"
                    value={question.url}
                    onChange={(e) => handleChange(e, questionIndex)}
                    required={false}
                    maxLength={200}
                  />
                </section>

                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <section className="grid-columns grid-items-middle grid-gap-20">
                      <section className="pz-form__field">
                        <Label>
                          {t("modals.quiz.option.text_input.title")}{" "}
                          {optionIndex + 1}
                        </Label>
                        <input
                          className="pz-form__input"
                          type="text"
                          name={`option_${optionIndex}`}
                          value={option.text}
                          onChange={(e) =>
                            handleChange(e, questionIndex, optionIndex)
                          }
                          required={true}
                          maxLength={100}
                        />
                      </section>
                      <article className="pz-filter__checkbox2">
                        <label className="pz-filter__label">
                          {t("modals.quiz.option.correct_input.title")}
                        </label>
                        <section className="pz-form__toggle">
                          <input
                            type="checkbox"
                            name={`is_correct_${optionIndex}`}
                            checked={option.is_correct}
                            onChange={(e) =>
                              handleChange(e, questionIndex, optionIndex)
                            }
                          />
                          <span />
                        </section>
                      </article>

                      <section className="grid-columns grid-gap-20">
                        {question.options.length > 1 ? (
                          <>
                            <Button
                              ghost
                              type="button"
                              title={t("modals.quiz.option.btns.remove")}
                              onClick={() =>
                                handleRemoveOption(questionIndex, optionIndex)
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                            <Button
                              ghost
                              primary
                              title={t("modals.quiz.option.btns.add")}
                              type="button"
                              onClick={() =>
                                handleAddOption(questionIndex, optionIndex)
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </>
                        ) : (
                          <Button
                            ghost
                            primary
                            title={t("modals.quiz.option.btns.add")}
                            type="button"
                            onClick={() =>
                              handleAddOption(questionIndex, optionIndex)
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                      </section>
                    </section>
                    <br />
                  </div>
                ))}
                <section className="grid-columns grid-gap-20">
                  {formData.questions.length > 1 ? (
                    <>
                      <Button
                        ghost
                        type="button"
                        title={t("modals.quiz.question.btns.remove")}
                        onClick={() => handleRemoveQuestion(questionIndex)}
                      >
                        {t("modals.quiz.question.btns.remove")}
                      </Button>
                      <Button
                        primary
                        ghost
                        title={t("modals.quiz.question.btns.add")}
                        type="button"
                        onClick={() => handleAddQuestion(questionIndex)}
                      >
                        {t("modals.quiz.question.btns.add")}
                      </Button>
                    </>
                  ) : (
                    <Button
                      primary
                      ghost
                      title={t("modals.quiz.question.btns.add")}
                      type="button"
                      onClick={() => handleAddQuestion(questionIndex)}
                    >
                      {t("modals.quiz.question.btns.add")}
                    </Button>
                  )}
                </section>
              </div>
            ))}
          </>
        )}
        <Button primary type="submit">
          {currentQuiz
            ? t("modals.quiz.submit_button.edit_title")
            : t("modals.quiz.submit_button.create_title")}
        </Button>
      </form>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "courses"));
