import { CourseCard } from "apps/courses/components";
import { Line } from "components/ui";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connectReduxReducers } from "hocs";

const TabCarousel = ({
  courses: reduxCourses,
  fullPage,
  autoPlay,
  t1,
  t2,
  t3,
  t4,
  t5,
}) => {
  React.useEffect(() => {
    getMoreCoursesCat("emprendimiento");
    // eslint-disable-next-line
  }, []);
  const [coursesCat, setCoursesCat] = React.useState([]);
  const [toggleState, setToggleState] = React.useState(1);
  const responsiveLarge = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsiveShort = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to next slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }
  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to previos slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }
  const toggleTab = (index) => {
    setToggleState(index);
    switch (index) {
      case 1:
        getMoreCoursesCat("emprendimiento");
        break;

      case 2:
        getMoreCoursesCat("lifehacks");
        break;
      case 3:
        getMoreCoursesCat("estrategia-de-negocios");
        break;
      case 4:
        getMoreCoursesCat("finanzas");
        break;
      case 5:
        getMoreCoursesCat("psicologia");
        break;

      default:
        break;
    }
  };
  const renderCourses = (courses) => {
    return (
      <>
        <Carousel
          responsive={fullPage === true ? responsiveLarge : responsiveShort}
          swipeable={false}
          draggable={false}
          showDots={true}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={autoPlay}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="all 0.5s ease-in-out"
          transitionDuration={500}
          itemClass="pz-courses__courses__carousel-item"
          dotListClass="custom-dot-list-style2"
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
        >
          {courses.map((course, key) => (
            <CourseCard course={course} key={key} />
          ))}
        </Carousel>
      </>
    );
  };
  const getMoreCoursesCat = (cat) => {
    const params = {
      category__slug: cat,
      limit: 9,
      offset: 0,
      ordering: "-num_reaction",
    };
    reduxCourses.getCourses(params, ({ data, status }) => {
      const sortedCourses2 = status === 200 ? data.results : null;
      setCoursesCat(sortedCourses2);
    });
  };
  return (
    <section className="pz-courses__courses__tabcontainer">
      <section className="pz-courses__courses__tabs">
        <h4
          className={
            toggleState === 1
              ? "pz-courses__courses__active-tab"
              : "pz-courses__courses__tab"
          }
          onClick={() => toggleTab(1)}
        >
          {t1}
        </h4>
        <h4
          className={
            toggleState === 2
              ? "pz-courses__courses__active-tab"
              : "pz-courses__courses__tab"
          }
          onClick={() => toggleTab(2)}
        >
          {t2}
        </h4>
        <h4
          className={
            toggleState === 3
              ? "pz-courses__courses__active-tab"
              : "pz-courses__courses__tab"
          }
          onClick={() => toggleTab(3)}
        >
          {t3}
        </h4>
        <h4
          className={
            toggleState === 4
              ? "pz-courses__courses__active-tab"
              : "pz-courses__courses__tab"
          }
          onClick={() => toggleTab(4)}
        >
          {t4}
        </h4>
        <h4
          className={
            toggleState === 5
              ? "pz-courses__courses__active-tab"
              : "pz-courses__courses__tab"
          }
          onClick={() => toggleTab(5)}
        >
          {t5}
        </h4>
      </section>
      <section className="pz-courses__courses__carousel">
        <Line />
        {renderCourses(coursesCat)}
      </section>
    </section>
  );
};

export default connectReduxReducers(TabCarousel, "courses");
