import React from 'react';


const Form = ({
    initialState, onChange, onSubmit, className, resetOnSubmit, children, ...props
}) => {
    const [data, setData] = React.useState(initialState);

    const handleOnChange = (inputValue) => {
        const nextData = {
            ...data,
            ...inputValue
        };
        onChange(nextData);
        setData(nextData);
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        if (resetOnSubmit) {
            console.log('reseting');
            e.target.reset();
        }
        onSubmit(data);
    };

    return (
        <form {...props} onSubmit={handleOnSubmit} className={className}>
            {children({
                onChange: handleOnChange,
                data: data
            })}
        </form>
    );
};

Form.defaultProps = {
    initialState: {},
    onChange: () => {},
    onSubmit: () => {},
    resetOnSubmit: false,
    className: 'pz-form'
};

export default Form;