import React from "react";
import { CONSTANTS } from "shared/constants";
import {
  faCaretDown,
  faCaretUp,
  faEdit,
  faFileAlt,
  faQuestionCircle,
  faVideo,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SlideToggle from "react-slide-toggle";
import eases from "eases";
import { renderClasses } from "shared/common/functions";
import { useTranslation } from "react-i18next";

const ModuleRender = ({ course, onEditModule, onEditLesson }) => {
  const { t } = useTranslation();
  const getIcon = (type) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.AUDIO:
        return faVolumeUp;
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return faFileAlt;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return faVideo;
      default:
        return faQuestionCircle;
    }
  };

  const renderModuleContent = (moduleContent, moduleId) =>
    moduleContent.map(({ id, title, description, type }, key) => (
      <section
        key={id}
        className={renderClasses({
          "pz-course-modules__module--lesson": true,
          selected: false,
        })}
      >
        <div className="pz-course-modules__module--lesson--content">
          <FontAwesomeIcon
            icon={getIcon(type)}
            className="pz-course-modules__module--lesson--icon"
          />
          <span className="pz-course-modules__module--lesson--title">
            {t(`landing.training.content.course.${moduleId}.${id}.title`)}
          </span>
          <h5 className="pz-course-modules__module--lesson--description">
            {description}
          </h5>
        </div>

        {typeof onEditLesson === "function" ? (
          <FontAwesomeIcon
            className="pz-course-modules__module--lesson--edit"
            icon={faEdit}
            onClick={() =>
              onEditLesson({ ...moduleContent[key], moduleId: moduleId })
            }
          />
        ) : null}
      </section>
    ));

  const renderModules = (modules) =>
    modules.map(({ id, title, description, content }, key) => (
      <SlideToggle
        key={id}
        duration={500}
        easeCollapse={eases["quintOut"]}
        easeExpand={eases["quintOut"]}
        render={({ onToggle, setCollapsibleElement, toggleState }) => (
          <section className="pz-course-modules__module2">
            <section
              className="pz-course-modules__module--header"
              onClick={onToggle}
            >
              <section>
                <h4 className="pz-course-modules__module--title bold">
                  Módulo {key + 1}:{" "}
                  {t(`landing.training.content.course.${id}.title`)}
                </h4>
                <h5 className="pz-course-modules__module--description">
                  {t(`landing.training.content.course.${id}.description`)}
                </h5>
              </section>
              <section className="grid-columns grid-gap-10 grid-items-middle">
                <h5>{content.length}</h5>
                <FontAwesomeIcon
                  icon={
                    toggleState === "EXPANDED" || toggleState === "EXPANDING"
                      ? faCaretUp
                      : faCaretDown
                  }
                />
                {typeof onEditModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => onEditModule(modules[key])}
                  />
                ) : null}
              </section>
            </section>
            <section
              className="pz-course-modules__module--body"
              ref={setCollapsibleElement}
            >
              {renderModuleContent(content, id)}
            </section>
          </section>
        )}
        bestPerformance
        collapsed={true}
      />
    ));

  return (
    <section className="pz-course-modules">
      {renderModules(course?.modules || [])}
    </section>
  );
};

export default ModuleRender;
