import React from 'react';
import { renderClasses } from "shared/common/functions";


const Label = ({
    htmlFor, children, labelAsText
}) => (
    <label
        htmlFor={htmlFor}
        className={renderClasses({
            'pz-form__label': !labelAsText,
            'pz-form__text-label': labelAsText,
        })}
    >{children}</label>
);

Label.defaultProps = {
    labelAsText: false
};

export default Label;