import React from "react";
import { Image } from "components/ui";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaugh,
  faFrown,
  faStar,
  faLightbulb,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";

const ThreadCard = ({ thread, forum_slug }) => {
  return (
    <Link
      to={CONSTANTS.URLS.FORUM.THREAD_DETAIL(forum_slug, thread?.id)}
      className="pz-thread-card"
    >
      <section className="pz-thread-card__content">
        <h4 className="pz-thread-card__name">{thread.title}</h4>
        <h5 className="pz-thread-card__description">{thread.body}</h5>
      </section>
      <small className="pz-thread-card__username grid-gap-20">
        <section className="pz-thread-card__username">
          {thread?.user?.profile?.picture ? (
            <Image
              src={
                thread?.user?.profile?.picture24
                  ? thread?.user?.profile?.picture24
                  : thread?.user?.profile?.picture
              }
            />
          ) : (
            <Image name="icons/male-avatar.svg" />
          )}
          <span>{thread?.user?.username}</span>
        </section>
        <section className="pz-thread-card__reactions">
          <h5 className="pz-thread-card__reaction">
            <FontAwesomeIcon icon={faLaugh} title="me gusta" />{" "}
            {thread?.reactions?.like_count}
          </h5>
          <h5 className="pz-thread-card__reaction">
            <FontAwesomeIcon icon={faFrown} title="no me gusta" />{" "}
            {thread?.reactions?.fun_count}
          </h5>
          <h5 className="pz-thread-card__reaction">
            <FontAwesomeIcon icon={faStar} title="calidad" />{" "}
            {thread?.reactions?.love_count}
          </h5>
          <h5 className="pz-thread-card__reaction">
            <FontAwesomeIcon icon={faLightbulb} title="útil" />{" "}
            {thread?.reactions?.intriguing_count}
          </h5>
          <h5 className="pz-thread-card__reaction">
            <FontAwesomeIcon icon={faClock} title="eficiente" />{" "}
            {thread?.reactions?.interesting_count}
          </h5>
        </section>
        <section className="grid-columns grid-items-bottom   ">
          <FontAwesomeIcon icon={faComment} />
          {thread?.thread_resposes?.length}
        </section>
      </small>
    </Link>
  );
};

export default ThreadCard;
