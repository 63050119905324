const others_es = {
  share_button: "Compartir",
  share_modal: {
    title: "Compartir este contenido",
  },
  input_valid: {
    empty: "El texto está vacío",
    special: "El texto tiene caracteres especiales",
    spaces: "El texto no debe contener espacios",
    password: "Debe tener mayusculas, minusculas y al menos 8 caracteres",
    youtube: "No es una URL válida de YouTube.",
    youtube_or_docs: "No es una URL válida de YouTube o Google Docs.",
  },
  forgot_password: {
    title: "¿Olvidaste tu contraseña?",
    subtitle:
      "Confirmanos tu usuario y tu correo electronico asociados a tu cuenta para poder enviarte un codigo de seguridad para que puedas cambiar tu contraseña.",
    input: "Correo",
    send: "Enviar",
    login: "Volver a iniciar sesión",
  },
  cookies: {
    banner: {
      title:
        "Nuestro sitio web usa cookies para mejorar tu experiencia, revisa nuestras ",
      subtitle: "Políticas de privacidad",
      allow: "Permitir todas",
      customize: "Personalizar",
    },
    modal: {
      title: "Configuración de cookies",
      subtitle:
        "Selecciona las cookies que nos permites usar, para mas información revisa nuestras",
      link: "políticas de privacidad",
      obligatory: "Necesarias (son obligatorias)",
      functionality: "Cookies que nos permiten mejorar la funcionalidad",
      relevant: "Cookies que nos permiten darte publicidad relevante",
      performance: "Cookies que nos permiten mejorar el rendimiento",
      button: "Aceptar",
    },
  },
  settings: {
    account: {
      title: "Datos de la cuenta",
      subtitle: "Acá encontrarás los datos de tu cuenta",
      button: "Editar cuenta",
    },
    profile: {
      title: "Datos del perfil",
      subtitle: "",
    },
    password: {
      title: "Cambia tu contraseña",
      subtitle: "",
      password: "Nueva Contraseña",
      password2: "Confirma tu nueva Contraseña",
      button: "Cambiar mi contraseña",
    },
    delete: {
      title: "Acá puedes eliminar tu cuenta si lo deseas.",
      button: "Eliminar mi cuenta",
      confirmation: "¿Eliminar tu cuenta?",
      confirmation_button: "Eliminar",
    },
  },
  trivia: {
    modal: {
      start_btn: "Comenzar trivia",
      close_btn: "Cerrar trivia",
      link_input: "Si no sabes la respuesta a esta pregunta",
      link: "visita este contenido",
      finished_title:
        "¡Felicitaciones por completar con éxito la trivia de Panzofi!",
      finished_desc:
        "Da clic en el botón de enviar para recibir tus resultados",
      send_btn: "Enviar trivia",
      results_title: "Acá estan tus resultados",
      results_pass:
        "¡Felicitaciones has pasado! se añadieron 10 puntos de estudiante a tu perfil",
      results_fail:
        "No has obtenido suficientes puntos, pero recuerda que puedes volverlo a intentar",
      results: "Obtuviste {{score}} puntos ",
    },
  },
  search: {
    not_found: "Lo sentimos, no hemos encontrado resultados para",
    recommendation: "Te pueden interesar nuestras recomendaciones",
    lessons: {
      title: "Lecciones",
      subtitle: "Lecciones encontradas",
    },
    posts: {
      title: "Educlips",
      subtitle: "Educlips encontrados",
    },
    profiles: {
      title: "Perfiles",
      subtitle: "Perfiles encontrados",
    },
    forums: {
      title: "Foros",
      subtitle: "Foros encontrados",
    },
  },
};
export default others_es;
