const notFound_es = {
  no_exist: {
    t1: "No estamos seguros de como llegaste hasta aquí,",
    t2: "pero este contenido no existe",
  },
  no_public: "Este recurso no es público aún",
  no_resource:
    "Algo paso tratando de obtener los recursos, intenta nuevamente.",
  no_courses: {
    h1: "Aún no hay contenido disponible en esta categoría",
    h2: "Sé el primero en subir contenido en esta categoría",
    button_create: "Crear curso",
    h4: "Intenta cambiar los filtros o vuelve a ver todos los cursos",
    button_courses: "Ver todo el contenido",
  },
};
export default notFound_es;
