import { Navbar } from "components/panzofi";
import { Button } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import queryString from "query-string";
import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";
import API from "core/api";
import { ForumCard, ForumModal } from "apps/forums/components";

const ForumsPage = ({
  auth,
  threads,
  defaultSearchValue,
  history,
  location,
}) => {
  const [forumList, setForumList] = React.useState([]);
  const [forumModalVisible, setForumModalVisible] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(defaultSearchValue);
  const [limit] = React.useState(6);
  const [offset, setOffset] = React.useState(0);
  const [moreforums, setMoreForums] = React.useState(true);
  const { t } = useTranslation();
  React.useEffect(() => {
    getMoreThreads();
    setSearchValue(defaultSearchValue);
    // eslint-disable-next-line
  }, [defaultSearchValue]);

  const getSearchParams = () => queryString.parse(location.search);

  const getThreads = () => {
    const params = {
      search: getSearchParams()?.search,
      limit: 6,
      offset: 0,
    };
    threads.getForums(params, ({ data, status }) => {
      setForumList(status === 200 ? data.results : null);
      data.next === null ? setMoreForums(false) : setMoreForums(true);
    });
  };

  const getMoreThreads = () => {
    const params = {
      search: getSearchParams()?.search,
      limit: limit,
      offset: offset,
    };
    threads.getForums(params, ({ data, status }) => {
      const sortedThreads =
        status === 200 ? [...forumList, ...data.results] : null;
      setForumList(sortedThreads);
      setOffset(offset + limit);
      data.next === null ? setMoreForums(false) : setMoreForums(true);
    });
  };

  const closeModalNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getThreads();
    }
    setForumModalVisible(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const params = {
      ...queryString.parse(location.search),
      search: searchValue !== "" ? searchValue : undefined,
    };
    history.push(
      `${CONSTANTS.URLS.FORUM.ALL}?${queryString.stringify(params)}`
    );
  };

  const renderForums = () =>
    forumList.map((forum, key) => {
      return <ForumCard forum={forum} key={key} />;
    });
  return (
    <main className="pz-forum">
      <Navbar />

      <section className="pz-forum__page">
        <>
          <section className="pz-forum__content">
            <h1>{t("forum.forums.title")}</h1>
            <section className="pz-forum__forums">
              <h4 className="pz-forum__forums--title">
                <form onSubmit={handleSubmit2} className="pz-forum__search">
                  <input
                    type="text"
                    value={searchValue}
                    onChange={handleChange}
                    className="pz-forum__search--input"
                    placeholder={t("forum.forums.input_search")}
                  />
                </form>
                {API.auth.isLoggedIn() ? (
                  <Button
                    thin
                    ghost
                    primary
                    onClick={() => {
                      setForumModalVisible(true);
                    }}
                  >
                    {t("forum.forums.btn_create")}
                  </Button>
                ) : (
                  <p>{t("forum.forums.btn_create_logout")}</p>
                )}
              </h4>
              {forumList ? (
                <>
                  <section className="pz-forum__forums--list">
                    {renderForums()}
                  </section>

                  <section className="grid grid-content-center">
                    {moreforums ? (
                      <Button primary thin onClick={getMoreThreads}>
                        {t("courses.more_courses")}
                      </Button>
                    ) : (
                      <Button disabled thin>
                        {t("courses.no_more_courses")}
                      </Button>
                    )}
                  </section>
                </>
              ) : (
                <section className="pz-forum__forums--list empty">
                  <span>{t("forum.forums.no_info")}</span>
                </section>
              )}
            </section>
          </section>
          <ForumModal
            currentNews={null}
            is_visible={forumModalVisible}
            closeModal={closeModalNews}
          />
        </>
      </section>
    </main>
  );
};

export default connectReduxReducers(withRouter(ForumsPage), "threads");
