import React from 'react';

const Image = ({
    name, src, alt, ...props
}) => (
    <img src={ src ? src : `${process.env.PUBLIC_URL}/assets/images/${name}`} loading='lazy' alt={alt} {...props} />
);

Image.defaultProps = {
    alt: 'pz',
    src: undefined
};

export default Image;