import React from "react";
import { Image } from "components/ui";
import { CONSTANTS } from "shared/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaugh,
  faFrown,
  faStar,
  faLightbulb,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import {
  faEdit,
  faFileAlt,
  faGlobe,
  faLock,
  faQuestionCircle,
  faTrash,
  faVideo,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const PostCard = ({
  post,
  onEditPost,
  onDeletePost,
  onChangeStatus,
  onDetailView,
}) => {
  const getIcon = (type) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.AUDIO:
        return faVolumeUp;
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return faFileAlt;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return faVideo;
      default:
        return faQuestionCircle;
    }
  };

  return (
    <section
      className="pz-posts__card-item"
      onClick={
        typeof onDetailView === "function"
          ? (e) => {
              e.stopPropagation();
              onDetailView(post);
            }
          : null
      }
    >
      <section className="pz-posts__card-item--type">
        <FontAwesomeIcon icon={getIcon(post?.type)} />
        <section className="grid-columns grid-gap-20 grid-items-middle">
          {typeof onChangeStatus === "function" ? (
            <FontAwesomeIcon
              icon={post.is_draft ? faLock : faGlobe}
              className={post.is_draft ? "pz-posts__card-item--private" : ""}
              title={post.is_draft ? "Privado" : "Publico"}
              onClick={(e) => {
                e.stopPropagation();
                onChangeStatus(post);
              }}
            />
          ) : null}
          {typeof onEditPost === "function" ? (
            <FontAwesomeIcon
              icon={faEdit}
              onClick={(e) => {
                e.stopPropagation();
                onEditPost(post);
              }}
            />
          ) : null}
          {typeof onDeletePost === "function" ? (
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => {
                e.stopPropagation();
                onDeletePost(post);
              }}
            />
          ) : null}
        </section>
      </section>
      <section className="pz-posts__card-item--content">
        <h4 className="bold">{post?.title}</h4>
        <p className="pz-posts__card-item--description">{post?.description}</p>
      </section>
      <section className="pz-posts__card-item--reactions">
        <h5 className="pz-posts__card-item--reaction" title="me gusta">
          <FontAwesomeIcon icon={faLaugh} /> {post?.reactions?.like_count}
        </h5>
        <h5 className="pz-posts__card-item--reaction" title="no me gusta">
          <FontAwesomeIcon icon={faFrown} /> {post?.reactions?.fun_count}
        </h5>
        <h5 className="pz-posts__card-item--reaction" title="top">
          <FontAwesomeIcon icon={faStar} /> {post?.reactions?.love_count}
        </h5>
        <h5 className="pz-posts__card-item--reaction" title="útil">
          <FontAwesomeIcon icon={faLightbulb} />{" "}
          {post?.reactions?.intriguing_count}
        </h5>
        <h5 className="pz-posts__card-item--reaction" title="eficiente">
          <FontAwesomeIcon icon={faClock} />{" "}
          {post?.reactions?.interesting_count}
        </h5>
      </section>
      <section className="pz-posts__card-item--user">
        {post?.owner?.profile?.picture ? (
          <Image
            src={
              post?.owner?.profile?.picture24
                ? post?.owner?.profile?.picture24
                : post?.owner?.profile?.picture
            }
          />
        ) : (
          <Image name="icons/male-avatar.svg" />
        )}
        <span>{post?.owner?.username}</span>
      </section>
    </section>
  );
};

export default withRouter(PostCard);
