import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";

import { withRouter } from "react-router-dom/cjs/react-router-dom";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getCookie, setCookie } from "shared/common";
import { CONSTANTS } from "shared/constants";
const NewsBanner = ({ news }) => {
  const [newsList, setNewsList] = React.useState([]);
  const [bannerCookie, setBannerCookie] = React.useState(true);
  React.useEffect(() => {
    setBannerCookie(
      getCookie(CONSTANTS.COOKIES.BANNERCOOKIES) !== undefined ? true : false
    );
    getNews();
    // eslint-disable-next-line
  }, [bannerCookie]);
  const getNews = () => {
    if (bannerCookie === false) {
      news.getNews(({ data, status }) => {
        setNewsList(status === 200 ? data : null);
      });
    }
  };

  const handleClose = () => {
    setBannerCookie(true);
    setCookie(CONSTANTS.COOKIES.BANNERCOOKIES, true, 7);
  };

  const renderNews = () => {
    return (
      <Carousel
        className="pz-news__item"
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={true}
        stopOnHover={true}
        interval={5000}
        swipeable={true}
        emulateTouch={true}
        infiniteLoop={true}
      >
        {newsList?.map((item, key) => (
          <section className="pz-news__item" key={key}>
            <h4>{item.title}</h4>
            <section className="pz-news__item--description">
              <h5>{item.description}</h5>
              {/* <FontAwesomeIcon icon={faArrowRight} className="pz-news__action" /> */}

              <Button thin className="pz-news__button">
                <a target="_blank" rel="noopener noreferrer" href={item.url}>
                  Ver{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="pz-landing__button--icon"
                  />
                </a>
              </Button>
            </section>
          </section>
        ))}
      </Carousel>
    );
  };

  return (
    <>
      {bannerCookie === false ? (
        <section className="pz-news">
          {newsList?.length > 0 ? renderNews() : null}
          <section className="pz-news__actions">
            <h5>
              <FontAwesomeIcon
                icon={faTimes}
                className="pz-news__close-button"
                onClick={handleClose}
              />
            </h5>
          </section>
        </section>
      ) : null}
    </>
  );
};

export default withRouter(connectReduxReducers(NewsBanner, "news"));
