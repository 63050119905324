import React from 'react';
import OTPInput from "otp-input-react";


const OTPInputField = ({
    name, value, onChange
}) => {
    const [otp, setOTP] = React.useState(value);

    const handleChange = (otpValue) => {
        const nextState = {
            [name]: otpValue
        };
        onChange(nextState);
        setOTP(otpValue);
    };

    const styles = {
        display: 'grid',
        justifyContent: 'center',
        gridAutoFlow: 'column',
        gridGap: '1em'
    };

    const inputStyles = {
        width: '3rem',
        height: '3rem',
        borderRadius: '50%',
        margin: 0,
        outline: 0,
        border: '1px solid #22a6d5',
        padding: '.5rem'
    };

    return (
        <OTPInput
            value={otp}
            onChange={handleChange}
            autoFocus
            OTPLength={6}
            otpType='number'
            inputStyles={inputStyles}
            style={styles}
        />
    );
};

export default OTPInputField;