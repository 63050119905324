import React from 'react';
import API from "core/api";
import { Redirect } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { connectReduxReducers } from "hocs";


const LoggedGuard = (Component) => {
    class LoggedGuardHOC extends React.Component {

        componentDidMount() {
            this.props.auth.validate_token();
        }

        render() {
            if (API.auth.isLoggedIn()) {
                return <Component {...this.props} />;
            } else {
                return <Redirect to={CONSTANTS.URLS.AUTH.LOGIN} />
            }
        }
    }
    return connectReduxReducers(LoggedGuardHOC, 'auth');
};

export default LoggedGuard;