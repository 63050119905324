import React from "react";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { Page } from "components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="pz-footer">
      <Page className="pz-footer__container">
        <section className="pz-footer__content">
          <section className="pz-footer__main">
            <section>
              <h3 className="bold">{t("footer.title")}</h3>
              <blockquote>{t("footer.subtitle")}</blockquote>
            </section>
            <section className="pz-footer__social pz-footer__row">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={CONSTANTS.URLS.SOCIAL.FACEBOOK}
                title="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={CONSTANTS.URLS.SOCIAL.LINKEDIN}
                title="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={CONSTANTS.URLS.SOCIAL.YOUTUBE}
                title="Youtube"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={CONSTANTS.URLS.SOCIAL.EMAIL}
                title="Email"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </section>
            <section className="pz-footer__columns">
              <section className="pz-footer__column">
                <h4 className="bold pz-footer__column--title">
                  {t("footer.explore.title")}
                </h4>
                <section className="pz-footer__column--content">
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.HOME}>
                    {t("footer.explore.users")}
                  </Link>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.TEACHERS}>
                    {t("footer.explore.creators")}
                  </Link>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.COMPANIES}>
                    {t("footer.explore.companies")}
                  </Link>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.UNIVERSITIES}>
                    {t("footer.explore.intitutions")}
                  </Link>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.SERVICES}>
                    {t("footer.explore.services")}
                  </Link>
                </section>
              </section>
              <section className="pz-footer__column">
                <h4 className="bold pz-footer__column--title">
                  {t("footer.community.title")}
                </h4>
                <section className="pz-footer__column--content">
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={"https://blogpanzofi.blogspot.com/"}
                  >
                    {t("footer.community.blog")}
                  </a>
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={
                      "https://sites.google.com/panzofi.com/contacto/contacto"
                    }
                  >
                    {t("footer.community.contact")}
                  </a>
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={"https://sites.google.com/panzofi.com/faq/faq"}
                  >
                    {t("footer.community.faq")}
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={CONSTANTS.URLS.LANDING.REPORT_ERROR}
                  >
                    {t("footer.community.report_bug")}
                  </a>
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={
                      "https://sites.google.com/panzofi.com/empleos/empleos"
                    }
                  >
                    {t("footer.community.hiring")}
                  </a>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section className="pz-footer__copyright">
          <span>{t("footer.copyright")}</span>
          <section className="pz-footer__row">
            <a
              target="_top"
              rel="noopener noreferrer"
              href={
                "https://sites.google.com/panzofi.com/terminos/t%C3%A9rminos-y-condiciones"
              }
            >
              {t("footer.terms")}
            </a>
            <a
              target="_top"
              rel="noopener noreferrer"
              href={"https://sites.google.com/panzofi.com/terminos/privacidad"}
            >
              {t("footer.policy")}
            </a>
          </section>
        </section>
      </Page>
    </footer>
  );
};

export default Footer;
