import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  categories: {},
  get_categories_request_status: CONSTANTS.FETCH.STANDBY,

  create_post_request_status: CONSTANTS.FETCH.STANDBY,
  get_posts_request_status: CONSTANTS.FETCH.STANDBY,
  get_post_request_status: CONSTANTS.FETCH.STANDBY,
  get_admin_posts_request_status: CONSTANTS.FETCH.STANDBY,
  modify_post_request_status: CONSTANTS.FETCH.STANDBY,
  delete_post_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  POST_REQUEST_STARTED: "POST_REQUEST_STARTED",
  POST_REQUEST_FINISHED: "POST_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.POST_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.POST_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  getCategories:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_categories_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) => {
        requestFinished(
          {
            categories: response.data.reduce(
              (map, item) => ({
                ...map,
                [item.id]: item,
              }),
              {}
            ),
            get_categories_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );
      };

      const errorCallback = (response) =>
        requestFinished(
          {
            get_categories_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCategories(successCallback, errorCallback);
    },

  createPost:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_post_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_post_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_post_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createPost(data, successCallback, errorCallback);
    },

  getPosts:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_posts_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_posts_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_posts_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getPosts(successCallback, errorCallback, filter);
    },

  getPostsRecommended:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_posts_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_posts_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_posts_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getPostsRecommended(successCallback, errorCallback);
    },

  getAdminPosts:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_admin_posts_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_admin_posts_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_admin_posts_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getAdminPosts(successCallback, errorCallback, filter);
    },

  modifyPost:
    (id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_post_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_post_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_post_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyPost(id, data, successCallback, errorCallback);
    },

  getPost:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_post_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_post_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_post_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getPost(id, successCallback, errorCallback);
    },

  deletePost:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_post_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_post_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_post_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deletePost(id, successCallback, errorCallback);
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.POST_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.POST_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
