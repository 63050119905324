import { Page } from "components/common";
import { Footer } from "components/panzofi";
import Navbar from "components/panzofi/navbar";
import { Button, Image } from "components/ui";
import React from "react";
import curso from "./course";
import ModuleRender from "./modules-render";
import { useTranslation } from "react-i18next";

const LandingTraining = () => {
  const { t } = useTranslation();
  const googleFormsUrl =
    "https://share.hsforms.com/1mcPepNzlTTaVD__n1jpYjwbptug";

  const handleContratarServicio = () => {
    window.open(googleFormsUrl, "_blank"); // Abre el formulario en una nueva pestaña del navegador
  };
  return (
    <main className="pz-landing">
      <Navbar />
      <Page>
        <div className="pz-principal">
          <section className="pz-landing__cover">
            <section className="pz-landing__audio--visual animate__animated animate__bounceIn">
              <div className="pz-landing__content">
                <Image
                  name="ilustrations/training.svg"
                  className="pz-landing__audio--visual__image--cover"
                />
              </div>
              <div className="pz-landing__content">
                <h1 className="text-center bold">
                  {t("landing.training.title")}
                </h1>
                <p>{t("landing.training.subtitle")}</p>
              </div>
            </section>
          </section>
        </div>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.training.learn.title")}</h2>
          </section>
          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/target.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.training.learn.item1.title")}
                  </h4>
                  <p>{t("landing.training.learn.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/team-tools.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.training.learn.item2.title")}
                  </h4>
                  <p>{t("landing.training.learn.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations\create-design.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.training.learn.item3.title")}
                  </h4>
                  <p>{t("landing.training.learn.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/calendar.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.training.learn.item4.title")}
                  </h4>
                  <p>{t("landing.training.learn.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__section ">
          <section className="text-center">
            <h2 className="bold">{t("landing.training.content.title")}</h2>
            <p>{t("landing.training.content.subtitle")} </p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-course__modules">
              <ModuleRender course={curso} />
            </section>
          </section>
        </section>
        <section className="pz-landing__section  ">
          <section className="text-center">
            <h2 className="bold">{t("landing.training.why_course.title")}</h2>
          </section>

          <section className="pz-landing__how-works ">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/publish.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <p>{t("landing.training.why_course.item1.title")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/estrategy.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <p>{t("landing.training.why_course.item2.title")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/marketing-strategy.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <p>{t("landing.training.why_course.item3.title")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/data.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <p>{t("landing.training.why_course.item4.title")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.training.prices.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column ">
              <article className="">
                <section className="">
                  <table className="pz-landing__audio--visual__table">
                    <thead>
                      <tr className="active-row">
                        <th>{t("landing.training.prices.table.col1.title")}</th>
                        <th>{t("landing.training.prices.table.col2.title")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h4>
                            {t("landing.training.prices.table.col1.row1")}
                          </h4>{" "}
                        </td>
                        <td>
                          <h4>$49 USD</h4>
                          <h5>
                            {" "}
                            38% Dto (<s>79 USD</s> )
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </article>
              <Button primary thin onClick={handleContratarServicio}>
                {t("landing.training.prices.table.button")}
              </Button>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <section className="pz-landing__how-works--item--content">
                  <h3>{t("landing.training.prices.list.title")}</h3>
                  <br />
                  <h4>{t("landing.training.prices.list.l1")}</h4>
                  <br />
                  <h4>{t("landing.training.prices.list.l2")}</h4>
                  <br />
                  <h4>{t("landing.training.prices.list.l3")}</h4>
                  <br />
                  <h4>{t("landing.training.prices.list.l4")}</h4>
                  <br />
                  <h4>{t("landing.training.prices.list.l5")}</h4>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__audio--visual__small">
          <p>{t("landing.training.prices.info1")}</p>
        </section>
      </Page>
      <Footer />
    </main>
  );
};

export default LandingTraining;
