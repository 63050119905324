import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {actions} from "core/redux";


const connectReduxReducers = (Component, ...reducers) => {
    if (reducers.length === 0) {
        reducers = Object.keys(actions)
    }

    const mapStateToProps = state => (
        reducers.reduce((stateProps, reducer) => ({
            ...stateProps,
            [reducer]: state[reducer]
        }), {})
    );

    const actionsToDispatch = (dispatch) => (
        reducers.reduce((dispatchProps, reducer) => ({
            ...dispatchProps,
            [reducer]: bindActionCreators(actions[reducer], dispatch)
        }), {})
    );

    const mergeProps = (stateProps, dispatchProps, ownProps) => (
        Object.keys(stateProps).reduce((mergedProps, key) => ({
            ...mergedProps,
            [key]: {
                ...stateProps[key],
                ...dispatchProps[key]
            }
        }), ownProps)
    );

    return connect(
        mapStateToProps,
        actionsToDispatch,
        mergeProps
    )(Component);
};

export default connectReduxReducers;