const forum_es = {
  forums: {
    title: "Foros Panzofi",
    no_info: "No hay foros aún",
    btn_create: "Crea tu foro",
    input_search: "Busca un foro",
    btn_create_logout: "Haz login para crear un foro",
  },
  forum: {
    title: "Foro Panzofi",
    btn_create: "Crear pregunta",
    input_search: "Busca una pregunta",
    btn_edit: "Editar foro",
    btn_delete: "Borrar foro",
    btn_create_logout: "Haz login para crear una pregunta",
  },
  thread: {
    btn_edit: "Editar pregunta",
    btn_delete: "Borrar pregunta",
  },
  answers: {
    title: "Respuestas",
    text: "Publicar una respuesta",
    btn_submit: "Publicar respuesta",
  },
};
export default forum_es;
