import { NavbarAdmin } from "apps/admin/components";
import { Navbar } from "components/panzofi";
import { Button } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  DeleteTriviaModal,
  TriviaCard,
  TriviaModal,
  PrivacyTriviaModal,
} from "./components";

const AdminTriviaPage = ({ auth, news }) => {
  const [newsList, setNewsList] = React.useState([]);
  const [newsModalVisible, setNewsModalVisible] = React.useState(false);
  const [newsDeleteModalVisible, setNewsDeleteModalVisible] =
    React.useState(false);
  const [newsPrivacyModalVisible, setNewsPrivacyModalVisible] =
    React.useState(false);
  const [newsToEdit, setNewsToEdit] = React.useState(null);

  React.useEffect(() => {
    getNews();
    // eslint-disable-next-line
  }, []);

  const getNews = () => {
    news.getAdminTrivias(({ data, status }) => {
      setNewsList(status === 200 ? data : null);
    });
  };
  const closeModalNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getNews();
    }
    setNewsToEdit(null);
    setNewsModalVisible(false);
  };
  const closeModalDeleteNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getNews();
    }
    setNewsToEdit(null);
    setNewsDeleteModalVisible(false);
  };
  const closeModalPrivacyNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getNews();
    }
    setNewsToEdit(null);
    setNewsPrivacyModalVisible(false);
  };
  const openNewsEdit = (currentNews) => {
    setNewsToEdit(currentNews);
    setNewsModalVisible(true);
  };
  const openModalDeleteNews = (currentNews) => {
    setNewsToEdit(currentNews);
    setNewsDeleteModalVisible(true);
  };
  const openModalPrivacyNews = (currentNews) => {
    setNewsToEdit(currentNews);
    setNewsPrivacyModalVisible(true);
  };

  const renderPosts = () =>
    newsList.map((post, key) => {
      return (
        <TriviaCard
          currentNews={post}
          key={key}
          onEditPost={openNewsEdit}
          onDeletePost={openModalDeleteNews}
          onChangeStatus={openModalPrivacyNews}
        />
      );
    });
  return (
    <main className="pz-admin">
      <Navbar />

      <section className="pz-admin__page">
        {auth.user.is_staff ? (
          <>
            <NavbarAdmin index={4} />
            <section className="pz-admin__content">
              <h1>Trivias</h1>
              <section className="pz-profile__courses">
                <h4 className="pz-profile__courses--title">
                  <span>
                    {"Trivias subidas"} ({newsList?.length})
                  </span>

                  <section>
                    <Button
                      thin
                      ghost
                      primary
                      onClick={() => {
                        setNewsModalVisible(true);
                      }}
                    >
                      {"Subir una trivia"}
                    </Button>
                  </section>
                </h4>
                {newsList?.length ? (
                  <section className="pz-profile__courses--list">
                    {renderPosts()}
                  </section>
                ) : (
                  <section className="pz-profile__courses--list empty">
                    <span>{"No ha noticias del banner"}</span>
                  </section>
                )}
              </section>
            </section>
            <TriviaModal
              currentNews={newsToEdit}
              is_visible={newsModalVisible}
              closeModal={closeModalNews}
            />
            <DeleteTriviaModal
              newsId={newsToEdit?.id}
              is_visible={newsDeleteModalVisible}
              closeModal={closeModalDeleteNews}
            />
            <PrivacyTriviaModal
              currentNews={newsToEdit}
              is_visible={newsPrivacyModalVisible}
              closeModal={closeModalPrivacyNews}
            />
          </>
        ) : (
          <>No tienes permiso para entrar aca</>
        )}
      </section>
    </main>
  );
};

export default connectReduxReducers(withRouter(AdminTriviaPage), "news");
