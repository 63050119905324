const reactions_es = {
  unlogged: "Regístrate para Reaccionar",
  reactions: {
    like: "me gusta",
    dislike: "no me gusta",
    quality: "calidad",
    useful: "útil",
    efficient: "eficiente",
  },
};
export default reactions_es;
