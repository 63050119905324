import React from "react";
import { Label } from "components/ui";
import { fileToBase64, formatBytes } from "shared/common/functions";

const InputField = ({
  label,
  name,
  onChange,
  notRequired,
  maxSize,
  nameDefault,
  errMsg,
}) => {
  const input = React.useRef(null);
  const [fileData, setFileData] = React.useState({
    name: nameDefault,
    size: "0 KB",
  });

  const handleOnChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      fileToBase64(files[0]).then(({ base64, file }) => {
        setFileData({
          name: file.name,
          size: formatBytes(file.size),
        });
        const data = {
          [name]: base64,
        };
        onChange(data, {
          name: file.name,
          size: formatBytes(file.size),
          type: file.type,
        });
      });
    }
  };

  const inputClick = () => {
    input.current.click();
  };

  return (
    <section className="pz-form__field">
      <Label htmlFor={name}>{label}</Label>
      <section className="pz-form__file-input" onClick={inputClick}>
        <span className="pz-form__file-input--size">{fileData?.size}</span>
        <span className="pz-form__file-input--name">{fileData?.name}</span>
        <input
          ref={input}
          type="file"
          id={name}
          name={name}
          onChange={handleOnChange}
          required={!notRequired}
        />
      </section>
      {fileData.name === nameDefault ? (
        <span className="pz-form__error_message">{errMsg}</span>
      ) : (
        <></>
      )}
    </section>
  );
};

InputField.defaultProps = {
  notRequired: false,
};

export default InputField;
