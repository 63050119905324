import React from "react";
import CookieConsent from "react-cookie-consent";
import ConsentModal from "./consent-modal";
import { setCookie } from "shared/common";
import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";

const CookieConsentBanner = () => {
  const { t } = useTranslation();

  const [consentModalVisible, setConsentModalVisible] = React.useState(false);

  const openConsentModal = () => {
    setConsentModalVisible(true);
  };
  const closeConsentModal = () => {
    setConsentModalVisible(false);
  };
  const perzonalize = () => {
    setCookie(CONSTANTS.COOKIES.NECESSARYCOOKIES, true);
    setCookie(CONSTANTS.COOKIES.FUNCTIONALCOOKIES, false);
    setCookie(CONSTANTS.COOKIES.TARGETCOOKIES, false);
    setCookie(CONSTANTS.COOKIES.PERFORMANCECOOKIES, false);
    openConsentModal();
  };

  return (
    <>
      <ConsentModal
        is_visible={consentModalVisible}
        closeModal={closeConsentModal}
      />
      <CookieConsent
        flipButtons={true}
        location="bottom"
        style={{
          background: "#E5E5E5",
          color: "black",
          location: "bottom",
        }}
        buttonText={t("others.cookies.banner.allow")}
        buttonClasses="pz-button"
        buttonStyle={{
          borderRadius: "1em",
          border: "0",
          padding: "0 2ex",
          boxShadow: "0 12px 5px -8px rgba(black, 0.45)",
          height: "3em",
          transition: "all 0.3s ease",
          backgroundColor: "#f67177",
          color: "white",
        }}
        enableDeclineButton
        declineButtonText={t("others.cookies.banner.customize")}
        declineButtonClasses="pz-button "
        declineButtonStyle={{
          borderRadius: "1em",
          padding: "0 2ex",
          boxShadow: "0 12px 5px -8px rgba(black, 0.45)",
          height: "3em",
          transition: "all 0.3s ease",
          backgroundColor: "white",
          border: "1px solid",
          borderColor: "#f67177",
          color: "#f67177",
        }}
        cookieName={CONSTANTS.COOKIES.ALLCOOKIES}
        onDecline={perzonalize}
        debug={false}
      >
        {t("others.cookies.banner.title")}
        <a
          target="_top"
          rel="noopener noreferrer"
          href={"https://sites.google.com/panzofi.com/terminos/privacidad"}
        >
          {" "}
          {t("others.cookies.banner.subtitle")}
        </a>
      </CookieConsent>
    </>
  );
};

export default CookieConsentBanner;
