import React from "react";
import { Modal } from "components/common";
import { Button, FileField, Form, InputField, Toggle } from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { CONSTANTS } from "shared/constants";
import { validateYoutubeUrl } from "shared/common";
import { useTranslation } from "react-i18next";
import { youtubeVal } from "shared/utils/input-validations";

const PracticalContentModal = ({
  courses,
  is_visible,
  closeModal,
  lesson,
  params,
  contentToEdit,
}) => {
  const [fileType, setFileType] = React.useState(undefined);
  const [isURL, setIsURL] = React.useState(false);
  const { t } = useTranslation();

  const handleURLTogle = () => {
    setIsURL(!isURL);
    if (isURL) {
      setFileType(CONSTANTS.FILE_TYPES.VIDEO);
    }
  };

  const handleSubmit = (data) => {
    const formData = {
      ...data,
      type: fileType,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }

    courses.createPracticalContent(
      params?.courseSlug,
      params?.moduleId,
      lesson?.id,
      formData,
      (response) => {
        if (response.status === 201) {
          closeModal(true);
        }
      }
    );
  };

  const updateContent = (data) => {
    const formData = {
      ...data,
      type: fileType,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }
    courses.updatePracticalContent(
      params?.courseSlug,
      params?.moduleId,
      lesson?.id,
      contentToEdit?.id,
      formData,
      (response) => {
        if (response.status === 200) {
          closeModal(true);
        }
      }
    );
  };

  const onFileChange = (onChange) => (data, fileData) => {
    if (fileData?.type) {
      switch (fileData.type) {
        case "video/mp4":
          setFileType(CONSTANTS.FILE_TYPES.VIDEO);
          break;
        case "audio/mpeg":
          setFileType(CONSTANTS.FILE_TYPES.AUDIO);
          break;
        case "application/pdf":
          setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
          break;
        default:
          setFileType(undefined);
          break;
      }
    }
    onChange(data, fileData);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {contentToEdit ? (
        <>
          <h1>{t("modals.practical.title.edit_title")}</h1>
          <p>{t("modals.practical.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.practical.title.create_title")}</h1>
          <p>{t("modals.practical.subtitle.create_subtitle")}</p>
        </>
      )}
      <Form
        onSubmit={contentToEdit ? updateContent : handleSubmit}
        className="pz-form"
      >
        {({ onChange, data: { description } }) => (
          <React.Fragment>
            <InputField
              label={t("modals.practical.title_input.title")}
              name="title"
              onChange={onChange}
              defaultValue={contentToEdit ? contentToEdit?.title : ""}
              notRequired={!!contentToEdit}
            />

            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.practical.youtube_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={isURL}
                onChange={handleURLTogle}
              />
            </article>

            {isURL ? (
              <InputField
                type="url"
                label={t("modals.practical.youtube_input.title")}
                name="youtubeurl"
                onChange={onChange}
                defaultValue={contentToEdit ? contentToEdit?.youtubeurl : ""}
                notRequired={!!contentToEdit}
                validations={[youtubeVal]}
              />
            ) : (
              <>
                <p>{t("modals.practical.file_label.title")}</p>
                <FileField
                  nameDefault={t("modals.practical.file_input.state")}
                  errMsg={t("modals.practical.file_input.error")}
                  label={t("modals.practical.file_input.title")}
                  name="file"
                  onChange={onFileChange(onChange)}
                  notRequired={!!contentToEdit}
                />
              </>
            )}
            <TextareaField
              label={`${t("modals.practical.description_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/300)`}
              maxLength={300}
              name="description"
              onChange={onChange}
              defaultValue={contentToEdit ? contentToEdit?.description : ""}
              notRequired={!!contentToEdit}
            />
            <Button type="submit" primary>
              {contentToEdit
                ? t("modals.practical.submit_button.edit_title")
                : t("modals.practical.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default connectReduxReducers(PracticalContentModal, "courses");
