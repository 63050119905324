const login_en = {
  title: "Log in",
  user: "Username or Email",
  password: "Password",
  button_login: "Log in",
  or: "or",
  button_signup: "¡Sign up for free!",
  button_forgot: "Forgot my password",
  label_forgot: "Did you forget your password?",
};
export default login_en;
