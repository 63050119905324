import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Page } from "components/common";
import { CalculatorProfit } from "apps/landing/components";
import { useTranslation } from "react-i18next";

const LandingTeachersPage = () => {
  const { t } = useTranslation();
  return (
    <main className="pz-landing">
      <Navbar />

      <Page>
        <section className="pz-landing__cover">
          <section className="pz-landing__cover--content animate__animated animate__bounceIn">
            <Image
              name="ilustrations/content_creator.svg"
              className="pz-landing__cover--image"
            />
            <h1 className="text-center bold">{t("landing.teachers.title")}</h1>
            <p>{t("landing.teachers.subtitle")}</p>
            <Button primary>
              <a
                href="https://panzofi.com/auth/signup"
                className="pz-landing__button"
              >
                {t("landing.teachers.button")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="pz-landing__button--icon"
                />
              </a>
            </Button>
          </section>
        </section>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.teachers.challenges.title")}</h2>
            <p>{t("landing.teachers.challenges.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/tomorrow.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.challenges.item1.title")}
                  </h4>
                  <p>{t("landing.teachers.challenges.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/powerful_teachers.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.challenges.item2.title")}
                  </h4>
                  <p>{t("landing.teachers.challenges.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/innovation.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.challenges.item3.title")}
                  </h4>
                  <p>{t("landing.teachers.challenges.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/learning.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.challenges.item4.title")}
                  </h4>
                  <p>{t("landing.teachers.challenges.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.teachers.tendencies.title")}</h2>
            <p>{t("landing.teachers.tendencies.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/tomorrow.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.tendencies.item1.title")}
                  </h4>
                  <p>{t("landing.teachers.tendencies.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/powerful_teachers.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.tendencies.item2.title")}
                  </h4>
                  <p>{t("landing.teachers.tendencies.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/innovation.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.tendencies.item3.title")}
                  </h4>
                  <p>{t("landing.teachers.tendencies.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/learning.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.tendencies.item4.title")}
                  </h4>
                  <p>{t("landing.teachers.tendencies.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.teachers.ecosystem.title")}</h2>
            <p>{t("landing.teachers.ecosystem.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/network_help.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.ecosystem.item1.title")}
                  </h4>
                  <p>{t("landing.teachers.ecosystem.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/Format_issues.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.ecosystem.item2.title")}
                  </h4>
                  <p>{t("landing.teachers.ecosystem.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/Location_issues.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.ecosystem.item3.title")}
                  </h4>
                  <p>{t("landing.teachers.ecosystem.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/wallet_issue.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.ecosystem.item4.title")}
                  </h4>
                  <p>{t("landing.teachers.ecosystem.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section ">
          <section className="text-center">
            <h2 className="bold">{t("landing.teachers.how_works.title")}</h2>
            <p>{t("landing.teachers.how_works.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/navigate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.how_works.item1.title")}
                  </h4>
                  <p>{t("landing.teachers.how_works.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/upload.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.how_works.item2.title")}
                  </h4>
                  <p>{t("landing.teachers.how_works.item2.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/share.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.how_works.item3.title")}
                  </h4>
                  <p>{t("landing.teachers.how_works.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/rate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.teachers.how_works.item4.title")}
                  </h4>
                  <p>{t("landing.teachers.how_works.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>

          <CalculatorProfit />

          <section className="pz-landing__section pz-landing__services-section">
            <section className="text-center">
              <h2 className="bold">
                {t("landing.teachers.influentecs.title")}
              </h2>
              <p>{t("landing.teachers.influentecs.subtitle")}</p>
            </section>

            <section className="pz-landing__how-works">
              <section className="pz-landing__how-works--column right">
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_CURIOSAMENTE.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemLeft1.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemLeft1.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/curiosamente"}
                      >
                        {t("landing.teachers.influentecs.itemLeft1.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_IVANEVSKY.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemLeft2.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemLeft2.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/ivanevsky"}
                      >
                        {t("landing.teachers.influentecs.itemLeft2.button")}
                      </a>
                    </Button>
                  </section>
                </article>

                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_TONIO.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemLeft3.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemLeft3.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/toniococina"}
                      >
                        {t("landing.teachers.influentecs.itemLeft3.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_GIBSON.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemLeft4.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemLeft4.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://www.panzofi.com/perfil/gmarval"}
                      >
                        {t("landing.teachers.influentecs.itemLeft4.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_HISTORIA.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemLeft5.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemLeft5.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/historiasparauncafe"}
                      >
                        {t("landing.teachers.influentecs.itemLeft5.button")}
                      </a>
                    </Button>
                  </section>
                </article>
              </section>

              <section className="pz-landing__how-works--column">
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_GUIDO.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemRight1.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemRight1.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/guidorosas"}
                      >
                        {t("landing.teachers.influentecs.itemRight1.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_GOOGLE.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemRight2.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemRight2.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/googleexpert"}
                      >
                        {t("landing.teachers.influentecs.itemRight2.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_JUAN.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemRight3.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemRight3.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/algebraparatodos"}
                      >
                        {t("landing.teachers.influentecs.itemRight3.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/creators/SVG_BYSPEL.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemRight4.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemRight4.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/perfil/byspel"}
                      >
                        {t("landing.teachers.influentecs.itemRight4.button")}
                      </a>
                    </Button>
                  </section>
                </article>
                <article className="pz-landing__how-works--item">
                  <Image
                    name="ilustrations/referencia-mas.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                  <section className="pz-landing__how-works--item--content">
                    <h4 className="bold">
                      {t("landing.teachers.influentecs.itemRight5.title")}
                    </h4>
                    <p>
                      {t("landing.teachers.influentecs.itemRight5.subtitle")}
                    </p>
                    <Button primary ghost thin>
                      <a
                        target="_top"
                        rel="noopener noreferrer"
                        href={"https://panzofi.com/cursos"}
                      >
                        {t("landing.teachers.influentecs.itemRight5.button")}
                      </a>
                    </Button>
                  </section>
                </article>
              </section>
            </section>
          </section>
        </section>
      </Page>

      <Footer />
    </main>
  );
};

export default LandingTeachersPage;
