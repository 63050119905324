import AuthAPI from './auth';
import PanzofiAPI from './panzofi';
import axios from "axios";
import Interceptor from './interceptor';


const axiosInstances = {
    auth: axios.create({
        baseURL: process.env.REACT_APP_AUTH_API_URL
    }),
    panzofi: axios.create({
        baseURL: process.env.REACT_APP_PANZOFI_API_URL
    })
};

const interceptor = new Interceptor();

const API = {
    auth: new AuthAPI(axiosInstances.auth, interceptor),
    panzofi: new PanzofiAPI(axiosInstances.panzofi, interceptor)
};

export default API;