const admin_es = {
  navbar: {
    option1: "Usuarios",
    option2: "Cursos",
    option3: "Edu clips",
  },
  form: {
    start_date: "Fecha inicio",
    end_date: "Fecha final",
    button: "Buscar",
  },
  users: {
    title: "Usuarios",
    headers: {
      resource_info: {
        title: "Información del usuario",
        id: {
          title: "ID",
        },
        username: {
          title: "Nombre de usuario",
        },
        name: {
          title: "Nombre",
        },
        email: {
          title: "Email",
        },
      },
      dates_info: {
        title: "Información de fechas",
        date_joined: {
          title: "Fecha en que se unió",
        },
        last_login: {
          title: "Último login",
        },
      },
    },
  },
  questionaire: {
    title: "Cuestionarios de los usuarios",
    headers: {
      resource_info: {
        title: "Cuestionarios de los usuarios",
        username: {
          title: "Nombre de usuario",
        },
        creator: {
          title: "¿Te interesa crear contenido?",
        },
        learn: {
          title: "¿Qué te gustaría aprender?",
        },
        teach: {
          title: "¿Qué te gustaría enseñar?",
        },
        experience: {
          title: "¿Cuál es tu nivel en esas categorias?",
        },
        background: {
          title: "¿Cuál es tu nivel de estudio?",
        },
        reason: {
          title: "¿Cuál es la razón por la que estás aprendiendo?",
        },
        media: {
          title: "¿Cuál es tu método de aprendizaje preferido?",
        },
        functionality: {
          title: "Funcionalidad",
        },
      },
    },
  },
  courses: {
    title: "Cursos",
    headers: {
      resource_info: {
        title: "Información del curso",
        id: {
          title: "ID",
        },
        name: {
          title: "Nombre del curso",
        },
        owner: {
          title: "Dueño",
        },
        language: {
          title: "Lenguage",
        },
        category: {
          title: "Categoria",
        },
        is_draft: {
          title: "Estado",
          private: "Privado",
          public: "Publico",
        },
      },
      dates_info: {
        title: "Información de fechas",
        created: {
          title: "Fecha de creacion",
        },
        updated: {
          title: "Fecha de actualizacion",
        },
      },
    },
  },

  posts: {
    title: "Edu clips",
    headers: {
      resource_info: {
        title: "Información del edu clip",
        id: {
          title: "ID",
        },
        title_post: {
          title: "Titulo del edu clip",
        },
        owner: {
          title: "Dueño",
        },
        category: {
          title: "Categoria",
        },
        type: {
          title: "Tipo",
        },
        is_draft: {
          title: "Estado",
          private: "Privado",
          public: "Publico",
        },
      },
      dates_info: {
        title: "Información de fechas",
        created: {
          title: "Fecha de creacion",
        },
        updated: {
          title: "Fecha de actualizacion",
        },
      },
    },
  },
};
export default admin_es;
