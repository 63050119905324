const cursos = [
  {
    id: "8763ce1a-42c4-4753-9fc6-8b8d82cab653",
    created: "2024-04-13T19:44:28.900564-05:00",
    updated: "2024-07-21T15:46:32.984786-05:00",
    slug: "java-desde-cero-netbeans-aprende-java-en-menos-de",
    owner: {
      id: "22dd29ea-9a30-495d-b9d4-d2e6f19aa204",
      slug: "byspel",
      username: "byspel",
      full_name: "Iván David López Hoyos",
      profile: {
        slug: "byspel",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/22dd29ea-9a30-495d-b9d4-d2e6f19aa204/picture24_1723733318.png",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/22dd29ea-9a30-495d-b9d4-d2e6f19aa204/picture_1721347497.png",
        introduction:
          'Soy un apasionado de la programación y la tecnología, "van casi 20 años, desde que empecé a programar, y aún siento el entusiasmo de la primera línea de código"..',
      },
    },
    is_draft: false,
    category: "53e381b8-9a2b-45f4-870a-a7a99687b7b8",
    name: "JAVA DESDE CERO NetBeans☕ APRENDE JAVA en MENOS de",
    description:
      "Si NUNCA has PROGRAMADO o tienes dificultad para APRENDER JAVA desde CERO es un curso hecho para ti, hoy te explicaré paso a paso, las estructuras primordiales de este lenguaje, te aseguro que empezarás a escribir programas básicos y podrás avanzar en tu aprendizaje!.",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/8763ce1a-42c4-4753-9fc6-8b8d82cab653/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/8763ce1a-42c4-4753-9fc6-8b8d82cab653/thumbnail200_1723732589.jpg",
    reactions: {
      like_count: 10,
      fun_count: 0,
      intriguing_count: 0,
      interesting_count: 0,
      love_count: 1,
    },
    users: 0,
    language: "español",
  },
  {
    id: "cbc1cbb6-3646-4594-9921-88f16e5da7c1",
    created: "2024-05-15T15:05:04.069798-05:00",
    updated: "2024-08-10T18:37:22.735136-05:00",
    slug: "educacion-para-la-sostenibilidad",
    owner: {
      id: "7599c410-61f7-4248-9106-51b51a2d79d8",
      slug: "curiosamente",
      username: "CuriosaMente",
      full_name: "CuriosaMente",
      profile: {
        slug: "curiosamente",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/7599c410-61f7-4248-9106-51b51a2d79d8/picture24_1723732957.png",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/7599c410-61f7-4248-9106-51b51a2d79d8/picture.png",
        introduction:
          "Una invitación para las mentes curiosas a despertar, con el poder de la palabra y la imagen, la diversión y el sentido de la maravilla que solo el conocimiento puede dar.",
      },
    },
    is_draft: false,
    category: "a6666b18-4b20-4df1-a6fa-ecff52311dbe",
    name: "Educación para la sostenibilidad",
    description:
      "¿Cuáles son los retos más grandes que enfrentamos como humanidad?",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/cbc1cbb6-3646-4594-9921-88f16e5da7c1/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/cbc1cbb6-3646-4594-9921-88f16e5da7c1/thumbnail200_1723732512.jpg",
    reactions: {
      like_count: 19,
      fun_count: 0,
      intriguing_count: 1,
      interesting_count: 1,
      love_count: 2,
    },
    users: 0,
    language: "español",
  },
  {
    id: "03d383c0-653d-4253-88d0-eb1b504869ac",
    created: "2024-03-25T17:57:21.368392-05:00",
    updated: "2024-07-21T15:40:48.750098-05:00",
    slug: "el-surrealismo-como-nunca-te-lo-han-contado",
    owner: {
      id: "1d62d1a9-7bd9-4cad-81a0-972098a12896",
      slug: "teexplicouncuadro",
      username: "TeExplicoUnCuadro",
      full_name: "Te Explico Un Cuadro",
      profile: {
        slug: "teexplicouncuadro",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/1d62d1a9-7bd9-4cad-81a0-972098a12896/picture24_1723733271.jpg",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/1d62d1a9-7bd9-4cad-81a0-972098a12896/picture.jpg",
        introduction: "Hago videos sobre arte :)",
      },
    },
    is_draft: false,
    category: "132de544-cc08-4ace-a7ba-22e7920d0877",
    name: "EL SURREALISMO como nunca te lo han contado :)",
    description:
      "Conoce a fondo una de las corrientes más divertidas del arte. 🦒",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/03d383c0-653d-4253-88d0-eb1b504869ac/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/03d383c0-653d-4253-88d0-eb1b504869ac/thumbnail200_1723732504.jpg",
    reactions: {
      like_count: 14,
      fun_count: 0,
      intriguing_count: 4,
      interesting_count: 1,
      love_count: 6,
    },
    users: 0,
    language: "español",
  },
  {
    id: "024c5cde-c3be-4cd0-84dd-c8e1a7556004",
    created: "2023-12-13T19:33:57.873563-05:00",
    updated: "2024-01-13T08:40:41.003551-05:00",
    slug: "como-crear-un-video-marketing-con-ia-flexclip",
    owner: {
      id: "db658fbd-1f68-4b41-967b-d46806fbff2d",
      slug: "negociostartoficial",
      username: "negociostartoficial",
      full_name: "Alejandro Ochoa",
      profile: {
        slug: "negociostartoficial",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/db658fbd-1f68-4b41-967b-d46806fbff2d/picture24_1723601098.png",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/db658fbd-1f68-4b41-967b-d46806fbff2d/picture.png",
        introduction:
          "Soy Creador de contenido en Páginas Web y canal de YouTube. He venido trabajando en el mercado de afilliados de manera independiente con otras plataformas y empresas. Tengo conocimiento en Diseño web con WordPress y Elementor. Soy Comunicador Social",
      },
    },
    is_draft: false,
    category: "1fc92599-0604-4e22-b827-a0de136863e1",
    name: "Cómo crear un Video Marketing con IA FlexClip",
    description:
      "En este tutorial, aprenderás a crear un video marketing con inteligencia artificial. La IA, puede ayudarte a crear videos de alta calidad de forma rápida y sencilla.\nSolo necesitas: Una computadora con conexión a internet y una herramienta de Video IA como FlexClip",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/024c5cde-c3be-4cd0-84dd-c8e1a7556004/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/024c5cde-c3be-4cd0-84dd-c8e1a7556004/thumbnail200_1723732433.jpg",
    reactions: {
      like_count: 35,
      fun_count: 1,
      intriguing_count: 1,
      interesting_count: 2,
      love_count: 22,
    },
    users: 0,
    language: "español",
  },
  {
    id: "4e72f501-0bf1-452a-977e-fd6c703f88a7",
    created: "2024-04-26T11:46:46.991840-05:00",
    updated: "2024-08-10T19:04:17.105849-05:00",
    slug: "introduccion-al-machine-learning",
    owner: {
      id: "92d66605-b6b1-42c5-a2e9-82d6a18d98e8",
      slug: "vviera",
      username: "vviera",
      full_name: "Victor Viera Balanta",
      profile: {
        slug: "vviera",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/92d66605-b6b1-42c5-a2e9-82d6a18d98e8/picture24_1723733282.jpg",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/92d66605-b6b1-42c5-a2e9-82d6a18d98e8/picture.jpg",
        introduction:
          "Ingeniero de Sistemas, profesor Universitario, Estudios de Maestría en Inteligencia Artificial. Experiencia en dirección de proyectos informáticos. Escritor sobre Computación Móvil e Inteligencia Artificial",
      },
    },
    is_draft: false,
    category: "45ddb923-5e46-4395-b1e2-4a0d523bc7ef",
    name: "Introducción al Machine Learning",
    description: "Introducción al Aprendizaje de Máquina",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/4e72f501-0bf1-452a-977e-fd6c703f88a7/thumbnail.png",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/4e72f501-0bf1-452a-977e-fd6c703f88a7/thumbnail200_1723732365.png",
    reactions: {
      like_count: 130,
      fun_count: 1,
      intriguing_count: 37,
      interesting_count: 15,
      love_count: 29,
    },
    users: 0,
    language: "español",
  },
  {
    id: "b3ba03fe-a448-4fed-a496-7ae32278e9f3",
    created: "2024-05-11T18:53:18.889305-05:00",
    updated: "2024-07-21T13:35:40.445386-05:00",
    slug: "mitologia-y-religion",
    owner: {
      id: "29394522-a289-49ed-807f-393da66efc17",
      slug: "historiasparauncafe",
      username: "HistoriasParaUnCafe",
      full_name: "Leonardo Bravo",
      profile: {
        slug: "historiasparauncafe",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/29394522-a289-49ed-807f-393da66efc17/picture24_1723733279.jpg",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/29394522-a289-49ed-807f-393da66efc17/picture.jpg",
        introduction:
          "Historias para un Café es un espacio dedicado a la Historia, sobretodo a recorrer las diferentes culturas, reinos e imperios.",
      },
    },
    is_draft: false,
    category: "c8c17d3e-1a54-4375-94a3-981efda6d295",
    name: "Mitología y Religión",
    description:
      "Recorrido sobre las diferentes Mitologías y Religiones, el panteón de Dioses, Origen y Culto",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/b3ba03fe-a448-4fed-a496-7ae32278e9f3/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/b3ba03fe-a448-4fed-a496-7ae32278e9f3/thumbnail200_1723732523.jpg",
    reactions: {
      like_count: 12,
      fun_count: 0,
      intriguing_count: 3,
      interesting_count: 2,
      love_count: 3,
    },
    users: 0,
    language: "español",
  },
  {
    id: "4b999336-8d88-4a2e-8c7a-bed1270cb13a",
    created: "2024-06-11T14:47:19.061836-05:00",
    updated: "2024-07-21T15:49:42.530518-05:00",
    slug: "retos-matematicos",
    owner: {
      id: "f928def6-a538-4ac3-835d-e9783caa2f5e",
      slug: "algebraparatodos",
      username: "AlgebraParaTodos",
      full_name: "Juan Ignacio Silva",
      profile: {
        slug: "algebraparatodos",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/f928def6-a538-4ac3-835d-e9783caa2f5e/picture24_1723733319.jpg",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/f928def6-a538-4ac3-835d-e9783caa2f5e/picture.jpg",
        introduction:
          "Creador de contenido educativo y programador. \nEs un placer para mi brindar videos de forma gratuita a todos aquellos que deseen aprender de forma independiente",
      },
    },
    is_draft: false,
    category: "ce18b6ee-8b8d-4c4c-b2b2-c9645af77ead",
    name: "Retos matemáticos",
    description:
      "Problemas destinados a que reflexiones para encontrar una solución, usando herramientas básicas de matemáticas.",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/4b999336-8d88-4a2e-8c7a-bed1270cb13a/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/4b999336-8d88-4a2e-8c7a-bed1270cb13a/thumbnail200_1723732492.jpg",
    reactions: {
      like_count: 22,
      fun_count: 0,
      intriguing_count: 2,
      interesting_count: 0,
      love_count: 3,
    },
    users: 0,
    language: "español",
  },
  {
    id: "2d04a87f-5994-4ed6-b2fa-c6ed2a2bd4eb",
    created: "2024-05-22T13:16:54.929172-05:00",
    updated: "2024-08-10T18:34:30.932953-05:00",
    slug: "orientacion-vocacional",
    owner: {
      id: "7599c410-61f7-4248-9106-51b51a2d79d8",
      slug: "curiosamente",
      username: "CuriosaMente",
      full_name: "CuriosaMente",
      profile: {
        slug: "curiosamente",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/7599c410-61f7-4248-9106-51b51a2d79d8/picture24_1723732957.png",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/7599c410-61f7-4248-9106-51b51a2d79d8/picture.png",
        introduction:
          "Una invitación para las mentes curiosas a despertar, con el poder de la palabra y la imagen, la diversión y el sentido de la maravilla que solo el conocimiento puede dar.",
      },
    },
    is_draft: false,
    category: "b9c2b386-d4a3-4525-984b-05bc66e797ab",
    name: "Orientación vocacional",
    description:
      "Ya los filósofos griegos se preguntaban ¿Qué significa llevar una buena vida? Para Sócrates la respuesta está en encontrar el equilibrio entre el placer y la sabiduría. Para Aristóteles la felicidad se alcanza cultivando la virtud y el conocimiento.",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/2d04a87f-5994-4ed6-b2fa-c6ed2a2bd4eb/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/2d04a87f-5994-4ed6-b2fa-c6ed2a2bd4eb/thumbnail200_1723732564.jpg",
    reactions: {
      like_count: 7,
      fun_count: 0,
      intriguing_count: 1,
      interesting_count: 1,
      love_count: 5,
    },
    users: 0,
    language: "español",
  },
  {
    id: "ddc41429-bcf6-43ea-aa76-791542f12e0f",
    created: "2024-05-19T22:21:00.851753-05:00",
    updated: "2024-05-20T09:00:12.831536-05:00",
    slug: "la-logica-de-la-programacion",
    owner: {
      id: "b2f768ed-45fc-46fa-8888-760df576c82d",
      slug: "unsimpledev",
      username: "unsimpleDev",
      full_name: "un simple Dev",
      profile: {
        slug: "unsimpledev",
        picture24:
          "https://panzofi-multimedia.panzofi.com/users/b2f768ed-45fc-46fa-8888-760df576c82d/picture24_1723733302.png",
        picture:
          "https://panzofi-multimedia.panzofi.com/users/b2f768ed-45fc-46fa-8888-760df576c82d/picture.png",
        introduction:
          "Soy Orlando, Ingeniero en Informática, con más de 15 años de experiencia en desarrollo de software, trabajando en diferentes empresas, empresa propia, freelance y en proyectos propios (lo que más me gusta hacer), pasando por muchos roles en el camino. Contenido de programación, diseño, arquitectura.",
      },
    },
    is_draft: false,
    category: "53e381b8-9a2b-45f4-870a-a7a99687b7b8",
    name: "La lógica de la programación",
    description:
      "Curso completo de lógica de la programación, la base para aprender a programar, y lo que debes saber para pasar una entrevistas de trabajo.",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/ddc41429-bcf6-43ea-aa76-791542f12e0f/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/ddc41429-bcf6-43ea-aa76-791542f12e0f/thumbnail200_1723732775.jpg",
    reactions: {
      like_count: 2,
      fun_count: 0,
      intriguing_count: 0,
      interesting_count: 0,
      love_count: 1,
    },
    users: 0,
    language: "español",
  },
  {
    id: "658a97a6-fd24-4ab4-a3ac-24fdfdcea595",
    created: "2024-04-13T15:33:13.121736-05:00",
    updated: "2024-05-05T12:23:13.585545-05:00",
    slug: "mesopotamia-la-cuna-de-la-civilizacion",
    owner: {
      id: "54373cd5-d089-46a4-94dd-7f29d8c6a00d",
      slug: "profeedgio",
      username: "ProfeEdGio",
      full_name: "Edwin Giovanni Becerra Duitama",
      profile: {
        slug: "profeedgio",
        picture24: null,
        picture: null,
        introduction: null,
      },
    },
    is_draft: false,
    category: "c8c17d3e-1a54-4375-94a3-981efda6d295",
    name: "MESOPOTAMIA: LA CUNA DE LA CIVILIZACIÓN",
    description:
      "En esta lección se aprenderán los elementos generales del origen de la civilización en Mesopotamia",
    thumbnail:
      "https://panzofi-multimedia.panzofi.com/courses/658a97a6-fd24-4ab4-a3ac-24fdfdcea595/thumbnail.jpg",
    thumbnail200:
      "https://panzofi-multimedia.panzofi.com/courses/658a97a6-fd24-4ab4-a3ac-24fdfdcea595/thumbnail200_1723732390.jpg",
    reactions: {
      like_count: 100,
      fun_count: 2,
      intriguing_count: 4,
      interesting_count: 5,
      love_count: 2,
    },
    users: 1,
    language: "español",
  },
];
export default cursos;
