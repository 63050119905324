import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { connectReduxReducers } from "hocs";

const QuizPassed = ({ auth, courses, id_quiz }) => {
  const [view, setView] = React.useState(false);

  React.useEffect(() => {
    getView();
    // eslint-disable-next-line
  }, []);

  const getView = (id = id_quiz) => {
    if (id !== undefined) {
      courses.getPassedQuizAttempt(id, ({ data, status }) => {
        setView(status === 200 ? true : false);
      });
    }
  };

  return (
    <>
      {view ? (
        <FontAwesomeIcon
          title="Ya has pasado este quiz"
          icon={faCheckCircle}
          className="pz-course-modules__module--lesson--view--active"
        ></FontAwesomeIcon>
      ) : (
        <FontAwesomeIcon
          title="No has pasado este quiz"
          icon={faMinusCircle}
          className="pz-course-modules__module--lesson--view"
        ></FontAwesomeIcon>
      )}
    </>
  );
};

export default connectReduxReducers(QuizPassed, "courses");
