import React from 'react';


const Checkbox = (props) => (
    <section className='pz-form__checkbox'>
        <input {...props}/>
        <span />
    </section>
);

export default Checkbox;