import React from "react";
import { Navbar, NavbarConfig } from "components/panzofi";
import { Button, Form, InputField } from "components/ui";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";

const SettingsPage = ({ auth, profile }) => {
  const { t } = useTranslation();
  const editUser = (data) => {
    profile.updateUser(auth.user?.slug, data, ({ status }) => {
      if (status === 200) {
      }
    });
  };
  return (
    <main className="pz-settings">
      <Navbar />
      <section className="pz-settings__page">
        <NavbarConfig index={0} />
        <section className="pz-settings__content">
          <h1>{t("others.settings.account.title")}</h1>
          <p>{t("others.settings.account.subtitle")}</p>
          <br />
          <Form onSubmit={editUser}>
            {({ onChange, data: { introduction } }) => (
              <React.Fragment>
                <InputField
                  label={t("register.name")}
                  name="first_name"
                  onChange={onChange}
                  defaultValue={auth.user?.first_name}
                  notRequired
                />
                <InputField
                  label={t("register.last_names")}
                  name="last_name"
                  onChange={onChange}
                  defaultValue={auth.user?.last_name}
                  notRequired
                />
                <InputField
                  label={t("register.user")}
                  name="username"
                  onChange={onChange}
                  defaultValue={auth.user?.username}
                  notRequired
                />
                <InputField
                  label={t("register.email")}
                  name="email"
                  onChange={onChange}
                  defaultValue={auth.user?.email}
                  notRequired
                />
                <dl>
                  <dd>
                    {" "}
                    <h6>{t(`profile.modal.info_input.title`)}</h6>
                  </dd>
                </dl>
                <section className="pz-auth-form__buttons">
                  <Button type="submit" primary thin>
                    {t("others.settings.account.button")}
                  </Button>
                </section>
              </React.Fragment>
            )}
          </Form>
        </section>
      </section>
    </main>
  );
};

export default connectReduxReducers(SettingsPage, "auth", "profile");
