import { Button } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ShareModal from "./share-modal";
import { useTranslation } from "react-i18next";

const ShareButton = ({ hashtag, title, url }) => {
  const [shareModalVisible, setShareModalVisible] = React.useState(false);
  const { t } = useTranslation();
  const openShareModal = () => {
    setShareModalVisible(true);
  };
  const closeShareModal = () => {
    setShareModalVisible(false);
  };
  return (
    <>
      <ShareModal
        is_visible={shareModalVisible}
        closeModal={closeShareModal}
        hashtag={hashtag}
        title={title}
        url={url}
      />

      <Button thin share onClick={openShareModal}>
        <h5 className="pz-landing__button">
          {t("others.share_button")}
          <FontAwesomeIcon
            icon={faShareAlt}
            className="pz-landing__button--icon"
          ></FontAwesomeIcon>
        </h5>
      </Button>
    </>
  );
};

export default ShareButton;
