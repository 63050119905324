import React from 'react';
import API from "core/api";
import { connectReduxReducers } from "hocs";


const OptionalLoggedGuard = (Component) => {
    class OptionalLoggedGuardHOC extends React.Component {

        componentDidMount() {
            if (API.auth.isLoggedIn()) {
                this.props.auth.validate_token();
            }
        }

        render() {
            return <Component {...this.props} />;
        }
    }
    return connectReduxReducers(OptionalLoggedGuardHOC, 'auth');
};

export default OptionalLoggedGuard;