import { applyMiddleware, bindActionCreators, compose, createStore } from "redux";
import thunk from 'redux-thunk';
import { actionCreators, initialState, rootReducer } from './reducers';


const Store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(thunk))
);

const BindActions = bindActionCreators(actionCreators, Store.dispatch);

export default Store;
export * from './reducers';
export { BindActions };