import React from "react";
import { Modal } from "components/common";
import {
  Button,
  FileField,
  Form,
  InputField,
  SelectField,
  TagField,
} from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { getAsList } from "shared/common/functions";
import { CONSTANTS } from "shared/constants";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModuleModal = ({ courses, is_visible, closeModal, course, history }) => {
  const { t } = useTranslation();

  const tagsArray =
    !!course && course.tags
      ? course.tags.split(",").map((tag) => tag.trim())
      : [];

  React.useEffect(() => {
    if (is_visible) {
      courses.getCategories();
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const handleSubmit = (data) => {
    courses.createCourse(data, (response) => {
      if (response.status === 201) {
        closeModal(true);
      }
    });
  };

  const editCourse = (data) => {
    courses.modifyCourse(course?.slug, data, (response) => {
      if (response.status === 200) {
        history.push(
          CONSTANTS.URLS.COURSES.COURSE_DETAIL(response?.data?.slug)
        );
      }
    });
  };

  const getCategoryOptions = (slug1, categories) =>
    categories.map(({ slug, id }) => ({
      label: t(`categories.${slug1}.sub_categories.${slug}.name`),
      value: id,
    }));

  const getCategoryGroupOptions = () =>
    getAsList(courses.categories).map(({ slug, sub_categories }) => ({
      label: t(`categories.${slug}.name`),
      options: getCategoryOptions(slug, sub_categories),
    }));

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {!!course ? (
        <>
          <h1>{t("modals.course.title.edit_title")}</h1>
          <p>{t("modals.course.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.course.title.create_title")}</h1>
          <p>{t("modals.course.subtitle.create_subtitle")}</p>
        </>
      )}
      <Form onSubmit={!!course ? editCourse : handleSubmit} className="pz-form">
        {({ onChange, data: { name, description, tags = tagsArray } }) => (
          <React.Fragment>
            <FileField
              errMsg={!!course ? "" : t("modals.course.file_input.error")}
              nameDefault={t("modals.course.file_input.state")}
              label={t("modals.course.file_input.title")}
              name="thumbnail"
              onChange={onChange}
              notRequired={!!course}
            />
            <InputField
              label={`${t("modals.course.name_input.title")} (${
                typeof name === "string" ? name.length : 0
              }/50)`}
              maxLength={50}
              name="name"
              onChange={onChange}
              defaultValue={!!course ? course?.name : ""}
              notRequired={!!course}
            />
            <SelectField
              label={t("modals.course.category_input.title")}
              name="category"
              onChange={onChange}
              options={getCategoryGroupOptions()}
              notRequired={!!course}
            />
            <TagField
              label={t("modals.course.tags_input.title")}
              name="tags"
              onChange={onChange}
              tags={tags}
              defaultValue={tagsArray}
              notRequired={!!course}
            />
            <SelectField
              label={t("modals.course.language_input.title")}
              name="language"
              onChange={onChange}
              options={[
                { value: "español", label: "Español" },
                { value: "inglés", label: "English" },
                { value: "portugués", label: "Portuguese" },
                { value: "francés", label: "Francais" },
              ]}
              notRequired={!!course}
            />
            <SelectField
              label={t("modals.course.level_input.title")}
              name="level"
              onChange={onChange}
              options={[
                {
                  value: "BEGINNER",
                  label: t("modals.course.level_input.levels.level1"),
                },
                {
                  value: "INTERMEDIATE",
                  label: t("modals.course.level_input.levels.level2"),
                },
                {
                  value: "ADVANCED",
                  label: t("modals.course.level_input.levels.level3"),
                },
              ]}
              notRequired={!!course}
            />
            <TextareaField
              label={`${t("modals.course.description_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/300)`}
              maxLength={300}
              name="description"
              onChange={onChange}
              defaultValue={!!course ? course?.description : ""}
              notRequired={!!course}
            />

            <small className="bold">
              {" "}
              {t("modals.course.info_input.title")}
            </small>

            <Button type="submit" primary>
              {!!course
                ? t("modals.course.submit_button.edit_title")
                : t("modals.course.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "courses"));
