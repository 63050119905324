import React from 'react';
import API from "core/api";
import { Redirect } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { connectReduxReducers } from "hocs/index";


const UnLoggedGuard = (Component) => {
    class UnLoggedGuardHOC extends React.Component {
        render() {
            if (API.auth.isLoggedIn()) {
                return <Redirect to={CONSTANTS.URLS.PROFILE.USER_LOGGED}/>
            } else {
                return <Component {...this.props} />;
            }
        }
    }
    return connectReduxReducers(UnLoggedGuardHOC, 'auth');
};

export default UnLoggedGuard;