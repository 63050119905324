import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  create_comment_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  COMMENTS_REQUEST_STARTED: "COMMENTS_REQUEST_STARTED",
  COMMENTS_REQUEST_FINISHED: "COMMENTS_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.COMMENTS_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.COMMENTS_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  createComment:
    (data, model, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_comment_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_comment_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_comment_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createComment(data, model, successCallback, errorCallback);
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.COMMENTS_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.COMMENTS_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
