const footer_es = {
  title: "Panzofi",
  subtitle:
    "Estamos en una misión para democratizar la educación en todo el mundo sin importar las fronteras. Tu imaginación y motivación son tus límites . . .",
  explore: {
    title: "Explorar",
    users: "Usuarios - Estudiantes",
    creators: "Creadores de contenido - Docentes",
    companies: "Empresas",
    intitutions: "Instituciones educativas",
    services: "Servicios Panzofi",
  },
  community: {
    title: "Comunidad",
    blog: "Blog",
    contact: "Contacto",
    faq: "FAQ",
    report_bug: "Reportar error",
    hiring: "¡Hey, estamos contratando!",
  },
  copyright: "Copyright © Panzofi 2024 -Todos los derechos reservados",
  terms: "Términos y condiciones",
  policy: "Políticas de privacidad",
};
export default footer_es;
