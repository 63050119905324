import React from "react";
import { inputHandler } from "hocs";
import { Label } from "components/ui";

const DateField = ({
  label,
  name,
  type,
  value,
  onChange,
  notRequired,
  validations,
  maxLength,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const checkValidations = (text) => {
    setErrorMessage(validations.find((val) => val.validation(text))?.message);
  };
  const handleChange = (event) => {
    checkValidations(event.target.value);
    onChange(event);
  };
  return (
    <section className="pz-form__field">
      <Label htmlFor={name}>{label}</Label>
      <input
        className="pz-form__input"
        type={type}
        id={name}
        name={name}
        onChange={handleChange}
        value={value}
        required={!notRequired}
        maxLength={maxLength}
        disabled={disabled}
      />
      <span className="pz-form__error_message">{errorMessage}</span>
    </section>
  );
};

DateField.defaultProps = {
  type: "date",
  notRequired: false,
  validations: [],
  maxLength: null,
  disabled: false,
};

export default inputHandler(DateField);
