import React from "react";
import Button from "../button";
import { useTranslation } from "react-i18next";

const PDFDocument = ({ url }) => {
  const { t } = useTranslation();
  return (
    <>
      <object
        data={
          url
          //  + "#toolbar=0"
        }
        type="application/pdf"
        style={{ border: 0, width: "100%", height: "45rem" }}
      >
        <embed
          src={
            url
            // + "#toolbar=0"
          }
          type="application/pdf"
        />
      </object>

      <a
        href={url}
        download="MyExampleDoc"
        target="_blank"
        rel="noopener noreferrer"
        style={{ margin: "10px", justifySelf: "center" }}
      >
        <Button primary>{t("lesson.pdf-button")}</Button>
      </a>
    </>
  );
};

export default PDFDocument;
