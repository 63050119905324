const categories_es = {
  "ciencias-sociales": {
    name: "Ciencias Sociales",
    sub_categories: {
      antropologia: {
        name: "Antropología",
        sub_categories: {},
      },
      "administracion-de-empresas": {
        name: "Administración de Empresas",
        sub_categories: {},
      },
      historia: {
        name: "Historia",
        sub_categories: {},
      },
      psicologia: {
        name: "Psicología",
        sub_categories: {},
      },
      "ciencias-economicas": {
        name: "Ciencias económicas",
        sub_categories: {},
      },
      "ciencias-politicas": {
        name: "Ciencias políticas",
        sub_categories: {},
      },
      contraterrorismo: {
        name: "Contraterrorismo",
        sub_categories: {},
      },
      "desarrollo-infantil": {
        name: "Desarrollo infantil",
        sub_categories: {},
      },
      "desarrollo-internacional": {
        name: "Desarrollo internacional",
        sub_categories: {},
      },
      educacion: {
        name: "Educación",
        sub_categories: {},
      },
      "educacion-mas-alta": {
        name: "Educación superior",
        sub_categories: {},
      },
      "estudios-de-genero": {
        name: "Estudios de género",
        sub_categories: {},
      },
      felicidad: {
        name: "Felicidad",
        sub_categories: {},
      },
      "formacion-del-profesorado": {
        name: "Formación del profesorado",
        sub_categories: {},
      },
      "gestion-de-riesgos": {
        name: "Gestión de riesgos",
        sub_categories: {},
      },
      globalizacion: {
        name: "Globalización",
        sub_categories: {},
      },
      inmigracion: {
        name: "Inmigración",
        sub_categories: {},
      },
      k12: {
        name: "K12",
        sub_categories: {},
      },
      macroeconomica: {
        name: "Macroeconómica",
        sub_categories: {},
      },
      microeconomia: {
        name: "Microeconomía",
        sub_categories: {},
      },
      sociologia: {
        name: "Sociología",
        sub_categories: {},
      },
      "tecnologia-educacional": {
        name: "Tecnologia Educacional",
        sub_categories: {},
      },
      "teoria-de-juego": {
        name: "Teoría de juego",
        sub_categories: {},
      },
      geografia: {
        name: "Geografía",
        sub_categories: {},
      },
      demografia: {
        name: "Demografía",
        sub_categories: {},
      },
      comunicacion: {
        name: "Comunicación ",
        sub_categories: {},
      },
      "ciencias-juridicas": {
        name: "Ciencias Jurídicas",
        sub_categories: {},
      },
    },
  },
  salud: {
    name: "Ciencias de la Salud",
    sub_categories: {
      "administracion-de-salubridad": {
        name: "Administración de salubridad",
        sub_categories: {},
      },
      "anatomia-humana": {
        name: "Anatomía humana",
        sub_categories: {},
      },
      "atencion-al-paciente": {
        name: "Atención al paciente",
        sub_categories: {},
      },
      enfermeria: {
        name: "Enfermería",
        sub_categories: {},
      },
      "informatica-de-salud": {
        name: "Informática de salud",
        sub_categories: {},
      },
      "la-seguridad": {
        name: "La seguridad",
        sub_categories: {},
      },
      medicina: {
        name: "Medicina",
        sub_categories: {},
      },
      "medicina-veterinaria": {
        name: "Medicina Veterinaria",
        sub_categories: {},
      },
      nutricion: {
        name: "Nutrición",
        sub_categories: {},
      },
      "salud-publica": {
        name: "Salud pública",
        sub_categories: {},
      },
      fisioterapia: {
        name: "Fisioterapia",
        sub_categories: {},
      },
    },
  },
  "administracion-de-empresas": {
    name: "Administración de Empresas",
    sub_categories: {
      contabilidad: {
        name: "Contabilidad",
        sub_categories: {},
      },
      emprendimiento: {
        name: "Emprendimiento",
        sub_categories: {},
      },
      "estrategia-de-negocios": {
        name: "Estrategia de negocios",
        sub_categories: {},
      },
      finanzas: {
        name: "Finanzas",
        sub_categories: {},
      },
      "finanzas-corporativas": {
        name: "Finanzas corporativas",
        sub_categories: {},
      },
      "fundamentos-empresariales": {
        name: "Fundamentos empresariales",
        sub_categories: {},
      },
      "gestion-de-proyectos": {
        name: "Gestión de proyectos",
        sub_categories: {},
      },
      "jefe-de-operaciones": {
        name: "Jefe de operaciones",
        sub_categories: {},
      },
      "liderazgo-y-gestion": {
        name: "Liderazgo y gestión",
        sub_categories: {},
      },
      marketing: {
        name: "Márketing",
        sub_categories: {},
      },
    },
  },
  "artes-y-humanidades": {
    name: "Artes y Humanidades",
    sub_categories: {
      arte: {
        name: "Arte",
        sub_categories: {},
      },
      idiomas: {
        name: "Idiomas",
        sub_categories: {},
      },
      composicion: {
        name: "Composición",
        sub_categories: {},
      },
      etica: {
        name: "Ética",
        sub_categories: {},
      },
      filosofia: {
        name: "Filosofía",
        sub_categories: {},
      },
      "historia-del-arte": {
        name: "Historia del Arte",
        sub_categories: {},
      },
      literatura: {
        name: "Literatura",
        sub_categories: {},
      },
      musica: {
        name: "Música",
        sub_categories: {},
      },
      religion: {
        name: "Religión",
        sub_categories: {},
      },
      linguistica: {
        name: "Lingüística",
        sub_categories: {},
      },
    },
  },
  "ciencia-de-los-datos": {
    name: "Ciencia de los datos",
    sub_categories: {
      "analisis-de-los-datos": {
        name: "Análisis de los datos",
        sub_categories: {},
      },
      "aprendizaje-automatico": {
        name: "Aprendizaje automático",
        sub_categories: {},
      },
      "gestion-de-datos": {
        name: "Gestión de datos",
        sub_categories: {},
      },
      "probabilidades-y-estadisticas": {
        name: "Probabilidades y estadísticas",
        sub_categories: {},
      },
    },
  },
  "ciencias-biologicas": {
    name: "Ciencias Biológicas",
    sub_categories: {
      adn: {
        name: "ADN",
        sub_categories: {},
      },
      bioelectricidad: {
        name: "Bioelectricidad",
        sub_categories: {},
      },
      bioinformatica: {
        name: "Bioinformática",
        sub_categories: {},
      },
      biologia: {
        name: "Biología",
        sub_categories: {},
      },
      "biologia-celular": {
        name: "Biología celular",
        sub_categories: {},
      },
      "biologia-molecular": {
        name: "Biología Molecular",
        sub_categories: {},
      },
      biotecnologia: {
        name: "Biotecnología",
        sub_categories: {},
      },
      "ciencia-basica": {
        name: "Ciencia básica",
        sub_categories: {},
      },
      "ciencias-de-la-vida": {
        name: "Ciencias de la vida",
        sub_categories: {},
      },
      evolucion: {
        name: "Evolución",
        sub_categories: {},
      },
      genetica: {
        name: "Genética",
        sub_categories: {},
      },
      investigacion: {
        name: "Investigación",
        sub_categories: {},
      },
      microbiologia: {
        name: "Microbiología",
        sub_categories: {},
      },
      neurociencia: {
        name: "Neurociencia",
        sub_categories: {},
      },
    },
  },
  "ciencias-fisicas-y-naturales": {
    name: "Ciencias naturales y física",
    sub_categories: {
      astronomia: {
        name: "Astronomía",
        sub_categories: {},
      },
      "calentamiento-global": {
        name: "Calentamiento global",
        sub_categories: {},
      },
      "ciencia-de-los-materiales": {
        name: "Ciencia de los Materiales",
        sub_categories: {},
      },
      biologia: {
        name: "Biología",
        sub_categories: {},
      },
      ecosistemas: {
        name: "Ecosistemas",
        sub_categories: {},
      },
      energia: {
        name: "Energía",
        sub_categories: {},
      },
      "energia-renovable": {
        name: "Energía renovable",
        sub_categories: {},
      },
      "energia-solar": {
        name: "Energía solar",
        sub_categories: {},
      },
      fisica: {
        name: "Física",
        sub_categories: {},
      },
      geologia: {
        name: "Geología",
        sub_categories: {},
      },
      mecanica: {
        name: "Mecánica",
        sub_categories: {},
      },
      nanotecnologia: {
        name: "Nanotecnología",
        sub_categories: {},
      },
      naturaleza: {
        name: "Naturaleza",
        sub_categories: {},
      },
      "planificacion-urbana": {
        name: "Planificación urbana",
        sub_categories: {},
      },
      quimica: {
        name: "Química",
        sub_categories: {},
      },
      sustentabilidad: {
        name: "Sustentabilidad",
        sub_categories: {},
      },
      termodinamica: {
        name: "Termodinámica",
        sub_categories: {},
      },
    },
  },
  "ciencias-politicas": {
    name: "Ciencias Políticas",
    sub_categories: {
      democracia: {
        name: "Democracia",
        sub_categories: {},
      },
      "derechos-humanos": {
        name: "Derechos humanos",
        sub_categories: {},
      },
      gobierno: {
        name: "Gobierno",
        sub_categories: {},
      },
      ley: {
        name: "Ley",
        sub_categories: {},
      },
      politica: {
        name: "Política",
        sub_categories: {},
      },
      "politica-exterior": {
        name: "Política exterior",
        sub_categories: {},
      },
      sociedad: {
        name: "Sociedad",
        sub_categories: {},
      },
    },
  },
  "desarrollo-personal": {
    name: "Desarrollo personal",
    sub_categories: {
      "desarrollo-personal-general": {
        name: "Desarrollo personal general",
        sub_categories: {},
      },
      lifehacks: {
        name: "Lifehacks",
        sub_categories: {},
      },
      meditacion: {
        name: "Meditación",
        sub_categories: {},
      },
      productividad: {
        name: "Productividad",
        sub_categories: {},
      },
    },
  },
  diseno: {
    name: "Diseño",
    sub_categories: {
      "3d-y-animacion": {
        name: "3D y animación",
        sub_categories: {},
      },
      "design-thinking": {
        name: "Design Thinking",
        sub_categories: {},
      },
      "diseno-arquitectonico": {
        name: "Diseño arquitectonico",
        sub_categories: {},
      },
      "diseno-de-interiores": {
        name: "Diseño de interiores",
        sub_categories: {},
      },
      "diseno-de-juegos": {
        name: "Diseño de juegos",
        sub_categories: {},
      },
      "diseno-grafico": {
        name: "Diseño grafico e ilustración",
        sub_categories: {},
      },
      "diseno-web-ui": {
        name: "Diseño web (UI)",
        sub_categories: {},
      },
      "experiencia-de-usuario-ux": {
        name: "Diseño UX/UI",
        sub_categories: {},
      },
      "herramientas-de-diseno": {
        name: "Herramientas de diseño",
        sub_categories: {},
      },
      moda: {
        name: "Diseño de modas",
        sub_categories: {},
      },
    },
  },
  gastronomia: {
    name: "Cocina y Bebidas",
    sub_categories: {
      "alta-cocina": {
        name: "Alta Cocina",
        sub_categories: {},
      },
      comidas: {
        name: "Comidas",
        sub_categories: {},
      },
      reposteria: {
        name: "Repostería",
        sub_categories: {},
      },
      bebidas: {
        name: "Bebidas",
        sub_categories: {},
      },
      "bar-y-cocteleria": {
        name: "Bar y Coctelería",
        sub_categories: {},
      },
      "bases-culinarias": {
        name: "Bases Culinarias",
        sub_categories: {},
      },
      "cocina-creativa": {
        name: "Cocina Creativa",
        sub_categories: {},
      },
      "cocina-de-autor": {
        name: "Cocina de Autor",
        sub_categories: {},
      },
      "cocina-fusion": {
        name: "Cocina Fusión",
        sub_categories: {},
      },
      "cocina-tradicional": {
        name: "Cocina tradicional",
        sub_categories: {},
      },
      "cocina-vanguardia": {
        name: "Cocina Vanguardia",
        sub_categories: {},
      },
      "nouvelle-cuisine": {
        name: "Nouvelle cuisine",
        sub_categories: {},
      },
    },
  },
  idiomas: {
    name: "Idiomas",
    sub_categories: {
      "aprender-portugues": {
        name: "Aprender portugués",
        sub_categories: {},
      },
      "aprendiendo-espanol": {
        name: "Aprendiendo español",
        sub_categories: {},
      },
      "aprendiendo-ingles": {
        name: "Aprendiendo ingles",
        sub_categories: {},
      },
      escritura: {
        name: "Escritura",
        sub_categories: {},
      },
      gramatica: {
        name: "Gramática",
        sub_categories: {},
      },
      "preparacion-de-prueba": {
        name: "Preparación de prueba",
        sub_categories: {},
      },
    },
  },
  "informatica-y-software": {
    name: "Informática y software",
    sub_categories: {
      blockchain: {
        name: "Blockchain",
        sub_categories: {},
      },
      "computacion-en-la-nube": {
        name: "Computación en la nube",
        sub_categories: {},
      },
      "desarrollo-de-software": {
        name: "Desarrollo de software",
        sub_categories: {},
      },
      "desarrollo-movil": {
        name: "Desarrollo móvil",
        sub_categories: {},
      },
      "desarrollo-web": {
        name: "Desarrollo web",
        sub_categories: {},
      },
      hardware: {
        name: "Hardware",
        sub_categories: {},
      },
      "inteligencia-artificial": {
        name: "Inteligencia artificial",
        sub_categories: {},
      },
      programacion: {
        name: "Programación",
        sub_categories: {},
      },
      "redes-y-seguridad": {
        name: "Redes y seguridad",
        sub_categories: {},
      },
    },
  },
  ingenieria: {
    name: "Ingeniería",
    sub_categories: {
      "ingenieria-aeroespacial": {
        name: "Ingeniería aeroespacial",
        sub_categories: {},
      },
      "ingenieria-industrial": {
        name: "Ingeniería industrial",
        sub_categories: {},
      },
      "informatica-y-software": {
        name: "Ingeniería En Sistemas Y Software",
        sub_categories: {},
      },
      "automatizacion-industrial": {
        name: "Automatización Industrial",
        sub_categories: {},
      },
      "ingenieria-ambiental": {
        name: "Ingeniería Ambiental",
        sub_categories: {},
      },
      "ingenieria-electrica": {
        name: "Ingeniería Eléctrica",
        sub_categories: {},
      },
      "ingenieria-electronica": {
        name: "Ingeniería Electrónica",
        sub_categories: {},
      },
      "ingenieria-estructural": {
        name: "Ingeniería estructural",
        sub_categories: {},
      },
      "ingenieria-mecanica": {
        name: "Ingeniería mecatrónica",
        sub_categories: {},
      },
      "ingeniero-civil": {
        name: "Ingeniero civil",
        sub_categories: {},
      },
    },
  },
  "matematicas-y-logica": {
    name: "Matemáticas",
    sub_categories: {
      algebra: {
        name: "Álgebra",
        sub_categories: {},
      },
      algoritmos: {
        name: "Algoritmos",
        sub_categories: {},
      },
      calculo: {
        name: "Cálculo",
        sub_categories: {},
      },
      estadisticas: {
        name: "Estadística",
        sub_categories: {},
      },
      geometria: {
        name: "Geometría",
        sub_categories: {},
      },
      logica: {
        name: "Lógica",
        sub_categories: {},
      },
      probabilidad: {
        name: "Probabilidad",
        sub_categories: {},
      },
      regresion: {
        name: "Regresión",
        sub_categories: {},
      },
      trigonometria: {
        name: "Trigonometría",
        sub_categories: {},
      },
    },
  },
};
export default categories_es;
