const course_en = {
  level: "level",
  number_modules: "number of modules",
  updated: "updated",
  language: "language",
  save: "Save",
  languages: {
    spanish: "spanish",
    english: "english",
    portuguese: "portuguese",
    french: "french",
  },
  states: {
    public: "public",
    private: "private",
    error:
      "Your content is private, click to make it public so that your content can be visible.",
  },
  buttons: {
    states_button: {
      make_public: "Make public",
      make_private: "Make private",
    },
    create_module: "Create module",
    upload_content: "Upload content",
    edit_lesson: "Edit lesson",
    delete_lesson: "Delete lesson",
    report_content: "Report Content",
  },
  no_mudules_logged:
    "You haven't created your first module yet, create one and start sharing your knowledge.",
  no_mudules_unlogged: "This list has no modules created yet.",
};
export default course_en;
