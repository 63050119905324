import React from "react";
import { AuthForm } from "apps/auth/components";
import { CONSTANTS } from "shared/constants";
import { Button, PasswordField } from "components/ui";
import { withRouter } from "react-router-dom";
import API from "core/api";
import { notEmpty, notSpaces, passVal } from "shared/utils/input-validations";
import { NavbarConfig } from "components/panzofi";

const ChangePasswordPage = ({ auth, history }) => {
  const passregex = /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/;

  const goToProfile = () => {
    history.push(CONSTANTS.URLS.PROFILE.USER_LOGGED);
  };

  const submitChangePassword = (data) => {
    if (!(data.password.match(passregex) && data.re_password.match(passregex)))
      return;
    const dataform = {
      ...data,
      user_id: API.auth.getUser()?.id,
    };
    auth.change_password2(dataform);
  };

  const navbarActions = <></>;

  if (auth.change_password_request_status === CONSTANTS.FETCH.SUCCESS) {
    return (
      <AuthForm
        navbarActions={navbarActions}
        title="Tu contraseña fue actualizada"
        message="Ahora vuelve e inicia sesión con tu nueva contraseña"
      >
        {() => (
          <section className="pz-auth-form__buttons">
            <Button type="button" ghost secondary onClick={goToProfile}>
              Volver a tu perfil
            </Button>
          </section>
        )}
      </AuthForm>
    );
  }

  return (
    <>
      <NavbarConfig />
      <AuthForm title="Cambia tu contraseña" onSubmit={submitChangePassword}>
        {({ onChange }) => (
          <React.Fragment>
            <PasswordField
              onChange={onChange}
              label="Nueva Contraseña"
              name="password"
              validations={[notEmpty, notSpaces, passVal]}
              required
            />
            <PasswordField
              onChange={onChange}
              label="Confirma tu nueva Contraseña"
              name="re_password"
              validations={[notEmpty, notSpaces, passVal]}
              required
            />
            <section className="pz-auth-form__buttons">
              <Button type="submit" primary>
                Cambiar mi contraseña
              </Button>
              <Button type="button" ghost thin secondary onClick={goToProfile}>
                Volver a tu perfil
              </Button>
            </section>
          </React.Fragment>
        )}
      </AuthForm>
    </>
  );
};

export default withRouter(ChangePasswordPage);
