import React from "react";
import {
  ProfilePage,
  SettingsDeletePage,
  SettingsPage,
  SettingsPasswordPage,
  SettingsProfilePage,
  TriviaResults,
} from "./pages";
import { Redirect } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import API from "core/api";
import { LoggedGuard, OptionalLoggedGuard } from "hocs/guards";

const Routes = [
  {
    path: "/",
    component: () => (
      <Redirect
        to={`${CONSTANTS.URLS.PROFILE.USER_LOGGED}/${API.auth.getUser()?.slug}`}
      />
    ),
  },
  {
    path: "/:userSlug",
    component: OptionalLoggedGuard(ProfilePage),
  },
  {
    path: "/:userSlug/settings",
    component: LoggedGuard(SettingsPage),
  },
  {
    path: "/:userSlug/settingsProfile",
    component: LoggedGuard(SettingsProfilePage),
  },
  {
    path: "/:userSlug/settingsPassword",
    component: LoggedGuard(SettingsPasswordPage),
  },
  {
    path: "/:userSlug/settingsDelete",
    component: LoggedGuard(SettingsDeletePage),
  },
  {
    path: "/:userSlug/results/:triviaId",
    component: OptionalLoggedGuard(TriviaResults),
  },
];

export default Routes;
