const navbar_es = {
  enter: "¡Ingresar!",
  login: "¡Inicia sesión!",
  login_span: "¿Ya tienes una cuenta?",
  signup: "¡Regístrate gratis!",
  signup_span: "¿No eres miembro aún?",
  categories: "Categorías",
  categories_dropdown: {
    all: "Todos los cursos",
  },
  search: "Busca una lección",
  dropdown: {
    profile: "Perfil",
    all_courses: "Todos los cursos",
    popular_categories: "Categorías Populares",
    other_services: "Otros servicios",
    home: "Inicio",
    courses: "Cursos",
    creators: "Creadores",
    companies: "Empresas",
    schools: "Instituciones Educativas",
    services: "Servicios Adicionales",
    asesories: "Asesoría Panzofi",
    socials: "Panzo-Socials",
    loguot: "Cerrar Sesión",
  },
  settings_navbar: {
    user: "Cuenta",
    profile: "Perfil",
    password: "Cambiar contraseña",
    delete: "Borrar cuenta",
  },
};
export default navbar_es;
