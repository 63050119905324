import React from 'react';


const inputHandler = (Component) => {
    const ChangeHandlerHOC = (props) => {
        const [value, setValue] = React.useState(props.defaultValue);

        const handleChange = (e) => {
            const { type, name, value, checked } = e.target;
            const inputValue = type === 'checkbox' ? checked : value;
            setValue(inputValue);
            const data = {
                [name]: inputValue
            };
            props.onChange(data, e);
        };

        return (
            <Component
                {...props}
                value={value}
                onChange={handleChange}
            />
        );
    }

    ChangeHandlerHOC.defaultProps = {
        defaultValue: '',
        onChange: () => { console.warn('onChange function not specified yet') }
    };

    return ChangeHandlerHOC;
};

export default inputHandler;