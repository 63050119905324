import {
  LandingHomePage,
  LandingCompaniesPage,
  LandingTeachersPage,
  LandingBusinessPartnersPage,
  LandingUniversitiesPage,
  LandingConsultantPage,
  LandingWorkWithUsPage,
  LandingAudioVideo,
  LandingMarketing,
  LandingServices,
  LandingTraining,
} from "./pages";
import { OptionalLoggedGuard } from "hocs/guards";

const Routes = [
  {
    path: "/",
    component: OptionalLoggedGuard(LandingHomePage),
  },
  {
    path: "/info/creadores-contenido",
    component: OptionalLoggedGuard(LandingTeachersPage),
  },
  {
    path: "/info/empresas",
    component: OptionalLoggedGuard(LandingCompaniesPage),
  },
  {
    path: "/info/socios-negocio",
    component: OptionalLoggedGuard(LandingBusinessPartnersPage),
  },
  {
    path: "/info/instituciones-educativas",
    component: OptionalLoggedGuard(LandingUniversitiesPage),
  },
  {
    path: "/info/servicios",
    component: OptionalLoggedGuard(LandingServices),
  },
  {
    path: "/info/asesoria",
    component: OptionalLoggedGuard(LandingConsultantPage),
  },
  {
    path: "/info/asesoria/audiovisual",
    component: OptionalLoggedGuard(LandingAudioVideo),
  },
  {
    path: "/info/asesoria/marketing",
    component: OptionalLoggedGuard(LandingMarketing),
  },
  {
    path: "/info/asesoria/training",
    component: OptionalLoggedGuard(LandingTraining),
  },
  {
    path: "/info/trabaja-con-nosotros",
    component: OptionalLoggedGuard(LandingWorkWithUsPage),
  },
];

export default Routes;
