import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { Page } from "components/common";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";

const LandingServices = () => {
  const { t } = useTranslation();
  return (
    <main className="pz-landing">
      <Navbar />

      <Page>
        <section className="pz-landing__cover">
          <section className="pz-landing__cover--content animate__animated animate__bounceIn">
            <Image
              name="ilustrations/content_creator.svg"
              className="pz-landing__cover--image"
            />
            <h1 className="text-center bold">{t("landing.services.title")}</h1>
            <p>{""}</p>
            <p>{t("landing.services.subtitle")}</p>
          </section>
        </section>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.services.creators.title")}</h2>
            <p>{t("landing.services.creators.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/freedom.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.services.creators.item1.title")}
                  </h4>
                  <p>{t("landing.services.creators.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/finance-div.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.services.creators.item2.title")}
                  </h4>
                  <p>{t("landing.services.creators.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/improve-courses.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.services.creators.item3.title")}
                  </h4>
                  <p>{t("landing.services.creators.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/grow-users.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.services.creators.item4.title")}
                  </h4>
                  <p>{t("landing.services.creators.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.services.inform.title")}</h2>
          </section>

          <section className="pz-landing__services">
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/report-promo.png"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <p>{t("landing.services.inform.item.title")}</p>
                <Button primary ghost thin>
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={
                      "https://share.hsforms.com/1apMxlwd8TOabZKzSKKBnYwbptug"
                    }
                  >
                    {t("landing.services.inform.item.button")}
                  </a>
                </Button>
              </section>
            </article>
          </section>
        </section>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold"> {t("landing.services.lines.title")}</h2>
            <p>{t("landing.services.lines.subtitle")}</p>

            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/marketing2.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.services.lines.item4.title")}
                </h3>
                <p>{t("landing.services.lines.item4.subtitle")}</p>

                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.MARKETING}>
                    {t("landing.services.lines.item4.button")}
                  </Link>
                </Button>
              </section>
            </article>
            <br />
            <br />
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/audiovisual.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.services.lines.item3.title")}
                </h3>
                <p>{t("landing.services.lines.item3.subtitle")}</p>
                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.AUDIOVISUAL}>
                    {t("landing.services.lines.item3.button")}
                  </Link>
                </Button>
              </section>
            </article>
            <br />
            <br />
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/training.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.services.lines.item2.title")}
                </h3>
                <p>{t("landing.services.lines.item2.subtitle")}</p>

                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.TRAINING}>
                    {t("landing.services.lines.item2.button")}
                  </Link>
                </Button>
              </section>
            </article>
            <br />
            <br />
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/consultan-cover.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.services.lines.item1.title")}
                </h3>
                <p>{t("landing.services.lines.item1.subtitle")}</p>

                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.CONSULTANT}>
                    {t("landing.services.lines.item1.button")}
                  </Link>
                </Button>
              </section>
            </article>
          </section>
        </section>
      </Page>

      <Footer />
    </main>
  );
};

export default LandingServices;
