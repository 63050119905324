import React from 'react';
import { inputHandler } from "hocs";
import { Label } from "components/ui/index";


const TextareaField = ({
    label, name, value, onChange, notRequired, maxLength
}) => {
    return (
        <section className='pz-form__field'>
            <Label htmlFor={name} labelAsText>{label}</Label>
            <textarea
                className='pz-form__textarea'
                id={name}
                name={name}
                onChange={onChange}
                required={!notRequired}
                value={value}
                maxLength={maxLength}
            />
        </section>
    );
};

TextareaField.defaultProps = {
    type: 'text',
    notRequired: false,
    maxLength: null
};

export default inputHandler(TextareaField);