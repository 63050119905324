import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "shared/constants";

const DeleteForumModal = ({
  is_visible,
  closeModal,
  threads,
  forumSlug,
  courseSlug,
  history,
}) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    deletePost();
  };

  const deletePost = () => {
    const deleteCourseCallback = ({ data, status }) => {
      if (status === 204) {
        if (courseSlug) {
          history.push(CONSTANTS.URLS.COURSES.COURSE_DETAIL(courseSlug));
        } else {
          history.push(CONSTANTS.URLS.FORUM.ALL);
        }
      }
    };
    threads.deleteForum(forumSlug, deleteCourseCallback);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h2>{t("modals.delete_forum.title")}</h2>

      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <Button thin onClick={handleDelete}>
          {t("modals.delete_forum.button")}
        </Button>
      </section>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(DeleteForumModal, "threads"));
