const register_es = {
  title: "¡Regístrate!",
  name: "Nombres",
  last_names: "Apellidos",
  user: "Usuario",
  email: "Correo",
  password: "Contraseña",
  terms1: "Al continuar, confirmas que estás de acuerdo",
  terms2: "con los Términos y Condiciones de Panzofi",
  terms3: "y que has leído la Política de privacidad",
  button: "Registrar",
  facebook_button: "Inicia con Facebook",
};
export default register_es;
