import React from "react";
import { Footer, Navbar, ProfileCard, Table } from "components/panzofi";
import { withRouter } from "react-router-dom";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import { Button, Image } from "components/ui";
import { CourseCard, CourseModal } from "apps/courses/components";
import { CreateEmployeeModal, UserProfileModal } from "apps/profile/components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  DeletePostModal,
  PostCard,
  PostDetailModal,
  PostModal,
  PrivacyPostModal,
} from "apps/posts/components";

const ProfilePage = ({
  profile,
  match: {
    params: { userSlug },
  },
  auth,
  organizations,
}) => {
  const [profileEditMode, setProfileEditMode] = React.useState(false);
  const [profileModalVisible, setProfileModalVisible] = React.useState(false);
  const [courseModalVisible, setCourseModalVisible] = React.useState(false);
  const [postModalVisible, setPostModalVisible] = React.useState(false);
  const [postDeleteModalVisible, setPostDeleteModalVisible] =
    React.useState(false);
  const [postPrivacyModalVisible, setPostPrivacyModalVisible] =
    React.useState(false);
  const [postDetailModalVisible, setPostDetailModalVisible] =
    React.useState(false);
  const [createEmployeeModalVisible, setCreateEmployeeModalVisible] =
    React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(undefined);
  const [employees, setEmployees] = React.useState([]);
  const [savedCourses, setSavedCourses] = React.useState([]);
  const [postToEdit, setPostToEdit] = React.useState(null);
  const [toggleState, setToggleState] = React.useState(1);
  const { t } = useTranslation();

  React.useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getUserProfile();

    // eslint-disable-next-line
  }, [auth.token]);

  const getUserProfile = () => {
    profile.getProfile(userSlug, ({ data, status }) => {
      if (data?.organization && isLoggedUser()) {
        getEmployees();
      }
      setSelectedUser(status === 200 ? data : null);
      getSavedCourses(data?.id);
    });
  };

  const getSavedCourses = (id) => {
    profile.getCourseSubscriptions(id, ({ status, data }) => {
      setSavedCourses(status === 200 ? data : []);
    });
  };

  const getEmployees = () => {
    organizations.getEmployees(({ status, data }) => {
      setEmployees(status === 200 ? data : []);
    });
  };

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const renderTabContent = () => {
    switch (toggleState) {
      case 1:
        return (
          <>
            <section className="pz-profile__courses">
              <h4 className="pz-profile__courses--title">
                <span>
                  {isLoggedUser()
                    ? t("profile.lessons_user")
                    : t("profile.lessons_no_user")}{" "}
                  ({selectedUser?.courses?.length})
                </span>
                {/* {isLoggedUser() ? (
                  <section>
                    <Button
                      primary
                      thin
                      ghost
                      onClick={() => {
                        setCourseModalVisible(true);
                      }}
                    >
                      {t("profile.lesson_button")}
                    </Button>
                  </section>
                ) : null} */}
              </h4>
              {selectedUser?.courses?.length ? (
                <section className="pz-profile__courses--list">
                  {renderCourses(selectedUser?.courses)}
                </section>
              ) : (
                <section className="pz-profile__courses--list empty">
                  <span>{t("profile.lessons_no_exist")}</span>
                </section>
              )}
            </section>
          </>
        );

      case 2:
        return (
          <>
            <section className="pz-profile__courses">
              <h4 className="pz-profile__courses--title">
                <span>
                  {isLoggedUser()
                    ? t("profile.posts.user")
                    : t("profile.posts.no_user")}{" "}
                  ({selectedUser?.posts?.length})
                </span>
                {/* {isLoggedUser() ? (
                  <section>
                    <Button
                      thin
                      ghost
                      primary
                      onClick={() => {
                        setPostModalVisible(true);
                      }}
                    >
                      {t("profile.posts.button")}
                    </Button>
                  </section>
                ) : null} */}
              </h4>
              {selectedUser?.posts?.length ? (
                <section className="pz-profile__courses--list">
                  {renderPosts(selectedUser?.posts)}
                </section>
              ) : (
                <section className="pz-profile__courses--list empty">
                  <span>{t("profile.posts.no_exist")}</span>
                </section>
              )}
            </section>
          </>
        );
      case 3:
        return (
          <>
            {isLoggedUser() ? (
              <section className="pz-profile__courses">
                <h4 className="pz-profile__courses--title">
                  <span>
                    {t("profile.lessons_saved")} ({savedCourses?.length})
                  </span>
                </h4>
                {savedCourses?.length ? (
                  <section className="pz-profile__courses--list">
                    {renderSubscribtions(savedCourses)}
                  </section>
                ) : (
                  <section className="pz-profile__courses--list empty">
                    <span>{t("profile.lessons_no_exist")}</span>
                  </section>
                )}
              </section>
            ) : null}
          </>
        );

      default:
        return <></>;
    }
  };

  const isLoggedUser = () => {
    return auth.user?.slug === userSlug;
  };

  const renderCourses = (courses) =>
    courses.map((course, key) => <CourseCard course={course} key={key} />);

  const renderSubscribtions = (subs) =>
    subs.map((sub, key) => <CourseCard course={sub.course} key={key} />);

  const renderPosts = (posts) =>
    posts.map((post, key) => {
      if (isLoggedUser()) {
        return (
          <PostCard
            post={post}
            key={key}
            onEditPost={isLoggedUser() ? openLessonEdit : null}
            onDeletePost={isLoggedUser() ? openModalDeletePost : null}
            onChangeStatus={isLoggedUser() ? openModalPrivacyPost : null}
            onDetailView={openModalDetailPost}
          />
        );
      } else if (!post?.is_draft) {
        return (
          <PostCard
            post={post}
            key={key}
            onEditPost={isLoggedUser() ? openLessonEdit : null}
            onDeletePost={isLoggedUser() ? openModalDeletePost : null}
            onChangeStatus={isLoggedUser() ? openModalPrivacyPost : null}
            onDetailView={openModalDetailPost}
          />
        );
      } else {
        return null;
      }
    });

  const editUserProfile = () => {
    setProfileEditMode(true);
    setProfileModalVisible(true);
  };

  const closeModal = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setCourseModalVisible(false);
  };

  const closeModalPost = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setPostToEdit(null);
    setPostModalVisible(false);
  };

  const openLessonEdit = (post) => {
    setPostToEdit(post);
    setPostModalVisible(true);
  };

  const closeModalDeletePost = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setPostToEdit(null);
    setPostDeleteModalVisible(false);
  };

  const openModalDeletePost = (post) => {
    setPostToEdit(post);
    setPostDeleteModalVisible(true);
  };

  const closeModalPrivacyPost = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setPostToEdit(null);
    setPostPrivacyModalVisible(false);
  };

  const openModalPrivacyPost = (post) => {
    setPostToEdit(post);
    setPostPrivacyModalVisible(true);
  };

  const closeModalDetailPost = (shouldGetProfile = true) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setPostToEdit(null);
    setPostDetailModalVisible(false);
  };

  const openModalDetailPost = (post) => {
    setPostToEdit(post);
    setPostDetailModalVisible(true);
  };

  if (selectedUser?.organization) {
    return (
      <main className="pz-profile">
        <Navbar />

        <Page className="pz-profile__content">
          <ProfileCard user={selectedUser} getUserProfile={getUserProfile} />

          {selectedUser?.organization?.is_active ? (
            <>
              {isLoggedUser() ? (
                <section className="pz-profile__employees">
                  <Table
                    title="Empleados"
                    keyField="id"
                    data={employees}
                    buttonActions={[
                      {
                        children: "Agregar empleado",
                        primary: true,
                        onClick: () => {
                          setCreateEmployeeModalVisible(true);
                        },
                      },
                    ]}
                    columns={[
                      { name: "ID", field: "id" },
                      { name: "Nombres", field: "first_name" },
                      { name: "Apellidos", field: "last_name" },
                      { name: "Usuario", field: "username" },
                      { name: "Email", field: "email" },
                      {
                        name: "Último inicio de sesión",
                        custom: ({ last_login }) =>
                          last_login
                            ? moment(last_login).format(
                                "MMMM D, YYYY - hh:mm a"
                              )
                            : "Nunca",
                      },
                    ]}
                  />
                </section>
              ) : null}

              <section className="pz-profile__courses">
                <h3 className="pz-profile__courses--title">
                  <span>
                    Lecciones subidas ({selectedUser?.courses?.length})
                  </span>
                  {isLoggedUser() ? (
                    <Button
                      primary
                      thin
                      ghost
                      onClick={() => {
                        setCourseModalVisible(true);
                      }}
                    >
                      Subir una lección
                    </Button>
                  ) : null}
                </h3>
                {selectedUser?.courses?.length ? (
                  <section className="pz-profile__courses--list">
                    {renderCourses(selectedUser?.courses)}
                  </section>
                ) : (
                  <section className="pz-profile__courses--list empty">
                    <span>No hay lecciones en esta sección</span>
                  </section>
                )}
              </section>
            </>
          ) : null}
        </Page>

        {isLoggedUser() ? (
          <>
            <CourseModal
              is_visible={courseModalVisible}
              closeModal={closeModal}
            />
            <CreateEmployeeModal
              is_visible={createEmployeeModalVisible}
              closeModal={(shouldGetEmployees = false) => {
                if (shouldGetEmployees) {
                  getEmployees();
                }
                setCreateEmployeeModalVisible(false);
              }}
            />
          </>
        ) : null}

        <Footer />
      </main>
    );
  } else if (selectedUser?.employee) {
    return (
      <main className="pz-profile">
        <Navbar />

        <Page className="pz-profile__content">
          <ProfileCard user={selectedUser} getUserProfile={getUserProfile} />
        </Page>

        <Footer />
      </main>
    );
  } else if (selectedUser) {
    return (
      <main className="pz-profile">
        <Navbar />

        <Page className="pz-profile__content">
          <ProfileCard
            user={selectedUser}
            onClickToEdit={isLoggedUser() ? editUserProfile : null}
            getUserProfile={isLoggedUser() ? getUserProfile : null}
          />

          {isLoggedUser() ? (
            <>
              <section className="pz-profile__actions">
                <Button
                  primary
                  onClick={() => {
                    setCourseModalVisible(true);
                  }}
                >
                  {t("profile.lesson_button")}
                </Button>
                <Button
                  primary
                  ghost
                  onClick={() => {
                    setPostModalVisible(true);
                  }}
                >
                  {t("profile.posts.button")}
                </Button>
              </section>
            </>
          ) : null}

          <section className="pz-profile__tabcontainer">
            <section className="pz-profile__tabs">
              <h4
                className={
                  toggleState === 1
                    ? "pz-profile__active-tab"
                    : "pz-profile__tab"
                }
                onClick={() => toggleTab(1)}
              >
                {t("profile.tabs.option1")}
              </h4>
              <h4
                className={
                  toggleState === 2
                    ? "pz-profile__active-tab"
                    : "pz-profile__tab"
                }
                onClick={() => toggleTab(2)}
              >
                {t("profile.tabs.option2")}
              </h4>
              {isLoggedUser() ? (
                <h4
                  className={
                    toggleState === 3
                      ? "pz-profile__active-tab"
                      : "pz-profile__tab"
                  }
                  onClick={() => toggleTab(3)}
                >
                  {t("profile.tabs.option3")}
                </h4>
              ) : null}
            </section>
            {renderTabContent()}
          </section>
        </Page>

        {isLoggedUser() ? (
          <>
            <CourseModal
              is_visible={courseModalVisible}
              closeModal={closeModal}
            />
            <PostModal
              post={postToEdit}
              coursesList={selectedUser.courses}
              is_visible={postModalVisible}
              closeModal={closeModalPost}
            />
            <DeletePostModal
              postId={postToEdit?.id}
              is_visible={postDeleteModalVisible}
              closeModal={closeModalDeletePost}
            />
            <PrivacyPostModal
              post={postToEdit}
              is_visible={postPrivacyModalVisible}
              closeModal={closeModalPrivacyPost}
            />
            <UserProfileModal
              editionMode={profileEditMode}
              is_visible={profileModalVisible}
              closeModal={(shouldGetProfile) => {
                if (shouldGetProfile) {
                  getUserProfile();
                  auth.validate_token();
                }
                setProfileModalVisible(false);
              }}
            />
          </>
        ) : null}

        <PostDetailModal
          postId={postToEdit?.id}
          is_visible={postDetailModalVisible}
          closeModal={closeModalDetailPost}
        />
        {/* <AdSenseBlock /> */}
        <Footer />
      </main>
    );
  } else if (selectedUser === null) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">
            {t("notFound.no_exist.t1")}
            <br />
            {t("notFound.no_exist.t2")}
          </h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  }

  return (
    <main className="pz-profile">
      <Navbar />
    </main>
  );
};

export default withRouter(
  connectReduxReducers(ProfilePage, "profile", "organizations")
);
