import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  create_threads_request_status: CONSTANTS.FETCH.STANDBY,
  get_threads_request_status: CONSTANTS.FETCH.STANDBY,
  get_thread_request_status: CONSTANTS.FETCH.STANDBY,
  modify_threads_request_status: CONSTANTS.FETCH.STANDBY,
  delete_threads_request_status: CONSTANTS.FETCH.STANDBY,

  create_forums_request_status: CONSTANTS.FETCH.STANDBY,
  get_forums_request_status: CONSTANTS.FETCH.STANDBY,
  get_forum_request_status: CONSTANTS.FETCH.STANDBY,
  modify_forums_request_status: CONSTANTS.FETCH.STANDBY,
  delete_forums_request_status: CONSTANTS.FETCH.STANDBY,

  create_responses_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  NEWS_REQUEST_STARTED: "NEWS_REQUEST_STARTED",
  NEWS_REQUEST_FINISHED: "NEWS_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.NEWS_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.NEWS_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  createThread:
    (slug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_threads_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_threads_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_threads_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createThread(slug, data, successCallback, errorCallback);
    },

  getThreads:
    (slug, filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_threads_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_threads_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_threads_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getThreads(slug, filter, successCallback, errorCallback);
    },

  getThread:
    (slug, id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_thread_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_thread_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_thread_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getThread(slug, id, successCallback, errorCallback);
    },

  modifyThread:
    (slug, id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_threads_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_threads_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_threads_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyThread(slug, id, data, successCallback, errorCallback);
    },

  deleteThread:
    (slug, id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_threads_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_threads_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_threads_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteThread(slug, id, successCallback, errorCallback);
    },

  createForum:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_forums_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_forums_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_forums_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createForum(data, successCallback, errorCallback);
    },

  getForum:
    (slug, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_forum_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_forum_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_forum_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getForum(slug, successCallback, errorCallback);
    },

  getForums:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_forums_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_forums_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_forums_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getForums(filter, successCallback, errorCallback);
    },

  modifyForum:
    (slug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_forums_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_forums_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_forums_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyForum(slug, data, successCallback, errorCallback);
    },

  deleteForum:
    (slug, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_forums_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_forums_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_forums_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteForum(slug, successCallback, errorCallback);
    },

  createThreadResponse:
    (slug, id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_responses_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_responses_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_responses_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createThreadResponse(
        slug,
        id,
        data,
        successCallback,
        errorCallback
      );
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.NEWS_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.NEWS_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
