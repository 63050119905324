import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Link, withRouter } from "react-router-dom";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import {
  Button,
  Image,
  Line,
  PDFDocument,
  VideoPlayer,
  AudioPlayer,
  GoogleDocumentViewer,
} from "components/ui";
import API from "core/api";
import { CONSTANTS } from "shared/constants";
import {
  CourseAI,
  CourseComments,
  CourseModules,
  CourseUserBadge,
  PracticalContentModal,
  QuizAttemptModal,
  Reactions,
  ViewCreation,
} from "apps/courses/components";
import {
  faEdit,
  faFileAlt,
  faQuestionCircle,
  faTrash,
  faVideo,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PracticalContentDetailModal from "apps/courses/components/practical-content-detail-modal";
import {
  faLaugh,
  faFrown,
  faStar,
  faLightbulb,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AdSense from "apps/courses/components/ads";

const LessonPage = ({
  courses,
  match: {
    params: { courseSlug, moduleId, lessonId },
  },
  auth,
  history,
}) => {
  const [contentToEdit, setContentToEdit] = React.useState(null);
  const [
    practicalContentDetailModalVisible,
    setPracticalContentDetailModalVisible,
  ] = React.useState(false);
  const [practicalContentModalVisible, setPracticalContentModalVisible] =
    React.useState(false);
  const [practicalContentId, setSelectedPracticalContentId] =
    React.useState(null);
  const [course, setCourse] = React.useState(undefined);
  const [lesson, setLesson] = React.useState(undefined);
  const [quizToAttempt, setQuizToAttempt] = React.useState(null);
  const [attemptModalVisible, setAttemptModalVisible] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const preventContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", preventContextMenu);

    return () => {
      document.removeEventListener("contextmenu", preventContextMenu);
    };
  }, []);
  React.useEffect(() => {
    getModuleLesson();
    getCourse();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getModuleLesson();
    getCourse();
    // eslint-disable-next-line
  }, [auth.token]);

  const getCourse = () => {
    courses.getCourse(courseSlug, ({ data, status }) => {
      if (status === 200) {
        setCourse(data);
      } else {
        setCourse(null);
      }
    });
  };

  const getModuleLesson = () => {
    courses.getModuleContent(
      courseSlug,
      moduleId,
      lessonId,
      ({ data, status }) => {
        if (status === 200) {
          setLesson(data);
        } else {
          setLesson(null);
        }
      }
    );
  };

  const deletePractical = (practical) => {
    courses.deletePracticalContent(
      courseSlug,
      moduleId,
      lessonId,
      practical.id,
      ({ status }) => {
        if (status === 204) {
          getModuleLesson();
        }
      }
    );
  };

  const ownerIsLoggedUser = () => {
    return auth.user?.slug === course?.owner?.slug;
  };

  const closePracticalContentModal = (shouldGetModuleLesson = false) => {
    if (shouldGetModuleLesson) {
      getModuleLesson();
    }
    setContentToEdit(null);
    setPracticalContentModalVisible(false);
  };

  const closePracticalContentDetailModal = () => {
    setPracticalContentDetailModalVisible(false);
  };

  const openQuizAttemptModal = (quiz) => {
    setQuizToAttempt(quiz);
    setAttemptModalVisible(true);
  };

  const closeQuizAttemptModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setQuizToAttempt(null);
    setAttemptModalVisible(false);
  };

  const getIcon = (type) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.AUDIO:
        return faVolumeUp;
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return faFileAlt;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return faVideo;
      default:
        return faQuestionCircle;
    }
  };

  const selectPracticalContent = (id) => () => {
    setSelectedPracticalContentId(id);
    setPracticalContentDetailModalVisible(true);
  };

  const openPracticalContentEdit = (content) => {
    setContentToEdit(content);
    setPracticalContentModalVisible(true);
  };

  const renderPracticalContent = (content) =>
    content.map(({ id, title, type, description, owner, reactions }, key) => (
      <section
        className="pz-lesson__practical-item"
        key={id}
        onClick={selectPracticalContent(id)}
      >
        <section className="pz-lesson__practical-item--type">
          <FontAwesomeIcon icon={getIcon(type)} />
          <section className="grid-columns grid-gap-20 grid-items-middle">
            {auth.user?.slug === owner?.slug ? (
              <FontAwesomeIcon
                icon={faEdit}
                onClick={(e) => {
                  e.stopPropagation();
                  openPracticalContentEdit(content[key]);
                }}
              />
            ) : null}
            {auth.user?.slug === owner?.slug ? (
              <FontAwesomeIcon
                icon={faTrash}
                onClick={(e) => {
                  e.stopPropagation();
                  deletePractical(content[key]);
                }}
              />
            ) : null}
          </section>
        </section>
        <section className="pz-lesson__practical-item--content">
          <h4 className="bold">{title}</h4>
          <p className="pz-lesson__practical-item--description">
            {description}
          </p>
        </section>
        <section className="pz-lesson__practical-item--reactions">
          <h5 className="pz-lesson__practical-item--reaction" title="me gusta">
            <FontAwesomeIcon icon={faLaugh} /> {reactions?.like_count}
          </h5>
          <h5
            className="pz-lesson__practical-item--reaction"
            title="no me gusta"
          >
            <FontAwesomeIcon icon={faFrown} /> {reactions?.fun_count}
          </h5>
          <h5 className="pz-lesson__practical-item--reaction" title="top">
            <FontAwesomeIcon icon={faStar} /> {reactions?.love_count}
          </h5>
          <h5 className="pz-lesson__practical-item--reaction" title="útil">
            <FontAwesomeIcon icon={faLightbulb} /> {reactions?.intriguing_count}
          </h5>
          <h5 className="pz-lesson__practical-item--reaction" title="eficiente">
            <FontAwesomeIcon icon={faClock} /> {reactions?.interesting_count}
          </h5>
        </section>
        <section className="pz-lesson__practical-item--user">
          {owner?.profile?.picture ? (
            <Image
              src={
                owner?.profile?.picture24
                  ? owner?.profile?.picture24
                  : owner?.profile?.picture
              }
            />
          ) : (
            <Image name="icons/male-avatar.svg" />
          )}
          <span>{owner?.full_name}</span>
        </section>
      </section>
    ));

  const renderMedia = (type, url) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        if (url.includes("google")) return <GoogleDocumentViewer url={url} />;
        return <PDFDocument url={url} />;
      case CONSTANTS.FILE_TYPES.AUDIO:
        return <AudioPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return <VideoPlayer url={url} />;
      default:
        return null;
    }
  };

  if (
    course &&
    lesson &&
    (ownerIsLoggedUser() || (!course.is_draft && !ownerIsLoggedUser()))
  ) {
    return (
      <main className="pz-lesson">
        <Navbar />

        <Page className="pz-lesson__content">
          <section className="pz-lesson__data">
            <section className="pz-lesson__file">
              {lesson.file
                ? renderMedia(lesson.type, lesson.file)
                : renderMedia(lesson.type, lesson.youtubeurl)}
            </section>
            <section>
              <h3 className="bold">{lesson.title}</h3>
              <p>{lesson.description}</p>
              <small>
                {t("lesson.uploaded")}{" "}
                {moment(lesson.created).format("MMMM D, YYYY")}
              </small>
            </section>
            <Line />
            <Reactions
              reactionList={lesson?.reactions}
              model={CONSTANTS.APP_MODELS.CORE_CONTENT}
              modelId={lessonId}
              onReact={getModuleLesson}
            />
            <Line className="pz-lesson__data--linepad" />
            <CourseComments
              commentList={lesson?.comments}
              model={CONSTANTS.APP_MODELS.CORE_CONTENT}
              modelId={lessonId}
              onComment={getModuleLesson}
            />
            {/* <Line />
            <AdSenseHorizontal /> */}
            <Line />
            <section className="pz-lesson__practic_content">
              <section>
                <h4 className="bold">{t("lesson.practical.title")}</h4>
                <p>{t("lesson.practical.no_practical")}</p>
              </section>
              {API.auth.isLoggedIn() ? (
                <Button
                  primary
                  ghost
                  thin
                  onClick={() => setPracticalContentModalVisible(true)}
                >
                  {t("lesson.practical.button")}
                </Button>
              ) : (
                <h5 className="bold">
                  {t("lesson.practical.no_practical_unlogged")}
                </h5>
              )}
              <section className="pz-lesson__grid">
                {renderPracticalContent(lesson?.practical_content || [])}
              </section>
            </section>
          </section>
          <section className="pz-lesson__description">
            <CourseAI />
            <Line />
            <Link
              to={CONSTANTS.URLS.COURSES.COURSE_DETAIL(course?.slug)}
              className="pz-lesson__course"
            >
              <Image src={course?.thumbnail} />
              <h4 className="bold">{course?.name}</h4>
            </Link>
            <CourseModules
              course={course}
              onQuizAttempt={
                auth.user.username
                  ? openQuizAttemptModal
                  : () => history.push(CONSTANTS.URLS.AUTH.LOGIN)
              }
            />
            <Line />
            <AdSense />
            <Line />
            <CourseUserBadge user={course?.owner} />
          </section>
        </Page>

        <PracticalContentModal
          contentToEdit={contentToEdit}
          is_visible={practicalContentModalVisible}
          closeModal={closePracticalContentModal}
          lesson={lesson}
          params={{ courseSlug, moduleId, lessonId }}
        />

        <PracticalContentDetailModal
          is_visible={practicalContentDetailModalVisible}
          closeModal={closePracticalContentDetailModal}
          params={{ courseSlug, moduleId, lessonId, practicalContentId }}
        />
        <QuizAttemptModal
          is_visible={attemptModalVisible}
          closeModal={closeQuizAttemptModal}
          quiz={quizToAttempt}
        />
        <ViewCreation
          id_content={lesson.id}
          model_content={CONSTANTS.APP_MODELS.CORE_CONTENT}
        />

        <Footer />
      </main>
    );
  } else if (course?.is_draft && !ownerIsLoggedUser()) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">{t("notFound.no_public")}</h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  } else if (lesson === null) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">
            {t("notFound.no_exist.t1")}
            <br />
            {t("notFound.no_exist.t2")}
          </h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  } else if (course === null) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">
            {t("notFound.no_exist.t1")}
            <br />
            {t("notFound.no_exist.t2")}
          </h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  }

  return (
    <main className="pz-profile">
      <Navbar />
    </main>
  );
};

export default withRouter(connectReduxReducers(LessonPage, "courses"));
