import React from "react";
import { Modal } from "components/common";
import { Button, Form, InputField } from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";

import { useTranslation } from "react-i18next";

const ModuleModal = ({
  courses,
  is_visible,
  closeModal,
  course,
  moduleToEdit,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (data) => {
    courses.createModule(course?.slug, data, (response) => {
      if (response.status === 201) {
        closeModal(true);
      }
    });
  };

  const updateModule = (data) => {
    courses.updateModule(course?.slug, moduleToEdit?.id, data, (response) => {
      if (response.status === 200) {
        closeModal(true);
      }
    });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {moduleToEdit ? (
        <>
          <h1>{t("modals.module.title.edit_title")}</h1>
          <p>
            {t("modals.module.subtitle.edit_subtitle")}
            <br />"{course?.name}"
          </p>
        </>
      ) : (
        <>
          <h1>{t("modals.module.title.create_title")}</h1>
          <p>
            {t("modals.module.subtitle.create_subtitle")}
            <br />"{course?.name}"
          </p>
        </>
      )}
      <Form
        onSubmit={moduleToEdit ? updateModule : handleSubmit}
        className="pz-form"
      >
        {({ onChange, data: { title, description } }) => (
          <React.Fragment>
            <InputField
              label={`${t("modals.module.title_input.title")}  (${
                typeof title === "string" ? title.length : 0
              }/100)`}
              name="title"
              onChange={onChange}
              defaultValue={moduleToEdit ? moduleToEdit?.title : ""}
              notRequired={!!moduleToEdit}
              maxLength={100}
            />
            <TextareaField
              label={`${t("modals.module.description_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/300)`}
              maxLength={300}
              name="description"
              onChange={onChange}
              defaultValue={moduleToEdit ? moduleToEdit?.description : ""}
              notRequired={!!moduleToEdit}
            />
            <dl>
              <small className="bold">
                {" "}
                {t("modals.module.info_input.title")}
              </small>
            </dl>
            <Button type="submit" primary>
              {moduleToEdit
                ? t("modals.module.submit_button.edit_title")
                : t("modals.module.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default connectReduxReducers(ModuleModal, "courses");
