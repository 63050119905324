const navbar_en = {
  enter: "¡Enter!",
  login: "Log in",
  login_span: "Do you already have an account?",
  signup: "Free sign up",
  signup_span: "Not a member yet?",
  categories: "Categories",
  categories_dropdown: {
    all: "All courses",
  },
  search: "Find a lesson",
  dropdown: {
    profile: "Profile",
    all_courses: "All Courses",
    popular_categories: "Popular Categories",
    other_services: "Other Services",
    home: "Home",
    courses: "Courses",
    creators: "Creators",
    companies: "Companies",
    schools: "Educational Institutions",
    services: "Additional Services",
    asesories: "Panzofi Advisory",
    socials: "Panzo-Socials",
    loguot: "Log Out",
  },
  settings_navbar: {
    user: "Account",
    profile: "Profile",
    password: "Change password",
    delete: "Delete account",
  },
};
export default navbar_en;
