import React from 'react';


const Toggle = (props) => (
    <section className='pz-form__toggle'>
        <input {...props}/>
        <span />
    </section>
);

export default Toggle;