import { Button } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";

const ForumSection = ({
  forum,
  owner,
  course_id,
  course_name,
  course_dec,
  hasModules,
  history,
  threads,
}) => {
  return forum ? null : owner ? (
    <section className="grid grid-content-left">
      <Button
        ghost
        thin
        disabled={!!!hasModules}
        onClick={() => {
          const formData = {
            course: course_id,
            name: course_name,
            description: course_dec,
            thumbnail: null,
          };
          threads.createForum(formData, (response) => {
            if (response.status === 201) {
              history.push(
                CONSTANTS.URLS.FORUM.FORUM_DETAIL(response?.data?.slug)
              );
            }
          });
        }}
      >
        Crear Foro
      </Button>
    </section>
  ) : null;
};

export default withRouter(connectReduxReducers(ForumSection, "threads"));
