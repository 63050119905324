// import { ThreadPage } from "./pages";
import { OptionalLoggedGuard } from "hocs/guards";
import { ForumPage, ForumsPage, ThreadPage } from "./pages";

const Routes = [
  {
    path: "",
    component: OptionalLoggedGuard(ForumsPage),
  },
  {
    path: "/:forumSlug",
    component: OptionalLoggedGuard(ForumPage),
  },
  {
    path: "/:forumSlug/thread/:threadId",
    component: OptionalLoggedGuard(ThreadPage),
  },
];

export default Routes;
