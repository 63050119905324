import React from "react";
import { Modal } from "components/common";
import { Button, Form, Toggle } from "components/ui";
import { setCookie } from "shared/common";
import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";

const ConsentModal = ({ is_visible, closeModal }) => {
  const { t } = useTranslation();
  const [necessaryCookies] = React.useState(true);
  const [functionalCookies, setFunctionalCookies] = React.useState(false);
  const [targetingCookies, setTargetingCookies] = React.useState(false);
  const [performanceCookies, setPerformanceCookies] = React.useState(false);

  const handleSubmit = () => {
    setCookie(CONSTANTS.COOKIES.NECESSARYCOOKIES, necessaryCookies);
    setCookie(CONSTANTS.COOKIES.FUNCTIONALCOOKIES, functionalCookies);
    setCookie(CONSTANTS.COOKIES.TARGETCOOKIES, targetingCookies);
    setCookie(CONSTANTS.COOKIES.PERFORMANCECOOKIES, performanceCookies);
    if (
      necessaryCookies &&
      functionalCookies &&
      targetingCookies &&
      performanceCookies
    )
      setCookie(CONSTANTS.COOKIES.ALLCOOKIES, true);
    closeModal(true);
  };

  const handleChangeNecessaryCookies = () => {};

  const handleChangeFunctionalCookies = () => {
    setFunctionalCookies(!functionalCookies);
  };
  const handleChangeTargetingCookies = () => {
    setTargetingCookies(!targetingCookies);
  };
  const handleChangePerformanceCookies = () => {
    setPerformanceCookies(!performanceCookies);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={false}
      contentClassName="grid grid-gap-20"
    >
      <h1>{t("others.cookies.modal.title")}</h1>
      <p>
        {t("others.cookies.modal.subtitle")}{" "}
        <a
          target="_top"
          rel="noopener noreferrer"
          href={"https://sites.google.com/panzofi.com/terminos/privacidad"}
        >
          {" "}
          {t("others.cookies.modal.link")}
        </a>
      </p>
      <Form onSubmit={handleSubmit} className="pz-form">
        {({ onChange, data: { description } }) => (
          <React.Fragment>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("others.cookies.modal.obligatory")}
              </label>
              <Toggle
                className="pz-form__file-input"
                name={"necessary"}
                type="checkbox"
                checked={necessaryCookies}
                onChange={handleChangeNecessaryCookies}
              />
            </article>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("others.cookies.modal.functionality")}
              </label>
              <Toggle
                className=""
                name={"functional"}
                type="checkbox"
                onChange={handleChangeFunctionalCookies}
              />
            </article>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("others.cookies.modal.relevant")}
              </label>
              <Toggle
                name={"targeting"}
                type="checkbox"
                onChange={handleChangeTargetingCookies}
              />
            </article>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("others.cookies.modal.performance")}
              </label>
              <Toggle
                name={"performance"}
                type="checkbox"
                onChange={handleChangePerformanceCookies}
              />
            </article>
            <section className="pz-auth-form__buttons">
              <Button type="submit" primary thin>
                {t("others.cookies.modal.button")}
              </Button>
            </section>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default ConsentModal;
