import React from "react";
import { Modal } from "components/common";
import {
  Button,
  FileField,
  Form,
  InputField,
  TextareaField,
} from "components/ui";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";

const UserProfileModal = ({
  auth,
  profile,
  is_visible,
  closeModal,
  editionMode,
}) => {
  const { t } = useTranslation();

  const createProfile = (data) => {
    profile.createProfile(
      {
        ...data,
        user: auth.user?.id,
      },
      ({ status }) => {
        if (status === 201) {
          closeModal(true);
        }
      }
    );
  };

  const editProfile = (data) => {
    profile.updateProfile(auth.user?.slug, data, ({ status }) => {
      if (status === 200) {
        closeModal(true);
      }
    });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {editionMode ? (
        <>
          <h1>{t(`profile.modal.title.edit_title`)}</h1>
          <p>
            {t(`profile.modal.subtitle.edit_subtitle`, {
              name: auth.user?.first_name,
            })}
          </p>
        </>
      ) : (
        <>
          <h1>{t(`profile.modal.title.create_title`)}</h1>
          <p>
            {t(`profile.modal.subtitle.create_subtitle`, {
              name: auth.user?.first_name,
            })}
          </p>
          <p>{t(`profile.modal.subtitle2.create_subtitle2`)} </p>

          <p style={{ color: "green" }}>
            {t(`profile.modal.subtitle2.create_subtitle3`)}
          </p>
        </>
      )}
      <Form
        onSubmit={editionMode ? editProfile : createProfile}
        className="pz-form"
      >
        {({ onChange, data: { introduction } }) => (
          <React.Fragment>
            <FileField
              errMsg={t(`profile.modal.file_input.error`)}
              nameDefault={t(`profile.modal.file_input.state`)}
              label={t(`profile.modal.file_input.title`)}
              name="picture"
              onChange={onChange}
              notRequired={editionMode}
            />
            <InputField
              label={t(`profile.modal.website_input.title`)}
              name="website"
              onChange={onChange}
              type="url"
              defaultValue={editionMode ? auth.user?.profile?.website : ""}
              notRequired
            />
            <InputField
              label={t(`profile.modal.location_input.title`)}
              name="location"
              onChange={onChange}
              defaultValue={editionMode ? auth.user?.profile?.location : ""}
              notRequired
            />
            <InputField
              label={t(`profile.modal.phone_input.title`)}
              name="phone"
              onChange={onChange}
              type="number"
              defaultValue={editionMode ? auth.user?.profile?.phone : ""}
              notRequired
            />
            <TextareaField
              label={`${t(`profile.modal.description_input.title`)} (${
                typeof introduction === "string" ? introduction.length : 0
              }/300)`}
              maxLength={300}
              name="introduction"
              onChange={onChange}
              type="number"
              defaultValue={editionMode ? auth.user?.profile?.introduction : ""}
              notRequired
            />
            <dl>
              <dd>
                {" "}
                <h6>{t(`profile.modal.info_input.title`)}</h6>
              </dd>
            </dl>
            <section className="pz-auth-form__buttons">
              <Button type="submit" primary thin>
                {editionMode
                  ? t(`profile.modal.submit_button.edit_title`)
                  : t(`profile.modal.submit_button.create_title`)}
              </Button>
            </section>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default connectReduxReducers(UserProfileModal, "auth", "profile");
