import React from 'react';
import { AuthForm } from "apps/auth/components";
import { CONSTANTS } from "shared/constants";
import { Button, OTPInputField, PasswordField } from "components/ui";
import { Redirect, withRouter } from "react-router-dom";
import moment from "moment";


const ChangePasswordPage = ({auth, history}) => {

    const goToLogin = () => {
        history.push(CONSTANTS.URLS.AUTH.LOGIN);
    };

    const submitChangePassword = (data) => {
        auth.change_password(data);
    };

    if (auth.forgot_password_request_status !== CONSTANTS.FETCH.SUCCESS) {
        return <Redirect to={CONSTANTS.URLS.AUTH.FORGOT_PASSWORD}/>;
    }

    const navbarActions = <>
        <h5>¿Ya tienes una cuenta?</h5>
        <Button primary thin onClick={() => {
            history.push(CONSTANTS.URLS.AUTH.LOGIN);
        }}>¡Inicia sesión!</Button>
    </>;

    if (auth.change_password_request_status === CONSTANTS.FETCH.SUCCESS) {
        return (
            <AuthForm
                navbarActions={navbarActions}
                title='Tu contraseña fue actualizada'
                message='Ahora vuelve e inicia sesión con tu nueva contraseña'
            >
                {() => (
                    <section className='pz-auth-form__buttons'>
                        <Button type='button' ghost secondary onClick={goToLogin}>Volver a iniciar sesión</Button>
                    </section>
                )}
            </AuthForm>
        );
    }

    return (
        <AuthForm
            navbarActions={navbarActions}
            title='Cambia tu contraseña'
            onSubmit={submitChangePassword}
            message={(
                <>
                    Hemos enviado un correo a <u>{auth.forgot_password_data.email}</u>, con un codigo de seguridad, para
                    que puedas cambiar tu contraseña
                    <br/><br/>
                    El codigo de seguridad
                    expirará <span>{moment(auth.forgot_password_data.expire_date).format('LLL')}</span>
                </>
            )}
        >
            {({ onChange }) => (
                <React.Fragment>
                    <OTPInputField onChange={onChange} name='code'/>
                    <PasswordField onChange={onChange} label='Nueva Contraseña' name='password' required/>
                    <PasswordField onChange={onChange} label='Confirma tu nueva Contraseña' name='re_password' required/>
                    <section className='pz-auth-form__buttons'>
                        <Button type='submit' primary>Cambiar mi contraseña</Button>
                        <Button type='button' ghost thin secondary onClick={goToLogin}>Volver a iniciar sesión</Button>
                    </section>
                </React.Fragment>
            )}
        </AuthForm>
    );
};

export default withRouter(ChangePasswordPage);