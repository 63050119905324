import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CarouselPortfolio } from "apps/landing/components";
import { Page } from "components/common";
import { Footer } from "components/panzofi";
import Navbar from "components/panzofi/navbar";
import { Button, Image } from "components/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";

const LandingMarketing = () => {
  const { t } = useTranslation();
  const googleFormsUrl =
    "https://share.hsforms.com/1it3lyQwxTvOfhaYIG8CA5gbptug";

  const handleContratarServicio = () => {
    window.open(googleFormsUrl, "_blank"); // Abre el formulario en una nueva pestaña del navegador
  };
  return (
    <main className="pz-landing">
      <Navbar />
      <Page>
        <div className="pz-principal">
          <section className="pz-landing__cover">
            <section className="pz-landing__audio--visual animate__animated animate__bounceIn">
              <div className="pz-landing__content">
                <Image
                  name="ilustrations/marketing2.svg"
                  className="pz-landing__audio--visual__image--cover"
                  style={{ height: 200 }}
                />
              </div>
              <div className="pz-landing__content">
                <h1 className="text-center bold">
                  {t("landing.marketing.title")}
                </h1>
                <p>{t("landing.marketing.subtitle")}</p>
              </div>
            </section>
          </section>
        </div>

        <section className="pz-landing__section">
          <h2 className="bold text-center">
            {t("landing.marketing.creators.title")}
          </h2>

          <article className="pz-landing__services--service">
            <Image
              name="ilustrations/marketing-strategy.svg"
              className="pz-landing__services--service--image"
            />
            <section className="pz-landing__services--service--content">
              <h3 className="bold">
                {t("landing.marketing.creators.item1.title")}
              </h3>
              <p>{t("landing.marketing.creators.item1.subtitle")}</p>
              <br />
              <ul>
                <li>{t("landing.marketing.creators.item1.li1")}</li>
                <li>{t("landing.marketing.creators.item1.li2")}</li>
                <li>{t("landing.marketing.creators.item1.li3")}</li>
                <li>{t("landing.marketing.creators.item1.li4")}</li>
                <li>{t("landing.marketing.creators.item1.li5")}</li>
                <li>{t("landing.marketing.creators.item1.li6")}</li>
              </ul>
            </section>
          </article>
          <br />
          <article className="pz-landing__services--service">
            <Image
              name="ilustrations/data.svg"
              className="pz-landing__services--service--image"
            />
            <section className="pz-landing__services--service--content">
              <h3 className="bold">
                {t("landing.marketing.creators.item2.title")}
              </h3>
              <p>{t("landing.marketing.creators.item2.subtitle")}</p>
              <br />
              <ul>
                <li>{t("landing.marketing.creators.item2.li1")}</li>
                <li>{t("landing.marketing.creators.item2.li2")}</li>
                <li>{t("landing.marketing.creators.item2.li3")}</li>
                <li>{t("landing.marketing.creators.item2.li4")}</li>
                <li>{t("landing.marketing.creators.item2.li5")}</li>
              </ul>
            </section>
          </article>
          <br />
          <article className="pz-landing__services--service">
            <Image
              name="ilustrations/publicity.svg"
              className="pz-landing__services--service--image"
            />
            <section className="pz-landing__services--service--content">
              <h3 className="bold">
                {t("landing.marketing.creators.item3.title")}
              </h3>
              <p>{t("landing.marketing.creators.item3.subtitle")}</p>
              <br />
              <ul>
                <li>{t("landing.marketing.creators.item3.li1")}</li>
                <li>{t("landing.marketing.creators.item3.li2")}</li>
                <li>{t("landing.marketing.creators.item3.li3")}</li>
                <li>{t("landing.marketing.creators.item3.li4")}</li>
                <li>{t("landing.marketing.creators.item3.li5")}</li>
                <li>{t("landing.marketing.creators.item3.li6")}</li>
              </ul>
            </section>
          </article>
          <br />
          <article className="pz-landing__services--service">
            <Image
              name="ilustrations/content-creation.svg"
              className="pz-landing__services--service--image"
            />
            <section className="pz-landing__services--service--content">
              <h3 className="bold">
                {t("landing.marketing.creators.item4.title")}
              </h3>
              <p>{t("landing.marketing.creators.item4.subtitle")}</p>
              <br />
              <ul>
                <li>{t("landing.marketing.creators.item4.li1")}</li>
                <li>{t("landing.marketing.creators.item4.li2")}</li>
                <li>{t("landing.marketing.creators.item4.li3")}</li>
                <li>{t("landing.marketing.creators.item4.li4")}</li>
                <li>{t("landing.marketing.creators.item4.li5")}</li>
              </ul>
            </section>
          </article>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.marketing.portfolio.title")}</h2>
          </section>

          <CarouselPortfolio />
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.marketing.testimonies.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Link
                    to={CONSTANTS.URLS.PROFILE.USER_DETAIL("googleexpert")}
                    className="pz-testimony-card"
                  >
                    <section
                      className="pz-testimony-card__content"
                      style={{ width: 280 }}
                    >
                      <h5 className="pz-testimony-card__description">
                        {t("landing.marketing.testimonies.t1")}
                      </h5>
                    </section>
                    <small className="pz-course-card__username">
                      <Image src="https://panzofi-multimedia.panzofi.com/users/84eb7a8a-c15a-42c2-8502-a3f0b039bc1f/picture.jpg" />

                      <span>Googleexpert</span>
                    </small>
                  </Link>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.marketing.prices.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column ">
              <article className=" ">
                <section className="">
                  <table className="pz-landing__audio--visual__table">
                    <thead>
                      <tr className="active-row">
                        <th>
                          {t("landing.marketing.prices.table.col1.title")}
                        </th>
                        <th>
                          {t("landing.marketing.prices.table.col2.title")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{t("landing.marketing.prices.table.col1.row1")}</td>
                        <td>{t("landing.marketing.prices.table.col2.row1")}</td>
                      </tr>
                      <tr>
                        <td>{t("landing.marketing.prices.table.col1.row3")}</td>
                        <td>{t("landing.marketing.prices.table.col2.row3")}</td>
                      </tr>
                      <tr>
                        <td>{t("landing.marketing.prices.table.col1.row4")}</td>
                        <td>{t("landing.marketing.prices.table.col2.row4")}</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </article>
              <Button primary thin onClick={handleContratarServicio}>
                {t("landing.marketing.packages.p1.button")}
              </Button>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <br />
                  <p>{t("landing.marketing.prices.list.l1")}</p>
                  <br />
                  <p>{t("landing.marketing.prices.list.l3")}</p>
                  <br />
                  <p>{t("landing.marketing.prices.list.l4")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.marketing.packages.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__package__card">
              <section className="grid grid-items-center">
                <h5 className="text-primary">
                  {t("landing.marketing.packages.p1.plan")}
                </h5>
                <h3>{t("landing.marketing.packages.p1.price")}</h3>
              </section>
              <p style={{ height: 120 }}>
                {t("landing.marketing.packages.p1.desc")}
              </p>
              <section className="grid-columns grid-gap-20">
                <section className="grid grid-items-center">
                  <p className="bold">
                    {t("landing.marketing.packages.p1.time.title")}
                  </p>
                  <p>{t("landing.marketing.packages.p1.time.desc")}</p>
                </section>
                <section className="grid grid-items-center">
                  <p className="bold">
                    {t("landing.marketing.packages.p1.revision.title")}
                  </p>
                  <p>{t("landing.marketing.packages.p1.revision.desc")}</p>
                </section>
              </section>
              <p className="bold">
                {t("landing.marketing.packages.p1.include.title")}
              </p>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p1.include.li1")}</p>
              </section>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p1.include.li2")}</p>
              </section>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p1.include.li3")}</p>
              </section>
              <p className="bold">
                {t("landing.marketing.packages.p1.platform.title")}
              </p>
              <section className="grid grid-items-center">
                <Image name="ilustrations/logos-social-media.svg" />
                <Button primary thin onClick={handleContratarServicio}>
                  {t("landing.marketing.packages.p1.button")}
                </Button>
              </section>
            </section>
            <section className="pz-landing__package__card">
              <section className="grid grid-items-center">
                <h5 className="text-primary">
                  {t("landing.marketing.packages.p2.plan")}
                </h5>
                <h3>{t("landing.marketing.packages.p2.price")}</h3>
              </section>
              <p style={{ height: 120 }}>
                {t("landing.marketing.packages.p2.desc")}
              </p>
              <section className="grid-columns grid-gap-20">
                <section className="grid grid-items-center">
                  <p className="bold">
                    {t("landing.marketing.packages.p2.time.title")}
                  </p>
                  <p>{t("landing.marketing.packages.p2.time.desc")}</p>
                </section>
                <section className="grid grid-items-center">
                  <p className="bold">
                    {t("landing.marketing.packages.p2.revision.title")}
                  </p>
                  <p>{t("landing.marketing.packages.p2.revision.desc")}</p>
                </section>
              </section>
              <p className="bold">
                {t("landing.marketing.packages.p2.include.title")}
              </p>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p2.include.li1")}</p>
              </section>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p2.include.li2")}</p>
              </section>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p2.include.li3")}</p>
              </section>
              <p className="bold">
                {t("landing.marketing.packages.p2.platform.title")}
              </p>
              <section className="grid grid-items-center">
                <Image name="ilustrations/logos-social-media.svg" />
                <Button primary thin onClick={handleContratarServicio}>
                  {t("landing.marketing.packages.p2.button")}
                </Button>
              </section>
            </section>
            <section className="pz-landing__package__card">
              <section className="grid grid-items-center">
                <h5 className="text-primary">
                  {t("landing.marketing.packages.p3.plan")}
                </h5>
                <h3>{t("landing.marketing.packages.p3.price")}</h3>
              </section>
              <p style={{ height: 120 }}>
                {t("landing.marketing.packages.p3.desc")}
              </p>
              <section className="grid-columns grid-gap-20">
                <section className="grid grid-items-center">
                  <p className="bold">
                    {t("landing.marketing.packages.p3.time.title")}
                  </p>
                  <p>{t("landing.marketing.packages.p3.time.desc")}</p>
                </section>
                <section className="grid grid-items-center">
                  <p className="bold">
                    {t("landing.marketing.packages.p3.revision.title")}
                  </p>
                  <p>{t("landing.marketing.packages.p3.revision.desc")}</p>
                </section>
              </section>
              <p className="bold">
                {t("landing.marketing.packages.p3.include.title")}
              </p>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p3.include.li1")}</p>
              </section>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p3.include.li2")}</p>
              </section>
              <section className="grid-columns grid-gap-20 grid-content-left grid-items-middle">
                <FontAwesomeIcon icon={faCheck} className="text-active" />
                <p>{t("landing.marketing.packages.p3.include.li3")}</p>
              </section>
              <p className="bold">
                {t("landing.marketing.packages.p3.platform.title")}
              </p>

              <section className="grid grid-items-center">
                <Image name="ilustrations/logos-social-media.svg" />
                <Button primary thin onClick={handleContratarServicio}>
                  {t("landing.marketing.packages.p3.button")}
                </Button>
              </section>
            </section>
          </section>
        </section>
        <section className="pz-landing__audio--visual__small">
          <h6>{t("landing.marketing.prices.info1")}</h6>
          <h6>{t("landing.marketing.prices.info2")}</h6>
        </section>
      </Page>
      <Footer />
    </main>
  );
};

export default LandingMarketing;
