import React from "react";
import { Modal } from "components/common";
import { Button, Label } from "components/ui";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const ModuleModal = ({
  news,
  is_visible,
  closeModal,
  currentNews,
  history,
}) => {
  // const { t } = useTranslation();
  const [formData, setFormData] = React.useState({
    title: "",
    description: "",
    is_draft: true,
    questions: [
      { text: "", url: "", options: [{ text: "", is_correct: false }] },
    ], // Initialize with one question and one option
  });

  React.useEffect(() => {
    if (is_visible && currentNews) {
      setFormData(currentNews);
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const handleSubmit = (e) => {
    e.preventDefault();
    news.createAdminTrivia(formData, (response) => {
      if (response.status === 201) {
        setFormData({
          title: "",
          description: "",
          is_draft: true,
          questions: [
            { text: "", url: "", options: [{ text: "", is_correct: false }] },
          ], // Initialize with one question and one option
        });
        closeModal(true);
      }
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    news.modifyAdminTrivia(currentNews.id, formData, (response) => {
      if (response.status === 200) {
        setFormData({
          title: "",
          description: "",
          is_draft: true,
          questions: [
            { text: "", url: "", options: [{ text: "", is_correct: false }] },
          ], // Initialize with one question and one option
        });
        closeModal(true);
      }
    });
  };

  const handleChange = (event, questionIndex, optionIndex) => {
    const { name, value, checked } = event.target;
    const updatedQuestions = [...formData.questions];
    if (name.startsWith("option_")) {
      // Update option text
      updatedQuestions[questionIndex].options[optionIndex].text = value;
    } else if (name.startsWith("is_correct_")) {
      // Update option's correctness
      updatedQuestions[questionIndex].options[optionIndex].is_correct = checked;
    } else {
      // Update question text or URL
      updatedQuestions[questionIndex][name] = value;
    }
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddQuestion = (questionIndex) => {
    const updatedQuestions = [...formData.questions];
    const newQuestion = {
      text: "",
      url: "",
      options: [{ text: "", is_correct: false }],
    };
    updatedQuestions.splice(questionIndex + 1, 0, newQuestion);
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...formData.questions];
    const newOption = {
      text: "",
      is_correct: false,
    };
    // Ensure the options array is initialized before adding the new option
    if (!updatedQuestions[questionIndex].options) {
      updatedQuestions[questionIndex].options = [];
    }
    updatedQuestions[questionIndex].options.splice(
      optionIndex + 1,
      0,
      newOption
    );
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleRemoveQuestion = (questionIndex) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions.splice(questionIndex, 1);
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...formData.questions];
    const updatedOptions = [...updatedQuestions[questionIndex].options];
    updatedOptions.splice(optionIndex, 1);
    updatedQuestions[questionIndex].options = updatedOptions;
    setFormData({ ...formData, questions: updatedQuestions });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {currentNews ? (
        <>
          <h1>Edita tu trivia </h1>
          <p>
            Por el momento solo es posible editar el nombre, la descripcion y su
            estado
          </p>
        </>
      ) : (
        <>
          <h1>Crea tu trivia </h1>
          <p>Pon aprueba los conocimientos de la comunidad panzofi</p>
        </>
      )}
      <form
        onSubmit={currentNews ? handleEdit : handleSubmit}
        className="pz-form"
      >
        {currentNews ? (
          <>
            <section className="pz-form__field">
              <Label>Title</Label>
              <input
                className="pz-form__input"
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required={true}
                maxLength={100}
              />
            </section>
            <section className="pz-form__field">
              <Label>Description:</Label>
              <textarea
                className="pz-form__textarea"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required={true}
                maxLength={300}
              />
            </section>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">Poner privado</label>
              <section className="pz-form__toggle">
                <input
                  type="checkbox"
                  name="is_draft"
                  checked={formData.is_draft}
                  onChange={(e) =>
                    setFormData({ ...formData, is_draft: e.target.checked })
                  }
                />
                <span />
              </section>
            </article>
          </>
        ) : (
          <>
            <section className="pz-form__field">
              <Label>Title</Label>
              <input
                className="pz-form__input"
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required={true}
                maxLength={100}
              />
            </section>
            <section className="pz-form__field">
              <Label>Description:</Label>
              <textarea
                className="pz-form__textarea"
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required={true}
                maxLength={300}
              />
            </section>
            {formData.questions.map((question, questionIndex) => (
              <div key={questionIndex} className="quiz__form__question">
                <section className="pz-form__field">
                  <Label>Question {questionIndex + 1}</Label>
                  <input
                    className="pz-form__input"
                    type="text"
                    name="text"
                    value={question.text}
                    onChange={(e) => handleChange(e, questionIndex)}
                    required={true}
                    maxLength={300}
                  />
                </section>

                <section className="pz-form__field">
                  <Label>URL</Label>
                  <input
                    className="pz-form__input"
                    type="text"
                    name="url"
                    value={question.url}
                    onChange={(e) => handleChange(e, questionIndex)}
                    required={false}
                    maxLength={200}
                  />
                </section>

                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <section className="grid-columns grid-items-middle grid-gap-20">
                      <section className="pz-form__field">
                        <Label>Option {optionIndex + 1}</Label>
                        <input
                          className="pz-form__input"
                          type="text"
                          name={`option_${optionIndex}`}
                          value={option.text}
                          onChange={(e) =>
                            handleChange(e, questionIndex, optionIndex)
                          }
                          required={true}
                          maxLength={100}
                        />
                      </section>
                      <article className="pz-filter__checkbox2">
                        <label className="pz-filter__label">
                          Opcion correcta?
                        </label>
                        <section className="pz-form__toggle">
                          <input
                            type="checkbox"
                            name={`is_correct_${optionIndex}`}
                            checked={option.is_correct}
                            onChange={(e) =>
                              handleChange(e, questionIndex, optionIndex)
                            }
                          />
                          <span />
                        </section>
                      </article>

                      <section className="grid-columns grid-gap-20">
                        {question.options.length > 1 ? (
                          <>
                            <Button
                              ghost
                              type="button"
                              onClick={() =>
                                handleRemoveOption(questionIndex, optionIndex)
                              }
                            >
                              <FontAwesomeIcon icon={faMinus} />
                            </Button>
                            <Button
                              ghost
                              primary
                              type="button"
                              onClick={() =>
                                handleAddOption(questionIndex, optionIndex)
                              }
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </Button>
                          </>
                        ) : (
                          <Button
                            ghost
                            primary
                            type="button"
                            onClick={() =>
                              handleAddOption(questionIndex, optionIndex)
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                      </section>
                    </section>
                    <br />
                  </div>
                ))}
                <section className="grid-columns grid-gap-20">
                  {formData.questions.length > 1 ? (
                    <>
                      <Button
                        ghost
                        type="button"
                        onClick={() => handleRemoveQuestion(questionIndex)}
                      >
                        Remove Question
                      </Button>
                      <Button
                        primary
                        ghost
                        type="button"
                        onClick={() => handleAddQuestion(questionIndex)}
                      >
                        Add Question
                      </Button>
                    </>
                  ) : (
                    <Button
                      primary
                      ghost
                      type="button"
                      onClick={() => handleAddQuestion(questionIndex)}
                    >
                      Add Question
                    </Button>
                  )}
                </section>
              </div>
            ))}

            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">Poner privado</label>
              <section className="pz-form__toggle">
                <input
                  type="checkbox"
                  name="is_draft"
                  checked={formData.is_draft}
                  onChange={(e) =>
                    setFormData({ ...formData, is_draft: e.target.checked })
                  }
                />
                <span />
              </section>
            </article>
          </>
        )}
        <Button primary type="submit">
          {currentNews ? "Edit Trivia" : "Create Trivia"}
        </Button>
      </form>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "news"));
