const register_en = {
  title: "Sign up",
  name: "Name",
  last_names: "Last Name",
  user: "User",
  email: "Email",
  password: "Password",
  terms1: "By continuing you confirm that you agree",
  terms2: "with Panzofi Terms and Conditions",
  terms3: "and that you have read the Privacy Policy",
  button: "Register",
  facebook_button: "Log in with Facebook",
};
export default register_en;
