const admin_en = {
  navbar: {
    option1: "Users",
    option2: "Courses",
    option3: "Edu Clips",
  },
  form: {
    start_date: "Start Date",
    end_date: "End Date",
    button: "Search",
  },
  users: {
    title: "Users",
    headers: {
      resource_info: {
        title: "User Information",
        id: {
          title: "ID",
        },
        username: {
          title: "Username",
        },
        name: {
          title: "Name",
        },
        email: {
          title: "Email",
        },
      },
      dates_info: {
        title: "Date Information",
        date_joined: {
          title: "Date Joined",
        },
        last_login: {
          title: "Last Login",
        },
      },
    },
  },

  questionaire: {
    title: "User Questionnaires",
    headers: {
      resource_info: {
        title: "User Questionnaires",
        username: {
          title: "Username",
        },
        creator: {
          title: "Are you interested in creating content?",
        },
        learn: {
          title: "What would you like to learn?",
        },
        teach: {
          title: "What would you like to teach?",
        },
        experience: {
          title: "What is your level in these categories?",
        },
        background: {
          title: "What is your level of education?",
        },
        reason: {
          title: "What is the reason you are learning?",
        },
        media: {
          title: "What is your preferred learning method?",
        },
        functionality: {
          title: "Functionality",
        },
      },
    },
  },
  courses: {
    title: "Courses",
    headers: {
      resource_info: {
        title: "Course Information",
        id: {
          title: "ID",
        },
        name: {
          title: "Course Name",
        },
        owner: {
          title: "Owner",
        },
        language: {
          title: "Language",
        },
        category: {
          title: "Category",
        },
        is_draft: {
          title: "Status",
          private: "Private",
          public: "Public",
        },
      },
      dates_info: {
        title: "Date Information",
        created: {
          title: "Created Date",
        },
        updated: {
          title: "Updated Date",
        },
      },
    },
  },
  posts: {
    title: "Edu Clips",
    headers: {
      resource_info: {
        title: "Edu Clip Information",
        id: {
          title: "ID",
        },
        title_post: {
          title: "Clip Title",
        },
        owner: {
          title: "Owner",
        },
        category: {
          title: "Category",
        },
        type: {
          title: "Type",
        },
        is_draft: {
          title: "Status",
          private: "Private",
          public: "Public",
        },
      },
      dates_info: {
        title: "Date Information",
        created: {
          title: "Created Date",
        },
        updated: {
          title: "Updated Date",
        },
      },
    },
  },
};
export default admin_en;
