import React from "react";
import { Navbar } from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";
import { NavbarAdmin } from "apps/admin/components";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
} from "react-table";
import { matchSorter } from "match-sorter";
import moment from "moment";
import { Button, DateField, Form } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from "recharts";

const AdminPostPage = ({ auth, posts }) => {
  const [coursesList, setCoursesList] = React.useState([]);
  const { t } = useTranslation();

  const getCourses = (data) => {
    const params = {
      start_date: data.startDate,
      end_date: data.endDate,
    };
    posts.getAdminPosts(params, ({ data, status }) => {
      setCoursesList(status === 200 ? data : null);
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t(`admin.posts.headers.resource_info.title`),
        columns: [
          {
            Header: t(`admin.posts.headers.resource_info.id.title`),
            accessor: "id",
          },
          // {
          //   Header: "location",
          //   accessor: "profile.location",
          // },
          {
            Header: t(`admin.posts.headers.resource_info.title_post.title`),
            accessor: "title",
          },
          {
            Header: t(`admin.posts.headers.resource_info.owner.title`),
            accessor: "owner.username",
          },
          {
            Header: t(`admin.posts.headers.resource_info.category.title`),
            accessor: "category.name",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: t(`admin.posts.headers.resource_info.type.title`),
            accessor: "type",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: t(`admin.posts.headers.resource_info.is_draft.title`),
            accessor: "is_draft",
          },
        ],
      },
      {
        Header: t(`admin.posts.headers.dates_info.title`),
        columns: [
          {
            Header: t(`admin.posts.headers.dates_info.created.title`),
            accessor: "created",
          },
          {
            Header: t(`admin.posts.headers.dates_info.updated.title`),
            accessor: "updated",
            // Filter: NumberRangeColumnFilter,
            // filter: 'between',
          },
        ],
      },
    ],
    [t]
  );

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        className="pz-form__input"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
      />
    );
  }

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  // function SliderColumnFilter({
  //   column: { filterValue, setFilter, preFilteredRows, id },
  // }) {
  //   // Calculate the min and max
  //   // using the preFilteredRows

  //   const [min, max] = React.useMemo(() => {
  //     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
  //     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
  //     preFilteredRows.forEach((row) => {
  //       min = Math.min(row.values[id], min);
  //       max = Math.max(row.values[id], max);
  //     });
  //     return [min, max];
  //   }, [id, preFilteredRows]);

  //   return (
  //     <>
  //       <input
  //         type="range"
  //         min={min}
  //         max={max}
  //         value={filterValue || min}
  //         onChange={(e) => {
  //           setFilter(parseInt(e.target.value, 10));
  //         }}
  //       />
  //       <button onClick={() => setFilter(undefined)}>Off</button>
  //     </>
  //   );
  // }

  // This is a custom UI for our 'between' or number range
  // filter. It uses two number boxes and filters rows to
  // ones that have values between the two

  // function NumberRangeColumnFilter({
  //   column: { filterValue = [], preFilteredRows, setFilter, id },
  // }) {
  //   const [min, max] = React.useMemo(() => {
  //     let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
  //     let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
  //     preFilteredRows.forEach((row) => {
  //       min = Math.min(row.values[id], min);
  //       max = Math.max(row.values[id], max);
  //     });
  //     return [min, max];
  //   }, [id, preFilteredRows]);

  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //       }}
  //     >
  //       <input
  //         value={filterValue[0] || ""}
  //         type="number"
  //         onChange={(e) => {
  //           const val = e.target.value;
  //           setFilter((old = []) => [
  //             val ? parseInt(val, 10) : undefined,
  //             old[1],
  //           ]);
  //         }}
  //         placeholder={`Min (${min})`}
  //         style={{
  //           width: "70px",
  //           marginRight: "0.5rem",
  //         }}
  //       />
  //       to
  //       <input
  //         value={filterValue[1] || ""}
  //         type="number"
  //         onChange={(e) => {
  //           const val = e.target.value;
  //           setFilter((old = []) => [
  //             old[0],
  //             val ? parseInt(val, 10) : undefined,
  //           ]);
  //         }}
  //         placeholder={`Max (${max})`}
  //         style={{
  //           width: "70px",
  //           marginLeft: "0.5rem",
  //         }}
  //       />
  //     </div>
  //   );
  // }

  function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  }

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= filterValue;
    });
  }

  // This is an autoRemove method on the filter function that
  // when given the new filter value and returns true, the filter
  // will be automatically removed. Normally this is just an undefined
  // check, but here, we want to remove the filter if it's not a number
  filterGreaterThan.autoRemove = (val) => typeof val !== "number";

  // This is a custom aggregator that
  // takes in an array of leaf values and
  // returns the rounded median

  // function roundedMedian(leafValues) {
  //   let min = leafValues[0] || 0;
  //   let max = leafValues[0] || 0;

  //   leafValues.forEach((value) => {
  //     min = Math.min(min, value);
  //     max = Math.max(max, value);
  //   });

  //   return Math.round((min + max) / 2);
  // }

  function Table2({ columns, data, updateMyData, skipReset }) {
    // Use the state and functions returned from useTable to build your UI
    const filterTypes = React.useMemo(
      () => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn,
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: {
        pageIndex,
        pageSize,
        // sortBy,
        // groupBy,
        // expanded,
        // filters,
        // selectedRowIds,
      },
    } = useTable(
      {
        columns,
        data,

        defaultColumn,
        filterTypes,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        updateMyData,
        // We also need to pass this so the page doesn't change
        // when we edit the data.
        autoResetPage: !skipReset,
        autoResetSelectedRows: !skipReset,
        disableMultiSort: true,
      },
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,

      (hooks) => {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "selection",
              // Make this column a groupByBoundary. This ensures that groupBy columns
              // are placed after it
              groupByBoundary: true,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => <div></div>,
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => <div></div>,
            },
            ...columns,
          ];
        });
      }
    );

    // Render the UI for your table
    return (
      <>
        <section className="pz-admin__table pz-table-container">
          <section className="pz-table__caption">
            <span>{t(`admin.posts.title`)}</span>
            <section className="pz-table__caption-actions"></section>
          </section>
          <table className="pz-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>
                        {column.canGroupBy ? (
                          // If the column can be grouped, let's add a toggle
                          <span {...column.getGroupByToggleProps()}>
                            {column.isGrouped ? " " : " "}
                          </span>
                        ) : null}
                        <span {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </div>
                      {/* Render the columns filter UI */}
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? "👇" : "👉"}
                              </span>{" "}
                              {cell.render("Cell", { editable: false })} (
                              {row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render("Aggregated")
                          ) : cell.isPlaceholder ? null : cell.value === "" ||
                            cell.value === null ? (
                            cell.render("Cell", { editable: true })
                          ) : !isNaN(new Date(cell.value)) ? (
                            cell.value === false || cell.value === true ? (
                              cell.value === false ? (
                                <>Publico</>
                              ) : (
                                <>Privado</>
                              )
                            ) : (
                              moment(cell.value).format("MMMM D, YYYY")
                            )
                          ) : (
                            cell.render("Cell", { editable: true })
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
        <div className="pz-paginator">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              className="pz-form__input"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            className={!canPreviousPage ? "inactive" : ""}
            size="lg"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            icon={faAngleDoubleLeft}
          />
          <FontAwesomeIcon
            className={!canPreviousPage ? "inactive" : ""}
            size="lg"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            icon={faAngleLeft}
          />
          <FontAwesomeIcon
            className={!canNextPage ? "inactive" : ""}
            size="lg"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            icon={faAngleRight}
          />
          <FontAwesomeIcon
            className={!canNextPage ? "inactive" : ""}
            size="lg"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            icon={faAngleDoubleRight}
          />
        </div>
      </>
    );
  }

  return (
    <main className="pz-admin">
      <Navbar />

      <section className="pz-admin__page">
        {auth.user.is_staff ? (
          <>
            <NavbarAdmin index={2} />
            <section className="pz-admin__content">
              <section className="pz-admin__form">
                <Form onSubmit={getCourses}>
                  {({ onChange, data: { introduction } }) => (
                    <React.Fragment>
                      <div className="pz-admin__dates">
                        <DateField
                          label={t(`admin.form.start_date`)}
                          name="startDate"
                          onChange={onChange}
                        />
                        <DateField
                          label={t(`admin.form.end_date`)}
                          name="endDate"
                          onChange={onChange}
                        />
                      </div>
                      <section className="pz-auth-form__buttons">
                        <Button type="submit" primary thin>
                          {t(`admin.form.button`)}
                        </Button>
                      </section>
                    </React.Fragment>
                  )}
                </Form>
              </section>
              <Table2 columns={columns} data={coursesList} />
              <section className="grid-gap-20 grid-items-middle grid-content-center">
                <h3 className="grid-gap-10 grid-items-middle grid-content-center">
                  Reacciones
                </h3>

                <BarChart
                  width={500}
                  height={300}
                  data={coursesList}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis hide dataKey="title" />
                  <YAxis />
                  <Tooltip />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ lineHeight: "40px" }}
                  />
                  <Brush dataKey="name" height={30} stroke="#8884d8" />
                  <Bar
                    dataKey="reactions.like_count"
                    fill="#8884d8"
                    stackId="a"
                    name="me gusta"
                  />
                  <Bar
                    dataKey="reactions.fun_count"
                    fill="#bd5151"
                    stackId="a"
                    name="no me gusta"
                  />
                  <Bar
                    dataKey="reactions.love_count"
                    fill="#82ca9d"
                    stackId="a"
                    name="calidad"
                  />
                  <Bar
                    dataKey="reactions.intriguing_count"
                    fill="#ca82ad"
                    stackId="a"
                    name="util"
                  />
                  <Bar
                    dataKey="reactions.interesting_count"
                    fill="#cac682"
                    stackId="a"
                    name="eficiente"
                  />
                </BarChart>
              </section>
            </section>
          </>
        ) : (
          <>No tienes permiso para entrar aca</>
        )}
      </section>
    </main>
  );
};

export default connectReduxReducers(AdminPostPage, "auth", "posts");
