import React from "react";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

const CalculatorProfit = () => {
  const [currentMonthViews, setCurrentMonthViews] = React.useState(35000);
  const [revenueMulti, setRevenueMulti] = React.useState(0.045);
  const [currentRevenue, setCurrentRevenue] = React.useState(1575);
  const { t } = useTranslation();
  return (
    <section>
      <div className="wrapper">
        <h1 className="wrapper__title">
          {t("landing.teachers.calculator.title")}
        </h1>
        <form className="calculator">
          <div className="calculator__row">
            <label htmlFor="cars">
              {t("landing.teachers.calculator.select")}
            </label>

            <select
              name="cars"
              id="cars"
              onChange={(e) => {
                setRevenueMulti(e.target.value * 1);
                setCurrentRevenue(currentMonthViews * e.target.value * 1);
              }}
            >
              <option value="0.045">
                {t("landing.teachers.calculator.categories.admin")}
              </option>
              <option value="0.014">
                {t("landing.teachers.calculator.categories.arts")}
              </option>
              <option value="0.019">
                {t("landing.teachers.calculator.categories.bio")}
              </option>
              <option value="0.036">
                {t("landing.teachers.calculator.categories.data")}
              </option>
              <option value="0.053">
                {t("landing.teachers.calculator.categories.phisics")}
              </option>
              <option value="0.016">
                {t("landing.teachers.calculator.categories.social")}
              </option>
              <option value="0.020">
                {t("landing.teachers.calculator.categories.personal")}
              </option>
              <option value="0.035">
                {t("landing.teachers.calculator.categories.design")}
              </option>
              <option value="0.022">
                {t("landing.teachers.calculator.categories.lang")}
              </option>
              <option value="0.034">
                {t("landing.teachers.calculator.categories.it")}
              </option>
              <option value="0.017">
                {t("landing.teachers.calculator.categories.math")}
              </option>
            </select>

            <br />
            <br />
          </div>
          <div className="calculator__row">
            <label htmlFor="bill">
              {t("landing.teachers.calculator.visits")}
            </label>
            <label>
              <NumericFormat
                value={currentMonthViews}
                displayType={"text"}
                thousandSeparator={true}
              />
            </label>
          </div>
          <div className="calculator__row">
            <input
              type="range"
              min={35000}
              max={10000000}
              step="1"
              className="calculator__tip"
              id="tip"
              value={currentMonthViews}
              required
              onChange={(e) => {
                setCurrentMonthViews(e.target.value);
                setCurrentRevenue(e.target.value * revenueMulti);
              }}
            />
          </div>
          <div className="calculator__row">
            <h2 className="calculator__info">
              {t("landing.teachers.calculator.earnings")}{" "}
              <span className="calculator__result">
                <NumericFormat
                  value={currentRevenue.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </span>
            </h2>
          </div>
        </form>
      </div>
      <section>
        <p>{t("landing.teachers.calculator.info")}</p>
      </section>
    </section>
  );
};

export default CalculatorProfit;
