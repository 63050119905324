import React from 'react';
import { Footer, Navbar } from "components/panzofi";
import { Form } from "components/ui";


class AuthForm extends React.Component {
    render() {
        const { children, onSubmit, title, message, navbarActions } = this.props;

        return (
            <main className='pz-auth-form'>
                <Navbar>
                    {navbarActions}
                </Navbar>
                <section className='pz-auth-form__form-section'>
                    <section className='pz-auth-form__content animate__animated animate__bounceIn'>
                        <h3 className='pz-auth-form__title'>{title}</h3>
                        <h5 className='pz-auth-form__message'>{message}</h5>
                        <Form onSubmit={onSubmit} className='pz-auth-form__form'>
                            {children}
                        </Form>
                    </section>
                </section>
                <Footer />
            </main>
        );
    }
}

AuthForm.defaultProps = {
    formData: {
        fields: []
    },
    message: '',
    onSubmit: () => {}
};

export default AuthForm;