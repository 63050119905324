

export const renderClasses = (classes) => (
    Object.keys(classes).filter(key => (
        classes[key]
    )).map(key => key).join(" ")
);

export const getAsList = (object) => (
    Object.keys(object).map((objectKey) => (
        object[objectKey]
    ))
);

export const fileToBase64 = (file) => (
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({
            base64: reader.result,
            file: file
        });
        reader.onerror = error => reject(error);
    })
);

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}