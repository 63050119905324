import NProgress from "nprogress";

const defaultState = {
    is_visible: false
};

const actionTypes = {
    TOGGLE_LOADING_MODAL: 'TOGGLE_LOADING_MODAL'
};

const actions = {
    toggleLoadingModal: () => dispatch => {
        dispatch({ type: actionTypes.TOGGLE_LOADING_MODAL });
    }
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_LOADING_MODAL:
            if (state.is_visible) NProgress.done();
            else {
                NProgress.start();
                NProgress.inc();
            }
            return {
                ...state,
                is_visible: !state.is_visible
            };

        default:
            return state;
    }
};

export default {
    defaultState,
    actionTypes,
    actions,
    reducer
};