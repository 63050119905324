import { Page } from "components/common";
import { Footer } from "components/panzofi";
import Navbar from "components/panzofi/navbar";
import { Button, Image } from "components/ui";
import React from "react";
import { useTranslation } from "react-i18next";

const LandingAudioVideo = () => {
  const { t } = useTranslation();
  const googleFormsUrl =
    "https://share.hsforms.com/1p0WmJqsZSnWh5zm-v1YBiwbptug";

  const handleContratarServicio = () => {
    window.open(googleFormsUrl, "_blank"); // Abre el formulario en una nueva pestaña del navegador
  };
  return (
    <main className="pz-landing">
      <Navbar />
      <Page>
        <div className="pz-principal">
          <section className="pz-landing__cover">
            <section className="pz-landing__audio--visual animate__animated animate__bounceIn">
              <div className="pz-landing__content">
                <Image
                  name="ilustrations/audiovisual.svg"
                  className="pz-landing__audio--visual__image--cover"
                />
              </div>
              <div className="pz-landing__content">
                <h1 className="text-center bold">
                  {t("landing.audiovisual.title")}
                </h1>
                <p>{t("landing.audiovisual.subtitle")}</p>
              </div>
            </section>
          </section>
        </div>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.audiovisual.services.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/estrategy.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <h4 className="bold">
                    {t("landing.audiovisual.services.item1.title")}
                  </h4>
                  <p>{t("landing.audiovisual.services.item1.subtitle")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/production.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <h4 className="bold">
                    {t("landing.audiovisual.services.item2.title")}
                  </h4>
                  <p>{t("landing.audiovisual.services.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <Image
                    name="ilustrations/publish.svg"
                    className="pz-landing__audio--visual__image"
                  />
                  <h4 className="bold">
                    {t("landing.audiovisual.services.item3.title")}
                  </h4>
                  <p>{t("landing.audiovisual.services.item3.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.audiovisual.help.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item">
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.audiovisual.help.item1.title")}
                  </h4>
                  <br />
                  <p>{t("landing.audiovisual.help.item1.l1")}</p>
                  <p>{t("landing.audiovisual.help.item1.l2")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.audiovisual.help.item2.title")}
                  </h4>
                  <br />
                  <p>{t("landing.audiovisual.help.item2.l1")}</p>
                  <p>{t("landing.audiovisual.help.item2.l2")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item ">
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.audiovisual.help.item3.title")}
                  </h4>
                  <br />
                  <p>{t("landing.audiovisual.help.item3.l1")}</p>
                  <p>{t("landing.audiovisual.help.item3.l2")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold"> {t("landing.audiovisual.prices.title")}</h2>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column ">
              <article className="">
                <section className="">
                  <table className="pz-landing__audio--visual__table">
                    <thead>
                      <tr className="active-row">
                        <th>
                          {t("landing.audiovisual.prices.table.col1.title")}
                        </th>
                        <th>
                          {t("landing.audiovisual.prices.table.col2.title")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {t("landing.audiovisual.prices.table.col1.row1")}
                        </td>
                        <td>
                          {t("landing.audiovisual.prices.table.col2.row1")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {t("landing.audiovisual.prices.table.col1.row2")}
                        </td>
                        <td>
                          {t("landing.audiovisual.prices.table.col2.row2")}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {t("landing.audiovisual.prices.table.col1.row3")}
                        </td>
                        <td>
                          {t("landing.audiovisual.prices.table.col2.row3")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </article>
              <Button primary thin onClick={handleContratarServicio}>
                {t("landing.audiovisual.prices.table.button")}
              </Button>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <section className="pz-landing__how-works--item--content">
                  <br />
                  <h4>{t("landing.audiovisual.prices.list.l1")}</h4>
                  <br />
                  <h4>{t("landing.audiovisual.prices.list.l2")}</h4>
                  <br />
                  <h4>{t("landing.audiovisual.prices.list.l3")}</h4>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__audio--visual__small">
          <p>{t("landing.audiovisual.prices.info1")}</p>
          <p>{t("landing.audiovisual.prices.info2")}</p>
        </section>
      </Page>
      <Footer />
    </main>
  );
};

export default LandingAudioVideo;
