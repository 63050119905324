import React from 'react';
import { Notification } from "components/common";
import { connectReduxReducers } from "hocs";


const EventNotification = ({notification}) => {
    const { is_visible, text, config, hideNotification } = notification;
    const { type, label } = config;

    return (
        <Notification
            is_visible={is_visible}
            className={type}
            closeModal={() => hideNotification()}
        >
            <section className='grid-columns grid-gap-10'>
                { label && <span>{label}</span>}
                <span>{text}</span>
            </section>
        </Notification>
    );
};

export default connectReduxReducers(EventNotification, 'notification');