import React from 'react';
import { Modal } from "components/common";
import { Button, Form, InputField } from "components/ui";
import { connectReduxReducers } from "hocs";


const CreateEmployeeModal = ({
    auth, is_visible, closeModal
}) => {

    const handleSubmit = (data) => {
        auth.createEmployee(data, (response) => {
            if (response.status === 201) {
                closeModal(true);
            }
        });
    };

    return (
        <Modal
            is_visible={is_visible}
            closeModal={closeModal}
            exitOnOverlayClick
            contentClassName='grid grid-gap-20'
        >
            <h1>Crea un empleado</h1>
            <p>Hola, bienvenido, para agregar un empleado solo llena el siguiente formulario y automaticamente se le asignara una contraseña y se le enviara por medio de un correo al email asignado.</p>
            <Form onSubmit={handleSubmit} className='pz-form'>
                {({onChange}) => (
                    <React.Fragment>
                        <InputField label='Nombres' name='first_name' onChange={onChange} />
                        <InputField label='Apellidos' name='last_name' onChange={onChange} />
                        <InputField label='Usuario' name='username' onChange={onChange} />
                        <InputField label='Correo' name='email' onChange={onChange} />
                        <Button type='submit' primary>Crear empleado</Button>
                    </React.Fragment>
                )}
            </Form>
        </Modal>
    );
};

export default connectReduxReducers(CreateEmployeeModal, 'auth');