import React from "react";
import { Modal } from "components/common";
import { connectReduxReducers } from "hocs";
import { CONSTANTS } from "shared/constants";
import { AudioPlayer, PDFDocument, VideoPlayer, Line } from "components/ui";
import moment from "moment";
import {
  CourseComments,
  Reactions,
  ViewCreation,
} from "apps/courses/components/index";

const PracticalContentDetailModal = ({
  courses,
  is_visible,
  closeModal,
  params: { courseSlug, moduleId, lessonId, practicalContentId },
}) => {
  const [lesson, setLesson] = React.useState(undefined);

  React.useEffect(() => {
    if (is_visible) {
      getLessonContent();
    } else {
      setLesson({});
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const getLessonContent = () => {
    courses.getPracticalContent(
      courseSlug,
      moduleId,
      lessonId,
      practicalContentId,
      ({ status, data }) => {
        if (status === 200) {
          setLesson(data);
        } else {
          closeModal();
        }
      }
    );
  };

  const renderMedia = (type, url) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return <PDFDocument url={url} />;
      case CONSTANTS.FILE_TYPES.AUDIO:
        return <AudioPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return <VideoPlayer url={url} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="pz-content-modal"
    >
      <section className="grid-gap-20 grid-content-top">
        {lesson?.file
          ? renderMedia(lesson.type, lesson.file)
          : renderMedia(lesson?.type, lesson?.youtubeurl)}
        <section>
          <h3 className="bold">{lesson?.title}</h3>
          <p>{lesson?.description}</p>
          <small>
            Subido en {moment(lesson?.created).format("MMMM D, YYYY")}
          </small>
        </section>
      </section>
      <section className="grid-gap-20 grid-content-top">
        <Reactions
          reactionList={lesson?.reactions}
          model={CONSTANTS.APP_MODELS.PRACTICAL_CONTENT}
          modelId={practicalContentId}
          onReact={getLessonContent}
        />
        <Line className="pz-lesson__data--linepad" />
        <CourseComments
          commentList={lesson?.comments}
          model={CONSTANTS.APP_MODELS.PRACTICAL_CONTENT}
          modelId={practicalContentId}
          onComment={getLessonContent}
        />
      </section>
      <ViewCreation
        id_content={practicalContentId}
        model_content={CONSTANTS.APP_MODELS.PRACTICAL_CONTENT}
      />
    </Modal>
  );
};

export default connectReduxReducers(PracticalContentDetailModal, "courses");
