const footer_en = {
  title: "Panzofi",
  subtitle:
    "We are on a mission to democratize education globally, transcending all borders. Your imagination and motivation are your only limits...",
  explore: {
    title: "Discover",
    users: "Users: Students",
    creators: "Content Creators: Educators",
    companies: "Businesses",
    intitutions: "Academic Institutions",
    services: "Panzofi Services",
  },
  community: {
    title: "Our Community",
    blog: "Insights: Blog",
    contact: "Get in Touch: Contact",
    faq: "Need Help? FAQs",
    report_bug: "Found an Issue? Report a Bug",
    hiring: "Join Our Team: We're Hiring!",
  },
  copyright: "Copyright © Panzofi 2024 -All rights reserved",
  terms: "Terms and Conditions",
  policy: "Privacy policies",
};
export default footer_en;
