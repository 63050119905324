import React from 'react';
import { renderClasses } from "shared/common/functions";


const Page = ({
    className, children
}) => (
    <section className={renderClasses({
        'pz-page-content': true,
        [className]: !!className
    })}>
        {children}
    </section>
);

Page.defaultProps = {
    className: ''
};

export default Page;