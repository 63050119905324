import React from 'react';
import { Modal } from 'components/common';
import { connectReduxReducers } from "hocs";


const ModalLoading = ({loadingModal}) => {
    const {is_visible} = loadingModal;

    return (
        <Modal
            is_visible={is_visible}
            className='pz-loading-modal'
            overlayClassName='pz-loading-modal__overlay'
        >
            <section className="pz-loading-modal__loader">
                <span />
                <span />
                <span />
                <span />
            </section>
        </Modal>
    );
}

export default connectReduxReducers(ModalLoading, 'loadingModal');