const profile_es = {
  modal: {
    title: {
      create_title: "!Crea tu perfil!",
      edit_title: "Edita tu perfil",
    },
    subtitle: {
      create_subtitle:
        "Hola {{name}}, solo nos queda este último paso, así nuestra comunidad podrá conocerte mejor.",
      edit_subtitle:
        "Hola {{name}}, edita la información de tu perfil o complementa tu información",
    },
    subtitle2: {
      create_subtitle2:
        "La información que especifiques a continuación, será pública en tu perfil.",
      create_subtitle3:
        "Además, es necesario para que puedas interactuar con la comunidad comentando!",
    },
    file_input: {
      title: "Foto de perfil (adjuntar imagen .png o .jpg)",
      state: "Ningún archivo seleccionado",
      error: "",
    },
    website_input: {
      title:
        "Sitio web - blog, perfil Linkedin, página personal, etc (debe comenzar con https://)",
    },
    location_input: {
      title: "Locación",
    },
    phone_input: {
      title: "Teléfono - máx 15 (número público de contacto)",
    },
    description_input: {
      title: "Cuéntanos un poco sobre ti",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Crear mi perfil",
      edit_title: "Editar perfil",
    },
  },
  experience: {
    title: "Experiencia Panzofi",
    about: "Sobre mí",
    pts_student: "Pts de Estudiante",
    pts_panzofi: "Pts Panzofi",
    pts_profesor: "Pts de Profesor",
  },
  tabs: {
    option1: "Lecciones",
    option2: "Edu clips",
    option3: "Guardados",
  },
  lessons_saved: "Lecciones guardadas",
  lessons_user: "Mis lecciones subidas",
  lessons_no_user: "Lecciones subidas",
  lessons_no_exist: "No hay lecciones en esta sección",
  lesson_button: "Subir una lección",
  posts: {
    saved: "Edu clips guardados",
    user: "Mis Edu clips subidos",
    no_user: "Edu clips subidos",
    no_exist: "No hay edu clips en esta sección",
    button: "Subir un edu clip",
  },
  challenges: {
    title: "Retos Panzofi",
    questionaire:
      "Contesta esta encuesta sobre tus preferencias para ganar puntos Panzofi",
    questionaire_done:
      "Ya enviaste la encuesta, tus puntos Panzofi han sido actualizados",
    trivia: "Juega nuestra trivia Panzofi para poner aprueba tus conocimientos",
  },
  trivia_results: {
    title_fail:
      "{{username}} no obtuvo suficiente puntaje para pasar esta trivia",
    title_pass: "{{username}} paso la trivia de Panzofi.",
    score: "Puntaje: {{score}} de 100 ",
    quote:
      "Recuerda: 'El único límite para el aprendizaje es nuestra voluntad de aprender.' - Brian Herbert",
    info: "Iniciar sesión o regístrate para realizar esta trivia",
  },
};
export default profile_es;
