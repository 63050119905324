import React, { useState } from "react";
import { Button } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaugh,
  faFrown,
  faStar,
  faLightbulb,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import { connectReduxReducers } from "hocs";
import { CONSTANTS } from "shared/constants";
import API from "core/api";
import { useTranslation } from "react-i18next";

const Reactions = ({ reactionList, model, modelId, reactions, onReact }) => {
  const { t } = useTranslation();
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [loveit, setLoveit] = useState(false);
  const [intriguin, setIntriguin] = useState(false);
  const [interesting, setInteresting] = useState(false);
  const handleClick = (type) => () => {
    if (type === CONSTANTS.REACTIONS.LIKE) setLike(!like);
    else if (type === CONSTANTS.REACTIONS.FUN) setDislike(!dislike);
    else if (type === CONSTANTS.REACTIONS.LOVE_IT) setLoveit(!loveit);
    else if (type === CONSTANTS.REACTIONS.INTRIGUING) setIntriguin(!intriguin);
    else if (type === CONSTANTS.REACTIONS.INTERESTING)
      setInteresting(!interesting);

    if (API.auth.isLoggedIn()) {
      const formData = {
        type: type,
        object_id: modelId,
      };
      reactions.createReaction(formData, model, (response) => {
        onReact(response);
      });
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className="reactions-warn-container"></div>
      {!API.auth.isLoggedIn() && (
        <div className="reactions-warn">{t("reactions.unlogged")}</div>
      )}
      <section
        className="grid-gap-20 grid-columns grid-content-left"
        style={{ position: "absolute" }}
      >
        <Button
          thin
          className={[like ? "primary" : "ghost"]}
          onClick={handleClick(CONSTANTS.REACTIONS.LIKE)}
        >
          <FontAwesomeIcon
            icon={faLaugh}
            title={t("reactions.reactions.like")}
          />{" "}
          {reactionList?.like_count}
        </Button>
        <Button
          thin
          className={[dislike ? "primary" : "ghost"]}
          onClick={handleClick(CONSTANTS.REACTIONS.FUN)}
        >
          <FontAwesomeIcon
            icon={faFrown}
            title={t("reactions.reactions.dislike")}
          />{" "}
          {reactionList?.fun_count}
        </Button>
        <Button
          thin
          className={[loveit ? "primary" : "ghost"]}
          onClick={handleClick(CONSTANTS.REACTIONS.LOVE_IT)}
        >
          <FontAwesomeIcon
            icon={faStar}
            title={t("reactions.reactions.quality")}
          />{" "}
          {reactionList?.love_count}
        </Button>
        <Button
          thin
          className={[intriguin ? "primary" : "ghost"]}
          onClick={handleClick(CONSTANTS.REACTIONS.INTRIGUING)}
        >
          <FontAwesomeIcon
            icon={faLightbulb}
            title={t("reactions.reactions.useful")}
          />{" "}
          {reactionList?.intriguing_count}
        </Button>
        <Button
          thin
          className={[interesting ? "primary" : "ghost"]}
          onClick={handleClick(CONSTANTS.REACTIONS.INTERESTING)}
        >
          <FontAwesomeIcon
            icon={faClock}
            title={t("reactions.reactions.efficient")}
          />{" "}
          {reactionList?.interesting_count}
        </Button>
      </section>
    </div>
  );
};

export default connectReduxReducers(Reactions, "reactions");
