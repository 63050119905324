import { combineReducers } from "redux";
import ReduxAuth from "./auth";
import ReduxProfile from "./profile";
import ReduxCourses from "./courses";
import ReduxLoadingModal from "./loading-modal";
import ReduxNotification from "./notifications";
import ReduxComments from "./comments";
import ReduxReactions from "./reactions";
import ReduxOrganizations from "./organizations";
import ReduxPosts from "./posts";
import ReduxContentViews from "./contentViews";
import ReduxNews from "./news";
import ReduxThreads from "./threads";

export const initialState = {
  auth: ReduxAuth.defaultState,
  profile: ReduxProfile.defaultState,
  courses: ReduxCourses.defaultState,
  comments: ReduxComments.defaultState,
  reactions: ReduxReactions.defaultState,
  loadingModal: ReduxLoadingModal.defaultState,
  notification: ReduxNotification.defaultState,
  organizations: ReduxOrganizations.defaultState,
  posts: ReduxPosts.defaultState,
  views: ReduxContentViews.defaultState,
  news: ReduxNews.defaultState,
  threads: ReduxThreads.defaultState,
};

export const actions = {
  auth: ReduxAuth.actions,
  profile: ReduxProfile.actions,
  courses: ReduxCourses.actions,
  comments: ReduxComments.actions,
  reactions: ReduxReactions.actions,
  loadingModal: ReduxLoadingModal.actions,
  notification: ReduxNotification.actions,
  organizations: ReduxOrganizations.actions,
  posts: ReduxPosts.actions,
  views: ReduxContentViews.actions,
  news: ReduxNews.actions,
  threads: ReduxThreads.actions,
};

export const rootReducer = combineReducers({
  auth: ReduxAuth.reducer,
  profile: ReduxProfile.reducer,
  courses: ReduxCourses.reducer,
  comments: ReduxComments.reducer,
  reactions: ReduxReactions.reducer,
  loadingModal: ReduxLoadingModal.reducer,
  notification: ReduxNotification.reducer,
  organizations: ReduxOrganizations.reducer,
  posts: ReduxPosts.reducer,
  views: ReduxContentViews.reducer,
  news: ReduxNews.reducer,
  threads: ReduxThreads.reducer,
});

export const actionCreators = {
  ...ReduxLoadingModal.actions,
  ...ReduxNotification.actions,
};
