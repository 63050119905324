import React from "react";
import { Modal } from "components/common";
import {
  Button,
  FileField,
  Form,
  InputField,
  SelectField,
  TagField,
  Toggle,
} from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { getAsList } from "shared/common/functions";
import { CONSTANTS } from "shared/constants";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateYoutubeUrl } from "shared/common";
import { youtubeVal } from "shared/utils/input-validations";

const ModuleModal = ({
  posts,
  courses,
  coursesList,
  is_visible,
  closeModal,
  post,
  history,
}) => {
  const { t } = useTranslation();
  const [fileType, setFileType] = React.useState(undefined);
  const [isURL, setIsURL] = React.useState(false);
  const [isPublic, setIsPublic] = React.useState(true);
  const [educlipInCourse, setEduclipInCourse] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [selectedModule, setSelectedModule] = React.useState(null);

  const tagsArray =
    !!post && post.tags ? post.tags.split(",").map((tag) => tag.trim()) : [];

  React.useEffect(() => {
    if (is_visible) {
      posts.getCategories();
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const handleURLTogle = () => {
    setIsURL(!isURL);
    if (isURL) {
      setFileType(CONSTANTS.FILE_TYPES.VIDEO);
    }
  };
  const handlePublicTogle = () => {
    setIsPublic(!isPublic);
  };
  const handleEduclipInCourseTogle = () => {
    setEduclipInCourse(!educlipInCourse);
  };

  const handleSubmit = (data) => {
    const formData = {
      ...data,
      type: fileType,
      is_draft: !isPublic,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }

    posts.createPost(formData, (response) => {
      if (response.status === 201) {
        if (educlipInCourse) {
          courses.createModuleContent(
            selectedCourse?.slug,
            selectedModule?.id,
            formData,
            (response) => {
              if (response.status === 201) {
                closeModal(true);
              }
            }
          );
        } else {
          closeModal(true);
        }
      }
    });
  };

  const editCourse = (data) => {
    const formData = {
      ...data,
      type: fileType,
      is_draft: !isPublic,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }
    posts.modifyPost(post?.id, formData, (response) => {
      if (response.status === 200) {
        closeModal(true);
      }
    });
  };

  const getCategoryOptions = (slug1, categories) =>
    categories.map(({ slug, id }) => ({
      label: t(`categories.${slug1}.sub_categories.${slug}.name`),
      value: id,
    }));

  const getCategoryGroupOptions = () =>
    getAsList(posts.categories).map(({ slug, sub_categories }) => ({
      label: t(`categories.${slug}.name`),
      options: getCategoryOptions(slug, sub_categories),
    }));

  const getModuleOptions = () =>
    selectedCourse?.modules?.map((module, key) => ({
      label: `${t("modals.post.module.title")} ${key + 1}: ${module.title}`,
      value: module,
    }));

  const getCourseOptions = () =>
    coursesList?.map((course, key) => ({
      label: `${t("modals.post.lesson.title")} ${key + 1}: ${course.name}`,
      value: course,
    }));

  const onFileChange = (onChange) => (data, fileData) => {
    if (fileData?.type) {
      switch (fileData.type) {
        case "video/mp4":
          setFileType(CONSTANTS.FILE_TYPES.VIDEO);
          break;
        case "audio/mpeg":
          setFileType(CONSTANTS.FILE_TYPES.AUDIO);
          break;
        case "application/pdf":
          setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
          break;
        default:
          setFileType(undefined);
          break;
      }
    }
    onChange(data, fileData);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {!!post ? (
        <>
          <h1>{t("modals.post.title.edit_title")}</h1>
          <p>{t("modals.post.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.post.title.create_title")}</h1>
          <p>{t("modals.post.subtitle.create_subtitle")}</p>
        </>
      )}
      <Form onSubmit={!!post ? editCourse : handleSubmit} className="pz-form">
        {({ onChange, data: { tags = tagsArray, title, description } }) => (
          <React.Fragment>
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.post.youtube_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={isURL}
                onChange={handleURLTogle}
              />
            </article>

            {isURL ? (
              <InputField
                type="url"
                label={t("modals.post.youtube_input.title")}
                name="youtubeurl"
                onChange={onChange}
                defaultValue={post ? post?.youtubeurl : ""}
                notRequired={!!post}
                validations={[youtubeVal]}
              />
            ) : (
              <>
                <p>{t("modals.post.file_label.title")}</p>
                <FileField
                  nameDefault={t("modals.practical.file_input.state")}
                  errMsg={t("modals.practical.file_input.error")}
                  label={t("modals.practical.file_input.title")}
                  name="file"
                  onChange={onFileChange(onChange)}
                  notRequired={!!post}
                />
              </>
            )}
            <InputField
              label={`${t("modals.post.title_input.title")} (${
                typeof title === "string" ? title.length : 0
              }/100)`}
              maxLength={100}
              name="title"
              onChange={onChange}
              defaultValue={!!post ? post?.title : ""}
              notRequired={!!post}
            />
            <SelectField
              label={t("modals.post.category_input.title")}
              name="category"
              onChange={onChange}
              options={getCategoryGroupOptions()}
              notRequired={!!post}
            />
            <TagField
              label={t("modals.course.tags_input.title")}
              name="tags"
              onChange={onChange}
              tags={tags}
              defaultValue={tagsArray}
              notRequired={!!post}
            />
            <TextareaField
              label={`${t("modals.post.description_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/300)`}
              maxLength={300}
              name="description"
              onChange={onChange}
              defaultValue={!!post ? post?.description : ""}
              notRequired={!!post}
            />

            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.post.privacy_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={isPublic}
                onChange={handlePublicTogle}
              />
            </article>
            {post ? null : (
              <>
                <article className="pz-filter__checkbox">
                  <label className="pz-filter__label">
                    {t("modals.post.inLesson_label.title")}
                  </label>
                  <Toggle
                    className="pz-form__file-input"
                    type="checkbox"
                    checked={educlipInCourse}
                    onChange={handleEduclipInCourseTogle}
                  />
                </article>

                {educlipInCourse ? (
                  <>
                    <SelectField
                      label={t("modals.post.lesson.title")}
                      name="course"
                      options={getCourseOptions()}
                      onChange={(val) => {
                        setSelectedCourse(val.course);
                      }}
                      notRequired={!!post}
                    />
                    <SelectField
                      label={t("modals.post.module.title")}
                      name="module"
                      options={getModuleOptions()}
                      onChange={(val) => {
                        setSelectedModule(val.module);
                      }}
                      notRequired={!!post}
                    />
                  </>
                ) : null}
              </>
            )}

            <small className="bold"> {t("modals.post.info_input.title")}</small>

            <Button type="submit" primary>
              {!!post
                ? t("modals.post.submit_button.edit_title")
                : t("modals.post.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default withRouter(
  connectReduxReducers(ModuleModal, "posts", "courses")
);
