import React from 'react';
import { renderClasses } from "shared/common/functions";
import { Button, Checkbox } from "components/ui";
import { Paginator } from "components/panzofi";


const Table = ({ title, columns, data, onSelect, onRowDoubleClick, keyField, buttonActions }) => {

    const [selectedRow, setSelectedRow] = React.useState(-1);
    const [dataCount, setDataCount] = React.useState(data.length);
    const [range, setRange] = React.useState([]);

    React.useEffect(() => {
        if (dataCount !== data.length) {
            setDataCount(data.length);
            setSelectedRow(-1);
            onSelect(null);
        }
    }, [dataCount, data.length, onSelect]);

    const renderHeader = () => (
        <tr>
            <th/>
            {columns.map(({name, align}, key) => (
                <th key={key} align={align || 'left'}>{name}</th>
            ))}
        </tr>
    );

    const toggleRow = (object, key) => () => {
        if (selectedRow !== key) {
            setSelectedRow(key);
            onSelect(object[keyField]);
        } else if(selectedRow !== -1) {
            setSelectedRow(-1);
            onSelect(null);
        }
    };

    const selectRowAndDoubleClick = (object, key) => () => {
        if (selectedRow !== key) {
            setSelectedRow(key);
            onSelect(object[keyField]);
        }
        onRowDoubleClick();
    };

    const renderRow = (object, keyRow) => (
        <tr
            key={keyRow}
            onDoubleClick={selectRowAndDoubleClick(object, keyRow)}
            className={renderClasses({
                'selected': selectedRow === keyRow,
                'animate__animated animate__fadeIn': true
            })}
        >
            <td>
                <Checkbox type='checkbox' checked={selectedRow === keyRow} onChange={toggleRow(object, keyRow)} />
            </td>
            {columns.map(({ field, custom, align }, key) => (
                <td key={key} align={align || 'left'}>
                    {custom ? custom(object) : object[field]}
                </td>
            ))}
        </tr>
    );

    const renderBody = (items) => (items.map((object) => {
        const keyRow = object[keyField];
        return renderRow(object, keyRow);
    }));

    const renderButtonActions = () => (
        buttonActions.map((button, key) => (
            <Button {...button} ghost thin key={key} />
        ))
    );

    return (
        <section className='pz-table-container'>
            <section className='pz-table__caption'>
                <span>{title} ({data.length})</span>
                <section className='pz-table__caption-actions'>
                    {renderButtonActions()}
                </section>
            </section>
            <section className='pz-table-container__table'>
                <table className='pz-table'>
                    <thead>
                        {renderHeader()}
                    </thead>
                    <tbody>
                        {renderBody(data.slice(...range))}
                    </tbody>
                </table>
            </section>
            <Paginator items={data} onChange={setRange} />
        </section>
    );
}

Table.defaultProps = {
    title: '',
    keyField: null,
    columns: [],
    data: [],
    onSelect: () => {},
    onRowDoubleClick: () => {},
    buttonActions: []
};

export default Table;