import React from "react";
import { Image } from "components/ui";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";

const ProfileCard = ({ prof }) => {
  return (
    <Link
      to={CONSTANTS.URLS.PROFILE.USER_DETAIL(prof.slug)}
      className="pz-profile-user-card"
    >
      {prof?.picture ? (
        <Image src={prof?.picture} className="pz-profile-user-card__image" />
      ) : (
        <Image
          name="icons/male-avatar.svg"
          className="pz-profile-user-card__image"
        />
      )}
      <section className="pz-profile-user-card__content">
        {prof.professor_exp >= CONSTANTS.BADGES_GOALS.PROFESSOR.BRONCE ? (
          <Image
            name={
              prof.professor_exp >= CONSTANTS.BADGES_GOALS.PROFESSOR.SILVER
                ? prof.professor_exp >= CONSTANTS.BADGES_GOALS.PROFESSOR.GOLD
                  ? "ilustrations/medals/ORO.svg"
                  : "ilustrations/medals/PLATA.svg"
                : "ilustrations/medals/BRONCE.svg"
            }
            title={
              prof.interaction_exp >= CONSTANTS.BADGES_GOALS.PROFESSOR.SILVER
                ? prof.interaction_exp >= CONSTANTS.BADGES_GOALS.PROFESSOR.GOLD
                  ? "Maestro"
                  : "Experto"
                : "Talento Emergente"
            }
            className="pz-profile-user-card--badge"
          />
        ) : null}
        <h4 className="pz-profile-user-card__name">{prof.username}</h4>
        <h5 className="pz-profile-user-card__description">
          {prof.introduction}
        </h5>
        <section className="pz-profile-user-card__points">
          <section className="grid-columns">
            <h5>Cursos: {prof.number_courses}</h5>
            <h5>Educlips: {prof.number_posts}</h5>
          </section>
          <section className="grid-columns">
            <h6>profesor: {prof.professor_exp}</h6>
            <h6>estudiante: {prof.apprentice_exp}</h6>
            <h6>panzofi: {prof.interaction_exp}</h6>
          </section>
        </section>
      </section>
    </Link>
  );
};

export default ProfileCard;
