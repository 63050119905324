const modals_es = {
  course: {
    title: {
      create_title: "Crear una lección",
      edit_title: "Editar lección",
    },
    subtitle: {
      create_subtitle:
        "Hola, crea tu lección para compartir tu conocimiento con la comunidad Panzofi",
      edit_subtitle:
        "Hola, edita la información de tu lección por si algo quedó mal",
    },
    file_input: {
      title: "Thumbnail o portada de la lección (adjuntar imagen .png o .jpg)",
      state: "Ningún archivo seleccionado",
      error: "Selecciona un archivo para poder seguir adelante",
    },
    name_input: {
      title: "Nombre de la lección",
    },
    category_input: {
      title: "Categoria",
    },
    tags_input: {
      title: "Etiquetas",
    },
    level_input: {
      title: "Nivel",
      levels: {
        level1: "Principiante",
        level2: "Intermedio",
        level3: "Avanzado",
      },
    },
    language_input: {
      title: "Lenguaje",
    },
    description_input: {
      title: "Descripción",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Crear lección",
      edit_title: "Editar lección",
    },
  },
  quiz: {
    title: {
      create_title: "Crear un quiz",
      edit_title: "Editar quiz",
    },
    subtitle: {
      create_subtitle:
        "Pon aprueba los conocimientos de tus estudiantes (recuerda que solo puedes crear 1 quiz por curso y 1 quiz por módulo)",
      edit_subtitle:
        "Hola, edita la información de tu quiz por si algo quedó mal",
    },
    module_input: {
      label: "¿Es para un módulo?",
      title: "Módulo",
    },
    title_input: {
      title: "Titulo",
    },
    description_input: {
      title: "Descripción",
    },
    question: {
      text_input: {
        title: "Pregunta",
      },
      url_input: {
        title: "URL",
      },
      btns: {
        add: "Añadir pregunta",
        remove: "Remover pregunta",
      },
    },
    option: {
      text_input: {
        title: "Opción",
      },
      correct_input: {
        title: "¿Opción correcta?",
      },
      btns: {
        add: "Añadir opción",
        remove: "Remover opción",
      },
    },

    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Crear Quiz",
      edit_title: "Editar Quiz",
    },
  },
  quiz_attempt: {
    start_btn: "Comenzar quiz",
    close_btn: "Cerrar quiz",
    link_input: "Si no sabes la respuesta a esta pregunta",
    link: "visita este contenido",
    finished_title:
      "¡Felicitaciones por completar con éxito el quiz de Panzofi!",
    finished_desc: "Da clic en el botón de enviar para recibir tus resultados",
    send_btn: "Enviar quiz",
    results_title: "Acá estan tus resultados",
    results_pass:
      "¡Felicitaciones has pasado! se añadieron 10 puntos de estudiante a tu perfil",
    results_fail:
      "No has obtenido suficientes puntos, pero recuerda que puedes volverlo a intentar",
    results: "Obtuviste {{score}} puntos ",
  },
  module: {
    title: {
      create_title: "Crear un módulo",
      edit_title: "Editar módulo",
    },
    subtitle: {
      create_subtitle:
        "Específica el título y descripción del módulo para tu lección",
      edit_subtitle:
        "Edita el módulo, actualiza la información del mismo para tu lección",
    },
    title_input: {
      title: "Título",
    },
    description_input: {
      title: "Descripción",
    },
    info_input: {
      title:
        "Nota: Los módulos son creados del más antiguo al más reciente. Los puedes editar cuando creas conveniente",
    },
    submit_button: {
      create_title: "Crear módulo",
      edit_title: "Editar módulo",
    },
  },
  content: {
    title: {
      create_title: "Subir contenido",
      edit_title: "Editar contenido",
    },
    subtitle: {
      create_subtitle:
        "Sube contenido teórico a un módulo que hayas creado previamente",
      edit_subtitle:
        "Edita el contenido, descripción o agrega algo que se te haya olvidado",
    },
    module_input: {
      title: "Módulo",
    },
    title_input: {
      title: "Título",
    },
    file_label: {
      title:
        "A continuación puedes subir contenido a tu módulo, puedes subir audios (mp3, wav), videos (mp4, webm, Ogg, MPEG4) o documentos PDF. (Max 100Mb)",
    },
    file_input: {
      title: "Contenido",
      state: "Ningún archivo seleccionado",
      error: "Selecciona un archivo para poder seguir adelante",
    },
    youtube_label: {
      title: "¿quieres usar un url de youtube o google docs?",
    },
    youtube_input: {
      title: "Youtube o Google Doc url",
    },
    description_input: {
      title: "Descripción del contenido",
    },
    inPost_label: {
      title: "¿Deseas crear un educlip con este contenido?",
    },
    submit_button: {
      create_title: "Subir contenido",
      edit_title: "Editar contenido",
    },
  },
  practical: {
    title: {
      create_title: "Subir aporte.",
      edit_title: "Editar aporte.",
    },
    subtitle: {
      create_subtitle: "Sube aportes para complementar con tu conocimiento",
      edit_subtitle:
        "Edita el aporte que subiste, complementalo y comparte todo lo que puedas aportar al respecto",
    },
    title_input: {
      title: "Título",
    },
    file_label: {
      title:
        "A continuación puedes subir un aporte para complementar el módulo que estas viendo, puedes subir audios (mp3, wav), videos (mp4, webm, Ogg, MPEG4) o documentos PDF. (Max 100Mb)",
    },
    file_input: {
      title: "Contenido",
      state: "Ningún archivo seleccionado",
      error: "Selecciona un archivo para poder seguir adelante",
    },
    youtube_label: {
      title: "¿quieres usar un url de youtube?",
    },
    youtube_input: {
      title: "Youtube url",
    },
    description_input: {
      title: "Descripción del contenido",
    },
    submit_button: {
      create_title: "Subir aporte",
      edit_title: "Editar aporte",
    },
  },
  post: {
    title: {
      create_title: "Crear un edu clip",
      edit_title: "Editar edu clip",
    },
    subtitle: {
      create_subtitle:
        "Hola, crea tu edu clip para compartir tu conocimiento con la comunidad Panzofi",
      edit_subtitle:
        "Hola, edita la información de tu edu clip por si algo quedó mal",
    },
    file_label: {
      title:
        "A continuación puedes subir contenido para completar el edu clip que estas viendo, puedes subir audios (mp3, wav), videos (mp4, webm, Ogg, MPEG4) o documentos PDF. (Max 100Mb)",
    },
    file_input: {
      title: "Contenido",
      state: "Ningún archivo seleccionado",
      error: "Selecciona un archivo para poder seguir adelante",
    },
    youtube_label: {
      title: "¿quieres usar un url de youtube?",
    },
    youtube_input: {
      title: "Youtube url",
    },
    title_input: {
      title: "Título",
    },
    category_input: {
      title: "Categoria",
    },
    description_input: {
      title: "Descripción",
    },
    privacy_label: {
      title: "¿Colocar Publico?",
    },
    inLesson_label: {
      title: "¿Deseas colocar este educlip en una lección?",
    },
    lesson: {
      title: "Lección",
    },
    module: {
      title: "Módulo",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Crear edu clip",
      edit_title: "Editar edu clip",
    },
  },
  news: {
    title: {
      create_title: "Crear una noticia para el banner",
      edit_title: "Editar noticia",
    },
    subtitle: {
      create_subtitle: "Hola, crea tu noticia ",
      edit_subtitle:
        "Hola, edita la información de tu noticia por si algo quedó mal",
    },
    title_input: {
      title: "Título",
    },
    description_input: {
      title: "Descripción",
    },
    url_input: {
      title: "Url",
    },
    privacy_label: {
      title: "¿Colocar Publico?",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Crear noticia",
      edit_title: "Editar noticia",
    },
  },
  thread: {
    title: {
      create_title: "Crear una pregunta",
      edit_title: "Editar pregunta",
    },
    subtitle: {
      create_subtitle:
        "Hola, crea tu pregunta para que la comunidad panzofi te ayude",
      edit_subtitle:
        "Hola, edita la información de tu pregunta por si algo quedó mal",
    },
    file_label: {
      title: "Subir archivo",
    },
    file_input: {
      title:
        "A continuación puedes subir contenido para completar tu pregunta, puedes subir audios (mp3, wav), videos (mp4, webm, Ogg, MPEG4), imagenes (png, jpeg) o documentos PDF. (Max 100Mb)",
      state: "Ningún archivo seleccionado",
      error: "Selecciona un archivo para poder seguir adelante",
    },
    title_input: {
      title: "Titulo",
    },
    body_input: {
      title: "Descripción",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    youtube_label: {
      title: "¿quieres usar un url de youtube?",
    },
    youtube_input: {
      title: "Youtube url",
    },
    submit_button: {
      create_title: "Crear pregunta",
      edit_title: "Editar pregunta",
    },
  },
  forum: {
    title: {
      create_title: "Crear un foro",
      edit_title: "Editar foro",
    },
    subtitle: {
      create_subtitle:
        "Hola, crea tu foro para compartir tu conocimiento con la comunidad Panzofi",
      edit_subtitle:
        "Hola, edita la información de tu foro por si algo quedó mal",
    },
    file_input: {
      title: "Thumbnail o portada de la lección (adjuntar imagen .png o .jpg)",
      state: "Ningún archivo seleccionado",
      error: "Selecciona un archivo para poder seguir adelante",
    },
    title_input: {
      title: "Nombre del foro",
    },
    body_input: {
      title: "Descripción",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Crear foro",
      edit_title: "Editar foro",
    },
  },
  delete_course: {
    title: "¿Deseas eliminar este curso?",
    button: "Eliminar",
  },
  delete_module: {
    title: "¿Deseas eliminar este módulo?",
    button: "Eliminar",
  },
  delete_content: {
    title: "¿Deseas eliminar este contenido?",
    button: "Eliminar",
  },
  delete_post: {
    title: "¿Deseas eliminar este edu clip?",
    button: "Eliminar",
  },
  privacy_post: {
    private: "¿Deseas colocar este edu clip privado?",
    public: "¿Deseas colocar este edu clip publico?",
    button: "Cambiar privacidad",
  },
  delete_news: {
    title: "¿Deseas eliminar esta noticia?",
    button: "Eliminar",
  },
  privacy_news: {
    private: "¿Deseas colocar esta noticia privada?",
    public: "¿Deseas colocar esta noticia publica?",
    button: "Cambiar privacidad",
  },
  delete_thread: {
    title: "¿Deseas eliminar esta pregunta?",
    button: "Eliminar",
  },
  delete_forum: {
    title: "¿Deseas eliminar este foro?",
    button: "Eliminar",
  },
  questionnaire: {
    title: {
      create_title: "Ayúdanos a mejorar tu experiencia.",
      edit_title: "",
    },
    subtitle: {
      create_subtitle: "Cuando termines, obtendrás 10 puntos Panzofi.",
      edit_subtitle: "",
    },
    creator: {
      title: "¿Te interesa crear contenido?",
      options: {
        option1: "Si",
        option2: "No",
      },
    },
    learn_category: {
      title: "¿Qué te gustaría aprender?",
    },
    teach_category: {
      title: "¿Qué te gustaría enseñar?",
    },
    category_experience: {
      title: "¿Cuál es tu nivel en esas categorias?",
      options: {
        option1: "Principiante",
        option2: "Intermedio",
        option3: "Avanzado",
      },
    },
    educational_background: {
      title: "¿Cuál es tu nivel de estudio?",
      options: {
        option1: "Educación media",
        option2: "Universidad",
        option3: "Postgrado",
      },
    },
    reason: {
      title: "¿Cuál es la razón por la que estás aprendiendo?",
      options: {
        option1: "Desarrollo personal",
        option2: "Avance profesional",
        option3: "Fines académicos",
      },
    },
    media: {
      title: "¿Cuál es tu método de aprendizaje preferido?",
      options: {
        option1: "Videos",
        option2: "Audios",
        option3: "Tutoriales basados en texto",
        option4: "Ejercicios interactivos",
      },
    },
    new_function: {
      title:
        "¿Hay alguna función o característica específica que te gustaría ver implementada en la plataforma para mejorar tu experiencia de aprendizaje o enseñanza?",
    },
    info_input: {
      title:
        "No olvides seguir los formatos solicitados, si tienes problemas técnicos enviar correo a info@panzofi.com",
    },
    submit_button: {
      create_title: "Enviar encuesta",
      edit_title: "",
    },
  },
};
export default modals_es;
