import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PrivacyPostModal = ({ is_visible, closeModal, posts, post, history }) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    changePrivacyPost();
  };

  const changePrivacyPost = () => {
    const formData = {
      is_draft: !post.is_draft,
    };
    const deleteCourseCallback = ({ data, status }) => {
      if (status === 200) closeModal(true);
    };
    posts.modifyPost(post.id, formData, deleteCourseCallback);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h2>
        {post?.is_draft
          ? t("modals.privacy_post.public")
          : t("modals.privacy_post.private")}{" "}
      </h2>

      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <Button thin onClick={handleDelete}>
          {t("modals.privacy_post.button")}
        </Button>
      </section>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(PrivacyPostModal, "posts"));
