import React from 'react';
import { inputHandler } from "hocs";
import { Label, PasswordToggle } from "components/ui";


const PasswordField = ({
    label, name, value, onChange, notRequired, validations
}) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const checkValidations = (text) => {
      setErrorMessage(validations.find((val) => val.validation(text))?.message);
    };
    const handleChange = (event) => {
      checkValidations(event.target.value);
      onChange(event);
    };
    const [type, setType] = React.useState('password');

    const toggleType = () => setType(type === 'password' ? 'text' : 'password');

    return (
        <section className='pz-form__field icon'>
            <Label htmlFor={name}>{label}</Label>
            <input
                className='pz-form__input'
                type={type}
                id={name}
                name={name}
                onChange={handleChange}
                value={value}
                required={!notRequired}
            />
            <PasswordToggle showPassword={type === 'password'} onClick={toggleType} />
            <span className="pz-form__error_message">{errorMessage}</span>
        </section>
    );
};

PasswordField.defaultProps = {
    notRequired: false,
    validations: []
};

export default inputHandler(PasswordField);