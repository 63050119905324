import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModuleModal = ({ is_visible, closeModal, courses, quiz_id, history }) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    deleteCourse();
  };

  const deleteCourse = () => {
    const deleteCourseCallback = ({ status }) => {
      if (status === 204) {
        closeModal(true);
      }
    };
    courses.deleteQuiz(quiz_id, deleteCourseCallback);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h2>{t("modals.delete_content.title")}</h2>

      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <Button thin onClick={handleDelete}>
          {t("modals.delete_content.button")}
        </Button>
      </section>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "courses"));
