import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import { Button, Image } from "components/ui";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { CONSTANTS } from "shared/constants";
import { Link } from "react-router-dom/cjs/react-router-dom";
import API from "core/api";
import { useTranslation } from "react-i18next";
import PostCard from "apps/posts/components/post-card";
import { PostDetailModal } from "apps/posts/components";

const PostsAll = ({ posts: ReduxPosts, location, history, auth }) => {
  const [posts, setPosts] = React.useState([]);
  const [limit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [morePosts, setMorePosts] = React.useState(true);
  const { t } = useTranslation();
  const [postDetailModalVisible, setPostDetailModalVisible] =
    React.useState(false);
  const [postToEdit, setPostToEdit] = React.useState(null);

  React.useEffect(() => {
    getMorePosts();
    // eslint-disable-next-line
  }, []);

  const getMorePosts = () => {
    const params = {
      search: getSearchParams()?.search,
      category__slug: getSearchParams()?.category,
      limit: limit,
      offset: offset,
      ordering: "-num_reaction",
    };
    ReduxPosts.getPosts(params, ({ data, status }) => {
      const sortedPosts = status === 200 ? [...posts, ...data.results] : null;
      setPosts(sortedPosts);
      setOffset(offset + limit);
      data.next === null ? setMorePosts(false) : setMorePosts(true);
    });
  };

  const renderCourses = (posts) => {
    return (
      <>
        {posts.map((post, key) => (
          <PostCard post={post} key={key} onDetailView={openModalDetailPost} />
        ))}
      </>
    );
  };

  const closeModalDetailPost = () => {
    setPostToEdit(null);
    setPostDetailModalVisible(false);
  };

  const openModalDetailPost = (post) => {
    setPostToEdit(post);
    setPostDetailModalVisible(true);
  };

  const getSearchParams = () => queryString.parse(location.search);

  if (posts) {
    return (
      <Page className="pz-courses__container">
        <h3 className="pz-profile__courses--title">
          <span>
            {t("courses.bar.shorts")} ({posts?.length})
          </span>
        </h3>
        {posts?.length > 0 ? (
          <>
            <section className="pz-courses__courses">
              {renderCourses(posts)}
            </section>
            <section className="pz-courses__load__button">
              {morePosts ? (
                <Button primary thin onClick={getMorePosts}>
                  {t("courses.more_courses")}
                </Button>
              ) : (
                <Button disabled thin>
                  {t("courses.no_more_courses")}
                </Button>
              )}
            </section>
          </>
        ) : (
          <Page className="pz-profile__404">
            <h1>{t("notFound.no_courses.h1")}</h1>
            <h3> {t("notFound.no_courses.h2")}</h3>
            <div>
              {API.auth.isLoggedIn() ? (
                <Link to={CONSTANTS.URLS.PROFILE.USER_LOGGED}>
                  <Button primary>
                    {t("notFound.no_courses.button_create")}
                  </Button>
                </Link>
              ) : (
                <Link to={CONSTANTS.URLS.AUTH.SIGNUP}>
                  <Button primary>
                    {t("notFound.no_courses.button_create")}
                  </Button>
                </Link>
              )}
            </div>
            <h4>{t("notFound.no_courses.h4")}</h4>
            <div>
              <Link to={CONSTANTS.URLS.COURSES.ALL}>
                <Button primary ghost>
                  {t("notFound.no_courses.button_courses")}
                </Button>
              </Link>
            </div>
            <Image name="ilustrations/404.svg" />
          </Page>
        )}

        <PostDetailModal
          postId={postToEdit?.id}
          is_visible={postDetailModalVisible}
          closeModal={closeModalDetailPost}
        />
      </Page>
    );
  } else if (posts === null) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">{t("notFound.no_resource")}</h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  }

  return (
    <main className="pz-profile">
      <Navbar />
    </main>
  );
};

export default connectReduxReducers(withRouter(PostsAll), "posts");
