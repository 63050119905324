import { Image } from "components/ui";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Carousel from "react-multi-carousel";
import { Line } from "components/ui";
import { CONSTANTS } from "shared/constants";

const CarouselTestimony = ({ autoPlay, title }) => {
  const [toggleState] = React.useState(1);
  const responsiveShort = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to next slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }
  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to previos slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  const renderTestimonies = () => {
    return (
      <>
        <Carousel
          responsive={responsiveShort}
          swipeable={false}
          draggable={false}
          showDots={true}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={autoPlay}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="all 0.5s ease-in-out"
          transitionDuration={500}
          itemClass="pz-courses__courses__carousel-item"
          dotListClass="custom-dot-list-style2"
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
        >
          <Link
            to={CONSTANTS.URLS.PROFILE.USER_DETAIL("negociostartoficial")}
            className="pz-testimony-card"
          >
            <section className="pz-testimony-card__content">
              <h5 className="pz-testimony-card__description">
                Panzofi, me parece una plataforma sumamente importante para el
                contexto mundial, ya que permite democratizar la educación,
                abriendo muchas posibilidades de aprendizaje a miles de personas
                que no cuentan con los recursos necesarios para capacitarse.
              </h5>
            </section>
            <small className="pz-course-card__username">
              <Image src="https://panzofi-multimedia.panzofi.com/users/db658fbd-1f68-4b41-967b-d46806fbff2d/picture.png" />

              <span>negociostart</span>
            </small>
          </Link>
          <Link
            to={CONSTANTS.URLS.PROFILE.USER_DETAIL("finanzasconrafa")}
            className="pz-testimony-card"
          >
            <section className="pz-testimony-card__content">
              <h5 className="pz-testimony-card__description">
                Como creador de contenido, Panzofi ha sido una plataforma sólida
                para compartir mis videos educativos, ampliando
                significativamente mi alcance con su misión de democratizar la
                educación a nivel global, sin limitaciones geográficas.
              </h5>
            </section>
            <small className="pz-course-card__username">
              <Image name="ilustrations/referencia-rafael.svg" />

              <span>Rafa Ruiz</span>
            </small>
          </Link>
          <Link
            to={CONSTANTS.URLS.PROFILE.USER_DETAIL("comidayvida")}
            className="pz-testimony-card"
          >
            <section className="pz-testimony-card__content">
              <h5 className="pz-testimony-card__description">
                Panzofi es una plataforma que nos permite compartir y hacer
                llegar nuestro contenido a todos los que nos dedicamos a la
                educación. Su equipo es cordial y resolutivo, ¡es un placer
                poder contar con Panzofi!
              </h5>
            </section>
            <small className="pz-course-card__username">
              <Image name="ilustrations/referencia-julia.svg" />

              <span>Julia Osorio</span>
            </small>
          </Link>
          <Link
            to={CONSTANTS.URLS.PROFILE.USER_DETAIL("byspel")}
            className="pz-testimony-card"
          >
            <section className="pz-testimony-card__content">
              <h5 className="pz-testimony-card__description">
                Sin duda, Panzofi es el lugar donde convergen muchas personas
                creativas, todas con la intención de compartir los conocimientos
                que han adquirido a lo largo de los años. ¡Este espacio no solo
                fomenta el intercambio de ideas, sino que también impulsa la
                colaboración y el aprendizaje de todas las personas!
              </h5>
            </section>
            <small className="pz-course-card__username">
              <Image name="ilustrations/creators/SVG_BYSPEL.svg" />

              <span>Byspel</span>
            </small>
          </Link>
          <Link
            to={CONSTANTS.URLS.PROFILE.USER_DETAIL("juanperez")}
            className="pz-testimony-card"
          >
            <section className="pz-testimony-card__content">
              <h5 className="pz-testimony-card__description">
                Panzofi destaca su compromiso con la democratización del
                conocimiento, ofreciendo una plataforma donde estudiantes,
                educadores y cualquier persona interesada en el aprendizaje
                pueden encontrar materiales de alta calidad sin costo alguno.
              </h5>
            </section>
            <small className="pz-course-card__username">
              <Image name="ilustrations/creators/SVG_JUANP.svg" />

              <span>Juan Pérez</span>
            </small>
          </Link>
          <Link
            to={CONSTANTS.URLS.PROFILE.USER_DETAIL("paoexcel")}
            className="pz-testimony-card"
          >
            <section className="pz-testimony-card__content">
              <h5 className="pz-testimony-card__description">
                Panzofi es una innovadora plataforma en línea diseñada para
                revolucionar el acceso a la educación, proporcionando un entorno
                digital que facilita tanto la creación como el consumo de
                recursos educativos.
              </h5>
            </section>
            <small className="pz-course-card__username">
              <Image name="ilustrations/creators/SVG_PAOEXCEL.svg" />

              <span>Paoexcel</span>
            </small>
          </Link>
        </Carousel>
      </>
    );
  };
  return (
    <section className={"pz-landing__carousel"}>
      <section className="pz-courses__courses__tabcontainer">
        <section className="pz-courses__courses__tabs">
          <h4
            className={
              toggleState === 1
                ? "pz-courses__courses__active-tab"
                : "pz-courses__courses__tab"
            }
            onClick={() => {}}
          >
            {title}
          </h4>
        </section>
        <section className="pz-courses__courses__carousel">
          <Line />
          {renderTestimonies()}
        </section>
      </section>
    </section>
  );
};

export default CarouselTestimony;
