import { Image } from "components/ui";
import React from "react";
import Carousel from "react-multi-carousel";

const CarouselTestimony = ({ autoPlay, title }) => {
  const responsiveShort = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to next slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }
  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to previos slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }

  const renderTestimonies = () => {
    return (
      <>
        <Carousel
          responsive={responsiveShort}
          swipeable={false}
          draggable={false}
          showDots={true}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={autoPlay}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="all 0.5s ease-in-out"
          transitionDuration={500}
          dotListClass="custom-dot-list-style2"
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
        >
          <section className="pz-landing__portfolio">
            <Image
              className="pz-landing__portfolio--profile"
              src="https://landingmediabucket.s3.amazonaws.com/portfolio/perfil_Creadordecocina.png"
            />
            <Image
              className="pz-landing__portfolio--image"
              src="https://landingmediabucket.s3.amazonaws.com/portfolio/Portafolio_Creadordepostres.png"
            />
          </section>
          <section className="pz-landing__portfolio">
            <Image
              className="pz-landing__portfolio--profile"
              src="https://landingmediabucket.s3.amazonaws.com/portfolio/perfil_Creadordefitness.png"
            />
            <Image
              className="pz-landing__portfolio--image"
              src="https://landingmediabucket.s3.amazonaws.com/portfolio/Portafolio_Creadordefitness.png"
            />
          </section>
          <section className="pz-landing__portfolio">
            <Image
              className="pz-landing__portfolio--profile"
              src="https://landingmediabucket.s3.amazonaws.com/portfolio/perfil_Creadordeliteratura.png"
            />
            <Image
              className="pz-landing__portfolio--image"
              src="https://landingmediabucket.s3.amazonaws.com/portfolio/Portafolio_Creadordeliteratura.png"
            />
          </section>
        </Carousel>
      </>
    );
  };
  return (
    <section className="pz-courses__courses__carousel">
      {renderTestimonies()}
    </section>
  );
};

export default CarouselTestimony;
