import React from 'react';

import { renderClasses } from "shared/common/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Portal } from "components/common";


const Modal = ({
    children, is_visible, className, contentClassName, overlayClassName,
    transparent, transparentOverlay,
    overlay, exitOnOverlayClick, closeModal
}) => {
    const states = {
        HIDE: 'HIDE',
        OPENING: 'OPENING',
        OPEN: 'OPEN',
        HIDING: 'HIDING'
    };
    const [animationState, setAnimationState] = React.useState('');
    const [state, setState] = React.useState(states.HIDE);

    React.useEffect(() => {
        if (is_visible && state === states.HIDE) {
            setState(states.OPENING);
            setAnimationState('animate__fadeIn');
        }
        else if(!is_visible && state === states.OPEN) {
            setState(states.HIDING);
            setAnimationState('animate__fadeOut');
        }
    }, [state, states, is_visible]);

    const close = () => {
        if (closeModal) {
            setState(states.HIDING);
            setAnimationState('animate__fadeOut');
        }
    }

    const closeFromOverlay = () => {
        if (exitOnOverlayClick) close();
    }

    const checkEndAnimation = () => {
        switch (animationState) {
            case 'animate__fadeOut':
                setState(states.HIDE);
                if (is_visible && closeModal) closeModal();
                break;
            case 'animate__fadeIn':
                setState(states.OPEN);
                break;
            default:
                break;
        }
    };

    if (state === states.HIDE)  return null;

    return (
        <Portal>
            {
                overlay &&
                <section
                    className={renderClasses({
                        'pz-modal__overlay': true,
                        'animate__animated': true,
                        'animate__faster': true,
                        [animationState]: true,
                        'transparent': transparentOverlay,
                        [overlayClassName]: overlayClassName
                    })} onClick={closeFromOverlay}
                    onAnimationEnd={checkEndAnimation}
                />
            }
            <section
                className={renderClasses({
                    'pz-modal__content': true,
                    'animate__animated': true,
                    'animate__faster': true,
                    [animationState]: true,
                    'transparent': transparent,
                    [className]: className
                })}
                onAnimationEnd={checkEndAnimation}
            >
                {
                    typeof closeModal === 'function' &&
                    <FontAwesomeIcon
                        icon={faTimes}
                        className='pz-modal__close-button'
                        onClick={close}
                    />
                }
                <section
                    className={renderClasses({
                        'pz-modal__children': true,
                        [contentClassName]: contentClassName
                    })}
                >
                    {children}
                </section>
            </section>
        </Portal>
    );
};

Modal.defaultProps = {
    is_visible: false,
    transparent: false,
    transparentOverlay: false,
    overlay: true,
    exitOnOverlayClick: false,
    closeModal: undefined
};

export default Modal;