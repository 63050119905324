import {
  faLock,
  faTrash,
  faUser,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Line } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";

const NavbarConfig = ({ auth, index, history }) => {
  const { t } = useTranslation();
  return (
    <nav className="pz-navbar-config">
      <section
        onClick={() => {
          history.push(
            CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS(auth.user?.slug)
          );
        }}
        className={
          index === 0 ? "pz-navbar-config--active" : "pz-navbar-config--item"
        }
        title={t(`navbar.settings_navbar.user`)}
      >
        <FontAwesomeIcon icon={faWrench} />
        {/* <h5>User Settings</h5> */}
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(
            CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS_PROFILE(auth.user?.slug)
          );
        }}
        className={
          index === 1 ? "pz-navbar-config--active" : "pz-navbar-config--item"
        }
        title={t(`navbar.settings_navbar.profile`)}
      >
        <FontAwesomeIcon icon={faUser} />
        {/* <h5>Profile Settings</h5> */}
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(
            CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS_PASSWORD(
              auth.user?.slug
            )
          );
        }}
        className={
          index === 2 ? "pz-navbar-config--active" : "pz-navbar-config--item"
        }
        title={t(`navbar.settings_navbar.password`)}
      >
        <FontAwesomeIcon icon={faLock} />
        {/* <h5>Change Password</h5> */}
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(
            CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS_DELETE(auth.user?.slug)
          );
        }}
        className={
          index === 3 ? "pz-navbar-config--active" : "pz-navbar-config--item"
        }
        title={t(`navbar.settings_navbar.delete`)}
      >
        <FontAwesomeIcon icon={faTrash} />
        {/* <h5>Change Password</h5> */}
      </section>
    </nav>
  );
};

export default connectReduxReducers(withRouter(NavbarConfig), "auth");
