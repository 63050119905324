const login_es = {
  title: "Inicia sesión",
  user: "Usuario o Email",
  password: "Contraseña",
  button_login: "Inicia sesión",
  or: "o",
  button_signup: "¡Regístrate gratis!",
  button_forgot: "Olvidé mi contraseña",
  label_forgot: "¿Olvidaste tu contraseña?",
};
export default login_es;
