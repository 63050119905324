import React from "react";
import { Button, Form, Image, TextareaField } from "components/ui";
import { connectReduxReducers } from "hocs";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import API from "core/api";
import moment from "moment";
import { renderClasses } from "shared/common/functions";
import { useTranslation } from "react-i18next";

const CourseComments = ({
  commentList,
  model,
  modelId,
  comments,
  onComment,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (data) => {
    const formData = {
      ...data,

      object_id: modelId,
    };
    comments.createComment(formData, model, (response) => {
      onComment(response);
    });
  };

  const renderComments = (lessonComments) =>
    lessonComments.map(({ id, text, user, created, ...other }) => (
      <section key={id} className="pz-course-comments__comment">
        <Link to={CONSTANTS.URLS.PROFILE.USER_DETAIL(user?.slug)}>
          {user?.profile?.picture ? (
            <Image
              className="pz-course-comments__picture"
              src={user?.profile?.picture}
            />
          ) : (
            <Image
              className="pz-course-comments__picture"
              name="icons/male-avatar.svg"
            />
          )}
        </Link>
        <section className="pz-course-comments__comment--content">
          <h5 className="pz-course-comments__comment--username">
            <Link
              to={CONSTANTS.URLS.PROFILE.USER_DETAIL(user?.slug)}
              className="bold"
            >
              {user?.full_name}
            </Link>
            <small>{moment(created).format("MMMM D, YYYY - hh:mm a")}</small>
          </h5>
          <span className="pz-course-comments__comment--text">{text}</span>
        </section>
      </section>
    ));

  return (
    <section className="pz-course-comments">
      <h4 className="bold">
        <span className="pz-course-comments__tag primary">
          {commentList?.length}
        </span>{" "}
        {t("comments.title")}
      </h4>
      {API.auth.isLoggedIn() ? (
        <Form
          className="pz-course-comments__form"
          onSubmit={handleSubmit}
          resetOnSubmit
        >
          {({ onChange, data: { text } }) => (
            <React.Fragment>
              <TextareaField
                label={`${t("comments.publish_label")} (${
                  typeof text === "string" ? text.length : 0
                }/300)`}
                name="text"
                onChange={onChange}
                maxLength={300}
              />
              <Button primary ghost thin>
                {t("comments.publish_button")}
              </Button>
            </React.Fragment>
          )}
        </Form>
      ) : (
        <h5 className="bold">{t("comments.unlogged")}</h5>
      )}
      <section
        className={renderClasses({
          "pz-course-comments__content": true,
          empty: !commentList?.length,
        })}
      >
        {commentList?.length > 0 ? (
          renderComments(commentList)
        ) : (
          <>
            <p>{t("comments.empty")}</p>
          </>
        )}
      </section>
    </section>
  );
};

CourseComments.defaultProps = {
  commentList: [],
};

export default connectReduxReducers(CourseComments, "comments");
