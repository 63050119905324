import React from "react";
import { Modal } from "components/common";
import { Button, Form, InputField, Toggle } from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModuleModal = ({
  news,
  is_visible,
  closeModal,
  currentNews,
  history,
}) => {
  const { t } = useTranslation();
  const [isPublic, setIsPublic] = React.useState(false);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, [is_visible]);

  const handlePublicTogle = () => {
    setIsPublic(!isPublic);
  };

  const handleSubmit = (data) => {
    const formData = {
      ...data,
      is_draft: !isPublic,
    };

    news.createNews(formData, (response) => {
      if (response.status === 201) {
        closeModal(true);
      }
    });
  };

  const editCourse = (data) => {
    const formData = {
      ...data,
      is_draft: !isPublic,
    };
    news.modifyNews(currentNews?.id, formData, (response) => {
      if (response.status === 200) {
        closeModal(true);
      }
    });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {!!currentNews ? (
        <>
          <h1>{t("modals.news.title.edit_title")}</h1>
          <p>{t("modals.news.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.news.title.create_title")}</h1>
          <p>{t("modals.news.subtitle.create_subtitle")}</p>
        </>
      )}
      <Form
        onSubmit={!!currentNews ? editCourse : handleSubmit}
        className="pz-form"
      >
        {({ onChange, data: { title, description } }) => (
          <React.Fragment>
            <InputField
              label={`${t("modals.news.title_input.title")} (${
                typeof title === "string" ? title.length : 0
              }/50)`}
              maxLength={50}
              name="title"
              onChange={onChange}
              defaultValue={!!currentNews ? currentNews?.title : ""}
              notRequired={!!currentNews}
            />

            <TextareaField
              label={`${t("modals.news.description_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/150)`}
              maxLength={150}
              name="description"
              onChange={onChange}
              defaultValue={!!currentNews ? currentNews?.description : ""}
              notRequired={!!currentNews}
            />

            <InputField
              type="url"
              label={t("modals.news.url_input.title")}
              name="url"
              onChange={onChange}
              defaultValue={currentNews ? currentNews?.url : ""}
              notRequired={!!currentNews}
            />

            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.news.privacy_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={isPublic}
                onChange={handlePublicTogle}
              />
            </article>

            <small className="bold"> {t("modals.news.info_input.title")}</small>

            <Button type="submit" primary>
              {!!currentNews
                ? t("modals.news.submit_button.edit_title")
                : t("modals.news.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "news"));
