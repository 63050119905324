import React from 'react';
import ReactDOM from 'react-dom';

import { CONSTANTS } from "shared/constants";


class Portal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('section');
    }

    componentDidMount() {
        CONSTANTS.DOM_ROOTS.ROOT_MODAL.appendChild(this.el);
    }

    componentWillUnmount() {
        CONSTANTS.DOM_ROOTS.ROOT_MODAL.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}

export default Portal;