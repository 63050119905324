import React from "react";
import { Image } from "components/ui";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaugh,
  faFrown,
  faStar,
  faLightbulb,
  faClock,
} from "@fortawesome/free-regular-svg-icons";

const CourseCard = ({ course }) => {
  return (
    <Link
      to={CONSTANTS.URLS.COURSES.COURSE_DETAIL(course.slug)}
      className="pz-course-card"
    >
      <Image
        src={course?.thumbnail200 ? course?.thumbnail200 : course?.thumbnail}
        className="pz-course-card__image"
      />
      <section className="pz-course-card__content">
        <h4 className="pz-course-card__name">{course.name}</h4>
        <h5 className="pz-course-card__description">{course.description}</h5>
      </section>
      <section className="pz-course-card__reactions">
        <h5 className="pz-course-card__reaction">
          <FontAwesomeIcon icon={faLaugh} title="me gusta" />{" "}
          {course?.reactions?.like_count}
        </h5>
        <h5 className="pz-course-card__reaction">
          <FontAwesomeIcon icon={faFrown} title="no me gusta" />{" "}
          {course?.reactions?.fun_count}
        </h5>
        <h5 className="pz-course-card__reaction">
          <FontAwesomeIcon icon={faStar} title="calidad" />{" "}
          {course?.reactions?.love_count}
        </h5>
        <h5 className="pz-course-card__reaction">
          <FontAwesomeIcon icon={faLightbulb} title="útil" />{" "}
          {course?.reactions?.intriguing_count}
        </h5>
        <h5 className="pz-course-card__reaction">
          <FontAwesomeIcon icon={faClock} title="eficiente" />{" "}
          {course?.reactions?.interesting_count}
        </h5>
      </section>
      <small className="pz-course-card__username">
        {course?.owner?.profile?.picture ? (
          <Image
            src={
              course?.owner?.profile?.picture24
                ? course?.owner?.profile?.picture24
                : course?.owner?.profile?.picture
            }
          />
        ) : (
          <Image name="icons/male-avatar.svg" />
        )}
        <span>{course?.owner?.username}</span>
      </small>
    </Link>
  );
};

export default CourseCard;
