import React from 'react';

import { renderClasses } from "shared/common/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Portal } from "components/common";


const Notification = ({
    children, is_visible, className, closeModal
}) => {
    const states = {
        HIDE: 'HIDE',
        OPENING: 'OPENING',
        OPEN: 'OPEN',
        HIDING: 'HIDING'
    };
    const [animationState, setAnimationState] = React.useState('');
    const [state, setState] = React.useState(states.HIDE);

    React.useEffect(() => {
        if (is_visible && state === states.HIDE) {
            setState(states.OPENING);
            setAnimationState('animate__bounceIn');
        }
        else if(!is_visible && state === states.OPEN) {
            setState(states.HIDING);
            setAnimationState('animate__bounceOut');
        }
    }, [state, states, is_visible]);

    const close = () => {
        if (closeModal) {
            setState(states.HIDING);
            setAnimationState('animate__bounceOut');
        }
    }

    const checkEndAnimation = () => {
        switch (animationState) {
            case 'animate__bounceOut':
                setState(states.HIDE);
                if (is_visible) closeModal();
                break;
            case 'animate__bounceIn':
                setState(states.OPEN);
                break;
            default:
                break;
        }
    };

    if (state === states.HIDE)  return null;

    return (
        <Portal>
            <section
                className='pz-notification'
            >
                <section
                    className={renderClasses({
                        'pz-notification__box': true,
                        [className]: className,
                        'animate__animated': true,
                        [animationState]: true,
                    })}
                    onAnimationEnd={checkEndAnimation}
                >
                    <section className='pz-notification__content'>
                        {children}
                    </section>
                    {
                        typeof closeModal === 'function' &&
                        <FontAwesomeIcon
                            icon={faTimes}
                            className='pz-notification__close-button'
                            onClick={close}
                        />
                    }
                </section>
            </section>
        </Portal>
    );
};

Notification.defaultProps = {
    is_visible: false,
    closeModal: undefined
};

export default Notification;