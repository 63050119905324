const notFound_en = {
  no_exist: {
    t1: "We're not sure how you got here,",
    t2: "but this content does not exist",
  },
  no_public: "This resource is not public yet",
  no_resource: "Something happened trying to get the resources, try again.",
  no_courses: {
    h1: "There is no content available in this category yet",
    h2: "Be the first to upload content in this category",
    button_create: "Create course",
    h4: "Try changing your filters or view all courses again",
    button_courses: "See all content",
  },
};
export default notFound_en;
