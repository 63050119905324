import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Page } from "components/common";
import { useTranslation } from "react-i18next";

const LandingUniversitiesPage = () => {
  const { t } = useTranslation();

  return (
    <main className="pz-landing">
      <Navbar />

      <Page>
        <section className="pz-landing__cover">
          <section className="pz-landing__cover--content animate__animated animate__bounceIn">
            <Image
              name="ilustrations/researching.svg"
              className="pz-landing__cover--image"
            />
            <h1 className="text-center bold">{t("landing.schools.title")}</h1>
            <p>{t("landing.schools.subtitle")}</p>
            <Button primary>
              <Link
                to={CONSTANTS.URLS.COURSES.ALL}
                className="pz-landing__button"
              >
                {t("landing.schools.button")}{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="pz-landing__button--icon"
                />
              </Link>
            </Button>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.schools.ecosystem.title")}</h2>
            <p>{t("landing.schools.ecosystem.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/network_help.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.ecosystem.item1.title")}
                  </h4>
                  <p>{t("landing.schools.ecosystem.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/Format_issues.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.ecosystem.item2.title")}
                  </h4>
                  <p>{t("landing.schools.ecosystem.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/Location_issues.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.ecosystem.item3.title")}
                  </h4>
                  <p>{t("landing.schools.ecosystem.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/wallet_issue.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.ecosystem.item4.title")}
                  </h4>
                  <p>{t("landing.schools.ecosystem.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.schools.brochure.title")}</h2>
          </section>

          <section className="pz-landing__services">
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/report-promo.png"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <p>{t("landing.schools.brochure.item.title")}</p>
                <Button primary ghost thin>
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={
                      "https://share.hsforms.com/1Snctr4SNRfO2Pcxl0EE0fAbptug"
                    }
                  >
                    {t("landing.schools.brochure.item.button")}
                  </a>
                </Button>
              </section>
            </article>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.schools.how_works.title")}</h2>
            <p>
              {" "}
              <a
                target="_top"
                rel="noopener noreferrer"
                href={"https://panzofi.com/cursos/tutorial-panzo-docentes"}
              >
                {t("landing.schools.how_works.subtitle")}
              </a>{" "}
            </p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <h3 className="bold">
                {t("landing.schools.how_works.without_acount.title")}
              </h3>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/navigate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.without_acount.item1.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.schools.how_works.without_acount.item1.subtitle"
                    )}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/search.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.without_acount.item2.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.schools.how_works.without_acount.item2.subtitle"
                    )}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/find.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.without_acount.item3.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.schools.how_works.without_acount.item3.subtitle"
                    )}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/share.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.without_acount.item4.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.schools.how_works.without_acount.item4.subtitle"
                    )}
                  </p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <h3 className="bold">
                {t("landing.schools.how_works.with_acount.title")}
              </h3>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/rate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.with_acount.item1.title")}
                  </h4>
                  <p>
                    {t("landing.schools.how_works.with_acount.item1.subtitle")}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/upload.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.with_acount.item2.title")}
                  </h4>
                  <p>
                    {t("landing.schools.how_works.with_acount.item2.subtitle")}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/participation.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.with_acount.item3.title")}
                  </h4>
                  <p>
                    {t("landing.schools.how_works.with_acount.item3.subtitle")}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/winner.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.how_works.with_acount.item4.title")}
                  </h4>
                  <p>
                    {t("landing.schools.how_works.with_acount.item4.subtitle")}
                  </p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.schools.cases.title")}</h2>
            <p>{t("landing.schools.cases.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/school-ref.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.cases.item1.title")}
                  </h4>
                  <p>{t("landing.schools.cases.item1.subtitle")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/university-ref.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.schools.cases.item2.title")}
                  </h4>
                  <p>{t("landing.schools.cases.item2.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
      </Page>

      <Footer />
    </main>
  );
};

export default LandingUniversitiesPage;
