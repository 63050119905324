import { PanzofIAPage } from "./pages";
import { OptionalLoggedGuard } from "hocs/guards";

const Routes = [
  {
    path: "",
    component: OptionalLoggedGuard(PanzofIAPage),
  },
];

export default Routes;
