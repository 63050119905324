import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  create_news_request_status: CONSTANTS.FETCH.STANDBY,
  get_news_request_status: CONSTANTS.FETCH.STANDBY,
  modify_news_request_status: CONSTANTS.FETCH.STANDBY,
  delete_news_request_status: CONSTANTS.FETCH.STANDBY,
  get_admin_news_request_status: CONSTANTS.FETCH.STANDBY,
  create_admin_trivia_request_status: CONSTANTS.FETCH.STANDBY,
  get_admin_trivia_request_status: CONSTANTS.FETCH.STANDBY,
  delete_admin_trivia_request_status: CONSTANTS.FETCH.STANDBY,
  modify_admin_trivia_request_status: CONSTANTS.FETCH.STANDBY,
  get_trivia_request_status: CONSTANTS.FETCH.STANDBY,
  get_trivia_attempt_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  NEWS_REQUEST_STARTED: "NEWS_REQUEST_STARTED",
  NEWS_REQUEST_FINISHED: "NEWS_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.NEWS_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.NEWS_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  createNews:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_news_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_news_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_news_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createNews(data, successCallback, errorCallback);
    },

  getNews:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_news_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_news_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_news_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getNews(successCallback, errorCallback);
    },

  getAdminNews:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_admin_news_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_admin_news_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_admin_news_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getAdminNews(successCallback, errorCallback);
    },

  modifyNews:
    (id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_news_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_news_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_news_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyNews(id, data, successCallback, errorCallback);
    },

  //   getPost:
  //     (id, callback = () => {}) =>
  //     (dispatch) => {
  //       requestStarted(
  //         {
  //           create_news_request_status: CONSTANTS.FETCH.PROGRESS,
  //         },
  //         dispatch
  //       );

  //       const successCallback = (response) =>
  //         requestFinished(
  //           {
  //             create_news_request_status: CONSTANTS.FETCH.SUCCESS,
  //           },
  //           dispatch,
  //           response,
  //           callback
  //         );

  //       const errorCallback = (response) =>
  //         requestFinished(
  //           {
  //             create_news_request_status: CONSTANTS.FETCH.FAILED,
  //           },
  //           dispatch,
  //           response,
  //           callback
  //         );

  //       API.panzofi.getPost(id, successCallback, errorCallback);
  //     },

  deleteNews:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_news_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_news_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_news_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteNews(id, successCallback, errorCallback);
    },

  createAdminTrivia:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_admin_trivia_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_admin_trivia_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_admin_trivia_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createAdminTrivia(data, successCallback, errorCallback);
    },

  getAdminTrivias:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_admin_trivia_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_admin_trivia_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_admin_trivia_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getAdminTrivias(successCallback, errorCallback);
    },

  deleteAdminTrivia:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_admin_trivia_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_admin_trivia_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_admin_trivia_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteAdminTrivia(id, successCallback, errorCallback);
    },

  modifyAdminTrivia:
    (id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_admin_trivia_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_admin_trivia_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_admin_trivia_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyAdminTrivia(id, data, successCallback, errorCallback);
    },

  getTrivias:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_trivia_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_trivia_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_trivia_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getTrivias(successCallback, errorCallback);
    },

  getTriviaAttempt:
    (id_attempt, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_trivia_attempt_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_trivia_attempt_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_trivia_attempt_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getTriviaAttempt(id_attempt, successCallback, errorCallback);
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.NEWS_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.NEWS_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
