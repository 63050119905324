import React from "react";
import { connectReduxReducers } from "hocs";
import API from "core/api";

const ViewCreation = ({ auth, views, id_content, model_content }) => {
  React.useEffect(() => {
    createView();
    // eslint-disable-next-line
  }, []);

  const createView = (id = id_content, model = model_content) => {
    let formData = {};
    if (API.auth.isLoggedIn()) {
      formData = {
        object_id: id,
        user: API.auth.getUser().id,
      };
    } else {
      formData = {
        object_id: id,
      };
    }

    views.createContentView(formData, model, (response) => {});
  };

  return <></>;
};

export default connectReduxReducers(ViewCreation, "views");
