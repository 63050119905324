import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEdit,
  faGlobe,
  faLock,
  faNewspaper,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

const NewsCard = ({
  currentNews,
  onEditPost,
  onDeletePost,
  onChangeStatus,
}) => {
  return (
    <section className="pz-news__card-item">
      <section className="pz-news__card-item--type">
        <FontAwesomeIcon icon={faNewspaper} />
        <section className="grid-columns grid-gap-20 grid-items-middle">
          {typeof onChangeStatus === "function" ? (
            <FontAwesomeIcon
              icon={currentNews.is_draft ? faLock : faGlobe}
              className={
                currentNews.is_draft ? "pz-news__card-item--private" : ""
              }
              title={currentNews.is_draft ? "Privado" : "Publico"}
              onClick={(e) => {
                e.stopPropagation();
                onChangeStatus(currentNews);
              }}
            />
          ) : null}
          {typeof onEditPost === "function" ? (
            <FontAwesomeIcon
              icon={faEdit}
              onClick={(e) => {
                e.stopPropagation();
                onEditPost(currentNews);
              }}
            />
          ) : null}
          {typeof onDeletePost === "function" ? (
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => {
                e.stopPropagation();
                onDeletePost(currentNews);
              }}
            />
          ) : null}
        </section>
      </section>
      <section className="pz-news__card-item--content">
        <h4 className="bold">{currentNews?.title}</h4>
        <p className="pz-news__card-item--description">
          {currentNews?.description}
        </p>
      </section>
    </section>
  );
};

export default withRouter(NewsCard);
