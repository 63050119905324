import React from "react";
import { CONSTANTS } from "shared/constants";
import { Image } from "components/ui";
import { Link } from "react-router-dom";

const PostUserBadge = ({ user }) => {
  return (
    <Link
      to={CONSTANTS.URLS.PROFILE.USER_DETAIL(user?.slug)}
      className="pz-posts__badge"
    >
      {user?.profile?.picture ? (
        <Image src={user?.profile?.picture} />
      ) : (
        <Image name="icons/male-avatar.svg" />
      )}
      <section>
        <h4 className="bold">{user?.full_name}</h4>
      </section>
    </Link>
  );
};

export default PostUserBadge;
