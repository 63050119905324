import { Button } from "components/ui";
import React from "react";
import QaModal from "../qa-modal";
import { useTranslation } from "react-i18next";
import { connectReduxReducers } from "hocs";
import { TriviaAttemptModal } from "..";

const Activities = ({ auth, news, user_slug, quest, getUserProfile }) => {
  const [qaModalVisible, setQAModalVisible] = React.useState(false);
  const [triviaModalVisible, setTriviaModalVisible] = React.useState(false);
  const [selectedTrivia, setSelectedTrivia] = React.useState(null);
  const [trivias, setTrivias] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    getTrivias();
    // eslint-disable-next-line
  }, [auth.token]);

  const getTrivias = () => {
    news.getTrivias(({ data, status }) => {
      setTrivias(status === 200 ? data : null);
    });
  };

  const closeModal = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setQAModalVisible(false);
  };

  const openModal = () => {
    if (!quest) setQAModalVisible(true);
  };

  const closeModalTrivia = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setTriviaModalVisible(false);
  };

  const openModalTrivia = (trivia) => {
    setSelectedTrivia(trivia);
    setTriviaModalVisible(true);
  };

  return (
    <section className="pz-profile__tasks">
      <h1>{t("profile.challenges.title")}</h1>
      <section className="pz-profile__tasks--content">
        {quest ? (
          <Button whiteghost secondary disabled>
            {t("profile.challenges.questionaire_done")}
          </Button>
        ) : (
          <Button whiteghost secondary onClick={openModal}>
            {t("profile.challenges.questionaire")}
          </Button>
        )}
        {trivias.map((trivia, key) => {
          return (
            <Button
              whiteghost
              secondary
              key={key}
              onClick={() => openModalTrivia(trivia)}
            >
              {trivia.title}
            </Button>
          );
        })}
      </section>
      <QaModal
        is_visible={qaModalVisible}
        user_slug={user_slug}
        course={quest}
        closeModal={closeModal}
      />
      <TriviaAttemptModal
        is_visible={triviaModalVisible}
        trivia={selectedTrivia}
        closeModal={closeModalTrivia}
      />
    </section>
  );
};

export default connectReduxReducers(Activities, "news", "auth");
