import { BindActions } from "core/redux";
import API from "core/api";

class Interceptor {
  constructor() {
    this.requests = [];
  }

  getAuthorizationHeader = () => {
    const token = API.auth.getToken();
    return `token ${token ? token.token : null}`;
  };

  showLoader = () => {
    BindActions.toggleLoadingModal();
  };

  hideLoader = () => {
    BindActions.toggleLoadingModal();
  };

  startRequest = (request_url) => {
    if (this.requests.length === 0) {
      this.showLoader();
    }
    this.requests.push(request_url);
  };

  endRequest = () => {
    this.requests.pop();
    if (this.requests.length === 0) {
      this.hideLoader();
    }
  };

  requestHandler = (request) => {
    this.startRequest(request.url);
    return request;
  };

  requestAuthorizationHandler = (request) => {
    this.startRequest(request.url);
    if (API.auth.getToken()) {
      request.headers["Authorization"] = this.getAuthorizationHeader();
    }
    return request;
  };

  errorHandler = (error) => {
    this.endRequest();
    this.notifyMessage(error.response || {});

    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        API.auth.logoutLocal();
      }
    }

    return Promise.reject({ ...error });
  };

  successHandler = (response) => {
    this.endRequest();
    return response;
  };

  getMessage = (response) => {
    const status = response.status || -1;
    let message = "";

    switch (status) {
      case 200:
        message = "Success";
        break;
      case 400:
      case 406:
        const data = response.data;

        if (data.non_field_errors) {
          message = data.non_field_errors;
        } else if (data.detail) {
          message = data.detail;
        } else {
          message = "Unknown error";
        }

        break;
      case 401:
      case 403:
        message = "No tienes permiso para acceder a este recurso";
        break;
      case 404:
        message = "No se encontró el recurso";
        break;
      default:
        message = "Algo sucedio en el servidor, intenta nuevamente";
        break;
    }

    return [status, message];
  };

  notifyMessage = (response) => {
    const [status, message] = this.getMessage(response);
    const type = 200 <= status && status <= 299 ? "secondary" : "alert";

    if (message !== "") {
      BindActions.showNotification(message, { type, label: status });
    }
  };
}

export default Interceptor;
