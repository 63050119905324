import { Page } from "components/common";
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";

const TriviaResults = ({
  match: {
    params: { triviaId },
  },
  news,
  history,
}) => {
  const [triviaAttempt, setTriviaAttempt] = React.useState(undefined);
  const { t } = useTranslation();
  React.useEffect(() => {
    getAttempt();
    // eslint-disable-next-line
  }, []);
  const getAttempt = () => {
    news.getTriviaAttempt(triviaId, ({ data, status }) => {
      setTriviaAttempt(status === 200 ? data : null);
    });
  };
  return (
    <main className="pz-profile">
      <Navbar />
      <Page className="pz-profile__content">
        <section className="grid grid-gap-20  grid-items-center">
          {triviaAttempt?.passed === false ? (
            <>
              <h2>
                {t("profile.trivia_results.title_fail", {
                  username: triviaAttempt?.user?.username,
                })}
              </h2>
              <h4>
                {t("profile.trivia_results.score", {
                  score: triviaAttempt?.score,
                })}
              </h4>
              <h5>{t("profile.trivia_results.quote")}</h5>
            </>
          ) : (
            <section className="grid grid-gap-10  grid-items-center">
              <h2>
                {t("profile.trivia_results.title_pass", {
                  username: triviaAttempt?.user?.username,
                })}
              </h2>
              <h4>
                {t("profile.trivia_results.score", {
                  score: triviaAttempt?.score,
                })}{" "}
              </h4>
              <Image
                name="ilustrations/winners.svg"
                className="pz-profile__trivia--imgresults"
              />
            </section>
          )}

          <h5>{t("profile.trivia_results.info")}</h5>
          <section className="grid-columns grid-gap-20">
            <Button primary thin>
              <Link target="_top" to={CONSTANTS.URLS.AUTH.LOGIN}>
                {t("login.button_login")}
              </Link>
            </Button>
            <Button primary ghost thin>
              <Link target="_top" to={CONSTANTS.URLS.AUTH.SIGNUP}>
                {t("login.button_signup")}
              </Link>
            </Button>
          </section>
        </section>
      </Page>
      <Footer />
    </main>
  );
};

export default withRouter(connectReduxReducers(TriviaResults, "news"));
