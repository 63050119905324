import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ReactGA from "react-ga4";

import AuthRoutes from "./auth";
import LandingRoutes from "./landing";
import ProfileRoutes from "./profile";
import CoursesRoutes from "./courses";
import FormRoutes from "./forums";
import PostsRoutes from "./posts";
import AdminRoutes from "./admin";
import IARoutes from "./ia";
import CookieConsentBanner from "components/panzofi/cookieconsentbanner";

const App = () => {
  const routes = [
    {
      path: "/auth",
      routes: AuthRoutes,
    },
    {
      path: "/admin",
      routes: AdminRoutes,
    },
    {
      path: "/perfil",
      routes: ProfileRoutes,
    },
    {
      path: "/cursos",
      routes: CoursesRoutes,
    },
    {
      path: "/forum",
      routes: FormRoutes,
    },
    {
      path: "/fastlearning",
      routes: PostsRoutes,
    },
    {
      path: "/panzofia",
      routes: IARoutes,
    },
    {
      path: "",
      routes: LandingRoutes,
    },
  ];

  const renderRoute = (path, Component, key) => (
    <Route path={path} exact key={key}>
      <Component />
    </Route>
  );

  const renderRoutes = (routes, path = "", index = 0) =>
    routes.reduce((renderedRoutes, mainRoute, i) => {
      const prePath = `${path}${mainRoute.path}`;
      const key = `${index}${i}`;
      const in_development =
        mainRoute.in_development === undefined
          ? false
          : mainRoute.in_development;

      if (process.env.NODE_ENV === "production" && in_development) {
        return renderedRoutes;
      }

      if (mainRoute.routes) {
        return [
          ...renderedRoutes,
          ...renderRoutes(mainRoute.routes, prePath, key),
        ];
      }

      if (mainRoute.component) {
        return [
          ...renderedRoutes,
          renderRoute(prePath, mainRoute.component, key),
        ];
      }

      return renderedRoutes;
    }, []);

  return (
    <BrowserRouter>
      <CookieConsentBanner></CookieConsentBanner>
      <Route
        render={({ location }) => {
          ReactGA.set({ page: location.pathname });
          ReactGA.send("pageview");

          return (
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={location.key}
                timeout={{ enter: 300, exit: 300 }}
                classNames="fade"
              >
                <Switch location={location}>{renderRoutes(routes)}</Switch>
              </CSSTransition>
            </TransitionGroup>
          );
        }}
      />
    </BrowserRouter>
  );
};

export default App;
