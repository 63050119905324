import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  user: {},
  token: null,

  login_request_status: CONSTANTS.FETCH.STANDBY,
  token_validation_request_status: CONSTANTS.FETCH.STANDBY,

  forgot_password_data: {},
  forgot_password_request_status: CONSTANTS.FETCH.STANDBY,

  change_password_request_status: CONSTANTS.FETCH.STANDBY,

  signup_organization_request_status: CONSTANTS.FETCH.STANDBY,

  signup_request_status: CONSTANTS.FETCH.STANDBY,

  active_account_request_status: CONSTANTS.FETCH.STANDBY,

  create_employee_request_status: CONSTANTS.FETCH.STANDBY,

  logout_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  AUTH_REQUEST_STARTED: "AUTH_REQUEST_STARTED",
  AUTH_REQUEST_FINISHED: "AUTH_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.AUTH_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.AUTH_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  logout:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          logout_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            user: {},
            token: null,
            logout_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            logout_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.auth.logoutToken(successCallback, errorCallback);
    },

  login:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          login_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            user: response.data?.user,
            token: response.data?.token,
            login_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            login_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.auth.login(data, successCallback, errorCallback);
    },

  facebook_login:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          login_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            user: response.data?.user,
            token: response.data?.token,
            login_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            login_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.auth.facebook_login(data, successCallback, errorCallback);
    },

  forgot_password:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          forgot_password_request_status: CONSTANTS.FETCH.PROGRESS,
          change_password_request_status: CONSTANTS.FETCH.STANDBY,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            forgot_password_data: response.data,
            forgot_password_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            forgot_password_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.auth.forgot_password(data, successCallback, errorCallback);
    },

  change_password:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          change_password_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            change_password_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            change_password_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.auth.change_password(data, successCallback, errorCallback);
    },

  change_password2:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          change_password_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            change_password_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            change_password_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.panzofi.change_password2(data, successCallback, errorCallback);
    },

  createUser:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          signup_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            signup_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            signup_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.auth.signup(data, successCallback, errorCallback);
    },

  deleteAccount:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          signup_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            signup_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            signup_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.panzofi.deleteAccount(data, successCallback, errorCallback);
    },

  createOrganization:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          signup_organization_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            signup_organization_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            signup_organization_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.panzofi.signupOrganization(
        data,
        successCallback,
        errorCallback
      );
    },

  activeAccount:
    (code, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          active_account_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            active_account_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            active_account_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.panzofi.activeAccount(code, successCallback, errorCallback);
    },

  createEmployee:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_employee_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_employee_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_employee_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.panzofi.createEmployee(data, successCallback, errorCallback);
    },

  validate_token:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          token_validation_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            user: response.data?.user,
            token: response.data?.token,
            token_validation_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            token_validation_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      return API.auth.validate_token(successCallback, errorCallback);
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.AUTH_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
