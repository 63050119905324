import React from "react";
import { Media, Player } from "react-media-player";
import Image from "../image";

const AudioPlayer = ({ url }) => {
  return (
    <section className="grid grid-items-center">
      <Image name="ilustrations/sound.svg" style={{ width: "50%" }} />
      <Media>
        <Player
          src={url}
          controls
          controlsList="nodownload"
          vendor="audio"
          style={{ width: "100%" }}
        />
      </Media>
    </section>
  );
};

export default AudioPlayer;
