import React from "react";
import { Modal } from "components/common";
import {
  Button,
  FileField,
  Form,
  InputField,
  SelectField,
  Toggle,
} from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { CONSTANTS } from "shared/constants";
import { validateGoogleDoc, validateYoutubeUrl } from "shared/common";
import { useTranslation } from "react-i18next";
import { youtubeDocVal } from "shared/utils/input-validations";

const ModuleModal = ({
  courses,
  posts,
  is_visible,
  closeModal,
  course,
  lessonToEdit,
}) => {
  const [fileType, setFileType] = React.useState(CONSTANTS.FILE_TYPES.VIDEO);
  const [isURL, setIsURL] = React.useState(false);
  const [createEduclip, setCreateEduclip] = React.useState(false);
  const { t } = useTranslation();

  const handleURLTogle = () => {
    setIsURL(!isURL);
    if (isURL) {
      setFileType(CONSTANTS.FILE_TYPES.VIDEO);
    }
  };

  const handleCreateEduclipTogle = () => {
    setCreateEduclip(!createEduclip);
  };

  const handleSubmit = ({ module, ...data }) => {
    const formData = {
      ...data,
      type: fileType,
    };
    if (isURL) {
      if (validateGoogleDoc(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.DOCUMENT;
        setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
      } else if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }
    courses.createModuleContent(course?.slug, module, formData, (response) => {
      if (response.status === 201) {
        if (createEduclip) {
          if (isURL && validateGoogleDoc(formData.youtubeurl)) {
            closeModal(true);
            return;
          }
          const postData = {
            ...formData,
            is_draft: false,
            category: course.category,
          };
          posts.createPost(postData, (response) => {
            if (response.status === 201) {
              closeModal(true);
            }
          });
        } else {
          closeModal(true);
        }
      }
    });
  };

  const updateLesson = (data) => {
    const formData = {
      ...data,
    };
    if (isURL) {
      if (validateGoogleDoc(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.DOCUMENT;
        setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
      } else if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
      if (formData.file) formData.type = fileType;
    }
    courses.updateModuleContent(
      course?.slug,
      lessonToEdit?.moduleId,
      lessonToEdit?.id,
      formData,
      (response) => {
        if (response.status === 200) {
          closeModal(true);
        }
      }
    );
  };

  const getModuleOptions = () =>
    course?.modules?.map(({ title, id }, key) => ({
      label: `${t("modals.content.module_input.title")} ${key + 1}: ${title}`,
      value: id,
    }));

  const onFileChange = (onChange) => (data, fileData) => {
    if (fileData?.type) {
      switch (fileData.type) {
        case "video/mp4":
          setFileType(CONSTANTS.FILE_TYPES.VIDEO);
          break;
        case "audio/mpeg":
          setFileType(CONSTANTS.FILE_TYPES.AUDIO);
          break;
        case "application/pdf":
          setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
          break;
        default:
          setFileType(undefined);
          break;
      }
    }
    onChange(data, fileData);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {lessonToEdit ? (
        <>
          <h1>{t("modals.content.title.edit_title")}</h1>
          <p>{t("modals.content.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.content.title.create_title")}</h1>
          <p>{t("modals.content.subtitle.create_subtitle")}</p>
        </>
      )}

      <Form
        onSubmit={lessonToEdit ? updateLesson : handleSubmit}
        className="pz-form"
      >
        {({ onChange, data: { title, description } }) => (
          <React.Fragment>
            <SelectField
              label={t("modals.content.module_input.title")}
              name="module"
              options={getModuleOptions()}
              onChange={onChange}
              notRequired={!!lessonToEdit}
            />
            <InputField
              label={`${t("modals.content.title_input.title")} (${
                typeof title === "string" ? title.length : 0
              }/100)`}
              name="title"
              maxLength={100}
              onChange={onChange}
              defaultValue={lessonToEdit ? lessonToEdit?.title : ""}
              notRequired={!!lessonToEdit}
            />
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.content.youtube_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={isURL}
                onChange={handleURLTogle}
              />
            </article>

            {isURL ? (
              <InputField
                type="url"
                label={t("modals.content.youtube_input.title")}
                name="youtubeurl"
                onChange={onChange}
                defaultValue={lessonToEdit ? lessonToEdit?.youtubeurl : ""}
                notRequired={!!lessonToEdit}
                validations={[youtubeDocVal]}
              />
            ) : (
              <>
                <p>{t("modals.content.file_label.title")}</p>
                <FileField
                  nameDefault={t("modals.content.file_input.state")}
                  errMsg={t("modals.content.file_input.error")}
                  label={t("modals.content.file_input.title")}
                  name="file"
                  onChange={onFileChange(onChange)}
                  notRequired={!!lessonToEdit}
                />
              </>
            )}
            <TextareaField
              label={`${t("modals.content.description_input.title")} (${
                typeof description === "string" ? description.length : 0
              }/300)`}
              maxLength={300}
              name="description"
              onChange={onChange}
              defaultValue={lessonToEdit ? lessonToEdit?.description : ""}
              notRequired={!!lessonToEdit}
            />
            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.content.inPost_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={createEduclip}
                onChange={handleCreateEduclipTogle}
              />
            </article>
            <Button type="submit" primary>
              {lessonToEdit
                ? t("modals.content.submit_button.edit_title")
                : t("modals.content.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default connectReduxReducers(ModuleModal, "courses", "posts");
