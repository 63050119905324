import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  categories: {},
  get_categories_request_status: CONSTANTS.FETCH.STANDBY,

  create_course_request_status: CONSTANTS.FETCH.STANDBY,
  modify_course_request_status: CONSTANTS.FETCH.STANDBY,
  get_course_request_status: CONSTANTS.FETCH.STANDBY,
  get_courses_request_status: CONSTANTS.FETCH.STANDBY,
  get_admin_courses_request_status: CONSTANTS.FETCH.STANDBY,
  delete_courses_request_status: CONSTANTS.FETCH.STANDBY,

  create_module_request_status: CONSTANTS.FETCH.STANDBY,
  update_module_request_status: CONSTANTS.FETCH.STANDBY,
  get_module_request_status: CONSTANTS.FETCH.STANDBY,
  delete_module_request_status: CONSTANTS.FETCH.STANDBY,
  order_module_request_status: CONSTANTS.FETCH.STANDBY,

  create_module_content_request_status: CONSTANTS.FETCH.STANDBY,
  update_module_content_request_status: CONSTANTS.FETCH.STANDBY,
  get_module_content_request_status: CONSTANTS.FETCH.STANDBY,
  delete_module_content_request_status: CONSTANTS.FETCH.STANDBY,
  order_module_content_request_status: CONSTANTS.FETCH.STANDBY,

  create_module_practical_content_request_status: CONSTANTS.FETCH.STANDBY,
  update_module_practical_content_request_status: CONSTANTS.FETCH.STANDBY,
  get_module_practical_content_request_status: CONSTANTS.FETCH.STANDBY,
  delete_module_practical_content_request_status: CONSTANTS.FETCH.STANDBY,

  create_course_sub_request_status: CONSTANTS.FETCH.STANDBY,
  get_course_sub_request_status: CONSTANTS.FETCH.STANDBY,
  delete_course_sub_request_status: CONSTANTS.FETCH.STANDBY,

  create_quiz_request_status: CONSTANTS.FETCH.STANDBY,
  modify_quiz_request_status: CONSTANTS.FETCH.STANDBY,
  delete_quiz_request_status: CONSTANTS.FETCH.STANDBY,
  create_quiz_attempt_request_status: CONSTANTS.FETCH.STANDBY,
  get_quiz_attempt_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  COURSE_REQUEST_STARTED: "COURSE_REQUEST_STARTED",
  COURSE_REQUEST_FINISHED: "COURSE_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.COURSE_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.COURSE_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  getCategories:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_categories_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) => {
        requestFinished(
          {
            categories: response.data.reduce(
              (map, item) => ({
                ...map,
                [item.id]: item,
              }),
              {}
            ),
            get_categories_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );
      };

      const errorCallback = (response) =>
        requestFinished(
          {
            get_categories_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCategories(successCallback, errorCallback);
    },

  createCourse:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_course_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_course_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_course_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createCourse(data, successCallback, errorCallback);
    },

  createCourseSubscription:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_course_sub_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_course_sub_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_course_sub_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createCourseSubscription(id, successCallback, errorCallback);
    },

  getCourseSubscription:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_course_sub_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_course_sub_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_course_sub_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCourseSubscription(id, successCallback, errorCallback);
    },

  deleteCourseSubscription:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_course_sub_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_course_sub_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_course_sub_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteCourseSubscription(id, successCallback, errorCallback);
    },

  modifyCourse:
    (slug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_course_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_course_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_course_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyCourse(slug, data, successCallback, errorCallback);
    },

  getCourses:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_courses_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_courses_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_courses_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCourses(successCallback, errorCallback, filter);
    },

  getCoursesRecommended:
    (callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_courses_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_courses_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_courses_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCoursesRecommended(successCallback, errorCallback);
    },

  getAdminCourses:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_admin_courses_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_admin_courses_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_admin_courses_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getAdminCourses(successCallback, errorCallback, filter);
    },

  getCourse:
    (slug, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_course_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_course_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_course_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCourse(slug, successCallback, errorCallback);
    },

  deleteCourse:
    (slug, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_courses_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_courses_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_courses_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteCourse(slug, successCallback, errorCallback);
    },

  createModule:
    (slug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_module_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_module_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_module_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createModule(slug, data, successCallback, errorCallback);
    },

  updateModule:
    (courseSlug, moduleId, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          update_module_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            update_module_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            update_module_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updateModule(
        courseSlug,
        moduleId,
        data,
        successCallback,
        errorCallback
      );
    },

  deleteModule:
    (courseSlug, moduleId, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_module_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_module_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_module_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteModule(
        courseSlug,
        moduleId,
        successCallback,
        errorCallback
      );
    },

  updateModuleOrder:
    (slug, id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          order_module_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            order_module_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            order_module_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updateModuleOrder(
        slug,
        id,
        data,
        successCallback,
        errorCallback
      );
    },

  deleteModuleContent:
    (courseSlug, moduleId, lessonId, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_module_content_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_module_content_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_module_content_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteModuleContent(
        courseSlug,
        moduleId,
        lessonId,
        successCallback,
        errorCallback
      );
    },
  createModuleContent:
    (slug, id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_module_content_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_module_content_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_module_content_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createModuleContent(
        slug,
        id,
        data,
        successCallback,
        errorCallback
      );
    },

  updateModuleContent:
    (courseSlug, moduleId, lessonId, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          update_module_content_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            update_module_content_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            update_module_content_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updateModuleContent(
        courseSlug,
        moduleId,
        lessonId,
        data,
        successCallback,
        errorCallback
      );
    },

  updateContentOrder:
    (slug, id, id2, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          order_module_content_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            order_module_content_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            order_module_content_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updateContentOrder(
        slug,
        id,
        id2,
        data,
        successCallback,
        errorCallback
      );
    },

  createPracticalContent:
    (slug, moduleId, contentId, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_module_practical_content_request_status:
            CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_module_practical_content_request_status:
              CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_module_practical_content_request_status:
              CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createPracticalContent(
        slug,
        moduleId,
        contentId,
        data,
        successCallback,
        errorCallback
      );
    },

  updatePracticalContent:
    (slug, moduleId, contentId, practicalId, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          update_module_practical_content_request_status:
            CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            update_module_practical_content_request_status:
              CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            update_module_practical_content_request_status:
              CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updatePracticalContent(
        slug,
        moduleId,
        contentId,
        practicalId,
        data,
        successCallback,
        errorCallback
      );
    },

  getPracticalContent:
    (slug, moduleId, contentId, practicalId, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_module_practical_content_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_module_practical_content_request_status:
              CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_module_practical_content_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getPracticalContent(
        slug,
        moduleId,
        contentId,
        practicalId,
        successCallback,
        errorCallback
      );
    },

  deletePracticalContent:
    (slug, moduleId, contentId, practicalId, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_module_practical_content_request_status:
            CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_module_practical_content_request_status:
              CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_module_practical_content_request_status:
              CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deletePracticalContent(
        slug,
        moduleId,
        contentId,
        practicalId,
        successCallback,
        errorCallback
      );
    },

  getModule:
    (slug, moduleId, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_module_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_module_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_module_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getModule(slug, moduleId, successCallback, errorCallback);
    },

  getModuleContent:
    (slug, moduleId, contentId, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_module_content_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_module_content_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_module_content_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getModuleContent(
        slug,
        moduleId,
        contentId,
        successCallback,
        errorCallback
      );
    },

  createQuiz:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_quiz_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_quiz_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_quiz_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createQuiz(data, successCallback, errorCallback);
    },

  deleteQuiz:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_quiz_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_quiz_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_quiz_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.deleteQuiz(id, successCallback, errorCallback);
    },

  getQuiz:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          delete_quiz_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            delete_quiz_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            delete_quiz_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getQuiz(id, successCallback, errorCallback);
    },

  modifyQuiz:
    (id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          modify_quiz_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            modify_quiz_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            modify_quiz_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.modifyQuiz(id, data, successCallback, errorCallback);
    },

  createQuizAttempt:
    (id, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_quiz_attempt_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_quiz_attempt_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_quiz_attempt_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createQuizAttempt(id, data, successCallback, errorCallback);
    },

  getPassedQuizAttempt:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_quiz_attempt_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_quiz_attempt_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_quiz_attempt_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getQuizAttempt(id, successCallback, errorCallback);
    },

  createAIRequest:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_quiz_attempt_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_quiz_attempt_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_quiz_attempt_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createAIRequest(data, successCallback, errorCallback);
    },

  listAIRequest:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_quiz_attempt_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_quiz_attempt_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_quiz_attempt_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.listAIRequest(successCallback, errorCallback, filter);
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.COURSE_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.COURSE_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
