import React from "react";
import ReactDom from "react-dom";
import ViewportSize from "react-viewport-size";
import { Provider } from "react-redux";

import { ServiceWorker } from "shared/common";
import { ModalLoading, EventNotification } from "components/panzofi";
import store from "core/redux";
import { CONSTANTS } from "shared/constants";
import App from "apps";
import moment from "moment";
import "moment/locale/es";
import ReactGA from "react-ga4";

import "shared/scss/index.scss";
import "./shared/common/I18n/index.js";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
moment.locale("es");
debugger;
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}
debugger;
ReactDom.render(<h1>Hello</h1>, CONSTANTS.DOM_ROOTS.ROOT_APP);
ReactDom.render(
  <Provider store={store}>
    <App />
    <ModalLoading />
    <EventNotification />
    <ViewportSize corner="bottom-left" />
  </Provider>,
  CONSTANTS.DOM_ROOTS.ROOT_APP
);

ServiceWorker.register();
