import React from 'react';
import { Filter, NavbarPageLayout } from "components/panzofi";


class TablePageLayout extends React.Component {
    render() {
        const { appName, navbarOptions, filterSections, onFilterChange, children } = this.props;

        return (
            <NavbarPageLayout appName={appName} navbarOptions={navbarOptions}>
                <section className='pz-table-page'>
                    <section className='pz-table-page__filter'>
                        <Filter sections={filterSections} onChange={onFilterChange} />
                    </section>
                    <section className='pz-table-page__table'>
                        <section className='pz-table-page__table-content'>
                            {children}
                        </section>
                    </section>
                </section>
            </NavbarPageLayout>
        );
    }
}

export default TablePageLayout;