const curso = {
  id: "b3da3cf72182",
  created: "2021-05-21T08:33:41.809583-05:00",
  updated: "2021-05-21T08:53:03.826373-05:00",
  slug: "entrenamiento",
  is_draft: false,
  category: "74f744b8-015e-4b0b-9665-544f335286ec",
  name: "Entrenamiento",
  description: "",
  thumbnail: "",
  reactions: {
    like_count: 0,
    fun_count: 0,
    intriguing_count: 0,
    interesting_count: 0,
    love_count: 0,
  },
  users: 0,
  language: "español",
  modules: [
    {
      id: "module1",
      title: "Introducción",
      description: "4 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module1content1",
          title: "Bienvenida",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module1content2",
          title:
            "Cómo crear y vender cursos en línea: estrategias para el éxito",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module1content3",
          title: "¡Crea tu primera clase ahora!",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module1content4",
          title: "¡Crea y vende tu primer curso en línea con éxito!",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module2",
      title: "Hardware, Software y requerimientos",
      description: "4 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module2content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module2content2",
          title: "Equipos y herramientas para crear cursos en línea de calidad",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module2content3",
          title: "Herramientas para crear presentaciones y grabar diapositivas",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module2content4",
          title: "Cómo crear un curso en línea exitoso: requisitos y consejos",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module3",
      title: "Economía",
      description: "5 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module3content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module3content2",
          title: "Modulo de costos",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module3content3",
          title: "Diversificación de fuentes de ingresos",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module3content4",
          title: "Escalabilidad en los negocios de cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module3content5",
          title: "Destacando en el mundo competitivo de los cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module4",
      title: "Validación del curso",
      description: "5 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module4content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module4content2",
          title: "Cómo encontrar pruebas de mercado",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module4content3",
          title: "Estructurando un curso atractivo",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module4content4",
          title: "Obteniendo retroalimentación para crear tu curso",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module4content5",
          title: "Preventa exitosa: vende tu curso antes de crearlo",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module5",
      title: "Creando un curso increíble",
      description: "4 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module5content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module5content2",
          title: "Estilos de curso en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module5content3",
          title: "Creación de comunidad",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module5content4",
          title: "Consejos",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module6",
      title: "Creando tu primera clase",
      description: "7 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content2",
          title: "Preparación y Diseño de Presentaciones Impactantes",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content3",
          title: "Iniciando con powerpoint",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content4",
          title: "Iniciando con Google Slides",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content5",
          title: "Iniciando con Keynote",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content6",
          title: "Iniciando con Canva",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module6content7",
          title: "Recursos para Diseñar Presentaciones Visualmente Atractivas",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module7",
      title: "Armar el curso",
      description: "3 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module7content1",
          title: "Poniendo el curso en marcha",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module7content2",
          title: "Creando una introducción impactante para tu curso en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module7content3",
          title:
            "Rompiéndolo en pedazos: la importancia de descomponer tu curso",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module8",
      title: "Grabación de tu curso",
      description: "4 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module8content1",
          title: "Grabación de tu curso: consejos para un video profesional",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module8content2",
          title: "Elige un estilo de grabación para tu conferencia",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module8content3",
          title: "Cómo grabar la conferencia perfecta",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module8content4",
          title: "Conecta Powerpoint con Camtasia",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module9",
      title: "Hospedaje de tu curso",
      description: "12 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content2",
          title: "Alojamiento propio vs. Plataformas de mercado",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content3",
          title: "¿Cuál opción de auto hospedaje deberías elegir?",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content4",
          title: "Comenzando con thinkific: tu plataforma para cursos online",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content5",
          title: "Empezando con Teachable: tu plataforma para cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content6",
          title: "Comienza con Podia: tu plataforma para crecer en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content7",
          title:
            "Comienza rápidamente con Learnworlds: tu plataforma de cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content8",
          title: "Los mejores plugins de Wordpress para cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content9",
          title: "Explorando los mercados de cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content10",
          title: "Cómo crear tu curso en Udemy ",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content11",
          title: "Cómo subir tu curso a Skillshare ",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module9content12",
          title: "Escribe mejores títulos y descripciones que venden",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module10",
      title: "Promoción de tu curso		",
      description: "14 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content2",
          title:
            "Identificando tu propuesta única de venta (usp) y audiencia objetivo",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content3",
          title:
            "Explotando el poder de las redes sociales para promocionar tu curso",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content4",
          title:
            "Aprovecha el potencial del seo para promocionar tus cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content5",
          title:
            "Cómo crear un video promocional de Youtube exitoso para tu curso online",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content6",
          title:
            "Cómo crear un video promocional exitoso para tu curso en Panzofi",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content7",
          title:
            "Cómo desarrollar un curso mini complementario para impulsar tus ventas",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content8",
          title:
            "Cómo ofrecer descuentos en tus cursos para impulsar las ventas",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content9",
          title:
            "Cómo reunir reseñas de tus estudiantes actuales para mejorar tu curso",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content10",
          title: "Cómo promocionar efectivamente tu curso online",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content11",
          title:
            "Aprovechando el poder de la publicidad en línea para tu curso",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content12",
          title:
            "Aprovechando el poder de las reseñas y editores para tu curso",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content13",
          title:
            "Cómo ser un invitado de podcast exitoso para promocionar tu curso online",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module10content14",
          title:
            "Cómo crear y presentar un seminario web en vivo atractivo para promocionar tu curso online",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module11",
      title: "Vender tu curso",
      description: "10 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content2",
          title:
            "¿Qué es un embudo de ventas? Guía para dirigir a los clientes hacia la compra",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content3",
          title: "Cómo construir el embudo de ventas perfecto en YouTube",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content4",
          title: "Cómo crear un embudo de correo electrónico efectivo",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content5",
          title: "Cómo crear un embudo de ventas automatizado",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content6",
          title: "Cómo crear un formulario con Google Forms",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content7",
          title: "Guía para comenzar con MailChimp",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content8",
          title: "Cómo comenzar con un crm para vender tus cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content9",
          title: "¿Cómo determinar el precio de mi curso online?",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module11content10",
          title: "¿Qué impulsa las compras de los consumidores?",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
    {
      id: "module12",
      title: "Haz crecer tu marca y tus ingresos",
      description: "6 clases",
      content: [
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module12content1",
          title: "Introducción",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module12content2",
          title: "Escalabilidad en la venta de cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module12content3",
          title:
            "Ser el primer jugador en la venta de cursos en línea             ",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module12content4",
          title: "Mejora tu estrategia de venta cruzada",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module12content5",
          title: "Optimiza tu negocio de cursos en línea con retroalimentación",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
        {
          created: "2021-05-21T08:36:51.105346-05:00",
          id: "module12content6",
          title: "Errores comunes en la creación de cursos en línea",
          description: "",
          type: "VID",
          youtubeurl: null,
        },
      ],
    },
  ],
  comments: [],
};
export default curso;
