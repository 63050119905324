import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PrivacyNewsModal = ({
  is_visible,
  closeModal,
  news,
  currentNews,
  history,
}) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    changePrivacyPost();
  };

  const changePrivacyPost = () => {
    const formData = {
      is_draft: !currentNews.is_draft,
    };
    const deleteCourseCallback = ({ data, status }) => {
      if (status === 200) closeModal(true);
    };
    news.modifyNews(currentNews.id, formData, deleteCourseCallback);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h2>
        {currentNews?.is_draft
          ? t("modals.privacy_news.public")
          : t("modals.privacy_news.private")}{" "}
      </h2>

      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <Button thin onClick={handleDelete}>
          {t("modals.privacy_news.button")}
        </Button>
      </section>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(PrivacyNewsModal, "news"));
