import React from "react";
import { AuthForm, FacebookLoginButton } from "apps/auth/components";
import { Button, DateField, InputField, PasswordField } from "components/ui";
import { withRouter } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import {
  notEmpty,
  notSpecialCharacters,
  notSpaces,
  passVal,
} from "shared/utils/input-validations";
import { useTranslation } from "react-i18next";
import API from "core/api";
const SignupPage = ({ auth, history }) => {
  const [success] = React.useState(false);
  const { t } = useTranslation();

  const passregex = /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/;

  const goToLogin = () => {
    history.push(CONSTANTS.URLS.AUTH.LOGIN);
  };

  const handleFacebookLogin = (accessToken) => {
    const data = {
      access_token: accessToken,
    };
    auth.facebook_login(data);
  };

  const navbarActions = (
    <>
      <h5>{t("navbar.login_span")}</h5>
      <Button
        primary
        thin
        onClick={() => {
          history.push(CONSTANTS.URLS.AUTH.LOGIN);
        }}
      >
        {t("navbar.login")}
      </Button>
    </>
  );

  const onSubmit = (data) => {
    if (data.password.match(passregex)) {
      auth.createUser(data, (response) => {
        if (response.status === 201) {
          auth.login(
            {
              username: data.username,
              password: data.password,
            },
            (response) => {
              if (response.status === 200) {
                API.panzofi.createProfile(
                  {
                    picture: null,
                    user: response.data.user?.id,
                  },
                  ({ status }) => {
                    if (status === 201) {
                      console.log("creado");
                    }
                  }
                );
              }
            }
          );
        }
      });
    }
  };

  if (success) {
    return (
      <AuthForm
        navbarActions={navbarActions}
        onSubmit={onSubmit}
        title="¡Excelente!"
        message="Tu registro ha sido exitoso. Bienvenido a la comunidad de Panzofi, esperamos que participes activamente."
      >
        {() => (
          <React.Fragment>
            <dl>
              <dt>
                {" "}
                <h5>Te invitamos a ver el siguiente enlace: </h5>
              </dt>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://panzofi.com/cursos/tutorial-panzofi"}
              >
                <h5>--- Tutorial Panzofi ---</h5>
              </a>
            </dl>
            <h5>Vuelve a iniciar sesión para comenzar</h5>
            <section className="pz-auth-form__buttons">
              <Button type="button" ghost secondary onClick={goToLogin}>
                Volver a iniciar sesión
              </Button>
            </section>
          </React.Fragment>
        )}
      </AuthForm>
    );
  }

  return (
    <AuthForm
      navbarActions={navbarActions}
      onSubmit={onSubmit}
      title={t("register.title")}
    >
      {({ onChange }) => (
        <React.Fragment>
          <InputField
            label={t("register.name")}
            name="first_name"
            onChange={onChange}
            validations={[notEmpty, notSpecialCharacters]}
            required
          />
          <InputField
            label={t("register.last_names")}
            name="last_name"
            onChange={onChange}
            validations={[notEmpty, notSpecialCharacters]}
            required
          />
          <DateField
            label={"Fecha de nacimiento"}
            name="date_of_birth"
            onChange={onChange}
            required
          />
          <InputField
            label={t("register.user")}
            name="username"
            onChange={onChange}
            validations={[notEmpty, notSpecialCharacters, notSpaces]}
            required
          />
          <InputField
            label={t("register.email")}
            name="email"
            onChange={onChange}
            type="email"
            validations={[notEmpty, notSpaces]}
            required
          />
          <PasswordField
            label={t("register.password")}
            name="password"
            onChange={onChange}
            validations={[notEmpty, notSpaces, passVal]}
            required
          />
          <dl>
            <dd>
              {" "}
              <h6>{t("register.terms1")}</h6>
            </dd>
            <dd>
              {" "}
              <h6>{t("register.terms2")}</h6>
            </dd>
            <dd>
              {" "}
              <h6>{t("register.terms3")}</h6>
            </dd>
          </dl>
          <section className="pz-auth-form__buttons">
            <Button type="submit" primary>
              {t("register.button")}
            </Button>
          </section>
          <section className="pz-auth-form__buttons">
            <FacebookLoginButton onLogin={handleFacebookLogin} />
          </section>
        </React.Fragment>
      )}
    </AuthForm>
  );
};

export default withRouter(SignupPage);
