import React from "react";
import {
  AudioPlayer,
  Button,
  FileField,
  Form,
  Image,
  InputField,
  PDFDocument,
  TextareaField,
  Toggle,
  VideoPlayer,
} from "components/ui";
import { connectReduxReducers } from "hocs";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import API from "core/api";
import moment from "moment";
import { renderClasses } from "shared/common/functions";
import { useTranslation } from "react-i18next";
import { youtubeVal } from "shared/utils/input-validations";
import { validateYoutubeUrl } from "shared/common";

const ThreadResponses = ({
  responsesList,
  forumSlug,
  threadId,
  threads,
  onResponse,
}) => {
  const { t } = useTranslation();
  const [fileType, setFileType] = React.useState(undefined);
  const [isURL, setIsURL] = React.useState(false);

  const handleSubmit = (data) => {
    const formData = {
      ...data,
      type: fileType,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }
    threads.createThreadResponse(forumSlug, threadId, formData, (response) => {
      onResponse(response);
    });
  };

  const onFileChange = (onChange) => (data, fileData) => {
    if (fileData?.type) {
      switch (fileData.type) {
        case "video/mp4":
          setFileType(CONSTANTS.FILE_TYPES.VIDEO);
          break;
        case "audio/mpeg":
          setFileType(CONSTANTS.FILE_TYPES.AUDIO);
          break;
        case "application/pdf":
          setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
          break;
        case "image/png":
          setFileType(CONSTANTS.FILE_TYPES.IMAGE);
          break;
        case "image/jpeg":
          setFileType(CONSTANTS.FILE_TYPES.IMAGE);
          break;
        case "image/jpg":
          setFileType(CONSTANTS.FILE_TYPES.IMAGE);
          break;
        default:
          setFileType(undefined);
          break;
      }
    }
    onChange(data, fileData);
  };

  const handleURLTogle = () => {
    setIsURL(!isURL);
    if (isURL) {
      setFileType(CONSTANTS.FILE_TYPES.VIDEO);
    }
  };

  const renderMedia = (type, url) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return <PDFDocument url={url} />;
      case CONSTANTS.FILE_TYPES.AUDIO:
        return <AudioPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return <VideoPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.IMAGE:
        return <Image src={url} />;
      default:
        return null;
    }
  };

  const renderComments = (lessonComments) =>
    lessonComments.map(
      ({ id, text, user, file, type, youtubeurl, created, ...other }) => (
        <section key={id} className="pz-course-comments__comment">
          <Link to={CONSTANTS.URLS.PROFILE.USER_DETAIL(user?.slug)}>
            {user?.profile?.picture ? (
              <Image
                className="pz-thread-responses__picture"
                src={user?.profile?.picture}
              />
            ) : (
              <Image
                className="pz-thread-responses__picture"
                name="icons/male-avatar.svg"
              />
            )}
          </Link>
          <section className="pz-thread-responses__comment--content">
            <h5 className="pz-thread-responses__comment--username">
              <Link
                to={CONSTANTS.URLS.PROFILE.USER_DETAIL(user?.slug)}
                className="bold"
              >
                {user?.full_name}
              </Link>
              <small>{moment(created).format("MMMM D, YYYY - hh:mm a")}</small>
            </h5>
            <span className="pz-thread-responses__comment--text">{text}</span>
            <section className="pz-thread-responses__file">
              {file || youtubeurl
                ? file
                  ? renderMedia(type, file)
                  : renderMedia(type, youtubeurl)
                : null}
            </section>
          </section>
        </section>
      )
    );

  return (
    <section className="pz-thread-responses">
      <h4 className="bold">
        <span className="pz-thread-responses__tag primary">
          {responsesList?.length}
        </span>{" "}
        {t("forum.answers.title")}
      </h4>
      {API.auth.isLoggedIn() ? (
        <Form
          className="pz-thread-responses__form"
          onSubmit={handleSubmit}
          resetOnSubmit
        >
          {({ onChange, data: { text } }) => (
            <React.Fragment>
              <TextareaField
                label={`${t("forum.answers.text")} (${
                  typeof text === "string" ? text.length : 0
                }/2000)`}
                name="text"
                onChange={onChange}
                maxLength={2000}
              />
              <article className="pz-filter__checkbox">
                <label className="pz-filter__label">
                  {t("modals.thread.youtube_label.title")}
                </label>
                <Toggle
                  className="pz-form__file-input"
                  type="checkbox"
                  checked={isURL}
                  onChange={handleURLTogle}
                />
              </article>
              {isURL ? (
                <InputField
                  type="url"
                  label={t("modals.thread.youtube_input.title")}
                  name="youtubeurl"
                  onChange={onChange}
                  defaultValue={""}
                  notRequired={true}
                  validations={[youtubeVal]}
                />
              ) : (
                <>
                  <p>{t("modals.thread.file_label.title")}</p>
                  <FileField
                    nameDefault={t("modals.thread.file_input.state")}
                    errMsg={""}
                    label={t("modals.thread.file_input.title")}
                    name="file"
                    onChange={onFileChange(onChange)}
                    notRequired={true}
                  />
                </>
              )}
              <section className="grid-columns grid-gap-20 grid-content-left">
                <Button primary ghost thin>
                  {t("forum.answers.btn_submit")}
                </Button>
              </section>
            </React.Fragment>
          )}
        </Form>
      ) : (
        <h5 className="bold">{t("comments.unlogged")}</h5>
      )}
      <section
        className={renderClasses({
          "pz-thread-responses__content": true,
          empty: !responsesList?.length,
        })}
      >
        {responsesList?.length > 0 ? (
          renderComments(responsesList)
        ) : (
          <>
            <p>{"No hay respuestas aún"}</p>
          </>
        )}
      </section>
    </section>
  );
};

ThreadResponses.defaultProps = {
  responsesList: [],
};

export default connectReduxReducers(ThreadResponses, "threads");
