import { Cookies } from "react-cookie-consent";

export const getCookie = (cookiename) => {
  return Cookies.get(cookiename);
};

export const setCookie = (cookiename, cookievalue, expireTime) => {
  Cookies.set(cookiename, cookievalue, {
    expires: expireTime ? expireTime : 364,
    sameSite: "Lax",
    path: "/",
  });
};

export const removeCookie = (cookiename) => {
  return Cookies.remove(cookiename);
};
