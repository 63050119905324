import React from "react";
import { AuthForm, FacebookLoginButton } from "apps/auth/components";
import { Button, InputField, PasswordField } from "components/ui";
import { withRouter } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";

const LoginPage = ({ auth, history }) => {
  const { t } = useTranslation();

  const goToForgotPassword = () => {
    history.push(CONSTANTS.URLS.AUTH.FORGOT_PASSWORD);
  };

  const handleFacebookLogin = (accessToken) => {
    const data = {
      access_token: accessToken,
    };
    auth.facebook_login(data);
  };

  const navbarActions = (
    <>
      <h5>{t("navbar.signup_span")}</h5>
      <Button
        primary
        thin
        onClick={() => {
          history.push(CONSTANTS.URLS.AUTH.SIGNUP);
        }}
      >
        {t("navbar.signup")}
      </Button>
    </>
  );

  const onSubmit = (data) => {
    auth.login(data);
    history.goBack();
  };

  return (
    <AuthForm
      navbarActions={navbarActions}
      onSubmit={onSubmit}
      title={t("login.title")}
    >
      {({ onChange }) => (
        <React.Fragment>
          <InputField
            label={t("login.user")}
            name="username"
            onChange={onChange}
          />
          <PasswordField
            label={t("login.password")}
            name="password"
            onChange={onChange}
          />
          <section className="pz-auth-form__buttons">
            <Button type="submit" primary thin>
              {t("login.button_login")}
            </Button>
            <Button
              type="button"
              secondary
              thin
              ghost
              onClick={goToForgotPassword}
            >
              {t("login.button_forgot")}
            </Button>
          </section>
          <section className="pz-auth-form__buttons">
            <FacebookLoginButton onLogin={handleFacebookLogin} />
          </section>
        </React.Fragment>
      )}
    </AuthForm>
  );
};

export default withRouter(LoginPage);
