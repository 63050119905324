import { LoggedAdminGuard } from "hocs";
import {
  AdminCoursesPage,
  AdminNewsPage,
  AdminPanzofiaPage,
  AdminPostPage,
  AdminTriviaPage,
  AdminUsersPage,
} from "./pages";

const Routes = [
  {
    path: "/users",
    component: LoggedAdminGuard(AdminUsersPage),
  },
  {
    path: "/courses",
    component: LoggedAdminGuard(AdminCoursesPage),
  },
  {
    path: "/posts",
    component: LoggedAdminGuard(AdminPostPage),
  },
  {
    path: "/news",
    component: LoggedAdminGuard(AdminNewsPage),
  },
  {
    path: "/trivias",
    component: LoggedAdminGuard(AdminTriviaPage),
  },
  {
    path: "/ais",
    component: LoggedAdminGuard(AdminPanzofiaPage),
  },
];

export default Routes;
