import React from "react";
import { Modal } from "components/common";
import { connectReduxReducers } from "hocs";
import { CONSTANTS } from "shared/constants";
import { AudioPlayer, VideoPlayer, Line, PostPDFDocument } from "components/ui";
import moment from "moment";
import {
  CourseComments,
  CourseUserBadge,
  Reactions,
  ViewCreation,
} from "apps/courses/components/index";

const PostDetailModal = ({ posts, is_visible, closeModal, postId }) => {
  const [currentPost, setCurrentPost] = React.useState(undefined);
  React.useEffect(() => {
    if (is_visible) {
      getLessonContent();
    } else {
      setCurrentPost({});
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const getLessonContent = () => {
    posts.getPost(postId, ({ status, data }) => {
      if (status === 200) {
        setCurrentPost(data);
      } else {
        closeModal();
      }
    });
  };

  const renderMedia = (type, url) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return <PostPDFDocument url={url} />;
      case CONSTANTS.FILE_TYPES.AUDIO:
        return <AudioPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return <VideoPlayer url={url} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="pz-posts__modal"
    >
      <section className="grid-gap-20 grid-content-top">
        <CourseUserBadge user={currentPost?.owner} />
        {currentPost?.file
          ? renderMedia(currentPost.type, currentPost.file)
          : renderMedia(currentPost?.type, currentPost?.youtubeurl)}
        <section className="pz-posts__data--modalinfo">
          <h3 className="bold">{currentPost?.title}</h3>
          <p>{currentPost?.description}</p>
          <small>
            Subido en {moment(currentPost?.created).format("MMMM D, YYYY")}
          </small>
        </section>
      </section>
      <section className="grid-gap-20 grid-content-top ">
        <Reactions
          reactionList={currentPost?.reactions}
          model={CONSTANTS.APP_MODELS.POSTS}
          modelId={currentPost?.id}
          onReact={getLessonContent}
        />
        <Line className="pz-lesson__data--linepad" />
        <CourseComments
          commentList={currentPost?.comments}
          model={CONSTANTS.APP_MODELS.POSTS}
          modelId={currentPost?.id}
          onComment={getLessonContent}
        />
      </section>
      <ViewCreation
        id_content={postId}
        model_content={CONSTANTS.APP_MODELS.POSTS}
      />
    </Modal>
  );
};

export default connectReduxReducers(PostDetailModal, "posts");
