import React from "react";
import { Modal } from "components/common";
import { Button, Image } from "components/ui";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ShareButton from "components/panzofi/sharebutton";
import { useTranslation } from "react-i18next";
const TriviaAttemptModal = ({
  auth,
  courses,
  profile,
  trivia,
  is_visible,
  closeModal,
  history,
}) => {
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(-1);
  const [results, setResults] = React.useState(null);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  React.useEffect(() => {
    setIndex(-1);
    setResults(null);
    setSelectedOptions([]);
    // eslint-disable-next-line
  }, [is_visible]);

  //   const handleSubmit = (data) => {
  //     profile.createQuestionnaire(data, (response) => {
  //       if (response.status === 201) {
  //         console.log("cerrado");
  //         closeModal(true);
  //       }
  //     });
  //   };

  const goToNextPage = () => {
    setIndex(index + 1);
  };

  const handleOptionSelect = (optionId) => {
    setSelectedOptions([...selectedOptions, optionId]);
    goToNextPage();
  };

  const submitTriviaAttempt = () => {
    // Prepare data for submission
    const triviaAttemptData = {
      triviaId: trivia.id,
      answers: trivia.questions.map((question, index) => ({
        questionId: question.id,
        selectedOptionIndex:
          selectedOptions[index] !== undefined ? selectedOptions[index] : null,
      })),
    };

    // Send data to the backend
    profile.createTriviaAttempt(trivia.id, triviaAttemptData, (response) => {
      if (response.status === 201) {
        setResults(response.data);
      }
    });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      <section className="pz-profile__trivia">
        {results ? (
          <section className="grid grid-gap-20 grid-content-center grid-items-center">
            <h1>{t("others.trivia.modal.results_title")}</h1>

            <section className="grid grid-gap-20 grid-content-center grid-items-center">
              {results?.passed === true ? (
                <>
                  <h3>{t("others.trivia.modal.results_pass")}</h3>
                  <Image
                    name="ilustrations/quiz_passed.svg"
                    className="pz-profile__trivia--img"
                  />
                </>
              ) : (
                <>
                  <h3>{t("others.trivia.modal.results_fail")}</h3>
                  <Image
                    name="ilustrations/quiz_failed.svg"
                    className="pz-profile__trivia--img"
                  />
                </>
              )}
              <h3>
                {t("others.trivia.modal.results", { score: results?.score })}
              </h3>
              <section className="pz-profile__trivia--actions">
                <Button thin onClick={closeModal}>
                  {t("others.trivia.modal.close_btn")}
                </Button>
                <ShareButton
                  url={`${window.location.origin}/perfil/${auth.user?.slug}/results/${results?.id}`}
                  hashtag={`¡Obtuve ${results?.score} en la trivia panzofi, intentalo tu tambien!`}
                  title={`¡Obtuve ${results?.score} en la trivia panzofi, intentalo tu tambien!`}
                />
              </section>
            </section>
          </section>
        ) : index === -1 ? (
          <section className="grid grid-gap-20 grid-content-center grid-items-center">
            <h1>{trivia?.title}</h1>
            <h3>{trivia?.description}</h3>
            <Image
              name="ilustrations/quiz.svg"
              className="pz-profile__trivia--img"
            />
            <section className="grid-columns grid-gap-20 ">
              <Button secondary onClick={goToNextPage}>
                {t("others.trivia.modal.start_btn")}
              </Button>
              <Button onClick={closeModal}>
                {t("others.trivia.modal.close_btn")}
              </Button>
            </section>
          </section>
        ) : index === trivia.questions.length ? (
          <section className="grid grid-content-center grid-items-center">
            <h1>{t("others.trivia.modal.finished_title")}</h1>
            <h3>{t("others.trivia.modal.finished_desc")}</h3>
            <section className="pz-profile__trivia--options">
              <Button secondary onClick={submitTriviaAttempt}>
                {t("others.trivia.modal.send_btn")}
              </Button>
            </section>
          </section>
        ) : (
          <section className="grid-content-center">
            <h5 className="pz-modal__page">
              {index + 1}/{trivia?.questions.length}
            </h5>
            <h2>{trivia?.questions[index]?.text}</h2>
            <section className="pz-profile__trivia--options">
              {trivia?.questions[index].options.map((opt, key) => {
                return (
                  <Button
                    secondary
                    ghost
                    key={key}
                    onClick={() => handleOptionSelect(opt.id)}
                  >
                    {opt.text}
                  </Button>
                );
              })}
            </section>
            {trivia?.questions[index]?.url ? (
              <h5 className="grid-self-right">
                {t("others.trivia.modal.link_input")}{" "}
                <a
                  className="bold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={trivia?.questions[index]?.url}
                >
                  {t("others.trivia.modal.link")}{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="pz-landing__button--icon"
                  />
                </a>
              </h5>
            ) : null}
          </section>
        )}
      </section>
    </Modal>
  );
};

export default withRouter(
  connectReduxReducers(TriviaAttemptModal, "profile", "auth")
);
