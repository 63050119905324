import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url }) => {
  const playerRef = React.useRef(null);

  React.useEffect(() => {
    const resumeAudioContext = () => {
      if (playerRef.current && playerRef.current.getInternalPlayer) {
        const internalPlayer = playerRef.current.getInternalPlayer();
        if (
          internalPlayer &&
          internalPlayer.context &&
          internalPlayer.context.state === "suspended"
        ) {
          internalPlayer.context.resume();
        }
      }
    };

    // Agregar event listeners para detectar interacciones del usuario
    window.addEventListener("click", resumeAudioContext);
    window.addEventListener("keydown", resumeAudioContext);

    return () => {
      // Limpiar event listeners
      window.removeEventListener("click", resumeAudioContext);
      window.removeEventListener("keydown", resumeAudioContext);
    };
  }, []);

  const isYouTubeVideo =
    url?.includes("youtube.com") || url?.includes("youtu.be");

  if (isYouTubeVideo) {
    const videoId = getYouTubeVideoId(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
      <ReactPlayer
        ref={playerRef}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        url={embedUrl}
        pip={true}
        controls
        width="100%"
      />
    );
  }

  return (
    <ReactPlayer
      ref={playerRef}
      config={{ file: { attributes: { controlsList: "nodownload" } } }}
      url={url}
      pip={true}
      controls
      width="100%"
    />
  );
};

export default VideoPlayer;

// Function to extract video ID from YouTube URL
function getYouTubeVideoId(url) {
  const youtubePatternLong = /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
  const youtubePatternShort = /youtu\.be\/([a-zA-Z0-9_-]+)/;
  const longMatch = url.match(youtubePatternLong);
  const shortMatch = url.match(youtubePatternShort);
  const otherMatch = url.match(
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\b[^/]+\b\/)?(?:watch\?(?:.*&)?v=|shorts\/|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/
  );

  if (longMatch) {
    return longMatch[1];
  } else if (shortMatch) {
    return shortMatch[1];
  } else if (otherMatch) {
    return otherMatch[1];
  }

  return "";
}
