const forum_en = {
  forums: {
    title: "Panzofi Forums",
    no_info: "There are no forums yet",
    btn_create: "Create your forum",
    input_search: "Search for a forum",
    btn_create_logout: "Log in to create a forum",
  },
  forum: {
    title: "Panzofi Forum",
    btn_create: "Create question",
    input_search: "Search a question",
    btn_edit: "Edit forum",
    btn_delete: "Delete forum",
    btn_create_logout: "Log in to create a question",
  },
  thread: {
    btn_edit: "Edit question",
    btn_delete: "Delete question",
  },
  answers: {
    title: "Answers",
    text: "Post an answer",
    btn_submit: "Submit answer",
  },
};
export default forum_en;
