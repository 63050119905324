const profile_en = {
  modal: {
    title: {
      create_title: "!Create your profile!",
      edit_title: "Edit your profile",
    },
    subtitle: {
      create_subtitle:
        "Hello {{name}}, we're just one step away; this way our community can get to know you better.",
      edit_subtitle:
        "Hello {{name}}, edit your profile information or add more details.",
    },
    subtitle2: {
      create_subtitle2:
        "The information you specify below will be public on your profile.",
      create_subtitle3:
        "Additionally, it's necessary for you to interact with the community by commenting!",
    },
    file_input: {
      title: "Profile Picture (attach .png or .jpg image)",
      state: "No file selected",
      error: "",
    },
    website_input: {
      title:
        "Website - blog, Linkedin profile, personal page, etc (must start with https://)",
    },
    location_input: {
      title: "Location",
    },
    phone_input: {
      title: "Phone - max 15 digits (public contact number)",
    },
    description_input: {
      title: "Tell us a bit about yourself",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats. If you encounter technical issues, send an email to info@panzofi.com",
    },
    submit_button: {
      create_title: "Create my profile",
      edit_title: "Edit profile",
    },
  },
  experience: {
    title: "Panzofi Experience",
    about: "About me",
    pts_student: "Student Pts",
    pts_panzofi: "Panzofi Pts",
    pts_profesor: "Teacher Pts",
  },
  tabs: {
    option1: "Lessons",
    option2: "Educlips",
    option3: "Saved",
  },
  lessons_saved: "Lessons saved",
  lessons_user: "My lessons uploaded",
  lessons_no_user: "Lessons uploaded",
  lessons_no_exist: "No lessons in this section",
  lesson_button: "Upload a lesson",
  posts: {
    saved: "Saved edu clips",
    user: "My Uploaded edu clips",
    no_user: "Uploaded edu clips",
    no_exist: "There are no edu clips in this section",
    button: "Upload an edu clip",
  },
  challenges: {
    title: "Panzofi Challenges",
    questionnaire:
      "Answer this survey about your preferences to earn Panzofi points",
    questionnaire_done:
      "You have already submitted the survey, your Panzofi points have been updated",
    trivia: "Play our Panzofi trivia to test your knowledge",
  },
  trivia_results: {
    title_fail: "{{username}} did not score enough to pass this trivia",
    title_pass: "{{username}} passed the Panzofi trivia.",
    score: "Score: {{score}} out of 100",
    quote:
      "Remember: 'The only limit to learning is our willingness to learn.' - Brian Herbert",
    info: "Log in or register to take this trivia",
  },
};
export default profile_en;
