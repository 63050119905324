import React from "react";
import { renderClasses } from "shared/common/functions";

const Button = ({
  children,
  className,
  ghost,
  thin,
  primary,
  share,
  secondary,
  whiteghost,
  tertiary,
  icon,
  common,
  active,
  warning,
  info,
  alert,
  ...props
}) => (
  <button
    {...props}
    className={renderClasses({
      "pz-button": true,
      ghost: ghost,
      thin: thin,
      primary: primary,
      secondary: secondary,
      tertiary: tertiary,
      common: common,
      active: active,
      warning: warning,
      info: info,
      alert: alert,
      icon: icon,
      share: share,
      whiteghost,
      [className]: className !== "",
    })}
  >
    {children}
  </button>
);

Button.defaultProps = {
  ghost: false,
  thin: false,
  disabled: false,
  default: false,
  primary: false,
  secondary: false,
  tertiary: false,
  common: false,
  active: false,
  warning: false,
  info: false,
  alert: false,
  icon: false,
  share: false,
  whiteghost: false,
  className: "",
};

export default Button;
