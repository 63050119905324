import React from 'react';
import { Modal } from 'components/common';
import { Button, Image } from "components/ui";
import { renderClasses } from "shared/common/functions";


const ModalForm = ({
    title, imageName, closeModal, is_visible, children, invert, buttonActions
}) => {

    const renderButtonActions = () => (
        buttonActions.map((button, key) => (
            <Button {...button} key={key} />
        ))
    );

    return (
        <Modal
            is_visible={is_visible}
            className={renderClasses({
                'pz-form-modal': true,
                'invert': invert
            })}
            closeModal={closeModal}
            exitOnOverlayClick
        >
            <section className='pz-form-modal__content'>
                <h1 className='pz-form-modal__title'>{title}</h1>
                <section className='pz-form-modal__children'>
                    {children}
                </section>
                <section className='pz-form-modal__actions'>
                    {renderButtonActions()}
                </section>
            </section>
            <Image className='pz-form-modal__image' name={imageName} />
        </Modal>
    );
}

ModalForm.defaultProps = {
    title: 'Modal form',
    imageName: 'logos/mo_color.svg',
    invert: false,
    buttonActions: []
};

export default ModalForm;