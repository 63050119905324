const modals_en = {
  course: {
    title: {
      create_title: "Create a lesson",
      edit_title: "Edit lesson",
    },
    subtitle: {
      create_subtitle:
        "Hello, create your lesson to share your knowledge with the Panzofi community",
      edit_subtitle:
        "Hi, edit your lesson information in case something went wrong.",
    },
    file_input: {
      title: "Thumbnail or lesson cover (attach .png or .jpg image)",
      state: "No file selected",
      error: "Select a file to advance",
    },
    name_input: {
      title: "Lesson name",
    },
    category_input: {
      title: "Category",
    },
    tags_input: {
      title: "Tags",
    },
    language_input: {
      title: "Language",
    },
    level_input: {
      title: "Level",
      levels: {
        level1: "Beginner",
        level2: "Intermediate",
        level3: "Advanced",
      },
    },
    description_input: {
      title: "Description",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats, if you have technical problems send email to info@panzofi.com",
    },
    submit_button: {
      create_title: "Create lesson",
      edit_title: "Edit lesson",
    },
  },
  quiz: {
    title: {
      create_title: "Create a Quiz",
      edit_title: "Edit Quiz",
    },
    subtitle: {
      create_subtitle:
        "Test your students' knowledge (remember you can only create 1 quiz per course and 1 quiz per module)",
      edit_subtitle:
        "Hello, edit your quiz information in case something was wrong",
    },
    module_input: {
      label: "Is it for a module?",
      title: "Module",
    },
    title_input: {
      title: "Title",
    },
    description_input: {
      title: "Description",
    },
    question: {
      text_input: {
        title: "Question",
      },
      url_input: {
        title: "URL",
      },
      btns: {
        add: "Add Question",
        remove: "Remove Question",
      },
    },
    option: {
      text_input: {
        title: "Option",
      },
      correct_input: {
        title: "Correct Option?",
      },
      btns: {
        add: "Add Option",
        remove: "Remove Option",
      },
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats, if you have technical problems send an email to info@panzofi.com",
    },
    submit_button: {
      create_title: "Create Quiz",
      edit_title: "Edit Quiz",
    },
  },
  quiz_attempt: {
    start_btn: "Start Quiz",
    close_btn: "Close Quiz",
    link_input: "If you don't know the answer to this question",
    link: "visit this content",
    finished_title:
      "Congratulations on successfully completing the Panzofi quiz!",
    finished_desc: "Click the submit button to receive your results",
    send_btn: "Submit Quiz",
    results_title: "Here are your results",
    results_pass:
      "Congratulations, you passed! 10 student points have been added to your profile",
    results_fail:
      "You didn't get enough points, but remember you can try again",
    results: "You scored {{score}} points",
  },
  module: {
    title: {
      create_title: "Create a module",
      edit_title: "Edit module",
    },
    subtitle: {
      create_subtitle:
        "Specify the module title and description for your lesson",
      edit_subtitle: "Edit the module, update its information for your lesson",
    },
    title_input: {
      title: "Title",
    },
    description_input: {
      title: "Description",
    },
    info_input: {
      title:
        "Note: Modules are created from oldest to recent. You can edit them whenever you see fit.",
    },
    submit_button: {
      create_title: "Create module",
      edit_title: "Edit module",
    },
  },
  content: {
    title: {
      create_title: "Upload content",
      edit_title: "Edit content",
    },
    subtitle: {
      create_subtitle:
        "Upload theoretical content to a module you have previously created",
      edit_subtitle:
        "Edit the content, description or add something you forgot",
    },
    module_input: {
      title: "Module",
    },
    title_input: {
      title: "Title",
    },
    file_label: {
      title:
        "Next you can upload content to your module, you can upload audios (mp3, wav), videos (mp4, webm, Ogg, MPEG4) or PDF documents. (Max 100Mb)",
    },
    file_input: {
      title: "Content",
      state: "No file selected",
      error: "Select a file to advance",
    },
    youtube_label: {
      title: "do you want to use a youtube or google docs url?",
    },
    youtube_input: {
      title: "Youtube or Google docs url",
    },
    description_input: {
      title: "Content description",
    },
    inPost_label: {
      title: "Do you want to create an educlip with this content?",
    },
    submit_button: {
      create_title: "Upload content",
      edit_title: "Edit content",
    },
  },
  practical: {
    title: {
      create_title: "Upload practical content",
      edit_title: "Edit practical content",
    },
    subtitle: {
      create_subtitle: "Upload practical content to complement your knowledge",
      edit_subtitle:
        "Edit the content you uploaded, complement it and share everything you can contribute about it",
    },
    title_input: {
      title: "Title",
    },
    file_label: {
      title:
        "Below you can upload practical content to complement the module you are watching, you can uploadaudios (mp3, wav), videos (mp4, webm, Ogg, MPEG4) or PDF documents. (Max 100Mb)",
    },
    file_input: {
      title: "Content",
      state: "No file selected",
      error: "Select a file to advance",
    },
    youtube_label: {
      title: "do you want to use a youtube url?",
    },
    youtube_input: {
      title: "Youtube url",
    },
    description_input: {
      title: "Content description",
    },
    submit_button: {
      create_title: "Upload content",
      edit_title: "Edit content",
    },
  },
  post: {
    title: {
      create_title: "Create an edu clip",
      edit_title: "Edit an edu clip",
    },
    subtitle: {
      create_subtitle:
        "Hello, create your edu clip to share your knowledge with the Panzofi community",
      edit_subtitle:
        "Hello, edit the information of your edu clip in case something went wrong",
    },
    file_label: {
      title:
        "Below, you can upload content to complete the edu clip you are viewing. You can upload audios (mp3, wav), videos (mp4, webm, Ogg, MPEG4), or PDF documents. (Max 100Mb)",
    },
    file_input: {
      title: "Content",
      state: "No file selected",
      error: "Select a file to proceed",
    },
    youtube_label: {
      title: "Do you want to use a YouTube URL?",
    },
    youtube_input: {
      title: "YouTube URL",
    },
    title_input: {
      title: "Title",
    },
    category_input: {
      title: "Category",
    },
    description_input: {
      title: "Description",
    },
    privacy_label: {
      title: "Make it Public?",
    },
    inLesson_label: {
      title: "Do you want to place this educlip in a lesson?",
    },
    lesson: {
      title: "Lesson",
    },
    module: {
      title: "Module",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats. If you have technical issues, send an email to info@panzofi.com",
    },
    submit_button: {
      create_title: "Create edu clip",
      edit_title: "Edit edu clip",
    },
  },
  news: {
    title: {
      create_title: "Create a notice for the banner",
      edit_title: "Edit notice",
    },
    subtitle: {
      create_subtitle: "Hello, create your notice",
      edit_subtitle:
        "Hello, edit the information of your notice in case something went wrong",
    },
    title_input: {
      title: "Title",
    },
    description_input: {
      title: "Description",
    },
    url_input: {
      title: "Url",
    },
    privacy_label: {
      title: "Make Public?",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats. If you have technical problems, send an email to info@panzofi.com",
    },
    submit_button: {
      create_title: "Create notice",
      edit_title: "Edit notice",
    },
  },

  thread: {
    title: {
      create_title: "Create a question",
      edit_title: "Edit question",
    },
    subtitle: {
      create_subtitle:
        "Hello, create your question so the Panzofi community can help you",
      edit_subtitle:
        "Hello, edit your question information in case something is wrong",
    },
    file_label: {
      title: "Upload file",
    },
    file_input: {
      title:
        "You can upload content to complete your question. You can upload audio files (mp3, wav), videos (mp4, webm, Ogg, MPEG4), images (png, jpeg), or PDF documents. (Max 100Mb)",
      state: "No file selected",
      error: "Select a file to proceed",
    },
    title_input: {
      title: "Title",
    },
    body_input: {
      title: "Description",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats. If you have technical problems, send an email to info@panzofi.com",
    },
    youtube_label: {
      title: "Do you want to use a YouTube URL?",
    },
    youtube_input: {
      title: "YouTube URL",
    },
    submit_button: {
      create_title: "Create question",
      edit_title: "Edit question",
    },
  },
  forum: {
    title: {
      create_title: "Create a forum",
      edit_title: "Edit forum",
    },
    subtitle: {
      create_subtitle:
        "Hello, create your forum to share your knowledge with the Panzofi community",
      edit_subtitle:
        "Hello, edit your forum's information in case something needs correction",
    },
    file_input: {
      title:
        "Thumbnail or cover image for the lesson (attach .png or .jpg image)",
      state: "No file selected",
      error: "Select a file to proceed",
    },
    title_input: {
      title: "Forum name",
    },
    body_input: {
      title: "Description",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats. If you have technical issues, send an email to info@panzofi.com",
    },
    submit_button: {
      create_title: "Create forum",
      edit_title: "Edit forum",
    },
  },
  delete_course: {
    title: "Delete this course?",
    button: "Delete",
  },
  delete_module: {
    title: "Delete this module?",
    button: "Delete",
  },
  delete_content: {
    title: "Delete this content?",
    button: "Delete",
  },
  delete_post: {
    title: "Delete this edu clip?",
    button: "Delete",
  },
  privacy_post: {
    private: "Do you want to make this edu clip private?",
    public: "Do you want to make this edu clip public?",
    button: "Change privacy",
  },
  delete_news: {
    title: "Do you want to delete this notice?",
    button: "Delete",
  },
  privacy_news: {
    private: "Do you want to make this notice private?",
    public: "Do you want to make this notice public?",
    button: "Change privacy",
  },
  delete_thread: {
    title: "Do you want to delete this question?",
    button: "Delete",
  },
  delete_forum: {
    title: "Do you want to delete this forum?",
    button: "Delete",
  },
  questionnaire: {
    title: {
      create_title: "Help us improve your experience.",
      edit_title: "",
    },
    subtitle: {
      create_subtitle: "When you finish, you will earn 10 Panzofi points.",
      edit_subtitle: "",
    },
    creator: {
      title: "Are you interested in creating content?",
      options: {
        option1: "Yes",
        option2: "No",
      },
    },
    learn_category: {
      title: "What would you like to learn?",
    },
    teach_category: {
      title: "What would you like to teach?",
    },
    category_experience: {
      title: "What is your level of expertise?",
      options: {
        option1: "Beginner",
        option2: "Intermediate",
        option3: "Advanced",
      },
    },
    educational_background: {
      title: "What is your level of education?",
      options: {
        option1: "High school",
        option2: "University",
        option3: "Postgraduate",
      },
    },
    reason: {
      title: "What is the reason you are learning?",
      options: {
        option1: "Personal development",
        option2: "Professional advancement",
        option3: "Academic purposes",
      },
    },
    media: {
      title: "What is your preferred learning method?",
      options: {
        option1: "Videos",
        option2: "Audios",
        option3: "Text-based tutorials",
        option4: "Interactive exercises",
      },
    },
    new_function: {
      title:
        "Is there any specific feature or functionality you would like to see implemented on the platform to improve your learning or teaching experience?",
    },
    info_input: {
      title:
        "Don't forget to follow the requested formats. If you have technical issues, please send an email to info@panzofi.com.",
    },
    submit_button: {
      create_title: "Submit survey",
      edit_title: "",
    },
  },
};
export default modals_en;
