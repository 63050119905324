import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Image,
  TextareaField,
  TypingEffectField,
} from "components/ui";
import API from "core/api";
import moment from "moment";
import { renderClasses } from "shared/common/functions";
import { getCookie, setCookie } from "shared/common";

const PanzofIAPage = ({ courses, location, history, auth }) => {
  //const { t } = useTranslation();
  const [responses, setResponses] = React.useState([]);
  const [nUses, setNUses] = React.useState(10);

  React.useEffect(() => {
    setNUses(getCookie("nUses") ? getCookie("nUses") : 10);
  }, []);

  const handleSubmit = (data) => {
    if (nUses < 1) return;
    const formData = {
      ...data,
    };

    courses.createAIRequest(formData, (response) => {
      if (response.status === 201) {
        setResponses((prevResponses) => [...prevResponses, response.data]);
        setCookie("nUses", nUses - 1, 1);
        setNUses(nUses - 1);
      }
    });
  };

  const renderResponse = (responses) =>
    responses.map((actual, key) => {
      return (
        <section className="pz-ai__comment" key={key}>
          <section>
            <Image className="pz-ai__picture" name="logos/panzofi.png" />
          </section>
          <section className="pz-ai__comment--content">
            <h5 className="pz-ai__comment--username">
              {"Panzofi"}

              <small>
                {moment(actual.created).format("MMMM D, YYYY - hh:mm a")}
              </small>
            </h5>
            <span className="pz-ai__comment--text">
              <TypingEffectField text={actual.response_text} speed={50} />
            </span>
          </section>
        </section>
      );
    });

  return (
    <main className="pz-profile">
      <Navbar />
      <section className="pz-ai">
        <h3 className="bold">PanzofIA</h3>
        <h4>
          <TypingEffectField
            text={"Hola soy PanzofIA, yo te enseñare lo que quieras"}
            speed={50}
          />
        </h4>

        {API.auth.isLoggedIn() ? (
          <Form className="pz-ai__form" onSubmit={handleSubmit} resetOnSubmit>
            {({ onChange, data: { user_message } }) => (
              <React.Fragment>
                <TextareaField
                  label={`${"Preguntame algo"} (${
                    typeof user_message === "string" ? user_message.length : 0
                  }/300) Te quedan ${nUses} preguntas`}
                  name="user_message"
                  onChange={onChange}
                  maxLength={300}
                />
                <Button primary ghost thin>
                  Preguntar a panzofi
                </Button>
              </React.Fragment>
            )}
          </Form>
        ) : (
          <h5 className="bold">{"Inicia sesíon para usar Panzofia"}</h5>
        )}
        <section
          className={renderClasses({
            "pz-ai__content": true,
          })}
        >
          {responses.length > 0 ? (
            renderResponse(responses)
          ) : (
            <>
              <p>No has preguntado algo</p>
            </>
          )}
        </section>
      </section>
      <Footer />
    </main>
  );
};

export default connectReduxReducers(withRouter(PanzofIAPage), "courses");
