import React from 'react';
import { Toggle } from "components/ui";


const Filter = ({sections, onChange}) => {

    const [data, setData] = React.useState(
        {}
    );

    React.useEffect(() => {
        onChange(data);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        const newData = sections.reduce((prev, {name: groupName, type, items}) => {
            if (!items) return prev;

            const sectionValues = {};
            items.forEach(({ name }) => {
                sectionValues[name] = type === 'text' ? '' : true;
            })
            return {
                ...prev,
                [groupName]: sectionValues
            };
        }, {});
        setData(newData);
        onChange(newData);
        // eslint-disable-next-line
    }, [JSON.stringify(sections)]);

    const getValue = (groupName, itemName, defaultValue) => {
        if (data[groupName] !== undefined) {
            if (data[groupName][itemName] !== undefined) {
                return data[groupName][itemName];
            }
        }
        return defaultValue;
    };

    const onChangeHandler = (e) => {
        const { type, checked, value, name } = e.target;
        const inputValue = type === 'checkbox' ? checked : value.toLowerCase();
        const [groupName, itemName] = name.split('__');
        const nextData = {...data};
        nextData[groupName][itemName] = inputValue;
        setData(nextData);
        onChange(nextData);
    };

    const renderCheckboxItems = (groupName, items) => (
        items.map(({name, label}, key) => (
            <article className='pz-filter__checkbox' key={key}>
                <label
                    className='pz-filter__label'
                    htmlFor={`${groupName}__${name}`}
                >
                    {label}
                </label>
                <Toggle
                    id={`${groupName}__${name}`}
                    name={`${groupName}__${name}`}
                    type='checkbox'
                    checked={getValue(groupName, name, true)}
                    onChange={onChangeHandler}
                />
            </article>
        ))
    );

    const renderTextItems = (groupName, items) => (
        items.map(({name, label}, key) => (
            <article className='pz-filter__text' key={key}>
                <input
                    id={`${groupName}__${name}`}
                    name={`${groupName}__${name}`}
                    type='text'
                    className='pz-form__only-input'
                    placeholder={label}
                    value={getValue(groupName, name, '')}
                    onChange={onChangeHandler}
                />
            </article>
        ))
    );

    const renderSections = () => (
        sections.map(( { name, type, items }, key) => (
            <section className='pz-filter__section' key={key}>
                {type === 'checkbox' && renderCheckboxItems(name, items)}
                {type === 'text' && renderTextItems(name, items)}
                {type === 'line' && <span className='line' />}
            </section>
        ))
    );

    return (
        <section className='pz-filter'>
            {renderSections()}
        </section>
    );
};

Filter.defaultProps = {
    sections: [],
    onChange: () => {}
};

export default Filter;