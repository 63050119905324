const categories = [
  {
    id: "c56d6e75-e7c9-4da7-8518-9ee7089cc594",
    slug: "ciencias-sociales",
    category: null,
    name: "Ciencias Sociales",
    description: "",
    sub_categories: [
      {
        id: "1fc92599-0604-4e22-b827-a0de136863e1",
        slug: "administracion-de-empresas",
        category: null,
        name: "Administración de Empresas",
        description: "",
        sub_categories: [],
      },
      {
        id: "c8c17d3e-1a54-4375-94a3-981efda6d295",
        slug: "historia",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Historia",
        description: "",
        sub_categories: [],
      },
      {
        id: "477366b0-11d0-4bcc-8ab1-258aaea0db39",
        slug: "geografia",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Geografía",
        description: "",
        sub_categories: [],
      },
      {
        id: "a1dde8b9-3cd4-44cb-bd2f-154eb91afce8",
        slug: "demografia",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Demografía",
        description: "",
        sub_categories: [],
      },
      {
        id: "7fd64a3d-c39f-4a75-a469-e45d0ff674a8",
        slug: "comunicacion",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Comunicación",
        description: "",
        sub_categories: [],
      },
      {
        id: "2b27cdcc-db94-4c9f-86a4-208c056c76db",
        slug: "ciencias-juridicas",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Ciencias Jurídicas",
        description: "",
        sub_categories: [],
      },
      {
        id: "35989ea1-89e0-45bb-b591-eafa82b81ca4",
        slug: "antropologia",
        category: "c56d6e75-e7c9-4da7-8518-9ee7089cc594",
        name: "Antropología",
        description: "",
        sub_categories: [],
      },
      {
        id: "bb594878-5506-4cfb-b7cd-1a6bcbe04f56",
        slug: "ciencias-politicas",
        category: "c56d6e75-e7c9-4da7-8518-9ee7089cc594",
        name: "Ciencias Políticas",
        description: "",
        sub_categories: [],
      },
      {
        id: "100673a5-0712-41b3-9c7c-857bd2769655",
        slug: "ciencias-economicas",
        category: "c56d6e75-e7c9-4da7-8518-9ee7089cc594",
        name: "Ciencias económicas",
        description: "",
        sub_categories: [],
      },
      {
        id: "a6666b18-4b20-4df1-a6fa-ecff52311dbe",
        slug: "sociologia",
        category: "c56d6e75-e7c9-4da7-8518-9ee7089cc594",
        name: "Sociología",
        description: "",
        sub_categories: [],
      },
      {
        id: "b9c2b386-d4a3-4525-984b-05bc66e797ab",
        slug: "psicologia",
        category: "7ccab909-6e7a-49ea-bb2c-bbaedae55319",
        name: "Psicología",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "7ccab909-6e7a-49ea-bb2c-bbaedae55319",
    slug: "salud",
    category: null,
    name: "Salud",
    description: "",
    sub_categories: [
      {
        id: "f74e8941-041d-4bc6-a93c-f616482a10b5",
        slug: "enfermeria",
        category: "7ccab909-6e7a-49ea-bb2c-bbaedae55319",
        name: "Enfermería",
        description: "",
        sub_categories: [],
      },
      {
        id: "c0f22c36-0243-45b5-b1e5-24a21727769e",
        slug: "medicina",
        category: "7ccab909-6e7a-49ea-bb2c-bbaedae55319",
        name: "Medicina",
        description: "",
        sub_categories: [],
      },
      {
        id: "234c2e8c-06a8-435b-b7d9-99f181b4e472",
        slug: "nutricion",
        category: "7ccab909-6e7a-49ea-bb2c-bbaedae55319",
        name: "Nutrición",
        description: "",
        sub_categories: [],
      },
      {
        id: "bbb3af95-03ba-48d4-9da8-71bc0561b830",
        slug: "fisioterapia",
        category: "7ccab909-6e7a-49ea-bb2c-bbaedae55319",
        name: "Fisioterapia",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
    slug: "artes-y-humanidades",
    category: null,
    name: "Artes y Humanidades",
    description: "",
    sub_categories: [
      {
        id: "132de544-cc08-4ace-a7ba-22e7920d0877",
        slug: "arte",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Arte",
        description: "",
        sub_categories: [],
      },
      {
        id: "37dc492a-26cd-4bd5-b67a-d8b7574741c8",
        slug: "idiomas",
        category: null,
        name: "Idiomas",
        description: "",
        sub_categories: [],
      },
      {
        id: "bf494116-317f-414b-928f-6efd6b51eb7b",
        slug: "filosofia",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Filosofía",
        description: "",
        sub_categories: [],
      },
      {
        id: "dae1588d-2af4-4cf9-8d11-011eae0b9ec0",
        slug: "literatura",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Literatura",
        description: "",
        sub_categories: [],
      },
      {
        id: "9878d922-bf1e-4301-8595-2edc667801f7",
        slug: "musica",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Música",
        description: "",
        sub_categories: [],
      },
      {
        id: "f68814e9-62af-4888-8a57-a1b202f4b192",
        slug: "religion",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Religión",
        description: "",
        sub_categories: [],
      },
      {
        id: "71df656d-bb36-4c14-8f85-b979395c00d9",
        slug: "linguistica",
        category: "97003ec0-8bc8-4d2e-85b4-2ac88b320ad1",
        name: "Lingüística",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "88f33d8b-edc1-4e53-a25b-a56ac8e0a496",
    slug: "ciencia-de-los-datos",
    category: null,
    name: "Ciencia de los datos",
    description: "",
    sub_categories: [
      {
        id: "7f59445b-168d-4766-b5a2-0c378eb97156",
        slug: "analisis-de-los-datos",
        category: "88f33d8b-edc1-4e53-a25b-a56ac8e0a496",
        name: "Análisis de los datos",
        description: "",
        sub_categories: [],
      },
      {
        id: "45ddb923-5e46-4395-b1e2-4a0d523bc7ef",
        slug: "aprendizaje-automatico",
        category: "88f33d8b-edc1-4e53-a25b-a56ac8e0a496",
        name: "Aprendizaje automático",
        description: "",
        sub_categories: [],
      },
      {
        id: "f76c5e95-f6bd-494c-aad8-49307eb655a4",
        slug: "gestion-de-datos",
        category: "88f33d8b-edc1-4e53-a25b-a56ac8e0a496",
        name: "Gestión de datos",
        description: "",
        sub_categories: [],
      },
      {
        id: "80916929-4744-4c6b-9d52-2e97703a7726",
        slug: "probabilidades-y-estadisticas",
        category: "88f33d8b-edc1-4e53-a25b-a56ac8e0a496",
        name: "Probabilidades y estadísticas",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "9de2ca9e-14de-4e75-b692-092abe3dfd0a",
    slug: "ciencias-fisicas-y-naturales",
    category: null,
    name: "Ciencias Físicas y Naturales",
    description: "",
    sub_categories: [
      {
        id: "76bbd68f-ac29-434a-8077-60c51ec5aa31",
        slug: "astronomia",
        category: "9de2ca9e-14de-4e75-b692-092abe3dfd0a",
        name: "Astronomía",
        description: "",
        sub_categories: [],
      },
      {
        id: "16d818db-f4e9-4e96-83dc-e1b879212608",
        slug: "biologia",
        category: "382e877c-976d-48c5-ac47-c7f3d681881a",
        name: "Biología",
        description: "",
        sub_categories: [],
      },
      {
        id: "4e24d1ee-c6ef-4153-9061-fc6f24b20f33",
        slug: "fisica",
        category: "9de2ca9e-14de-4e75-b692-092abe3dfd0a",
        name: "Física",
        description: "",
        sub_categories: [],
      },
      {
        id: "e4655ea3-44fc-45db-a90f-797c104d0795",
        slug: "geologia",
        category: "9de2ca9e-14de-4e75-b692-092abe3dfd0a",
        name: "Geología",
        description: "",
        sub_categories: [],
      },
      {
        id: "23d18998-5787-435a-b394-e64a77f6a672",
        slug: "quimica",
        category: "9de2ca9e-14de-4e75-b692-092abe3dfd0a",
        name: "Química",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
    slug: "diseno",
    category: null,
    name: "Diseño",
    description: "",
    sub_categories: [
      {
        id: "c1d53a63-d3d2-4603-8f15-66c1270018e3",
        slug: "3d-y-animacion",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "3D y animación",
        description: "",
        sub_categories: [],
      },
      {
        id: "4c7753f0-242f-4286-9331-529d05e24185",
        slug: "design-thinking",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Design Thinking",
        description: "",
        sub_categories: [],
      },
      {
        id: "afb8f801-f385-42d7-b718-1161d33e6eba",
        slug: "diseno-arquitectonico",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Diseño arquitectonico",
        description: "",
        sub_categories: [],
      },
      {
        id: "09d4998d-ba8b-4e50-b533-41e476fd9afc",
        slug: "diseno-de-interiores",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Diseño de interiores",
        description: "",
        sub_categories: [],
      },
      {
        id: "85ef636c-5441-481a-a868-9db5994ce4ad",
        slug: "diseno-de-juegos",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Diseño de juegos",
        description: "",
        sub_categories: [],
      },
      {
        id: "92bb8de3-b966-4530-8f33-99ec9cb4fa10",
        slug: "diseno-grafico",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Diseño grafico",
        description: "",
        sub_categories: [],
      },
      {
        id: "c67d2a97-b035-42a5-bb02-85bf2ea0ed22",
        slug: "experiencia-de-usuario-ux",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Experiencia de usuario (UX)",
        description: "",
        sub_categories: [],
      },
      {
        id: "5f842a36-9fb0-47af-bfa5-35c7e3f7b410",
        slug: "herramientas-de-diseno",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Herramientas de diseño",
        description: "",
        sub_categories: [],
      },
      {
        id: "f14127b2-3831-4ed1-bbb9-4ba7f93a3c09",
        slug: "moda",
        category: "116493b2-fb7a-4823-b0b5-2ee5fab00d5a",
        name: "Moda",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "b735c85a-7469-493b-abb8-84942711bcd9",
    slug: "gastronomia",
    category: null,
    name: "Cocina y Bebidas",
    description: "",
    sub_categories: [
      {
        id: "11b00114-9fbb-459f-859d-b851717d769a",
        slug: "comidas",
        category: "b735c85a-7469-493b-abb8-84942711bcd9",
        name: "Comidas",
        description: "",
        sub_categories: [],
      },
      {
        id: "e0c31b8c-643a-43db-bddf-0b4cd31050f1",
        slug: "reposteria",
        category: "b735c85a-7469-493b-abb8-84942711bcd9",
        name: "Repostería",
        description: "",
        sub_categories: [],
      },
      {
        id: "fa83e13d-1bd8-4272-8d83-812c0644fb8e",
        slug: "bebidas",
        category: "b735c85a-7469-493b-abb8-84942711bcd9",
        name: "Bebidas",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "34d0c652-9bbb-48c0-b59f-62f8688f2b91",
    slug: "ingenieria",
    category: null,
    name: "Ingeniería",
    description: "",
    sub_categories: [
      {
        id: "53e381b8-9a2b-45f4-870a-a7a99687b7b8",
        slug: "informatica-y-software",
        category: "34d0c652-9bbb-48c0-b59f-62f8688f2b91",
        name: "Informática y software",
        description: "",
        sub_categories: [],
      },
      {
        id: "8d4323c0-daaf-49ab-ab7c-8ce24f12de9d",
        slug: "ingenieria-ambiental",
        category: "34d0c652-9bbb-48c0-b59f-62f8688f2b91",
        name: "Ingeniería Ambiental",
        description: "",
        sub_categories: [],
      },
      {
        id: "eefcba47-5304-414a-a735-abbf85ad59df",
        slug: "ingenieria-mecanica",
        category: "34d0c652-9bbb-48c0-b59f-62f8688f2b91",
        name: "Ingeniería mecánica",
        description: "",
        sub_categories: [],
      },
      {
        id: "b12854db-6a77-4e81-94e6-63b6babc6646",
        slug: "ingeniero-civil",
        category: "34d0c652-9bbb-48c0-b59f-62f8688f2b91",
        name: "Ingeniería civil",
        description: "",
        sub_categories: [],
      },
      {
        id: "2c690007-02f5-49c2-8145-65aa1ed76251",
        slug: "ingenieria-industrial",
        category: "34d0c652-9bbb-48c0-b59f-62f8688f2b91",
        name: "Ingeniería industrial",
        description: "",
        sub_categories: [],
      },
    ],
  },
  {
    id: "42d6330b-e9f1-424a-a1cf-f72cf53cf9ec",
    slug: "matematicas-y-logica",
    category: null,
    name: "Matemáticas y Lógica",
    description: "",
    sub_categories: [
      {
        id: "ce18b6ee-8b8d-4c4c-b2b2-c9645af77ead",
        slug: "algebra",
        category: "42d6330b-e9f1-424a-a1cf-f72cf53cf9ec",
        name: "Álgebra",
        description: "",
        sub_categories: [],
      },
      {
        id: "dde37b47-b8c4-49cd-97d4-677eb288bbbe",
        slug: "geometria",
        category: "42d6330b-e9f1-424a-a1cf-f72cf53cf9ec",
        name: "Geometría",
        description: "",
        sub_categories: [],
      },
      {
        id: "d4faa498-319f-4f24-875a-72e0095bb87d",
        slug: "calculo",
        category: "42d6330b-e9f1-424a-a1cf-f72cf53cf9ec",
        name: "Cálculo",
        description: "",
        sub_categories: [],
      },
      {
        id: "c767da3e-5308-42c7-9540-5f86324753fb",
        slug: "estadisticas",
        category: "42d6330b-e9f1-424a-a1cf-f72cf53cf9ec",
        name: "Estadísticas",
        description: "",
        sub_categories: [],
      },
      {
        id: "c767da3e-5308-42c7-9540-5f86324753fb",
        slug: "trigonometria",
        category: "42d6330b-e9f1-424a-a1cf-f72cf53cf9ec",
        name: "Trigonometría",
        description: "",
        sub_categories: [],
      },
    ],
  },
];
export default categories;
