import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Link, withRouter } from "react-router-dom";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import {
  Button,
  Image,
  Line,
  PDFDocument,
  VideoPlayer,
  AudioPlayer,
} from "components/ui";
import { CONSTANTS } from "shared/constants";
import { Reactions } from "apps/courses/components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  DeleteThreadsModal,
  ThreadModal,
  ThreadResponses,
} from "apps/forums/components";

const ThreadPage = ({
  threads,
  match: {
    params: { threadId, forumSlug },
  },
  auth,
  history,
}) => {
  const [thread, setThread] = React.useState(undefined);
  const [newsModalVisible, setNewsModalVisible] = React.useState(false);
  const [newsDeleteModalVisible, setNewsDeleteModalVisible] =
    React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    getThread();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getThread();
    // eslint-disable-next-line
  }, [auth.token]);

  const googleFormsUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSfeuJvJNEhZwEXR9up8sJ6TMnkfOJG8PzmO_1taF_yZiZQNcA/viewform?usp=sf_link";

  const handleReportarContenido = () => {
    window.open(googleFormsUrl, "_blank"); // Abre el formulario en una nueva pestaña del navegador
  };

  const getThread = () => {
    threads.getThread(forumSlug, threadId, ({ data, status }) => {
      if (status === 200) {
        setThread(data);
      } else {
        setThread(null);
      }
    });
  };

  const closeModalNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getThread();
    }
    setNewsModalVisible(false);
  };
  const closeModalDeleteNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      history.push(CONSTANTS.URLS.FORUM.ALL);
    }
    setNewsDeleteModalVisible(false);
  };

  const ownerIsLoggedUser = () => {
    return auth.user?.slug === thread?.user?.slug;
  };

  const renderMedia = (type, url) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return <PDFDocument url={url} />;
      case CONSTANTS.FILE_TYPES.AUDIO:
        return <AudioPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return <VideoPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.IMAGE:
        return <Image src={url} />;
      default:
        return null;
    }
  };

  if (thread) {
    return (
      <main className="pz-thread">
        <Navbar />

        <Page className="pz-thread__content">
          <section className="pz-thread__data">
            <section className="pz-thread__description grid-columns grid-gap-20 grid-content-left">
              <Link
                to={CONSTANTS.URLS.PROFILE.USER_DETAIL(thread.user?.slug)}
                className="pz-thread-user-badge"
              >
                {thread.user?.profile?.picture ? (
                  <Image src={thread.user?.profile?.picture} />
                ) : (
                  <Image name="icons/male-avatar.svg" />
                )}
                <section>
                  <h5 className="bold">{thread.user?.username}</h5>
                </section>
              </Link>
              {ownerIsLoggedUser() ? (
                <section className="grid-columns grid-gap-20 grid-content-left">
                  <Button
                    thin
                    ghost
                    primary
                    onClick={() => {
                      setNewsModalVisible(true);
                    }}
                  >
                    {t("forum.thread.btn_edit")}
                  </Button>
                  <Button
                    thin
                    onClick={() => {
                      setNewsDeleteModalVisible(true);
                    }}
                  >
                    {t("forum.thread.btn_delete")}
                  </Button>
                </section>
              ) : null}
            </section>
            <section className="grid grid-content-middle ">
              <h3 className="bold">{thread.title}</h3>
              <p>{thread.body}</p>
              <section className="pz-thread__file">
                {thread.file || thread.youtubeurl
                  ? thread.file
                    ? renderMedia(thread.type, thread.file)
                    : renderMedia(thread.type, thread.youtubeurl)
                  : null}
              </section>

              <small>
                {t("lesson.uploaded")}{" "}
                {moment(thread.created).format("MMMM D, YYYY")}
              </small>
            </section>
            <section className=" grid-gap-20 grid-content-left">
              <Line />
              <Reactions
                reactionList={thread?.reactions}
                model={CONSTANTS.APP_MODELS.THREAD}
                modelId={threadId}
                onReact={getThread}
              />
              <Line className="pz-thread__data--linepad" />
              <ThreadResponses
                responsesList={thread?.thread_resposes}
                forumSlug={forumSlug}
                threadId={threadId}
                onResponse={getThread}
              />
              <Line />
              <section className="grid-columns grid-gap-20 grid-content-center">
                <Button onClick={handleReportarContenido}>
                  {t("course.buttons.report_content")}
                </Button>
              </section>
            </section>
          </section>
          {ownerIsLoggedUser() ? (
            <>
              <ThreadModal
                currentNews={thread}
                is_visible={newsModalVisible}
                closeModal={closeModalNews}
              />
              <DeleteThreadsModal
                newsId={thread?.id}
                forumSlug={forumSlug}
                is_visible={newsDeleteModalVisible}
                closeModal={closeModalDeleteNews}
              />
            </>
          ) : null}
        </Page>

        <Footer />
      </main>
    );
  } else if (thread === null) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">
            {t("notFound.no_exist.t1")}
            <br />
            {t("notFound.no_exist.t2")}
          </h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  }

  return (
    <main className="pz-profile">
      <Navbar />
    </main>
  );
};

export default withRouter(connectReduxReducers(ThreadPage, "threads", "auth"));
