import React from "react";
import { CourseCard } from "apps/courses/components";
import { Line } from "components/ui";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { connectReduxReducers } from "hocs";

const CarouseRecent = ({
  courses: reduxCourses,
  coursesdomi,
  fullPage,
  autoPlay,
  autoPlaySpeed,
  title,
}) => {
  const [coursesRec, setCoursesRec] = React.useState([]);
  const [toggleState, setToggleState] = React.useState(1);
  React.useEffect(() => {
    if (coursesdomi !== null) {
      setCoursesRec(coursesdomi);
    } else {
      getMoreCoursesRec();
    }
    // eslint-disable-next-line
  }, []);
  const getMoreCoursesRec = () => {
    const params = {
      limit: 9,
      offset: 0,
      ordering: "-created",
    };
    reduxCourses.getCourses(params, ({ data, status }) => {
      const sortedCourses = status === 200 ? data.results : null;
      setCoursesRec(sortedCourses);
    });
  };
  const responsiveLarge = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const responsiveShort = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to next slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }
  function CustomLeftArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      //console.log("Right button clicked, go to previos slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      />
    );
  }
  const toggleTab = (index) => {
    setToggleState(index);
    switch (index) {
      case 1:
        getMoreCoursesRec();
        break;

      case 2:
        getMoreCoursesRec();
        break;
      case 3:
        getMoreCoursesRec();
        break;
      case 4:
        getMoreCoursesRec();
        break;
      case 5:
        getMoreCoursesRec();
        break;

      default:
        break;
    }
  };
  const renderCourses = (courses) => {
    return (
      <>
        <Carousel
          responsive={fullPage === true ? responsiveLarge : responsiveShort}
          swipeable={false}
          draggable={false}
          showDots={true}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={autoPlay}
          autoPlaySpeed={autoPlaySpeed ? autoPlaySpeed : 5000}
          keyBoardControl={true}
          customTransition="all 0.5s ease-in-out"
          transitionDuration={500}
          itemClass="pz-courses__courses__carousel-item"
          dotListClass="custom-dot-list-style2"
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
        >
          {courses.map((course, key) => (
            <CourseCard course={course} key={key} />
          ))}
        </Carousel>
      </>
    );
  };

  return (
    <section
      className={
        fullPage === true
          ? "pz-courses__courses__carousel"
          : "pz-landing__carousel"
      }
    >
      <section className="pz-courses__courses__tabcontainer">
        <section className="pz-courses__courses__tabs">
          <h4
            className={
              toggleState === 1
                ? "pz-courses__courses__active-tab"
                : "pz-courses__courses__tab"
            }
            onClick={() => {
              if (coursesdomi === null) {
                toggleTab(1);
              }
            }}
          >
            {title}
          </h4>
        </section>
        <section className="pz-courses__courses__carousel">
          <Line />
          {renderCourses(coursesRec)}
        </section>
      </section>
    </section>
  );
};

export default connectReduxReducers(CarouseRecent, "courses");
