import React from 'react';
import { Footer, Navbar } from "components/panzofi";
import { Page } from "components/common";


const LandingWorkWithUsPage = () => {

    return (
        <main className='pz-landing'>

            <Navbar />

            <Page>

            </Page>

            <Footer />
        </main>
    );
};

export default LandingWorkWithUsPage;