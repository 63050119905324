const courses_en = {
  category_entrepreneurship: "Entrepreneurship",
  category_lifehacks: "Lifehacks",
  category_businessstrategy: "Business Strategy",
  category_finance: "finance",
  categpry_psychology: "Psychology",
  recent: "Panzo recent content",
  content: "Panzo content",
  more_courses: "Show more content",
  no_more_courses: "No more content to show",
  bar: {
    lessons: "Lessons",
    shorts: "Edu clips (Beta)",
  },
};
export default courses_en;
