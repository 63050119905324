import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { withRouter, Link } from "react-router-dom";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import {
  Line,
  PostPDFDocument,
  VideoPlayer,
  AudioPlayer,
  Button,
} from "components/ui";

import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";
import { Reactions } from "apps/courses/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { PostDetailModal } from "apps/posts/components";
import PostUserBadge from "apps/posts/components/post-badge";
import API from "core/api";
import AdSense from "apps/courses/components/ads";

const PostsPage = ({ posts, views, auth }) => {
  const [postsList, setPostsList] = React.useState([]);
  const [morePosts] = React.useState(true);
  const [postDetailModalVisible, setPostDetailModalVisible] =
    React.useState(false);
  const [postToEdit, setPostToEdit] = React.useState(null);
  const [postToEditIndex, setPostToEditIndex] = React.useState(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    getPosts();
    // eslint-disable-next-line
  }, []);

  const getPosts = () => {
    posts.getPostsRecommended(({ data, status }) => {
      if (status === 200) {
        const sortedPosts = [...postsList, ...data];
        setPostsList(sortedPosts);
        data.forEach((element) => {
          let formData = {};
          if (API.auth.isLoggedIn()) {
            formData = {
              object_id: element.id,
              user: API.auth.getUser().id,
            };
          } else {
            formData = {
              object_id: element.id,
            };
          }

          views.createContentView(
            formData,
            CONSTANTS.APP_MODELS.POSTS,
            (response) => {}
          );
        });
      } else {
        setPostsList(null);
      }
    });
  };

  const handleGetPost = (post_id, index) => {
    const newPostsList = [...postsList];

    posts.getPost(post_id, ({ status, data }) => {
      if (status === 200) {
        newPostsList[index] = data;
        setPostsList(newPostsList);
      } else {
      }
    });
  };

  const renderMedia = (type, url) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return <PostPDFDocument url={url} />;
      case CONSTANTS.FILE_TYPES.AUDIO:
        return <AudioPlayer url={url} />;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return <VideoPlayer url={url} />;
      default:
        return null;
    }
  };

  const closeModalDetailPost = () => {
    handleGetPost(postToEdit.id, postToEditIndex);
    setPostToEdit(null);
    setPostToEditIndex(null);
    setPostDetailModalVisible(false);
  };

  const openModalDetailPost = (post, index) => {
    setPostToEdit(post);
    setPostToEditIndex(index);
    setPostDetailModalVisible(true);
  };

  return (
    <main className="pz-posts">
      <Navbar />
      <section className="pz-posts__head">
        <Link
          to={CONSTANTS.URLS.COURSES.ALL}
          className="pz-posts__head--item bold"
        >
          {t("courses.bar.lessons")}
        </Link>
        |
        <Link
          to={CONSTANTS.URLS.COURSES.POSTS}
          className="pz-posts__head--item bold pz-posts__head--active"
        >
          {t("courses.bar.shorts")}
        </Link>
      </section>

      {postsList.map((post, index) => {
        return (
          <Page className="pz-posts__content" key={post.id}>
            <section className="pz-posts__data">
              <section className="pz-posts__file">
                {post?.file
                  ? renderMedia(post?.type, post?.file)
                  : renderMedia(post?.type, post?.youtubeurl)}
              </section>
            </section>
            <section className="pz-posts__description">
              <PostUserBadge user={post?.owner} />
              <section className="pz-posts__data--info">
                <h3 className="bold">{post?.title}</h3>
                <p>{post?.description}</p>
              </section>
              <Line />
              <Reactions
                reactionList={post?.reactions}
                model={CONSTANTS.APP_MODELS.POSTS}
                modelId={post?.id}
                onReact={() => {
                  handleGetPost(post.id, index);
                }}
              />
              <Line className="pz-posts__data--linepad" />
              <section>
                <Button
                  ghost
                  className="grid-gap-10 grid-content-left grid-items-middle grid-columns"
                  onClick={() => {
                    openModalDetailPost(post, index);
                  }}
                >
                  <FontAwesomeIcon icon={faComment} />
                  {post?.comments.length}
                </Button>
              </section>
            </section>
            {(index + 1) % 5 === 0 && <AdSense />}
          </Page>
        );
      })}

      <section className="pz-posts__load-button">
        {morePosts ? (
          <Button primary thin onClick={getPosts}>
            {t("courses.more_courses")}
          </Button>
        ) : (
          <Button disabled thin>
            {t("courses.no_more_courses")}
          </Button>
        )}
      </section>
      <PostDetailModal
        postId={postToEdit?.id}
        is_visible={postDetailModalVisible}
        closeModal={closeModalDetailPost}
      />

      <Footer />
    </main>
  );
};

export default withRouter(connectReduxReducers(PostsPage, "posts", "views"));
