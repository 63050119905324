import React from 'react';
import { renderClasses } from "shared/common/functions";


const Line = ({
    className, primary, secondary, tertiary,
    common, active, warning, info, alert
}) => (
    <span
        className={renderClasses({
            'pz-line': true,
            'primary-text': primary,
            'secondary-text': secondary,
            'tertiary-text': tertiary,
            'common-text': common,
            'active-text': active,
            'warning-text': warning,
            'info-text': info,
            'alert-text': alert,
            [className]: className !== ''
        })}
    />
);

Line.defaultProps = {
    primary: false,
    secondary: false,
    tertiary: false,
    common: false,
    active: false,
    warning: false,
    info: false,
    alert: false,
    className: ''
};

export default Line;