import React from "react";
import { Image } from "components/ui";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";

const ForumCard = ({ forum }) => {
  return (
    <Link
      to={CONSTANTS.URLS.FORUM.FORUM_DETAIL(forum?.slug)}
      className="pz-forum-card"
    >
      {forum?.thumbnail ? (
        <Image src={forum?.thumbnail} className="pz-forum-card__image" />
      ) : (
        <Image name="ilustrations/forum.svg" className="pz-forum-card__image" />
      )}
      <section className="pz-forum-card__content">
        <h4 className="pz-forum-card__name">{forum?.name}</h4>
        <h5 className="pz-forum-card__description">{forum?.description}</h5>
      </section>
    </Link>
  );
};

export default ForumCard;
