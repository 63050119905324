import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";


const Paginator = ({
    items, onChange, itemsPerPage
}) => {

    const [index, setIndex] = React.useState(0);
    const [{first, last}, setRange] = React.useState({
        first: 0,
        last: 0
    });

    React.useEffect(() => {
        const _first = itemsPerPage * index;
        const _last = itemsPerPage + _first - 1;

        setRange({ first: _first, last: _last });
    }, [index, itemsPerPage]);

    React.useEffect(() => {
        onChange([first, last + 1]);
        // eslint-disable-next-line
    }, [first, last, items.length]);

    const FirstButton = () => {
        const action = () => {
            if (index !== 0) setIndex(0);
        };

        const disabled = index === 0;

        return <FontAwesomeIcon className={disabled ? 'inactive' : ''} size='lg' onClick={action} icon={faAngleDoubleLeft}/>;
    };

    const LastButton = () => {
        const lastIndex = Math.floor((items.length - 1) / itemsPerPage);

        const action = () => {
            if (index !== lastIndex) setIndex(lastIndex);
        };

        const disabled = index === lastIndex;

        return <FontAwesomeIcon className={disabled ? 'inactive' : ''} size='lg' onClick={action} icon={faAngleDoubleRight}/>;
    };

    const PrevButton = () => {
        const action = () => {
            if (index > 0) setIndex(index - 1);
        };

        const disabled = index === 0;

        return <FontAwesomeIcon className={disabled ? 'inactive' : ''} size='lg' onClick={action} icon={faAngleLeft}/>;
    };

    const NextButton = () => {
        const lastIndex = Math.floor((items.length - 1) / itemsPerPage);

        const action = () => {
            if (index < lastIndex) setIndex(index + 1);
        };

        const disabled = index === lastIndex;

        return <FontAwesomeIcon className={disabled ? 'inactive' : ''} size='lg' onClick={action} icon={faAngleRight}/>;
    };

    return (
        <section className='pz-paginator'>
            <span>{Math.min(first + 1, items.length)} - {Math.min(last + 1, items.length)} <small>of {items.length}</small></span>
            <FirstButton />
            <PrevButton />
            <NextButton />
            <LastButton />
        </section>
    );
};

Paginator.defaultProps = {
    itemsPerPage: 10,
    items: [],
    onChange: () => {}
};

export default Paginator;