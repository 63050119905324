const landing_en = {
  students: {
    title: "Discover and share knowledge",
    subtitle:
      "Panzofi is a free collaborative network focused on educational content for everyone",
    button: "Explore Free Content",
    carrousel_title: "Panzo popular content",
    carrousel_testimony_title: "Testimonials",
    ecosystem: {
      title: "We are creating an ecosystem",
      subtitle: "of learning and teaching, where you can...",
      item1: {
        title: "Collaboratively",
        subtitle:
          "Learn In the Panzo-community, you can share your knowledge as well as acquire and strengthen new skills through interacting with others in the network.",
      },
      item2: {
        title: "Save Time on Searching",
        subtitle:
          "You can find what you're looking for more quickly with the diverse lessons and materials shared by the Panzo-community in different formats.",
      },
      item3: {
        title: "Find Theory and Practice in the Same Place",
        subtitle:
          "You can reinforce theory by uploading examples, testimonials, experiments, and much more, following the learn-by-doing model.",
      },
      item4: {
        title: "Economize Your Expenses for Creating and Disseminating",
        subtitle:
          " There is no cost for registration, uploading content, or contributing. Our goal is to make education more accessible worldwide.",
      },
    },
    features: {
      title: "Features to explore",
      subtitle: "and What Sets Us Apart from Other Platforms...",
      item1: {
        title: "Different types of content",
        subtitle:
          "The Panzo-community can upload various formats such as .pdf, videos, and audios or podcasts. Use the formats that are most convenient for your audience or for you.",
      },
      item2: {
        title: "Content Exchange",
        subtitle:
          "The Panzo-community can interact with the content created by others with their own content to reinforce the theory and put into practice what they have learned.",
      },
      item3: {
        title: "Enrich Your Profile",
        subtitle:
          " Create recognition within the Panzo-community, where others can learn about your contributions, your ranking, your score, and much more about you.",
      },
      item4: {
        title: "The Power to Rate",
        subtitle:
          "The opinion of the Panzo-community is very important. You can rate, comment, and share to give and receive valuable feedback on your content.",
      },
    },
    how_works: {
      title: "How does it work?",
      subtitle:
        "It is very easy and intuitive (for more information see our tutorial by clicking here)",
      without_acount: {
        title: "Without an Account",
        item1: {
          title: "Browse",
          subtitle:
            "View the content completely free and without restrictions.",
        },
        item2: {
          title: "Search",
          subtitle: "Search for content of your interest or a specific topic.",
        },
        item3: {
          title: "Find",
          subtitle: "Find theoretical and practical resources on the network.",
        },
        item4: {
          title: "Share",
          subtitle: "Share with your family, friends, and study partners.",
        },
      },
      with_acount: {
        title: "With an Account",
        item1: {
          title: "Rate",
          subtitle: "Rate the content for being fun, efficient, and useful.",
        },
        item2: {
          title: "Upload",
          subtitle:
            "Upload theoretical and practical content for everyone to see.",
        },
        item3: {
          title: "Participate",
          subtitle:
            "Participate and collaborate with other users for better learning.",
        },
        item4: {
          title: "Earn",
          subtitle:
            "Gain a reputation on the network and improve your educational profile.",
        },
      },
    },
    havent_found: {
      title: "Haven't Found What You're Looking For?",
      subtitle:
        "You can upload content and become an educational content creator. What are you waiting for? Become part of the Panzo-community.",
      item: {
        title: "For Creators",
        subtitle:
          "Contribute to the Panzo-community by sharing your skills, talents, and knowledge on any topic or area of interest.",
        subtitle2: "Your imagination and motivation are the only limits!",
        button: "Learn More",
      },
    },
    services: {
      title: "Panzofi Services",
      subtitle:
        "We offer specialized services for businesses, educational institutions, and to aid content creators.",
      item1: {
        title: "For Businesses",
        subtitle:
          "Elevate your team's skills to new heights: inspire, educate, and optimize both time and resources.",
        subtitle2:
          "Your employees will appreciate the investment in their development!",
        button: "Learn More",
      },
      item2: {
        title: "For Educational Institutions",
        subtitle:
          "Be a part of the Panzo-community, where your educators can collaborate with peers and focus more effectively on their students.",
        subtitle2:
          "Both teachers and students will be grateful for this enhanced educational experience!",
        button: "Learn More",
      },
      item3: {
        title: "Additional Services",
        subtitle:
          "We provide expert guidance to content creators aiming for digital visibility and exploring new avenues for revenue generation.",
        subtitle2: "",
        button: "Learn More",
      },
    },
    recognitions: {
      title: "Recognitions",
      subtitle:
        "Panzofi was accepted into the AWS EdStart global acceleration program for 2021-2023, catering to emerging educational technology (EdTech) companies.",
      item: {
        title: "AWS EdStart Members",
        subtitle:
          "We receive both commercial and technical support for StartUps. Additionally, we are part of a large international EdTech community.",
        bold: "Benefits",
        subtitle2:
          "Mentorship, networking and event participation, technical training, AWS credits, and more.",
        subtitle3: "Learn more about the program",
        bold2: "here.",
      },
    },
  },
  teachers: {
    title: "Embracing All Knowledge",
    subtitle:
      "Join us in enriching the collective wisdom and fostering information exchange within the Panzofi network.",
    button: "Join the Panzo-community Family",
    challenges: {
      title: "The challenges",
      subtitle: "of educational content creators are...",
      item1: {
        title: "Technical Skillset ",
        subtitle:
          "Crafting educational content often demands proficiency in graphic design, video editing, programming, and audiovisual production.",
      },
      item2: {
        title: "Time and Resource Constraints",
        subtitle:
          "The creation of educational content can be demanding in terms of time and resources.",
      },
      item3: {
        title: "Audience Engagement",
        subtitle:
          "Content creators need to find effective strategies to connect with and retain their audience's interest.",
      },
      item4: {
        title: "Ensuring Content",
        subtitle:
          "Excellence It's crucial for creators to produce content that is not only high in quality and creativity but also accurate, to maintain existing followers and attract new ones.",
      },
    },
    tendencies: {
      title: "Trends",
      subtitle: "of educational content creators are...",
      item1: {
        title: "Creators Shaping the Future",
        subtitle:
          "They engage in sharing and collaborating to pinpoint optimal teaching methods and the most suitable content for their students.",
      },
      item2: {
        title: "Visionaries of Tomorrow",
        subtitle:
          "These creators grasp the community's needs and foster active engagement by developing new educational environments on the network.",
      },
      item3: {
        title: "Pioneers in Innovation",
        subtitle:
          "Utilizing strategic information, they minimize production costs through network collaboration and sharing the digital creation workload.",
      },
      item4: {
        title: "Trendsetters of Today",
        subtitle:
          "They build their reputation in the educational sphere and monetize their influence through followers and sponsors in a digital marketplace.",
      },
    },
    ecosystem: {
      title: "We are creating an ecosystem",
      subtitle: "of learning and teaching, where you can...",
      item1: {
        title: "Deepen Understanding of Your Audience",
        subtitle:
          "At Panzofi, you can track how your audience engages with your content. Insights from ratings and comments will guide you in refining and enhancing your material.",
      },
      item2: {
        title: "Amplify Your Work",
        subtitle:
          "Panzofi assists in promoting your content across our social networks and within the Panzo-community, helping you expand your reach.",
      },
      item3: {
        title: "Expand Your Digital Footprint With us",
        subtitle:
          "Diversify your presence across digital platforms. Panzofi serves as an additional channel to broadcast your content and gain global recognition.",
      },
      item4: {
        title: "Generate Revenue from Your Content",
        subtitle:
          "Panzofi offers an opportunity to earn income based on the traffic and engagement your content receives. For more details, contact us at info@panzofi.com.",
      },
    },
    how_works: {
      title: "How does it work?",
      subtitle: "It is very easy and intuitive regardless of age",
      item1: {
        title: "Register",
        subtitle: "Register and create your profile",
      },
      item2: {
        title: "Upload",
        subtitle: "Upload the lesson of your interest or a specific topic",
      },
      item3: {
        title: "Share",
        subtitle: "Share theoretical and practical resources online",
      },
      item4: {
        title: "Rate",
        subtitle: "Share, comment and rate the community",
      },
    },
    calculator: {
      title: "How Much Could You Earn?",
      select: "Select Your Category :",
      visits: "Monthly Visits:",
      earnings: "Annual Earnings:",
      categories: {
        admin: "Business Administration",
        arts: "Arts and Humanities",
        bio: "Biology and life sciences",
        data: "Data Sciences",
        phisics: "Physics and Engineering",
        social: "Social Sciences",
        personal: "Personal Development",
        design: "Design",
        lang: "Languages",
        it: "IT and SW",
        math: "Mathematics and Logic",
      },
      info: "*Please note, there is no guarantee or commitment that you will earn this specific amount. The estimates are based on the content category you have chosen. Actual earnings are subject to a variety of factors, including advertiser demand, user location, user device, content vertical, seasonal trends, ad size, and currency exchange rates.",
    },
    influentecs: {
      title: "Meet Our Influencers",
      subtitle:
        "We thank the creators for joining efforts and providing opportunities through education.",
      itemLeft1: {
        title: "CuriosaMente",
        subtitle: "Science and culture, for the pleasure of learning.",
        button: "Go to Profile",
      },
      itemLeft2: {
        title: "Ivanevsky",
        subtitle:
          "Illustrator and Concept Artist. Drawing and art have always been my passion.",
        button: "Go to Profile",
      },
      itemLeft3: {
        title: "Tonio Cocina",
        subtitle: "Easy and delicious recipes for everyday life.",
        button: "Go to Profile",
      },
      itemLeft4: {
        title: "Gibson Marval",
        subtitle: "Discover the art of teaching with passion and creativity.",
        button: "Go to Profile",
      },
      itemLeft5: {
        title: "Historias para un café",
        subtitle: "History, cultures, kingdoms, and empires.",
        button: "Go to Profile",
      },
      itemRight1: {
        title: "Guido Rosas",
        subtitle: "Author and Consultant specializing in Human Potential.",
        button: "Go to Profile",
      },
      itemRight2: {
        title: "Google Expert",
        subtitle: "Systems Engineer, specialist in Google solutions.",
        button: "Go to Profile",
      },
      itemRight3: {
        title: "Juan Ignacio Silva",
        subtitle: "Linear algebra, calculus, differential equations, and more.",
        button: "Go to Profile",
      },
      itemRight4: {
        title: "Byspel Tech",
        subtitle: "Programming languages, algorithms, databases.",
        button: "Go to Profile",
      },
      itemRight5: {
        title: "and more",
        subtitle: "Explore the other influencers of the Panzo-community.",
        button: "Explore",
      },
    },
  },
  companies: {
    title: "Online Training for Businesses",
    subtitle:
      "We offer courses designed to train your employees in an efficient, agile, and enjoyable manner.",
    button: "",
    courses: {
      title: "Courses in Soft and Technical Skills ",
      subtitle:
        "Designed to make your employees more productive, motivated, and results-oriented, while maintaining a balance between work and personal life.",
      item1: {
        title: "Leadership",
        subtitle: "Empowering your employees to become leaders.",
      },
      item2: {
        title: "Mental Health",
        subtitle: "Helping your employees find inner peace.",
      },
      item3: {
        title: "Digital Skills",
        subtitle: "Boosting productivity with technological tools.",
      },
      item4: {
        title: "Healthy Eating",
        subtitle: "Ensuring your employees are healthier and more energetic.",
      },
      item5: {
        title: "Remote Work",
        subtitle: "Learning the best methodologies for more efficient work.",
      },
      item6: {
        title: "Physical Activity",
        subtitle: "Reducing the risk of physical and mental illnesses.",
      },
      item7: {
        title: "Personal Finance",
        subtitle:
          "Teaching best financial practices for your employees' future.",
      },
      item8: {
        title: "Corporate communication",
        subtitle:
          "Strengthening corporate culture and enhancing employee performance.",
      },
    },
    free_access: {
      title: "Access the Free Meditation Course.",
      subtitle:
        "Yoga and Meditation. A space crafted for awakening and personal growth",
      item: {
        title: "Ethel Deutsch R.",
        subtitle:
          "Register via the following link, and you'll receive an email with the next steps to access the free course (offer available while supplies last).",
        button: "Learn More",
      },
    },
    plans: {
      title: "Plans and Pricing ",
      subtitle: "Choose the plan that best suits your company.",
      item1: {
        button: "Purchase",
      },
      item2: {
        button: "Purchase",
      },
      item3: {
        button: "Contact a Representative",
      },
    },
    how_works: {
      title: "How Does It Work?",
      subtitle:
        "The process is straightforward, no matter the size of your organization.",
      item1: {
        title: "Select the Plan",
        subtitle: "Complete the form to register your company.",
      },
      item2: {
        title: "Plan Confirmation",
        subtitle: "An advisor will contact you to finalize the plan purchase.",
      },
      item3: {
        title: "Process the Payment",
        subtitle: "Make the payment according to the selected plan.",
      },
      item4: {
        title: "Activate the Account",
        subtitle: "Your employees will then gain access to the courses.",
      },
    },
    cases: {
      title: "Discover Some of Our Success Stories",
      subtitle:
        "More companies are choosing Panzofi to enhance their human capital and achieve better results.",
      item1: {
        title: "Iscon S.A.S.",
        subtitle:
          "A company in urbanization and construction, offering a variety of services in the sector.",
      },
      item2: {
        title: "Sanüable",
        subtitle:
          "Providers of wellness through products and services that translate into happiness.",
      },
    },
    experts: {
      title: "Meet Some of Our Experts",
      subtitle:
        "Over 10 experts with years of experience have created valuable, concise, and brief content for your employees.",
      item1: {
        title: "Ethel Deutsch R",
        subtitle: "Expert in Yoga and Meditation. A guide for personal growth.",
      },
      item2: {
        title: "Sally Digital",
        subtitle: "Expert in Digital Marketing and the Creative Sector.",
      },
    },
  },
  schools: {
    title: "Discover and Share Knowledge",
    subtitle:
      "Panzofi is freely accessible to schools and universities, contributing to a collaborative learning network.",
    button: "Explore the Panzo-community's  Content",
    ecosystem: {
      title: "We are building a learning and teaching ecosystem",
      subtitle: "where educational institutions can...",
      item1: {
        title: "Collaboratively Learn",
        subtitle:
          "Within the Panzo-community, teachers and students can share a wide range of knowledge.",
      },
      item2: {
        title: "Integrate Theory and Practice",
        subtitle:
          "Teachers can reinforce theoretical concepts with practical content, following the 'learn by doing' model.",
      },
      item3: {
        title: "Save Time in Searching",
        subtitle:
          "Different educational institutions can form alliances and coordinate to create content collectively.",
      },
      item4: {
        title: "Economize on Expenses",
        subtitle:
          "Reduce the costs of producing and creating digital content. Simultaneously, save on platform development costs.",
      },
    },
    brochure: {
      title: "Access the Brochure for Educational Institutions",
      item: {
        title:
          "Click the button below to download the Panzofi brochure for universities and schools. Inside, you'll find details about the benefits, features, and a tutorial.",
        button: "Download",
      },
    },
    how_works: {
      title: "How Does It Work?",
      subtitle:
        "It's very easy and intuitive (for more information, check out the teacher's tutorial by clicking here).",
      without_acount: {
        title: "Without an Account",
        item1: {
          title: "Browse",
          subtitle:
            "Access content completely free and without any restrictions.",
        },
        item2: {
          title: "Search",
          subtitle: "Look for content that interests you or a specific topic.",
        },
        item3: {
          title: "Find",
          subtitle: "Discover theoretical and practical resources online.",
        },
        item4: {
          title: "Share",
          subtitle: "Share with your family, friends, and study peers.",
        },
      },
      with_acount: {
        title: "With an Account",
        item1: {
          title: "Rate",
          subtitle:
            "Evaluate content based on its fun, efficiency, and usefulness.",
        },
        item2: {
          title: "Upload",
          subtitle: "Post theoretical and practical content for all to see.",
        },
        item3: {
          title: "Participate",
          subtitle:
            "Engage and collaborate with other users for enhanced learning.",
        },
        item4: {
          title: "Earn",
          subtitle:
            "Build a reputation on the network and improve your educational profile.",
        },
      },
    },
    cases: {
      title: "Discover Some of Our Success Stories",
      subtitle:
        "More schools and universities are choosing Panzofi to narrow the educational gap and make education more accessible.",
      item1: {
        title: "Villa Ricaurte School",
        subtitle:
          "They are utilizing Panzofi for their laboratories and experiments in physics, chemistry, and computing.",
      },
      item2: {
        title: "Ariza University",
        subtitle:
          "They are using Panzofi to create educational communities and promote collaborative learning.",
      },
    },
  },
  services: {
    title: "Additional Services for Content Creators ",
    subtitle:
      "We provide advisory, training, audiovisual production, and marketing services to enhance the success of your educational courses.",
    creators: {
      title: "Educational Creators",
      subtitle: "Are Seeking...",
      item1: {
        title: "More Freedom",
        subtitle:
          "To spend additional time with friends and family, and achieve financial independence.",
      },
      item2: {
        title: "Income Diversification",
        subtitle: "To start creating online courses for passive income.",
      },
      item3: {
        title: "Enhanced Course Success",
        subtitle:
          "To increase course sales and enrollments, receive better feedback and reviews.",
      },
      item4: {
        title: "Audience Growth",
        subtitle:
          "To expand their community and improve the promotion of their courses.",
      },
    },
    inform: {
      title:
        "Access the free report on revenue and key figures from educational tools",
      item: {
        title:
          "Click the button below to download the key figures report on the educational tools most used by content creators. (Promotion until places are sold out).",
        button: "Download",
      },
    },
    lines: {
      title: "Panzofi Service Lines",
      subtitle:
        "Depending on the maturity of your business, we offer services that best fit your needs.",
      item1: {
        title: "Consulting Services",
        subtitle:
          "We offer two packages for beginners and advanced users that will guide you in adopting the best practices in the educational content creation market.",
        button: "Learn More",
      },
      item2: {
        title: "Training Services",
        subtitle:
          "How to create a successful online course. In this training, you will learn about technological tools, promotion, pricing, and creating online courses.",
        button: "Learn More",
      },
      item3: {
        title: "Audiovisual Production",
        subtitle:
          "Services Understanding the power of videos in virtual course production, Panzofi specializes in creating engaging and memorable videos.",
        button: "Learn More",
      },
      item4: {
        title: "Marketing Services",
        subtitle:
          "For long-term success in online education, it's essential not just to create quality courses but also to implement effective marketing strategies. At Panzofi, we assist in developing comprehensive marketing and advertising strategies.",
        button: "Learn More",
      },
    },
  },
  marketing: {
    title: "Marketing Service for Educational Creators",
    subtitle:
      "We offer a wide range of marketing and advertising services for online courses, helping you achieve your marketing goals and succeed with your course.",
    creators: {
      title: "Our Services Include",
      item1: {
        title: "Marketing Strategy",
        subtitle:
          "We help you define your marketing goals and create a strategy to achieve them",
        li1: "Analysis of your current content and performance metrics.",
        li2: "Research of your target audience and their needs.",
        li3: "Identification of your competitors and their strategies.",
        li4: "Creation of an editorial calendar tailored to your audience.",
        li5: "Strategies to foster engagement with your audience.",
        li6: "Clarification of your short, medium, and long-term goals.",
      },
      item2: {
        title: "Data Analysis",
        subtitle:
          "We help you evaluate the performance of your marketing campaigns",
        li1: "Review of your current metrics and performance.",
        li2: "Use of advanced analysis tools to monitor the performance of your strategies.",
        li3: "Generation of reports to assess progress and adjust strategies as necessary.",
        li4: "Use of data to improve the performance of your ads.",
        li5: "Clarification of your short, medium, and long-term goals.",
      },
      item3: {
        title: "Advertising",
        subtitle: "We help you reach your target audience with effective ads",
        li1: "Analysis of your current content and performance metrics.",
        li2: "Strategies to improve your search engine ranking.",
        li3: "Advanced strategies to increase your visibility.",
        li4: "Advertising campaigns on Facebook, Instagram, LinkedIn, and Twitter.",
        li5: "Constant evaluation to identify areas for improvement.",
        li6: "Clarification of your short, medium, and long-term goals.",
      },
      item4: {
        title: "Content Creation",
        subtitle:
          "We help you create engaging and relevant content for your target audience",
        li1: "Creation of graphic pieces for platforms like Instagram, Facebook, LinkedIn, and Twitter.",
        li2: "Production of impactful and optimized short videos and Reels for Instagram and other platforms.",
        li3: "Creation of introductory videos.",
        li4: "Custom graphics based on the creator's brand image.",
        li5: "Review and adjustments of graphic pieces.",
      },
    },
    portfolio: {
      title: "Portfolio",
    },
    testimonies: {
      title: "Testimonials",
      t1: "Panzofi helped me with the creation of graphic pieces for my social networks, meeting my expectations. Their prompt delivery and the design of the content were to my complete satisfaction. I recommend their services for those who need to enhance their content and capture the attention of their target audience.",
    },
    prices: {
      title: "Prices",
      info1:
        "These prices are only an estimate and may vary depending on the complexity of the course, the number of videos, the duration of the videos, and other factors. For a personalized quote, contact us.",
      info2:
        "If you are interested in creating an online course, contact us for more information.",
      table: {
        button: "Hire Service",
        col1: {
          title: "Service",
          row1: "Marketing Strategy",
          row2: "Content Creation",
          row3: "Advertising",
          row4: "Data Analysis",
        },
        col2: {
          title: "Price",
          row1: "From $500 USD",
          row2: "From $100 USD per content piece",
          row3: "From $200 USD per campaign",
          row4: "From $200 USD per month",
        },
      },
      list: {
        l1: "Marketing Strategy: This service includes target audience analysis, goal creation, and strategy definition to achieve them.",
        l2: "Content Creation: This service includes writing texts, creating images and videos, and developing email campaigns.",
        l3: "Advertising: This service includes creating and launching ads on platforms like Google, Facebook, and Instagram.",
        l4: "Data Analysis: This service includes tracking key metrics to evaluate the performance of marketing campaigns.",
      },
    },
    packages: {
      title: "Content Creation: Packages",
      p1: {
        plan: "Basic",
        price: "$54",
        desc: "3 graphic pieces for social networks (single post, carousel, stories) OR reel-type video (15-30 seconds)",
        button: "Hire Service",
        time: {
          title: "Delivery Time:",
          desc: "in 3 days",
        },
        revision: {
          title: "Revisions:",
          desc: "1",
        },
        include: {
          title: "Includes:",
          li1: "Research of 6 keywords/hashtags",
          li2: "Custom graphics",
          li3: "Copy out for each post",
        },
        platform: {
          title: "Platforms:",
        },
      },
      p2: {
        plan: "Standard",
        price: "$98",
        desc: "3 graphic pieces for social networks (single post, carousel, stories) OR reel-type video (15-30 seconds) 1 introductory video for YouTube (15-30 seconds)",
        button: "Hire Service",
        time: {
          title: "Delivery Time:",
          desc: "in 6 days",
        },
        revision: {
          title: "Revisions:",
          desc: "1",
        },
        include: {
          title: "Includes:",
          li1: "Research of 6 keywords/hashtags",
          li2: "Custom graphics",
          li3: "Copy out for each post",
        },
        platform: {
          title: "Platforms:",
        },
      },
      p3: {
        plan: "Premium",
        price: "$148",
        desc: "3 graphic pieces for social networks (single post, carousel, stories) 1 reel-type video (15-30 seconds) 1 introductory video for YouTube (15-30 seconds)",
        button: "Hire Service",
        time: {
          title: "Delivery Time:",
          desc: "in 10 days",
        },
        revision: {
          title: "Revisions:",
          desc: "2",
        },
        include: {
          title: "Includes:",
          li1: "Research of 6 keywords/hashtags",
          li2: "Custom graphics",
          li3: "Copy out for each post",
        },
        platform: {
          title: "Platforms:",
        },
      },
    },
  },
  audiovisual: {
    title: "Online Course Video Production Service ",
    subtitle:
      "We create innovative ideas and produce high-quality videos and audios that make an impact and connect with your audience, ensuring your online courses are successful.",
    services: {
      title: "Our Services Include",
      item1: {
        title: "Strategy and Planning",
        subtitle: "We design a clear and effective structure for your course.",
      },
      item2: {
        title: "Production",
        subtitle:
          "We record and edit your videos in line with the highest quality standards.",
      },
      item3: {
        title: "Publication",
        subtitle:
          "We assist you in publishing your course on the appropriate platforms.",
      },
    },
    help: {
      title: "How Do We Assist Course Creators?",
      item1: {
        title:
          "If your course is already structured, we handle the video production.",
        l1: "We record and edit the videos according to the highest quality standards.",
        l2: "We deliver the videos ready for you to upload to the platform you prefer.",
      },
      item2: {
        title:
          "If your course isn’t yet structured, we assist in designing it.",
        l1: "We advise you so that the course is attractive and generates sales.",
        l2: "We help you organize your content and create a production plan.",
      },
      item3: {
        title:
          " Additionally, we assist in publishing and promoting your course.",
        l1: "We publish the course on the platform of your choice.",
        l2: "We help you create a marketing strategy to reach your audience.",
      },
    },
    prices: {
      title: "Prices",
      info1:
        "These prices are estimates and may vary based on the complexity of the course, number of videos, length of videos, and other factors. For a personalized quote, please contact us.",
      info2:
        "If you are interested in creating an online course, get in touch with us for more information.",
      table: {
        button: "Hire Service",
        col1: {
          title: "Service",
          row1: "Strategy and planning",
          row2: "Production",
          row3: "Publication",
        },
        col2: {
          title: "Price",
          row1: "Starting at $500 USD",
          row2: "Starting at $1000 USD per video",
          row3: "Starting at $200 USD",
        },
      },
      list: {
        l1: "Strategy and Planning: This service includes designing a course structure, creating a script, and selecting necessary resources.",
        l2: "Production: This service covers recording, editing, and color correction of the videos.",
        l3: "Publication: This service involves uploading videos to the creator’s chosen platform and setting up technical configurations.",
      },
    },
  },
  training: {
    title: "Online Course Creation Training Service",
    subtitle:
      "We offer a comprehensive training program to ensure your educational courses are more successful, achieve digital positioning, and generate new income sources.",
    learn: {
      title: "What You'll Learn",
      item1: {
        title: "Planning",
        subtitle:
          "We'll teach you the best practices for planning your online course creation.",
      },
      item2: {
        title: "Tools and Equipment",
        subtitle:
          "Learn about the necessary equipment and software requirements for creating courses.",
      },
      item3: {
        title: "Creation and Design",
        subtitle:
          "We'll show you how to use the most popular tools for creating engaging online courses.",
      },
      item4: {
        title: "Publishing and Promotion",
        subtitle:
          "Learn various strategies to successfully launch and promote your course.",
      },
    },
    content: {
      title: "Course content",
      subtitle: "12 Modules | 78 Lessons",
      course: {
        module1: {
          title: "Introduction",
          description: "4 Lessons",
          module1content1: {
            title: "Welcome",
            description: "",
          },
          module1content2: {
            title:
              "How to Create and Sell Online Courses: Strategies for Success",
            description: "",
          },
          module1content3: {
            title: "Create Your First Class Now!",
            description: "",
          },
          module1content4: {
            title: "Successfully Create and Sell Your First Online Course",
            description: "",
          },
        },
        module2: {
          title: "Hardware, Software, and Requirements",
          description: "4 Lessons",
          module2content1: {
            title: "Introduction",
            description: "",
          },
          module2content2: {
            title: "Equipment and Tools for Quality Online Course Creation",
            description: "",
          },
          module2content3: {
            title: "Tools for Creating Presentations and Recording Slides",
            description: "",
          },
          module2content4: {
            title:
              "How to Create a Successful Online Course: Requirements and Tips",
            description: "",
          },
        },
        module3: {
          title: "Economics",
          description: "5 Lessons",
          module3content1: {
            title: "Introduction",
            description: "",
          },
          module3content2: {
            title: "Cost Module",
            description: "",
          },
          module3content3: {
            title: "Diversifying Income Sources",
            description: "",
          },
          module3content4: {
            title: "Scalability in Online Course Business",
            description: "",
          },
          module3content5: {
            title: "Standing Out in the Competitive World of Online Courses",
            description: "",
          },
        },
        module4: {
          title: "Course Validation",
          description: "5 Lessons",
          module4content1: {
            title: "Introduction",
            description: "",
          },
          module4content2: {
            title: "Finding Market Proof",
            description: "",
          },
          module4content3: {
            title: "Structuring an Attractive Course",
            description: "",
          },
          module4content4: {
            title: "Getting Feedback to Shape Your Course",
            description: "",
          },
          module4content5: {
            title: "Successful Presales: Selling Your Course Before Creation",
            description: "",
          },
        },
        module5: {
          title: "Creating an Amazing Course",
          description: "4 Lessons",
          module5content1: {
            title: "Introduction",
            description: "",
          },
          module5content2: {
            title: "Online Course Styles",
            description: "",
          },
          module5content3: {
            title: "Building a Community",
            description: "",
          },
          module5content4: {
            title: "Tips and Tricks",
            description: "",
          },
        },
        module6: {
          title: "Creating Your First Class",
          description: "7 Lessons",
          module6content1: {
            title: "Introduction",
            description: "",
          },
          module6content2: {
            title: "Preparing and Designing Impactful Presentations",
            description: "",
          },
          module6content3: {
            title: "Getting Started with PowerPoint",
            description: "",
          },
          module6content4: {
            title: "Getting Started with Google Slides",
            description: "",
          },
          module6content5: {
            title: "Getting Started with Keynote",
            description: "",
          },
          module6content6: {
            title: "Getting Started with Canva",
            description: "",
          },
          module6content7: {
            title: "Resources for Designing Visually Appealing Presentations",
            description: "",
          },
        },
        module7: {
          title: "Assembling the Course",
          description: "3 classes",
          module7content1: {
            title: "Getting the Course Started",
            description: "",
          },
          module7content2: {
            title: "Creating a Striking Introduction for Your Online Course",
            description: "",
          },
          module7content3: {
            title:
              "Breaking It Down: The Importance of Breaking Down Your Course",
            description: "",
          },
        },
        module8: {
          title: "Recording Your Course",
          description: "4 classes",
          module8content1: {
            title: "Recording Your Course: Tips for Professional Video",
            description: "",
          },
          module8content2: {
            title: "Choose a Recording Style for Your Lecture",
            description: "",
          },
          module8content3: {
            title: "How to Record the Perfect Lecture",
            description: "",
          },
          module8content4: {
            title: "Connect PowerPoint with Camtasia",
            description: "",
          },
        },
        module9: {
          title: "Hosting Your Course",
          description: "12 classes",
          module9content1: {
            title: "Introduction",
            description: "",
          },
          module9content2: {
            title: "Self-Hosting vs. Marketplace Platforms",
            description: "",
          },
          module9content3: {
            title: "Which Self-Hosting Option Should You Choose?",
            description: "",
          },
          module9content4: {
            title:
              "Getting Started with Thinkific: Your Online Course Platform",
            description: "",
          },
          module9content5: {
            title:
              "Getting Started with Teachable: Your Online Course Platform",
            description: "",
          },
          module9content6: {
            title: "Get Started with Podia: Your Platform for Growing Online",
            description: "",
          },
          module9content7: {
            title: "Quick Start with Learnworlds: Your Online Course Platform",
            description: "",
          },
          module9content8: {
            title: "The Best WordPress Plugins for Online Courses",
            description: "",
          },
          module9content9: {
            title: "Exploring Online Course Markets",
            description: "",
          },
          module9content10: {
            title: "How to Create Your Course on Udemy",
            description: "",
          },
          module9content11: {
            title: "How to Upload Your Course to Skillshare",
            description: "",
          },
          module9content12: {
            title: "Write Compelling Titles and Descriptions that Sell",
            description: "",
          },
        },
        module10: {
          title: "Promoting Your Course",
          description: "14 classes",
          module10content1: {
            title: "Introduction",
            description: "",
          },
          module10content2: {
            title:
              "Identifying Your Unique Selling Proposition (USP) and Target Audience",
            description: "",
          },
          module10content3: {
            title:
              "Harnessing the Power of Social Media to Promote Your Course",
            description: "",
          },
          module10content4: {
            title: "Utilizing SEO Potential to Promote Your Online Courses",
            description: "",
          },
          module10content5: {
            title:
              "How to Create a Successful Promotional YouTube Video for Your Online Course",
            description: "",
          },
          module10content6: {
            title:
              "How to Create a Successful Promotional Video for Your Course on Panzofi",
            description: "",
          },
          module10content7: {
            title:
              "How to Develop a Complementary Mini Course to Boost Your Sales",
            description: "",
          },
          module10content8: {
            title: "How to Offer Discounts on Your Courses to Boost Sales",
            description: "",
          },
          module10content9: {
            title:
              "How to Gather Reviews from Your Current Students to Improve Your Course",
            description: "",
          },
          module10content10: {
            title: "How to Effectively Promote Your Online Course",
            description: "",
          },
          module10content11: {
            title: "Harnessing the Power of Online Advertising for Your Course",
            description: "",
          },
          module10content12: {
            title:
              "Leveraging the Power of Reviews and Editors for Your Course",
            description: "",
          },
          module10content13: {
            title:
              "How to Be a Successful Podcast Guest to Promote Your Online Course",
            description: "",
          },
          module10content14: {
            title:
              "How to Create and Deliver an Engaging Live Webinar to Promote Your Online Course",
            description: "",
          },
        },
        module11: {
          title: "Selling Your Course",
          description: "10 classes",
          module11content1: {
            title: "Introduction",
            description: "",
          },
          module11content2: {
            title:
              "What is a Sales Funnel? Guide to Directing Customers towards Purchase",
            description: "",
          },
          module11content3: {
            title: "How to Build the Perfect Sales Funnel on YouTube",
            description: "",
          },
          module11content4: {
            title: "How to Create an Effective Email Funnel",
            description: "",
          },
          module11content5: {
            title: "How to Create an Automated Sales Funnel",
            description: "",
          },
          module11content6: {
            title: "How to Create a Form with Google Forms",
            description: "",
          },
          module11content7: {
            title: "Guide to Getting Started with MailChimp",
            description: "",
          },
          module11content8: {
            title: "How to Start with a CRM to Sell Your Courses Online",
            description: "",
          },
          module11content9: {
            title: "How to Determine the Price of My Online Course?",
            description: "",
          },
          module11content10: {
            title: "What Drives Consumer Purchases?",
            description: "",
          },
        },
        module12: {
          title: "Grow Your Brand and Income",
          description: "6 classes",
          module12content1: {
            title: "Introduction",
            description: "",
          },
          module12content2: {
            title: "Scalability in Selling Online Courses",
            description: "",
          },
          module12content3: {
            title: "Being the First Player in Selling Online Courses",
            description: "",
          },
          module12content4: {
            title: "Improve Your Cross-Selling Strategy",
            description: "",
          },
          module12content5: {
            title: "Optimize Your Online Course Business with Feedback",
            description: "",
          },
          module12content6: {
            title: "Common Mistakes in Creating Online Courses",
            description: "",
          },
        },
      },
    },
    why_course: {
      title: "Why Take This Course with Panzofi?",
      item1: {
        title:
          "We assist you in shaping your idea, identifying your market niche, and defining your target audience and demographic.",
      },
      item2: {
        title:
          "We teach you how to structure your course, always aiming for simplicity and effectiveness in the content you wish to present.",
      },
      item3: {
        title:
          "We help you determine the ideal pricing for your course, based on key strategies and competitive market pricing.",
      },
      item4: {
        title:
          "We introduce you to successful strategies for promotion and student management.",
      },
    },
    prices: {
      title: "Prices",
      info1:
        "If you're interested in our training services, please contact us for more information.",
      table: {
        button: "Hire Services",
        col1: {
          title: "Service",
          row1: "Training tutorial",
        },
        col2: {
          title: "Price",
          row1: "Dto",
        },
      },
      list: {
        title: "The online course creation training program includes:",
        l1: "12 modules",
        l2: "78 lessons",
        l3: "Immediate access to all content",
        l4: "Lifetime access to future updates",
        l5: "Prompt and helpful support in the Q&A section",
      },
    },
  },
  advisory: {
    title: "Consultation and Training for Course Creators ",
    subtitle:
      "We offer a comprehensive advisory and training program to make your educational courses more successful.",
    button: "Request to be Contacted",
    service: {
      title: "What Will You Learn from Our Service?",
      item1: {
        title: "Boost Your Online Course Sales",
      },
      item2: {
        title: "Learn How to Create High-Potential Courses",
      },
      item3: {
        title: "Validate Your Course Idea or Theme",
      },
      item4: {
        title: "Master Effective Course Promotion",
      },
      item5: {
        title: "Develop Automated Sales Systems",
      },
      item6: {
        title: "Design High-Quality Courses",
      },
      item7: {
        title: "Learn to Build and Expand Your Audience",
      },
      item8: {
        title: "Tips for Creating More Successful Courses",
      },
    },
    starter: {
      title: "Advisory and Training (Starter) Program",
      item1: {
        title: "Who Is It For?",
        subtitle:
          "Individuals from various knowledge areas who wish to create online educational content or improve their outcomes",
      },
      item2: {
        title: "Duration",
        subtitle:
          "The program includes 9 advisory sessions, each lasting 2 hours, totaling 18 hours of guided support.",
        subtitle2:
          "Additionally, there are online training sessions during the advisory period.",
      },
      item3: {
        title: "Capacity",
        subtitle:
          "Limited to 30 participants per course. If the quota isn't met, the course will start on the next scheduled date.",
      },
      item4: {
        title: "Dates",
        subtitle:
          "Courses begin in the first week of each month. Upon registration, the start date will be confirmed.",
        subtitle2: "For more information, email info@panzofi.com",
      },
    },
    level: {
      title: "Reach the Next Level",
      subtitle:
        "If you've been creating content for over a year and want to accelerate the process with technology tools to reduce production costs and automate sales to enhance your outcomes, this is for you.",
    },
    advance: {
      title: "Advisory and Training (Advanced)",
      item1: {
        title: "Who Is It For?",
        subtitle:
          "This program is designed for content creators with over a year of experience who are looking to enhance their results (in sales, production, etc.).",
      },
      item2: {
        title: "Duration",
        subtitle:
          "The program consists of 9 advisory sessions, each lasting 2 hours, for a total of 18 hours dedicated to supporting your process.",
        subtitle2:
          "This is supplemented by online training sessions throughout the advisory period.",
      },
      item3: {
        title: "Capacity",
        subtitle:
          "The course is limited to 30 participants. If the full number of participants is not met, the course will commence on the next scheduled date.",
      },
      item4: {
        title: "Dates",
        subtitle:
          "The course is held every two months (on even-numbered months). Your start date will be confirmed upon registration.",
        subtitle2: "For more information, please email info@panzofi.com.",
      },
    },
    plans: {
      title: "Plans and Pricing",
      subtitle: "Choose the plan that best suits you.",
      item1: {
        button: "Purchase",
      },
      item2: {
        button: "Learn More",
      },
    },
    how_works: {
      title: "How Does It Work?",
      subtitle: "It's quite simple, just follow these steps...",
      item1: {
        title: "Select the Plan",
        subtitle:
          "An advisor will contact you to proceed with the plan purchase.",
      },
      item2: {
        title: "Make the Payment",
        subtitle: "The payment depends on the plan you choose.",
      },
      item3: {
        title: "Account Activation",
        subtitle: "An email will be sent with access to the courses.",
      },
      item4: {
        title: "Course Start",
        subtitle:
          "You will receive an email with the scheduled sessions for the month.",
      },
    },
    cases: {
      title: "Discover Some of Our Success Stories",
      subtitle:
        "More content creators are choosing Panzofi to increase their earnings and strengthen their community.",
      item1: {
        title: "Camila Prieto (29 years old)",
        subtitle:
          "With Panzofi's guidance, I managed to create my course on agile methodologies across different platforms. I succeeded in positioning my brand as a consultant and gained experience in content creation.",
        subtitle2: "My income increased by 36%, and I am truly delighted.",
      },
      item2: {
        title: "Juan Robles (35 years old)",
        subtitle:
          "I really appreciated the advisory sessions. They allowed me to step into my students' shoes and connect with them more effectively. The sessions also introduced me to new technological tools, making me more successful and reducing my operational workload.",
        subtitle2:
          "Since then, I have launched more courses with a higher registration rate.",
      },
    },
  },
};
export default landing_en;
