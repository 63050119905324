import React from 'react';
import { Label } from "components/ui";
import Select from 'react-select';


const SelectField = ({
    label, name, defaultValue, onChange, options, notRequired, multiple
}) => {

    const handleChange = (value, action) => {
        const nextValue = Array.isArray(value) ? value.map(({value}) => value) : value?.value;
        const data = {
            [action.name]: nextValue
        };
        onChange(data);
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        background: '#172B4D',
        color: '#EBECF0',
        margin: 0
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    return (
        <section className='pz-form__field'>
            <Label htmlFor={name} labelAsText>{label}</Label>
            <Select
                inputId={name}
                name={name}
                styles={{
                    container: (provided) => ({
                        ...provided,
                        padding: "0",
                    }),
                    control: (provided) => ({
                        ...provided,
                        border: 0,
                        fontSize: "0.8rem"
                    }),
                    menu: (provided) => ({
                        ...provided,
                        fontSize: "0.8rem"
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        padding: "0",
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        paddingRight: "0"
                    }),
                    group: (provided) => ({}),
                    groupHeading: (provided) => ({})
                }}
                options={options}
                defaultValue={defaultValue}
                closeMenuOnSelect={!multiple}
                className='pz-form__input'
                isMulti={multiple}
                onChange={handleChange}
                formatGroupLabel={formatGroupLabel}
            />
        </section>
    );
};

SelectField.defaultProps = {
    defaultValue: '',
    notRequired: false,
    multiple: false,
    options: []
};

export default SelectField;