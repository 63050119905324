import React from "react";
import { Modal } from "components/common";
import { Button, Form, SelectField } from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { getAsList } from "shared/common/functions";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QAModal = ({
  courses,
  profile,
  user_slug,
  course,
  is_visible,
  closeModal,
  history,
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (is_visible) {
      courses.getCategories();
    }
    // eslint-disable-next-line
  }, [is_visible]);

  const handleSubmit = (data) => {
    profile.createQuestionnaire(user_slug, data, (response) => {
      if (response.status === 201) {
        closeModal(true);
      }
    });
  };

  const getCategoryOptions = (slug1, categories) =>
    categories.map(({ slug, id }) => ({
      label: t(`categories.${slug1}.sub_categories.${slug}.name`),
      value: id,
    }));

  const getCategoryGroupOptions = () =>
    getAsList(courses.categories).map(({ slug, sub_categories }) => ({
      label: t(`categories.${slug}.name`),
      options: getCategoryOptions(slug, sub_categories),
    }));

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {!!course ? (
        <></>
      ) : (
        <>
          <h1>{t("modals.questionnaire.title.create_title")}</h1>
          <p>{t("modals.questionnaire.subtitle.create_subtitle")}</p>
          <Form onSubmit={!!course ? null : handleSubmit} className="pz-form">
            {({ onChange, data: { name, new_function } }) => (
              <React.Fragment>
                <SelectField
                  label={t("modals.questionnaire.creator.title")}
                  name="creator"
                  onChange={onChange}
                  options={[
                    {
                      value: true,
                      label: t("modals.questionnaire.creator.options.option1"),
                    },
                    {
                      value: false,
                      label: t("modals.questionnaire.creator.options.option2"),
                    },
                  ]}
                  notRequired
                />
                <SelectField
                  label={t("modals.questionnaire.learn_category.title")}
                  name="learn_category"
                  onChange={onChange}
                  options={getCategoryGroupOptions()}
                  notRequired
                />
                <SelectField
                  label={t("modals.questionnaire.teach_category.title")}
                  name="teach_category"
                  onChange={onChange}
                  options={getCategoryGroupOptions()}
                  notRequired
                />
                <SelectField
                  label={t("modals.questionnaire.category_experience.title")}
                  name="category_experience"
                  onChange={onChange}
                  options={[
                    {
                      value: "beginner",
                      label: t(
                        "modals.questionnaire.category_experience.options.option1"
                      ),
                    },
                    {
                      value: "intermediate",
                      label: t(
                        "modals.questionnaire.category_experience.options.option2"
                      ),
                    },
                    {
                      value: "advance",
                      label: t(
                        "modals.questionnaire.category_experience.options.option3"
                      ),
                    },
                  ]}
                  notRequired
                />
                <SelectField
                  label={t("modals.questionnaire.educational_background.title")}
                  name="educational_background"
                  onChange={onChange}
                  options={[
                    {
                      value: "high school",
                      label: t(
                        "modals.questionnaire.educational_background.options.option1"
                      ),
                    },
                    {
                      value: "bachelor",
                      label: t(
                        "modals.questionnaire.educational_background.options.option2"
                      ),
                    },
                    {
                      value: "graduate",
                      label: t(
                        "modals.questionnaire.educational_background.options.option3"
                      ),
                    },
                  ]}
                  notRequired
                />
                <SelectField
                  label={t("modals.questionnaire.reason.title")}
                  name="reason"
                  onChange={onChange}
                  options={[
                    {
                      value: "personal",
                      label: t("modals.questionnaire.reason.options.option1"),
                    },
                    {
                      value: "professional",
                      label: t("modals.questionnaire.reason.options.option2"),
                    },
                    {
                      value: "academic",
                      label: t("modals.questionnaire.reason.options.option3"),
                    },
                  ]}
                  notRequired
                />
                <SelectField
                  label={t("modals.questionnaire.media.title")}
                  name="media"
                  onChange={onChange}
                  options={[
                    {
                      value: "video",
                      label: t("modals.questionnaire.media.options.option1"),
                    },
                    {
                      value: "audio",
                      label: t("modals.questionnaire.media.options.option2"),
                    },
                    {
                      value: "text",
                      label: t("modals.questionnaire.media.options.option3"),
                    },
                    {
                      value: "interactive",
                      label: t("modals.questionnaire.media.options.option4"),
                    },
                  ]}
                  notRequired
                />
                <TextareaField
                  label={`${t("modals.questionnaire.new_function.title")} (${
                    typeof new_function === "string" ? new_function.length : 0
                  }/300)`}
                  maxLength={300}
                  name="new_function"
                  onChange={onChange}
                  notRequired
                />

                <small className="bold">
                  {" "}
                  {t("modals.questionnaire.info_input.title")}
                </small>

                <Button type="submit" primary>
                  {t("modals.questionnaire.submit_button.create_title")}
                </Button>
              </React.Fragment>
            )}
          </Form>
        </>
      )}
    </Modal>
  );
};

export default withRouter(connectReduxReducers(QAModal, "profile", "courses"));
