import React from "react";
import Button from "../button";
import { useTranslation } from "react-i18next";

const GoogleDocumentViewer = ({ url }) => {
  const { t } = useTranslation();
  return (
    <>
      <iframe title={"GD"} src={url} width="100%" height="700px" />
      <a
        href={url}
        download="MyExampleDoc"
        target="_blank"
        rel="noopener noreferrer"
        style={{ margin: "10px", justifySelf: "center" }}
      >
        <section className="grid grid-gap-10 grid-content-middle">
          <h5>{t("lesson.doc-text")}</h5>
          <Button primary className="grid-self-middle grid-self-center">
            {t("lesson.doc-button")}
          </Button>
        </section>
      </a>
    </>
  );
};

export default GoogleDocumentViewer;
