import React from "react";
import { Modal } from "components/common";
import { Button, FileField, Form, InputField, Toggle } from "components/ui";
import TextareaField from "components/ui/form/fields/textarea-field";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { youtubeVal } from "shared/utils/input-validations";
import { CONSTANTS } from "shared/constants";
import { validateYoutubeUrl } from "shared/common";

const ModuleModal = ({
  threads,
  is_visible,
  closeModal,
  currentNews,
  forum_id,
  history,
}) => {
  const { t } = useTranslation();
  const [isURL, setIsURL] = React.useState(false);
  const [fileType, setFileType] = React.useState(undefined);

  React.useEffect(() => {
    // eslint-disable-next-line
  }, [is_visible]);

  const handleSubmit = (data) => {
    const formData = {
      ...data,
      type: fileType,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }

    threads.createThread(forum_id, formData, (response) => {
      if (response.status === 201) {
        closeModal(true);
      }
    });
  };

  const editCourse = (data) => {
    const formData = {
      ...data,
      type: fileType,
    };
    if (isURL) {
      if (validateYoutubeUrl(data.youtubeurl)) {
        formData.file = null;
        formData.type = CONSTANTS.FILE_TYPES.VIDEO;
        setFileType(CONSTANTS.FILE_TYPES.VIDEO);
      } else {
        return;
      }
    } else {
      formData.youtubeurl = null;
    }
    threads.modifyThread(forum_id, currentNews?.id, formData, (response) => {
      if (response.status === 200) {
        closeModal(true);
      }
    });
  };

  const handleURLTogle = () => {
    setIsURL(!isURL);
    if (isURL) {
      setFileType(CONSTANTS.FILE_TYPES.VIDEO);
    }
  };

  const onFileChange = (onChange) => (data, fileData) => {
    if (fileData?.type) {
      switch (fileData.type) {
        case "video/mp4":
          setFileType(CONSTANTS.FILE_TYPES.VIDEO);
          break;
        case "audio/mpeg":
          setFileType(CONSTANTS.FILE_TYPES.AUDIO);
          break;
        case "application/pdf":
          setFileType(CONSTANTS.FILE_TYPES.DOCUMENT);
          break;
        case "image/png":
          setFileType(CONSTANTS.FILE_TYPES.IMAGE);
          break;
        case "image/jpeg":
          setFileType(CONSTANTS.FILE_TYPES.IMAGE);
          break;
        case "image/jpg":
          setFileType(CONSTANTS.FILE_TYPES.IMAGE);
          break;
        default:
          setFileType(undefined);
          break;
      }
    }
    onChange(data, fileData);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
      className="bigger-modal"
    >
      {!!currentNews ? (
        <>
          <h1>{t("modals.thread.title.edit_title")}</h1>
          <p>{t("modals.thread.subtitle.edit_subtitle")}</p>
        </>
      ) : (
        <>
          <h1>{t("modals.thread.title.create_title")}</h1>
          <p>{t("modals.thread.subtitle.create_subtitle")}</p>
        </>
      )}
      <Form
        onSubmit={!!currentNews ? editCourse : handleSubmit}
        className="pz-form"
      >
        {({ onChange, data: { title, body } }) => (
          <React.Fragment>
            <InputField
              label={`${t("modals.thread.title_input.title")} (${
                typeof title === "string" ? title.length : 0
              }/200)`}
              maxLength={200}
              name="title"
              onChange={onChange}
              defaultValue={!!currentNews ? currentNews?.title : ""}
              notRequired={!!currentNews}
            />

            <TextareaField
              label={`${t("modals.thread.body_input.title")} (${
                typeof body === "string" ? body.length : 0
              }/2000)`}
              maxLength={2000}
              name="body"
              onChange={onChange}
              defaultValue={!!currentNews ? currentNews?.body : ""}
              notRequired={!!currentNews}
            />

            <article className="pz-filter__checkbox">
              <label className="pz-filter__label">
                {t("modals.thread.youtube_label.title")}
              </label>
              <Toggle
                className="pz-form__file-input"
                type="checkbox"
                checked={isURL}
                onChange={handleURLTogle}
              />
            </article>

            {isURL ? (
              <InputField
                type="url"
                label={t("modals.thread.youtube_input.title")}
                name="youtubeurl"
                onChange={onChange}
                defaultValue={currentNews ? currentNews?.youtubeurl : ""}
                notRequired={!!currentNews}
                validations={[youtubeVal]}
              />
            ) : (
              <>
                <p>{t("modals.thread.file_label.title")}</p>
                <FileField
                  nameDefault={t("modals.thread.file_input.state")}
                  errMsg={t("modals.thread.file_input.error")}
                  label={t("modals.thread.file_input.title")}
                  name="file"
                  onChange={onFileChange(onChange)}
                  notRequired={true}
                />
              </>
            )}

            <small className="bold">
              {" "}
              {t("modals.thread.info_input.title")}
            </small>

            <Button type="submit" primary>
              {!!currentNews
                ? t("modals.thread.submit_button.edit_title")
                : t("modals.thread.submit_button.create_title")}
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(ModuleModal, "threads"));
