import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import categories_en from "./categories/en";
import categories_es from "./categories/es";
import navbar_es from "./navbar/es";
import navbar_en from "./navbar/en";
import courses_en from "./courses/en";
import courses_es from "./courses/es";
import course_en from "./course/en";
import course_es from "./course/es";
import lesson_en from "./lesson/en";
import lesson_es from "./lesson/es";
import profile_en from "./profile/en";
import profile_es from "./profile/es";
import register_en from "./register/en";
import register_es from "./register/es";
import login_en from "./login/en";
import login_es from "./login/es";
import footer_en from "./footer/en";
import footer_es from "./footer/es";
import comments_en from "./comments/en";
import comments_es from "./comments/es";
import reactions_en from "./reactions/en";
import reactions_es from "./reactions/es";
import modals_en from "./modals/en";
import modals_es from "./modals/es";
import landing_en from "./landing/en";
import landing_es from "./landing/es";
import others_en from "./others/en";
import others_es from "./others/es";
import notFound_en from "./404/en";
import notFound_es from "./404/es";
import admin_en from "./admin/en";
import admin_es from "./admin/es";
import forum_en from "./forum/en";
import forum_es from "./forum/es";

i18next

  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "es", // if you're using a language detector, do not define the lng option
    debug: false,
    resources: {
      en: {
        translation: {
          navbar: navbar_en,
          courses: courses_en,
          course: course_en,
          lesson: lesson_en,
          profile: profile_en,
          register: register_en,
          login: login_en,
          footer: footer_en,
          comments: comments_en,
          reactions: reactions_en,
          modals: modals_en,
          landing: landing_en,
          categories: categories_en,
          notFound: notFound_en,
          others: others_en,
          admin: admin_en,
          forum: forum_en,
        },
      },
      es: {
        translation: {
          navbar: navbar_es,
          courses: courses_es,
          course: course_es,
          lesson: lesson_es,
          profile: profile_es,
          register: register_es,
          login: login_es,
          footer: footer_es,
          comments: comments_es,
          reactions: reactions_es,
          modals: modals_es,
          landing: landing_es,
          categories: categories_es,
          notFound: notFound_es,
          others: others_es,
          admin: admin_es,
          forum: forum_es,
        },
      },
    },
  });
// initialized and ready to go!
// i18next is already initialized, because the translation resources where passed via init function
export default i18next;
