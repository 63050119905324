import i18n from "i18next";

export const notEmpty = {
  validation: (text) => text === "",
  message: () => i18n.t("others.input_valid.empty"),
};

export const notSpecialCharacters = {
  validation: (text) => /[^\w\s]/.test(text),
  message: () => i18n.t("others.input_valid.special"),
};

export const notSpaces = {
  validation: (text) => /\s/.test(text),
  message: () => i18n.t("others.input_valid.spaces"),
};

export const passVal = {
  validation: (text) => !/^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/.test(text),
  message: () => i18n.t("others.input_valid.password"),
};

export const youtubeVal = {
  validation: (text) =>
    !/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\b[^/]+\b\/)?(?:watch\?(?:.*&)?v=|shorts\/|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/.test(
      text
    ),
  message: () => i18n.t("others.input_valid.youtube"),
};

export const youtubeDocVal = {
  validation: (text) => {
    const youtubeRegex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\b[^/]+\b\/)?(?:watch\?(?:.*&)?v=|shorts\/|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
    const googleDocsRegex = /^(?:https?:\/\/)?(?:www\.)?docs\.google\.com\/.+$/;

    return !youtubeRegex.test(text) && !googleDocsRegex.test(text);
  },
  message: () => i18n.t("others.input_valid.youtube_or_docs"),
};
