const lesson_en = {
  uploaded: "Uploaded on",
  "pdf-button": "View PDF",
  "doc-button": "View Google doc",
  "doc-text":
    "We recommend viewing this content in Chrome. If it still doesn't display correctly, click the following button.",
  practical: {
    title: "Community Contributions",
    no_practical:
      "Knowledge, experiences, and resources shared by community members related to the lesson, such as examples, links, videos, audios, and documents.",
    no_practical_unlogged: "Log in to upload community contributions.",
    button: "Upload a Contribution",
  },
};
export default lesson_en;
