
const defaultState = {
    id: 0,
    is_visible: false,
    text: '',
    config: {
        type: 'default',
        timeout: 5000,
        label: null
    }
};

const actionTypes = {
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    HIDE_NOTIFICATION: 'HIDE_NOTIFICATION'
};

const actions = {
    showNotification: (text, customConfig = {}) => dispatch => {
        const config = {
            ...defaultState.config,
            ...customConfig
        };

        dispatch({ type: actionTypes.SHOW_NOTIFICATION, payload: { text, config } });

        setTimeout(() => {
            dispatch({ type: actionTypes.HIDE_NOTIFICATION });
        }, config.timeout);
    },

    hideNotification: () => dispatch => {
        dispatch({ type: actionTypes.HIDE_NOTIFICATION });
    }
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_NOTIFICATION:
            return {
                ...state,
                is_visible: true,
                ...action.payload,
            };
        case actionTypes.HIDE_NOTIFICATION:
            return {
                ...state,
                is_visible: false
            };

        default:
            return state;
    }
};

export default {
    defaultState,
    actionTypes,
    actions,
    reducer
};