import React, { useEffect, useRef } from "react";

const AdSense = () => {
  const adRef = useRef(null);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5923250675596968";
    script.async = true;
    script.crossOrigin = "anonymous";
    script.onload = () => {
      if (adRef.current) {
        try {
          //eslint-disable-next-line
          (adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("Adsense error:", e);
        }
      }
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Aquí puedes agregar tus bloques de anuncios de Google AdSense */}
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-5923250675596968"
        data-ad-slot="6125665547"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>

      {/* <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="-6t+ed+2i-1n-4w"
        data-ad-client="ca-pub-5923250675596968"
        data-ad-slot="5933297690"
      ></ins> */}
    </div>
  );
};

export default AdSense;
