import axios from "axios";

class AuthAPI {
  constructor(http, interceptor) {
    this.http = http;
    this.interceptor = interceptor;
    this.cancelToken = axios.CancelToken;

    this.http.interceptors.request.use(interceptor.requestHandler);

    this.http.interceptors.response.use(
      interceptor.successHandler,
      interceptor.errorHandler
    );
  }

  login = (data, successCallback, errorCallback) => {
    return this._getToken(data, (authenticated, response) => {
      if (authenticated) {
        this.setLoginInfo(response.data);
        if (successCallback) successCallback(response);
      } else {
        if (errorCallback) errorCallback(response);
      }
    });
  };

  facebook_login = (data, successCallback, errorCallback) => {
    return this._getTokenFacebook(data, (authenticated, response) => {
      if (authenticated) {
        this.setLoginInfo(response.data);
        if (successCallback) successCallback(response);
      } else {
        if (errorCallback) errorCallback(response);
      }
    });
  };

  forgot_password = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/forgot-password", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  change_password = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/change-password", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  change_password2 = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/change-password2", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  signup = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/signup", data, {
        cancelToken: source.token,
      })
      .then((response) => {
        successCallback(response);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return source;
  };

  deleteAccount = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/auth/delete/${data.id}`, data, {
        cancelToken: source.token,
      })
      .then((response) => {
        successCallback(response);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return source;
  };

  validate_token = (successCallback, errorCallback) => {
    return this._validate_token((authenticated, response) => {
      if (authenticated) {
        this.setLoginInfo(response.data);
        if (successCallback) successCallback(response);
      } else {
        this.logoutLocal();
        if (errorCallback) errorCallback(response);
      }
    });
  };

  setLoginInfo = (data) => {
    const { user, ...token } = data;
    const loginInfo = {
      token,
      user,
    };
    localStorage.setItem("app.data", JSON.stringify(loginInfo));
  };

  getLoginInfo = () => JSON.parse(localStorage.getItem("app.data"));

  getUser = () => {
    const loginInfo = this.getLoginInfo();
    return loginInfo ? loginInfo.user : null;
  };

  getToken = () => {
    const loginInfo = this.getLoginInfo();
    return loginInfo ? loginInfo.token : null;
  };

  isLoggedIn = () => {
    return !!this.getToken();
  };

  isLoggedInAsAdmin = () => {
    if (this.getUser()) return !!this.getUser().is_staff;
    else {
      return false;
    }
  };

  logoutLocal = () => {
    localStorage.clear();
  };

  logoutToken = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(
        "/auth/logout",
        {},
        {
          cancelToken: source.token,
          headers: {
            Authorization: this.interceptor.getAuthorizationHeader(),
          },
        }
      )
      .then((response) => {
        this.logoutLocal();
        successCallback(response);
      })
      .catch((error) => errorCallback(error));
    return source;
  };

  _getTokenFacebook = (data, callback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/facebook-login", data, {
        cancelToken: source.token,
      })
      .then((response) => callback(true, response))
      .catch((error) => callback(false, error));
  };

  _getToken = (data, callback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/token", data, {
        cancelToken: source.token,
      })
      .then((response) => callback(true, response))
      .catch((error) => callback(false, error));
    return source;
  };

  _validate_token = (callback) => {
    const source = this.cancelToken.source();
    this.http
      .get("/auth/validate-token", {
        cancelToken: source.token,
        headers: {
          Authorization: this.interceptor.getAuthorizationHeader(),
        },
      })
      .then((response) => callback(true, response))
      .catch((error) => callback(false, error));
    return source;
  };
}

export default AuthAPI;
