import React from "react";
import { Label } from "components/ui";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // Import the CSS file for styling

const TagField = ({
  label,
  name,
  tags = [], // Ensure tags is always an array
  onChange,
  placeholder,
  notRequired,
  defaultValue,
}) => {
  const [errorMessage] = React.useState(null);

  const handleTagsChange = (newTags) => {
    onChange({ [name]: newTags });
  };

  return (
    <section className="pz-form__field">
      <Label htmlFor={name}>{label}</Label>
      <TagsInput
        value={tags}
        onChange={handleTagsChange}
        inputProps={{ placeholder }}
        className="pz-form__tagsinput"
      />
      <span className="pz-form__error_message">{errorMessage}</span>
    </section>
  );
};

export default TagField;
