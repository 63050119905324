import React from 'react';
import { Modal } from "components/common";
import { Button, Form, InputField } from "components/ui";
import { connectReduxReducers } from "hocs";


const ActiveAccountModal = ({
    auth, is_visible, closeModal
}) => {

    const handleSubmit = (data) => {
        auth.activeAccount(data['code'], (response) => {
            if (response.status === 200) {
                closeModal(true);
            }
        });
    };

    return (
        <Modal
            is_visible={is_visible}
            closeModal={closeModal}
            exitOnOverlayClick
            contentClassName='grid grid-gap-20'
        >
            <h1>Activa tu cuenta</h1>
            <p>Para activar tu cuenta, utiliza el codigo de seguridad que recibiste en tu correo despues de que se haya verificado tu pago, si no has recibido el correo o no has hecho el pago, contacta nuestro equipo de cuentas al correo <a
                href="mailto:accounts@panzofi.com">accounts@panzofi.com</a></p>
            <Form onSubmit={handleSubmit} className='pz-form'>
                {({onChange}) => (
                    <React.Fragment>
                        <InputField label='Codigo de verificación' name='code' onChange={onChange} />
                        <Button type='submit' primary>Activar cuenta</Button>
                    </React.Fragment>
                )}
            </Form>
        </Modal>
    );
};

export default connectReduxReducers(ActiveAccountModal, 'auth');