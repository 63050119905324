import React from "react";
import { renderClasses } from "shared/common/functions";
import Image from "../image";

const UsernameBadge = ({
  user,
  className,
  invert,
  disabled,
  primary,
  secondary,
  tertiary,
  active,
  warning,
  info,
  alert,
}) => {
  return (
    <article
      className={renderClasses({
        "pz-username-badge": true,
        invert: invert,
        "disabled-text": disabled,
        "primary-text": primary,
        "secondary-text": secondary,
        "tertiary-text": tertiary,
        "active-text": active,
        "warning-text": warning,
        "info-text": info,
        "alert-text": alert,
        [className]: className !== "",
      })}
    >
      <span className="pz-username-badge__pic">
        {user?.profile?.picture ? (
          <Image
            src={
              user?.profile?.picture24
                ? user?.profile?.picture24
                : user?.profile?.picture
            }
          />
        ) : (
          <Image name="icons/male-avatar.svg" />
        )}
      </span>
    </article>
  );
};

UsernameBadge.defaultProps = {
  className: "",
  invert: false,
  disabled: false,
  primary: false,
  secondary: false,
  tertiary: false,
  active: false,
  warning: false,
  info: false,
  alert: false,
};

export default UsernameBadge;
