export const validateYoutubeUrl = (url) => {
  const youtubePatternShort =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  const shortMatch = url.match(youtubePatternShort);

  if (shortMatch) return true;
  return false;
};

export const validateGoogleDoc = (url) => {
  if (url.includes("google")) return true;
  return false;
};
