const lesson_es = {
  uploaded: "Subido en",
  "pdf-button": "Ver PDF",
  "doc-button": "Ver Google doc",
  "doc-text":
    "Te recomendamos ver este contenido en Chrome. Si todavía no se muestra bien, haz clic en el siguiente botón.",
  practical: {
    title: "Aportes de la comunidad",
    no_practical:
      "Conocimientos, experiencias y recursos compartidos por los miembros de la comunidad en relación con la lección, tales como ejemplos, enlaces, videos, audios y documentos.",
    no_practical_unlogged:
      "Inicia sesión para poder subir aportes de la comunidad.",
    button: "Subir un aporte",
  },
};
export default lesson_es;
