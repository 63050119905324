import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


const PasswordToggle = ({showPassword, onClick}) => (
    <section onClick={onClick} className='pz-form__icon'>
        {
            showPassword ?
                <FontAwesomeIcon
                    icon={faEyeSlash}
                    size='sm'
                />
                :
                <FontAwesomeIcon
                    icon={faEye}
                    size='sm'
                />
        }
    </section>
);

export default PasswordToggle