import { Modal } from "components/common";
import { Button } from "components/ui";
import React from "react";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DeleteTriviaModal = ({
  is_visible,
  closeModal,
  news,
  newsId,
  history,
}) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    deletePost();
  };

  const deletePost = (id = newsId) => {
    const deleteCourseCallback = ({ data, status }) => {
      if (status === 200) closeModal(true);
    };
    news.deleteAdminTrivia(id, deleteCourseCallback);
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick={true}
      contentClassName="grid grid-gap-20 grid-content-center grid-items-middle"
    >
      <h2>{t("modals.delete_news.title")}</h2>

      <section className="grid-gap-20 grid-columns grid-content-center grid-items-middle">
        <Button thin onClick={handleDelete}>
          {t("modals.delete_news.button")}
        </Button>
      </section>
    </Modal>
  );
};

export default withRouter(connectReduxReducers(DeleteTriviaModal, "news"));
