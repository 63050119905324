import { CONSTANTS } from "shared/constants";
import API from "core/api";


const defaultState = {
    categories: {},
    get_employees_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
    ORGANIZATION_REQUEST_STARTED: 'ORGANIZATION_REQUEST_STARTED',
    ORGANIZATION_REQUEST_FINISHED: 'ORGANIZATION_REQUEST_FINISHED',
};

const requestStarted = (payload, dispatch) => {
    dispatch({ type: actionTypes.ORGANIZATION_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
    dispatch({ type: actionTypes.ORGANIZATION_REQUEST_FINISHED, payload });
    callback(response);
};

const actions = {
    getEmployees: (callback = () => {}) => (dispatch) => {
        requestStarted({
            get_employees_request_status: CONSTANTS.FETCH.PROGRESS
        }, dispatch);

        const successCallback = (response) => requestFinished({
            get_employees_request_status: CONSTANTS.FETCH.SUCCESS
        }, dispatch, response, callback);

        const errorCallback = (response) => requestFinished({
            get_employees_request_status: CONSTANTS.FETCH.FAILED
        }, dispatch, response, callback);

        API.panzofi.getEmployees(successCallback, errorCallback);
    }
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_REQUEST_FINISHED:
            return {
                ...state,
                ...action.payload
            };

        case actionTypes.ORGANIZATION_REQUEST_STARTED:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export default {
    defaultState,
    actionTypes,
    actions,
    reducer
};