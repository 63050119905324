import React from 'react';
import { Navbar } from "components/panzofi";
import { renderClasses } from "shared/common/functions";


class NavbarPageLayout extends React.Component {
    render() {
        const { noBackground, appName, navbarOptions, children } = this.props;

        return (
            <main
                className={renderClasses({
                    'pz-navbar-page': true,
                    'no-background': noBackground
                })}
            >
                <Navbar appName={appName} options={navbarOptions} />
                {children}
            </main>
        );
    }
}

export default NavbarPageLayout;